import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import BOL from './BOL'
import { appendNotification } from '../notifications/notificationsSlice'
import { restApi } from '../Api'
import Graphql from '../Graphql'

const BOLFormFields = ['id', 'number', 'version', 'status', 'carrier_id', 'origin_id', 'pick_up_date', 'pick_up_time', 'pick_up_time_not_specified',
    'delivery_date', 'delivery_time', 'delivery_time_not_specified', 'truck_number', 'equipment_length', 'equipment_type',
    'dispatch_name', 'dispatch_phone', 'dispatch_email', 'driver_name', 'driver_phone', 'driver_email',
    'descriptions_list', 'rate_confirmation_notes', 'bol_notes', 'total_cost', 'total_miles', 'covered_on', 'in_transit_on', 'delivered_on',
    'costs_list', 'weight', 'bill_of_lading', 'rate_confirmation', 'proof_of_delivery']

const BOLDataQuery = (bolNumber) => {
  return Graphql.BOL({
    filters: {number: bolNumber},
    fields: [
      ...BOLFormFields,
      Graphql.relatedCarrier({fields: ['name']}),
      Graphql.relatedLoad({fields: ['id', 'load_boards']}),
      Graphql.relatedOrigin({fields: ['id', 'name', 'city', 'state', 'postal']}),
      Graphql.relatedReceivers({fields: ['id', 'name', 'address_1', 'address_2', 'phone', 'email', 'city', 'state', 'postal']}),
      Graphql.relatedSignedRateConfirmation({fields: ['id', 'name', 'vendor_recipients']}),
      Graphql.relatedTrackingUpdates({fields: ['id', 'bol_id', 'considered_delayed_at', 'delayed_response_detected', 'latitude', 'longitude', 'long_address', 'requested_at', 'responded_at', 'scheduled_at', 'short_address', 'status_string']})
    ]
  })
}

function EditBOL() {
  const { bolNumber } = useParams()
  const dispatch = useDispatch()
  const [carrierName, setCarrierName] = useState('')

  const setInitialBOLValues = ({setBOLNumber, setFormData, setOrigin, setSignedRateConfirmationData, setTrackingUpdates, loadBoardsPostedTo}, res) => {
    const BOLFormData = Graphql.transformData(Graphql.getValue(res, 'bol'), BOLFormFields)
    const origin = Graphql.getValue(res, 'bol.origin')
    const receiversFormData = Graphql.transformData({...Graphql.getValue(res, 'bol.receivers')})
    const load = Graphql.getValue(res, 'bol.load')

    setBOLNumber(BOLFormData.number)
    setOrigin(origin)
    setTrackingUpdates(Graphql.getValue(res, 'bol.tracking_updates'))
    setCarrierName(Graphql.getValue(res, 'bol.carrier.name'))
    setFormData({
      ...BOLFormData,
      receiversList: receiversFormData
    })
    setSignedRateConfirmationData(Graphql.transformData(Graphql.getValue(res, 'bol.signed_rate_confirmation')))

    if (load) {
      loadBoardsPostedTo.current = {[BOLFormData.id]: {load_boards: load.load_boards}}
    }
  }

  const handleBOLUpdateSuccess = ({setFormData}, res) => {
    setFormData(prevFormState => {
      return {
        ...prevFormState,
        version: res.data.version,
        billOfLading: res.data.bill_of_lading_name,
        coveredOn: res.data.covered_on,
        deliveredOn: res.data.delivered_on,
        inTransitOn: res.data.in_transit_on,
        proofOfDelivery: res.data.proof_of_delivery_name,
        rateConfirmation: res.data.rate_confirmation_name,
      }
    })
    dispatch(appendNotification({type: 'BOLUpdated', number: bolNumber}))
  }

  return (
    <BOL onMountFetchQuery={BOLDataQuery(bolNumber)}
          onMountFetchCallback={setInitialBOLValues}
          formRequest={restApi.updateBOL}
          handleSuccess={handleBOLUpdateSuccess}
          carrierName={carrierName}
    />
  )
}

export default EditBOL
