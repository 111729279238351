import _ from 'lodash'
import React, { useEffect } from 'react'
import { Alert } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { clearErrors } from './errorsSlice'

function HeaderErrors({appContainer}) {
  const dispatch = useDispatch()
  const errors = useSelector(state => state.errors.errors)

  useEffect(() => {
    if (errors.length > 0 && appContainer) {
      appContainer.current.scrollTo({ top: 0, behavior: 'smooth' })
    }
  })

  if (errors.length > 0) {
    return (
      <Alert id="header-errors" variant="danger" className={`rounded-0 ${appContainer ? 'mb-2 errors-inside-container' : ''}`}>
        <FontAwesomeIcon className="cursor-pointer float-end" icon='times' onClick={_ => dispatch(clearErrors())} />
        <ul>
          {
            _.map(errors, (error, i) => {
              return (
                <li key={i}>
                  {error}
                </li>
              )
            })
          }
        </ul>
      </Alert>
    )
  } else {
    return null
  }
}

export default HeaderErrors
