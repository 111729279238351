import React, { useEffect, useState } from 'react'
import { Button, Form, InputGroup, Modal, Spinner } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import GenericForm from '../forms/GenericForm'
import { restApi } from '../Api'
import { appendNotification } from '../notifications/notificationsSlice'

const ConfirmCarrierRemovalModal = ({isShowing, handleClose, carrierName, carrierId, companyId}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [confirmationText, setConfirmationText] = useState('')
  const canRemove = confirmationText === 'REMOVE'

  const handleCarrierRemovalSuccess = (res) => {
    handleClose()
    dispatch(appendNotification({type: 'CarrierAction', companyId: companyId, carrierId: res.data.id, carrierName: res.data.name, verb: 'Removed'}))
    navigate(`/company/${companyId}/carriers`)
  }

  useEffect(() => {
    if (!isShowing && confirmationText) {
      setConfirmationText('')
    }
  }, [isShowing]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal id="remove-carrier-modal" show={isShowing} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Carrier Removal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>Are you sure you want to remove the following carrier?</div>
        <div className="fw-bold mb-2">{carrierName}</div>
        <div className="mb-4">
          <i>Removing this carrier means you will not be able to assign it as a carrier for <b>new</b> BOLs. Total Control will also stop monitoring this carrier.</i>
        </div>
        <label htmlFor="confirmation-entry">Type "REMOVE" to confirm</label>
        <InputGroup>
          <Form.Control id="confirmation-entry" autoComplete="off" value={confirmationText} onChange={e => setConfirmationText(e.currentTarget.value)}/>
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        <GenericForm
          formRequest={restApi.removeCarrier}
          params={{id: carrierId}}
          handleSuccess={handleCarrierRemovalSuccess}
          formContent={
            ({isSubmitting}) => {
              return (
                <Button disabled={!canRemove} variant="danger" className="w-100" type="submit">
                  {isSubmitting ? <Spinner size="sm" animation="border" variant="light" /> : 'Remove'}
                </Button>
              )
            }
          }
          />
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmCarrierRemovalModal
