import { useEffect, useState } from 'react'

function useScreenWidth() {
  const [windowWidth, setWindowWidth] = useState(() => window.innerWidth)

  useEffect(() => {
    const setWindowWidthCallback = _ => setWindowWidth(window.innerWidth)

    window.addEventListener('resize', setWindowWidthCallback)

    return () => window.removeEventListener('resize', setWindowWidthCallback)
  }, [])

  return windowWidth
}

export default useScreenWidth
