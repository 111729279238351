import _ from 'lodash'
import { default as immutability_update } from 'immutability-helper'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { DragDropContext, Droppable } from '@hello-pangea/dnd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import InteractableTableColumn from './InteractableTableColumn'
import GenericSearch from '../search/GenericSearch'
import Paginator from './Paginator'
import Requestable from './Requestable'

function InteractableTable(props) {
  const {
    appliedFilterCount,
    currentPageNumber,
    draggableType,
    filterableValues,
    filteredItems,
    filteredValues,
    pageSize,
    rightOfSearchContent,
    searchable,
    searchFilters,
    searchIndex,
    searchPlaceholder,
    searchRefinement,
    selectedColumns,
    setCurrentPageNumber,
    setSearchHits,
    setSearchRefinement,
    setSelectedColumns,
    settings,
  } = props

  const currentDraggable = useRef({})
  const [draggingOverIndex, setDraggingOverIndex] = useState()
  const viewableItems = useMemo(() => {
    return filteredItems.slice((currentPageNumber - 1) * pageSize, currentPageNumber * pageSize)
  }, [filteredItems, currentPageNumber, pageSize])

  const onDragUpdate = useCallback((update) => {
    if (!update.destination) return
    setDraggingOverIndex(update.destination.index)
  }, [])

  const onDragEnd = useCallback((result) => {
    // dropped outside the list
    if (!result.destination) return

    setSelectedColumns(
      immutability_update(selectedColumns, {
        $splice: [
          [result.source.index, 1],
          [result.destination.index, 0, selectedColumns[result.source.index]],
        ],
      })
    )
    setDraggingOverIndex()
  }, [selectedColumns]) // eslint-disable-line react-hooks/exhaustive-deps

  const flexFactorMultiplier = useMemo(() => {
    const flexFactorSum = _.sumBy(selectedColumns, column => column.flexFactor ? column.flexFactor : 1)
    return flexFactorSum < 1 ? (1 / flexFactorSum).toFixed(2) : 1
  }, [selectedColumns.length]) // eslint-disable-line react-hooks/exhaustive-deps

  const gridTemplateColumns = useMemo(() => {
    return _.reduce(selectedColumns, (result, column) => {
      return result += `minmax(min-content, ${column.flexFactor ? (column.flexFactor * flexFactorMultiplier).toFixed(2) : 1}fr) `
    }, '')
  }, [selectedColumns]) // eslint-disable-line react-hooks/exhaustive-deps

  const renderTableColumn = (column, index, dragStartIndex) => {
    const addColumn = (selectableColumn) => {
      setSelectedColumns(
        immutability_update(selectedColumns, {
          $splice: [
            [index + 1, 0, selectableColumn]
          ],
        })
      )
    }

    const removeColumn = () => {
      setSelectedColumns(
        immutability_update(selectedColumns, {
          $splice: [
            [index, 1]
          ],
        })
      )
    }

    return (
      <InteractableTableColumn
        key={`${column.name}-${index}-column`}
        index={index}
        currentDraggable={currentDraggable}
        dragStartIndex={dragStartIndex}
        draggingOverIndex={_.isInteger(draggingOverIndex) ? draggingOverIndex : dragStartIndex}
        id={column.name}
        columnItems={viewableItems}
        addColumn={addColumn}
        removeColumn={removeColumn}
        isFirstColumn={index === 0}
        isLastColumn={index === selectedColumns.length - 1}
        {...column}
        {...props}
        filterableValues={filterableValues[column.name]}
        filteredValues={filteredValues[column.name]}
      />
    )
  }

  const renderTable = () => {
    return (
      <DragDropContext onDragUpdate={onDragUpdate} onDragEnd={onDragEnd}>
        <Droppable droppableId={draggableType} direction="horizontal">
          {(provided, snapshot) => {
            const dragStartIndex = parseInt(snapshot.draggingFromThisWith && snapshot.draggingFromThisWith.slice(0,2))
            return (
              <div className="interactable-table"
                   ref={provided.innerRef}
                   {...provided.droppableProps}
                   style={{gridTemplateColumns: gridTemplateColumns}}
              >
                {
                  _.map(selectedColumns, (column, columnIndex) => renderTableColumn(column, columnIndex, dragStartIndex))
                }
                {viewableItems.length === 0 &&
                  <div className="no-table-results w-100 d-flex flex-column text-center align-items-center justify-content-center rounded-bottom" style={{gridArea: `${appliedFilterCount > 0 ? 3 : 2} / 1 / span 6 / -1`}}>
                    <div>
                      <FontAwesomeIcon size="6x" icon="fill-drip" />
                    </div>
                    <h4 className="mt-4 fw-bold">
                      No Results
                    </h4>
                  </div>
                }
                {
                  snapshot.draggingFromThisWith &&
                  <div
                    ref={el => currentDraggable.current = el}
                    style={{gridArea: `${appliedFilterCount > 0 ? 2 : 1} / ${dragStartIndex + 1} / span 1 / span 1`}}>
                    {provided.placeholder}
                  </div>
                }
              </div>
            )
          }}
        </Droppable>
      </DragDropContext>
    )
  }

  return (
    <Requestable
      withoutLoading
      render={({_isLoading, _performRequest}) => {
        return (
          <>
            {searchable &&
              <Row className="mb-2">
                <Col xs={4}>
                  <GenericSearch
                    currentRefinement={searchRefinement}
                    filters={searchFilters}
                    indexName={searchIndex}
                    placeholder={searchPlaceholder}
                    setCurrentRefinement={setSearchRefinement}
                    setSearchHits={setSearchHits}
                  />
                </Col>
                {rightOfSearchContent}
              </Row>
            }
            <div className="d-flex justify-content-end mb-2">
              {settings}
            </div>
            {renderTable()}
            <div className="d-flex mt-3 justify-content-center">
              <Paginator totalItems={filteredItems.length} perPage={pageSize} currentPage={currentPageNumber} setCurrentPage={setCurrentPageNumber} />
            </div>
          </>
        )
      }}
    />
  )
}

export default InteractableTable
