import _ from 'lodash'

function getValue(res, path) {
  return (res, path) => {
    return _.get(res.data.data, path)
  }
}

function transformData() {
  return (obj, keysToSelect) => {
    return _.transform(obj, (result, value, key) => {
      if (keysToSelect !== undefined && !_.includes(keysToSelect, key)) {
        // Don't add to result
      } else {
        try {
          result[_.camelCase(key)] = JSON.parse(value)
        } catch {
          result[_.camelCase(key)] = value
        }
      }
    })
  }
}

function buildQuery(queryBody) {
  return {'query': `query {${queryBody}}`}
}

function buildQueryParams(filtersObj) {
  if (_.size(filtersObj) === 0) return ''

  return `(
    ${
      _.join(
        _.map(filtersObj, (v, k) => {
          if (typeof(v) === "object") {
            return `${k}: ${JSON.stringify(v)}`
          }

          return `${k}: ${v}`
        }),
        ', '
      )
    }
  )`
}

function baseResource(resourceName) {
  return ({fields, filters = {}}) => {
    return (buildQuery(
      `${resourceName} ${buildQueryParams(filters)} ${fields ? '{' + _.toString(fields) + '}' : ''}`
    ))
  }
}

function relatedResource(resourceName) {
  return ({fields, filters = {}}) => {
    return `${resourceName} ${buildQueryParams(filters)} {${_.toString(fields)}}`
  }
}

const Graphql = {
  getValue: getValue(),
  transformData: transformData(),

  BOL: baseResource('bol'),
  BOLPreviousVersion: baseResource('previous_bol_version'),
  BOLPreviousVersions: baseResource('bol_histories'),
  carrier: baseResource('carrier'),
  company: baseResource('company'),
  currentUser: baseResource('current_user'),
  fundingSourceOptions: baseResource('funding_source_options'),
  insuranceConstraints: baseResource('company_insurance_constraints'),
  load: baseResource('load'),
  origin: baseResource('origin'),
  receiver: baseResource('receiver'),
  receiverBOLs: baseResource('receiver_bols'),

  relatedBOL: relatedResource('bol'),
  relatedBOLs: relatedResource('bols'),
  relatedCarrier: relatedResource('carrier'),
  relatedCarriers: relatedResource('carriers'),
  relatedContacts: relatedResource('contacts'),
  relatedCreator: relatedResource('created_by'),
  relatedDwollaCustomer: relatedResource('dwolla_customer'),
  relatedEsignables: relatedResource('esignable_documents'),
  relatedInvoices: relatedResource('invoices'),
  relatedLoad: relatedResource('load'),
  relatedLocation: relatedResource('location_of_origin'),
  relatedLocations: relatedResource('locations'),
  relatedNotifications: relatedResource('notifications'),
  relatedOrigin: relatedResource('origin'),
  relatedOrigins: relatedResource('origins'),
  relatedQueryMatches: relatedResource('matches'),
  relatedReceivers: relatedResource('receivers'),
  relatedSettings: relatedResource('settings'),
  relatedSignedCarrierAgreement: relatedResource('signed_carrier_agreement'),
  relatedSignedRateConfirmation: relatedResource('signed_rate_confirmation'),
  relatedUsers: relatedResource('users'),
  relatedTrackingUpdates: relatedResource('tracking_updates'),
  relatedTruckQuery: relatedResource('truck_query'),

  // Custom Queries
  currentDatRates: baseResource('current_dat_rates'),
  datLaneSummary: baseResource('dat_lane_summary'),
  datEquipmentQueryOptions: buildQuery('dat_equipment_query_options'),
  datLoadPostOptions: buildQuery('dat_load_post_options'),
}

export default Graphql
