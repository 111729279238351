import React, { useState, Fragment } from 'react'
import { Button, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useNavigate, useParams } from 'react-router-dom'

import GenericForm from '../forms/GenericForm'
import Requestable from '../generic/Requestable'
import useScreenWidth from '../utils/useScreenWidth'
import { restApi } from '../Api'

function Login(props) {
  const [formData, setFormData] = useState({})
  const { postLoginPath } = useParams()
  const screenWidth = useScreenWidth()
  const navigate = useNavigate()

  const handleLoginSuccess = (res) => {
    postLoginPath ? navigate(postLoginPath) : navigate(`/company/${res.data.company_id}/dashboard`)
  }

  const handleAlreadyLoggedIn = (res) => {
    if (res.data.authenticated) {
      handleLoginSuccess(res)
    }
  }

  const formContent = ({handleFormChange}) => {
    return (
      <Fragment>
          <div className="my-auto">
            <div className="input-container mb-2">
              <FontAwesomeIcon id="login-icon" icon='user' />
              <Form.Control required type="text" id="usernameOrEmail" className="d-inline" placeholder="Username or Email" onChange={e=>handleFormChange(setFormData, e)} />
            </div>
            <div className="input-container">
              <FontAwesomeIcon id="login-icon" icon='unlock' />
              <Form.Control required type="password" id="password" className="d-inline" placeholder="Password" onChange={e=>handleFormChange(setFormData, e)} />
            </div>
            <Form.Text className="float-end mb-xl-0 mb-3">
              <Link to='/forgot_password' style={{color: '#6c757d'}}>Forgot Password?</Link>
            </Form.Text>
          </div>
          <Button className="mt-auto mb-3 w-100 indigo-button" type="submit">
            Login
          </Button>
      </Fragment>
    )
  }

  const renderForLargeDevice = () => {
    return (
      <div className="w-100 h-100 d-flex flex-column">
        <h3 className="mt-4">Login</h3>
        <GenericForm className="d-flex flex-column h-100" formContent={formContent} formRequest={restApi.loginUser} params={formData} handleSuccess={handleLoginSuccess} />
      </div>
    )
  }

  const renderForSmallDevice = () => {
    return (
      <div className="mx-auto w-100">
        <GenericForm formContent={formContent} formRequest={restApi.loginUser} params={formData} handleSuccess={handleLoginSuccess} />
      </div>
    )
  }

  return (
    <Requestable
      withoutLoading
      onMountFetch={restApi.checkAuth({}, handleAlreadyLoggedIn)}
      render={() => {
        return screenWidth > 1199 ? renderForLargeDevice() : renderForSmallDevice()
      }}
    />
  )
}

export default Login
