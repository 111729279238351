import _ from 'lodash'
import React, { Fragment, useState } from 'react'
import { Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'

import BOLColumns from '../bol/BOLRefinableColumns'
import CardsList from '../generic/CardsList'
import Graphql from '../Graphql'
import { graphqlApi } from '../Api'
import InteractableTable from '../generic/InteractableTable'
import RefinableList from '../generic/RefinableList'
import RefinableListSettingsModal from '../generic/RefinableListSettingsModal'
import Requestable from '../generic/Requestable'
import useScreenWidth from '../utils/useScreenWidth'
import { MINIMUM_SCREEN_WIDTH_FOR_TABLE_VIEW } from '../generic/interactableTableConstraints'

const BOLFields = ['number', 'status', 'origin_name', 'pick_up_date', 'pick_up_time', 'delivery_date', 'delivery_time', 'delivered_on', 'truck_number', 'total_cost']

function OriginBOLs({companyId, originId}) {
  const [bols, setBOLs] = useState([])
  const preferencedColumns  = useSelector(state => _.get(state.user.currentUser, 'settings.originBolsColumns', []))
  const screenWidth = useScreenWidth()

  const selectableColumns = {
    number: BOLColumns.number(companyId),
    status: BOLColumns.status,
    carrier: BOLColumns.carrier(companyId),
    totalStops: BOLColumns.totalStops,
    finalReceiver: BOLColumns.finalReceiver(companyId),
    pickUpDate: BOLColumns.pickUpDate,
    pickUpTime: BOLColumns.pickUpTime,
    deliveryDate: BOLColumns.deliveryDate,
    deliveryTime: BOLColumns.deliveryTime,
    deliveredOn: BOLColumns.deliveredOn,
    truckNumber: BOLColumns.truckNumber,
    totalCost: BOLColumns.totalCost
  }

  const originBOLsQuery = Graphql.origin({
                             filters: {id: originId},
                             fields: [
                               Graphql.relatedBOLs({
                                 fields: [
                                   ...BOLFields,
                                   Graphql.relatedCarrier({fields: ['id', 'name']}),
                                   Graphql.relatedReceivers({fields: ['id', 'name']}),
                                 ]
                               })
                             ]
                           })

  const originBOLsLoadSuccess = (res) => {
    setBOLs(Graphql.getValue(res, 'origin.bols'))
  }

  const renderTable = () => {
    return (
      <RefinableList
        Renderer={InteractableTable}
        initialSelectedColumns={_.values(_.pick(selectableColumns, preferencedColumns))}
        initialSortedColumn="BOL #"
        selectableColumns={_.values(selectableColumns)}
        draggableType="bolColumn"
        searchable
        searchPlaceholder="Search Origin's BOLs"
        searchIndex={`tc_bols_${process.env.NODE_ENV}`}
        searchFilters={`company_id = ${companyId} AND origin_id = ${originId}`}
        items={bols}
        settings={<RefinableListSettingsModal selectableColumns={selectableColumns} userPreferencedColumns={preferencedColumns} refinableListSettingsName='originBolsColumns' />}
        pageSize={25}
      />
    )
  }

  const renderCards = () => {
    return (
      <RefinableList
        Renderer={CardsList}
        initialSelectedColumns={_.values(_.pick(selectableColumns, _.without(preferencedColumns, 'number')))}
        initialSortedColumn="BOL #"
        selectableColumns={_.values(selectableColumns)}
        cardHeaderColumn={selectableColumns.number}
        items={bols}
        searchable
        searchPlaceholder="Search Origin's BOLs"
        searchIndex={`tc_bols_${process.env.NODE_ENV}`}
        searchFilters={`company_id = ${companyId} AND origin_id = ${originId}`}
        settings={<RefinableListSettingsModal selectableColumns={selectableColumns} userPreferencedColumns={preferencedColumns} refinableListSettingsName='originBolsColumns' />}
        pageSize={10}
      />
    )
  }

  return (
    <Requestable
      onMountFetch={graphqlApi.execute(originBOLsQuery, originBOLsLoadSuccess)}
      render={() => {
          if (preferencedColumns.length > 0) {
            return (
              <Fragment>
                <Row className="form-sub-header mt-2 g-0">
                  <h4>Origin BOLs</h4>
                </Row>
                {screenWidth > MINIMUM_SCREEN_WIDTH_FOR_TABLE_VIEW ? renderTable() : renderCards()}
              </Fragment>
            )
          }
        }
      }
    />
  )
}

export default OriginBOLs
