import _ from 'lodash'
import React, { Fragment, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CardsList from '../generic/CardsList'
import Graphql from '../Graphql'
import { graphqlApi } from '../Api'
import InteractableTable from '../generic/InteractableTable'
import ReceiverColumns from './ReceiverRefinableColumns'
import RefinableList from '../generic/RefinableList'
import RefinableListSettingsModal from '../generic/RefinableListSettingsModal'
import Requestable from '../generic/Requestable'
import useScreenWidth from '../utils/useScreenWidth'
import { MINIMUM_SCREEN_WIDTH_FOR_TABLE_VIEW } from '../generic/interactableTableConstraints'

const receiverFields = ['id', 'name', 'phone', 'city', 'state', 'postal', 'address_1', 'address_2']

function Receivers(props) {
  const { companyId } = useParams()
  const [receivers, setReceivers] = useState([])
  const preferencedColumns  = useSelector(state => state.user.currentUser.settings.receiversColumns)
  const screenWidth = useScreenWidth()

  const selectableColumns = {
    name: ReceiverColumns.name(companyId),
    phone: ReceiverColumns.phone,
    city: ReceiverColumns.city,
    state: ReceiverColumns.state,
    postal: ReceiverColumns.postal,
    address1: ReceiverColumns.address1,
    address2: ReceiverColumns.address2
  }

  const companyReceiversQuery = Graphql.company({
                                 fields: [
                                   'id',
                                   Graphql.relatedReceivers({
                                     fields: receiverFields
                                   })
                                 ]
                               })

  const companyReceiversLoadSuccess = (res) => {
    setReceivers(
      _.map(Graphql.getValue(res, 'company.receivers'), (receiver) => {
        return Graphql.transformData(receiver)
      })
    )
  }

  const newReceiverButton = <Link to={`/company/${companyId}/locations/receivers/create`}>
    <Button className="indigo-button w-100">
      <FontAwesomeIcon className="plus me-2" icon='plus' />
      Create New Receiver
    </Button>
  </Link>

  const renderTable = () => {
    return (
      <RefinableList
        Renderer={InteractableTable}
        initialSelectedColumns={_.values(_.pick(selectableColumns, preferencedColumns))}
        initialSortedColumn="Name"
        selectableColumns={_.values(selectableColumns)}
        draggableType="receiverColumn"
        searchable
        searchPlaceholder="Search Receivers"
        searchIndex={`tc_receivers_${process.env.NODE_ENV}`}
        searchFilters={`company_id = ${companyId}`}
        items={receivers}
        settings={<RefinableListSettingsModal selectableColumns={selectableColumns} userPreferencedColumns={preferencedColumns} refinableListSettingsName='receiversColumns' />}
        pageSize={25}
        rightOfSearchContent={<Col xs={2}>{newReceiverButton}</Col>}
      />
    )
  }

  const renderCards = () => {
    return (
      <RefinableList
        Renderer={CardsList}
        cardHeaderColumn={selectableColumns.name}
        initialSelectedColumns={_.values(_.pick(selectableColumns, _.without(preferencedColumns, 'name')))}
        initialSortedColumn="Name"
        selectableColumns={_.values(selectableColumns)}
        searchable
        searchPlaceholder="Search Receivers"
        searchIndex={`tc_receivers_${process.env.NODE_ENV}`}
        searchFilters={`company_id = ${companyId}`}
        items={receivers}
        settings={<RefinableListSettingsModal selectableColumns={selectableColumns} userPreferencedColumns={preferencedColumns} refinableListSettingsName='receiversColumns' />}
        pageSize={10}
        belowSearchContent={newReceiverButton}
      />
    )
  }

  return (
    <Requestable
      onMountFetch={graphqlApi.execute(companyReceiversQuery, companyReceiversLoadSuccess)}
      render={() => {
          return (
            <Fragment>
              <Row className="form-sub-header g-0">
                <h5>Receivers</h5>
              </Row>
              {screenWidth > MINIMUM_SCREEN_WIDTH_FOR_TABLE_VIEW ? renderTable() : renderCards()}
            </Fragment>
          )
        }
      }
    />
  )
}

export default Receivers
