import _ from 'lodash'
import React, { Fragment, useState } from 'react'
import { Badge, OverlayTrigger, FloatingLabel, Form, Button, ListGroup, InputGroup, Spinner } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AppendableFieldsGroup from '../forms/AppendableFieldsGroup'
import Requestable from '../generic/Requestable'
import { restApi } from '../Api'
import { setConfirmableAction } from '../generic/confirmableActionSlice'
import { appendNotification } from '../notifications/notificationsSlice'
import { reviewableSignersHtml } from '../esign/utils'

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

const areEqual = (prevProps, nextProps) => {
  if (nextProps.esignable) {
    return (prevProps.esignableId === nextProps.esignableId &&
            prevProps.esignableIsProcessing === nextProps.esignableIsProcessing &&
            prevProps.esignableIsComplete === nextProps.esignableIsComplete &&
            prevProps.currentUserHasNotSigned === nextProps.currentUserHasNotSigned &&
            prevProps.anyNonUsersHaveNotSigned === nextProps.anyNonUsersHaveNotSigned &&
            prevProps.signedDocumentIsDownloading === nextProps.signedDocumentIsDownloading &&
            prevProps.labelExtra === nextProps.labelExtra)
  } else {
    return (prevProps.fileExists === nextProps.fileExists &&
            prevProps.fileSource === nextProps.fileSource &&
            prevProps.filePath === nextProps.filePath &&
            prevProps.labelExtra === nextProps.labelExtra)
  }
}

const FormDocument = React.memo(({id, relatedObjId, relatedObjName, fileExists, filePath, fileLabel, fileSource, handleDocumentChange, disabled=false, emailable=false, esignable=false, externalSigners=[], esignableId, setEsignableId, esignableIsProcessing, esignableIsComplete, currentUserHasNotSigned, anyNonUsersHaveNotSigned, getEsignableLink, sendEsignRequestReminder, signedDocumentIsDownloading, esignersStatusPopover, esignResetter, labelExtra}) => {
  const [emailExpanded, setEmailExpanded] = useState(false)
  const [initiatingEsign, setInitiatingEsign] = useState(false)
  const [emailTo, setEmailTo] = useState('')
  const [recipientsList, setRecipientsList] = useState({recipients: {}})
  const dispatch = useDispatch()

  const generateFileDescriptor = () => {
    return fileSource instanceof File ? fileSource.name
        : esignableIsComplete ? `Signed ${fileLabel}`
        : fileLabel
  }

  const handleEmailDocumentResponse = (res) => {
    if (res.data.errors) {
      dispatch(appendNotification({type: 'EmailFailed'}))
    } else {
      dispatch(appendNotification({type: 'EmailSent'}))
    }
  }

  const emailDocument = (performRequest) => {
    if (emailRegex.test(emailTo)) {
      const params = {recipient: emailTo, klass: relatedObjName, document_owner_id: relatedObjId, document: `${esignableIsComplete ? 'signed_' : ''}${_.snakeCase(id)}`}
      dispatch(setConfirmableAction({performAction: performRequest, action: restApi.emailDocument(params, handleEmailDocumentResponse), details: {title: `Emailing Document: ${generateFileDescriptor()}`, confirmationBody: <div>Confirm the recipient's email address: <b>{emailTo}</b></div>}}))
    }
  }

  const handleInitiateEsignResponse = (res) => {
    setEsignableId(res.data.id)
    setInitiatingEsign(false)
  }

  const initiateEsign = (performRequest) => {
    const hasValidRecipientsList = _.every(externalSigners, signerRole => {
      let signerEmail = _.get(recipientsList.recipients[signerRole], 'email')

      return _.get(recipientsList.recipients[signerRole], 'first_name') &&
        _.get(recipientsList.recipients[signerRole], 'last_name') &&
        signerEmail && emailRegex.test(signerEmail)
    })

    if (hasValidRecipientsList) {
      const params = {recipients: recipientsList.recipients, klass: relatedObjName, id: relatedObjId, document: _.snakeCase(id)}
      dispatch(setConfirmableAction({performAction: performRequest, action: restApi.initiateEsign(params, handleInitiateEsignResponse), details: {title: `Initiating E-sign for: ${generateFileDescriptor()}`, confirmationBody: <div><div>Confirm Signer(s) Details:</div>{reviewableSignersHtml(externalSigners, recipientsList.recipients)}</div>}}))
    }
  }

  return (
    <Form.Group controlId={id}>
      {
        !disabled &&
        <Form.Label>{fileLabel}</Form.Label>
      }
      <ListGroup className="form-document-container">
        { disabled &&
          <ListGroup.Item className="form-document-list-group-item">
              <Form.Control disabled className="d-flex" as='div'>
                {generateFileDescriptor()}
                {
                  esignable && esignableId && !esignableIsProcessing && !esignableIsComplete ?
                    <Requestable
                      withoutLoading
                      withErrorHandling
                      render={({performRequest, isLoading}) => {
                        return (
                          <Fragment>
                            <FontAwesomeIcon icon='undo-alt' className="ms-auto my-auto me-2 cursor-pointer" onClick={_ => {esignResetter(performRequest, esignableId, setEsignableId)}} />
                            <OverlayTrigger trigger="hover" overlay={esignersStatusPopover} placement="left">
                              <Badge bg="light" className={`my-auto ${isLoading ? 'loading' : ''}`}>
                                {isLoading ? <Spinner size="sm" animation="border" variant="secondary" /> : 'E-Signers Status'}
                              </Badge>
                            </OverlayTrigger>
                          </Fragment>
                        )
                      }}
                    />
                  : labelExtra && labelExtra(esignableId)
                }
              </Form.Control>
          </ListGroup.Item>
        }
        {
          fileExists && !signedDocumentIsDownloading &&
          <ListGroup.Item className="form-document-list-group-item">
            <ListGroup horizontal="md" className="form-document-actions">
              <ListGroup.Item action active={false} target="_blank" rel="noopener noreferrer" href={filePath}>
                View
              </ListGroup.Item>
              {
                emailable &&
                  <ListGroup.Item action type="button" active={false} onClick={_=>setEmailExpanded(!emailExpanded)}>
                    Email
                  </ListGroup.Item>
              }
              {
                esignable && !esignableId && // Esignable document hasn't been created yet
                  <ListGroup.Item action type="button" active={false} onClick={_=>setInitiatingEsign(!initiatingEsign)}>
                    Initiate E-sign
                  </ListGroup.Item>
              }
              {
                esignable && esignableIsProcessing &&
                  <ListGroup.Item action type="button" active={false}>
                    E-Sign Processing<Spinner className="ms-2" size="sm" animation="border" variant="light" />
                  </ListGroup.Item>
              }
              {
                esignable && esignableId && currentUserHasNotSigned &&
                  <Requestable
                    withoutLoading
                    render={({performRequest, isLoading}) => {
                      return (
                        <ListGroup.Item action type="button" active={false} onClick={_ => getEsignableLink(performRequest)}>
                          {
                            isLoading ? <Spinner size="sm" animation="border" variant="light" />
                              : 'E-Sign'
                          }
                        </ListGroup.Item>
                      )
                    }}
                  />
              }
              {
                esignable && esignableId && anyNonUsersHaveNotSigned &&
                  <Requestable
                    withoutLoading
                    render={({performRequest, isLoading}) => {
                      return (
                        <ListGroup.Item action type="button" active={false} onClick={_ => sendEsignRequestReminder(performRequest, generateFileDescriptor())}>
                          {
                            isLoading ? <Spinner size="sm" animation="border" variant="light" />
                              : 'Request Signature'
                          }
                        </ListGroup.Item>
                      )
                    }}
                  />
              }
            </ListGroup>
          </ListGroup.Item>
        }
        {
          signedDocumentIsDownloading &&
          <ListGroup.Item className="form-document-list-group-item">
            <ListGroup className="form-document-actions">
              <ListGroup.Item>
                Downloading Signed Document
                <Spinner className="ms-2" size="sm" animation="border" variant="light" />
              </ListGroup.Item>
            </ListGroup>
          </ListGroup.Item>
        }
        {
          !disabled &&
          <ListGroup.Item className="form-document-list-group-item">
            <Form.Control type="file" onChange={handleDocumentChange} />
          </ListGroup.Item>
        }
      </ListGroup>
      {
        emailExpanded &&
        <Requestable
          withoutLoading
          render={({performRequest, isLoading}) => {
            return (
              <InputGroup className="mt-2">
                <InputGroup.Text>@</InputGroup.Text>
                <Form.Control isInvalid={!_.isEmpty(emailTo) && !emailRegex.test(emailTo)} type="email" placeholder="Email this document to someone." value={emailTo} onChange={e=>setEmailTo(e.target.value)}/>
                  <Button className="outline-indigo" onClick={_ => emailDocument(performRequest)} style={{borderTopRightRadius: '0.25rem', borderBottomRightRadius: '0.25rem'}}>
                    {
                      isLoading ? <Spinner size="sm" animation="border" className="spinner-indigo" />
                        : 'Send'
                    }
                  </Button>
                <Form.Control.Feedback type="invalid">Invalid email address</Form.Control.Feedback>
              </InputGroup>
            )
          }}
        />
      }
      {
        initiatingEsign &&
        <Requestable
          withoutLoading
          render={({performRequest, isLoading}) => {
            return (
              <Fragment>
                {
                  _.map(externalSigners, signerRole => {
                    return (
                      <AppendableFieldsGroup
                        key={signerRole}
                        dataKey={signerRole}
                        groupName={'recipients'}
                        group={recipientsList.recipients}
                        setState={setRecipientsList}
                        renderContent={
                          ({getAppendableValue, setAppendableValue}) => {
                            return (
                              <Fragment>
                                <div className="mt-3 mb-1 w-100 d-flex">
                                  <FontAwesomeIcon icon="file-signature" size="lg" className="me-2" />{`${_.startCase(signerRole)} Signer`}
                                </div>
                                <FloatingLabel controlId="first_name" label="Signer First Name">
                                  <Form.Control placeholder="Signer First Name" isInvalid={_.isEmpty(getAppendableValue('first_name'))} type="text" value={getAppendableValue('first_name')} onChange={e=>setAppendableValue(e)} />
                                  <Form.Control.Feedback type="invalid">First Name is required</Form.Control.Feedback>
                                </FloatingLabel>
                                <FloatingLabel controlId="last_name" label="Signer Last Name">
                                  <Form.Control placeholder="Signer Last Name" isInvalid={_.isEmpty(getAppendableValue('last_name'))} type="text" value={getAppendableValue('last_name')} onChange={e=>setAppendableValue(e)} />
                                  <Form.Control.Feedback type="invalid">Last Name is required</Form.Control.Feedback>
                                </FloatingLabel>
                                <FloatingLabel controlId="email" label="Signer Email">
                                  <Form.Control placeholder="Signer Email" isInvalid={_.isEmpty(getAppendableValue('email')) || !emailRegex.test(getAppendableValue('email'))} type="text" value={getAppendableValue('email')} onChange={e=>setAppendableValue(e)} />
                                  <Form.Control.Feedback type="invalid">A valid email is required</Form.Control.Feedback>
                                </FloatingLabel>
                              </Fragment>
                            )
                          }
                        }
                      />
                    )
                  })
                }
                <div className="mt-2 w-100">
                  <Button className="indigo-button w-100" onClick={_ => initiateEsign(performRequest)}>
                    {
                      isLoading ? <Spinner size="sm" animation="border" variant="light" />
                        : 'Create Esignable Document'
                    }
                  </Button>
                </div>
              </Fragment>
            )
          }}
        />
      }
    </Form.Group>
  )
}, areEqual)

export default FormDocument
