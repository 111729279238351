import React, { useState, Fragment } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import GenericForm from '../forms/GenericForm'
import useScreenWidth from '../utils/useScreenWidth'
import { restApi } from '../Api'

function ForgotPassword(props) {
  const [email, setEmail] = useState('')
  const screenWidth = useScreenWidth()

  const handleForgotPasswordSuccess = (res) => {
    setEmail('')
  }

  const formContent = ({handleFormChange, validated, isSubmitting}) => {
    return (
      <Fragment>
        <div className="my-xl-auto mb-4">
          <div className="input-container">
            <FontAwesomeIcon icon='at' />
            <Form.Control required type="email" className="d-inline" placeholder="Email a reset link" value={email} onChange={e=>setEmail(e.target.value)} />
          </div>
          {validated && <div className="text-danger mt-1" style={{'font-size': '80%'}}>Email is required</div>}
        </div>
        <div className="mt-auto mb-3">
          <Button className="w-100 indigo-button" type="submit">
            {isSubmitting ? <Spinner size="sm" animation="border" variant="light" /> : 'Submit'}
          </Button>
        </div>
      </Fragment>
    )
  }

  const renderForLargeDevice = () => {
    return (
      <div className="w-100 h-100 d-flex flex-column">
        <h3 className="mt-4">Forgot Password?</h3>
        <GenericForm className="d-flex flex-column h-100" formContent={formContent} formRequest={restApi.forgotPassword} params={{email: email}} handleSuccess={handleForgotPasswordSuccess} />
      </div>
    )
  }

  const renderForSmallDevice = () => {
    return (
      <div className="mx-auto w-100">
        <h3>Forgot Password?</h3>
        <GenericForm formContent={formContent} formRequest={restApi.forgotPassword} params={{email: email}} handleSuccess={handleForgotPasswordSuccess} />
      </div>
    )
  }

  return screenWidth > 1199 ? renderForLargeDevice() : renderForSmallDevice()
}

export default ForgotPassword
