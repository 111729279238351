import _ from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'

const RefinableColumns = {
  name: (companyId) => {
    return (
      {
        name: 'Name',
        accessor: tableItem => tableItem.name,
        sortable: true,
        converter: txt => _.lowerCase(txt),
        displayer: tableItem => <Link to={`/company/${companyId}/locations/receivers/edit/${tableItem.id || tableItem.objectID}`}>{tableItem.name}</Link>,
        asCardHeader: ({children, tableItem}) => <Link to={`/company/${companyId}/locations/receivers/edit/${tableItem.id || tableItem.objectID}`} className="card-header">{children}</Link>
      }
    )
  },
  phone: {
    name: 'Phone',
    accessor: tableItem => tableItem.phone,
  },
  city: {
    name: 'City',
    accessor: tableItem => tableItem.city,
  },
  state: {
    name: 'State',
    accessor: tableItem => tableItem.state,
    sortable: true,
    filterable: true,
    flexFactor: 0.25
  },
  postal: {
    name: 'Postal',
    accessor: tableItem => tableItem.postal,
    flexFactor: 0.25
  },
  address1: {
    name: 'Address 1',
    accessor: tableItem => tableItem.address1,
  },
  address2: {
    name: 'Address 2',
    accessor: tableItem => tableItem.address2,
  }
}

export default RefinableColumns
