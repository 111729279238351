import _ from 'lodash'
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { Container, Row, Spinner } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { clearErrors, setErrors } from '../errorHandling/errorsSlice'

function Requestable({onMountFetch, onMountMultiFetch, render, requestOnly, withoutLoading, withErrorHandling}) {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const pendingRequests = useRef([])
  const [isLoading, setIsLoading] = useState(false)

  // Handle one or many fetches upon mounting
  useEffect(() => {
    if (_.isFunction(onMountFetch)) {
      performRequest(onMountFetch)
    }

    if (_.isArray(onMountMultiFetch)) {
      _.forEach(onMountMultiFetch, (reqFunc) => {
        performRequest(reqFunc)
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Cleanup pending requests prior to unmounting
  useEffect(() => {
    return () => {
      cancelPendingRequests()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const performRequest = useCallback((requestFunc) => {
    setIsLoading(true)
    if (withErrorHandling) dispatch(clearErrors())
    return requestFunc(pendingRequests.current, handleRequestSuccess, handleRequestError)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const cancelPendingRequests = () => {
    _.forEach(pendingRequests.current, (reqIdWithCancelToken) => {
      reqIdWithCancelToken.cancelToken('Request cancelled via unmount')
    })
  }

  const handleRequestSuccess = (onSuccessCallback, res) => {
    if (_.isEmpty(pendingRequests.current)) setIsLoading(false)
    if (withErrorHandling && _.get(res, 'data.errors')) {
      dispatch(setErrors(res.data.errors))
    } else {
      return onSuccessCallback(res)
    }
  }

  const handleRequestError = error => {
    if (process.env.NODE_ENV === 'development') console.log(error)

    if (_.get(error, 'response.status') === 302) {
      navigate(`/referer/${encodeURIComponent(pathname)}`, { replace: true })
      return
    }

    if (_.isEmpty(pendingRequests.current)) setIsLoading(false)
    navigate('/500')
  }

  const renderIsLoading = () => {
    return (
      <Container><Row className="justify-content-center"><Spinner animation="border" variant="secondary" /></Row></Container>
    )
  }

  return (
    <Fragment>
      {
        !withoutLoading && isLoading ? renderIsLoading()
          : !requestOnly && render({
              performRequest: performRequest,
              isLoading: isLoading
            })
      }
    </Fragment>
  )
}

export default Requestable
