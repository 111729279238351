import React, { useRef } from 'react'
import { Container } from 'react-bootstrap'
import { Navigate, Route, Routes } from "react-router-dom";

import AppContextProvider from './AppContextProvider';
import BOLs from '../bol/BOLs'
import Carriers from '../carriers/Carriers'
import ConfirmActionModal from '../generic/ConfirmActionModal'
import Dashboard from './Dashboard'
import EditBOL from '../bol/EditBOL'
import EditOrigin from '../origins/EditOrigin'
import EditReceiver from '../receivers/EditReceiver'
import Esignables from '../esign/Esignables'
import EsignOverlay from '../esign/EsignOverlay'
import HeaderErrors from '../errorHandling/HeaderErrors'
import Invoices from '../invoices/Invoices'
import LoadPostings from '../loads/LoadPostings'
import NewBOL from '../bol/NewBOL'
import NewOrigin from '../origins/NewOrigin'
import NewReceiver from '../receivers/NewReceiver'
import OnboardCarrier from '../carriers/OnboardCarrier'
import Origins from '../origins/Origins'
import PopupNotificationsList from '../notifications/PopupNotificationsList'
import RateLookup from '../loads/RateLookup'
import Receivers from '../receivers/Receivers'
import Reports from '../reports/Reports'
import Settings from '../settings/Settings'
import MainNav from './MainNav'
import ViewCarrier from '../carriers/ViewCarrier'

function TotalControl(props) {
  const appContainerRef = useRef()

  return (
    <AppContextProvider>
      <div id="app-inner">
        <MainNav />
        <div id="tc-container" ref={appContainerRef} className="w-100">
          <Container fluid>
            <PopupNotificationsList />
            <HeaderErrors appContainer={appContainerRef} />
            <ConfirmActionModal />
            <EsignOverlay />
            <Routes>
              <Route path='carriers'>
                <Route index element={<Carriers />} />
                <Route path='onboard' element={<OnboardCarrier />} />
                <Route path='view/:carrierId' element={<ViewCarrier />} />
              </Route>
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='esignables' element={<Esignables />} />
              <Route path='invoices' element={<Invoices />} />
              <Route path='locations'>
                <Route path='origins'>
                  <Route index element={<Origins />} />
                  <Route path='create' element={<NewOrigin />} />
                  <Route path='edit/:originId' element={<EditOrigin />} />
                </Route>
                <Route path='receivers'>
                  <Route index element={<Receivers />} />
                  <Route path='create' element={<NewReceiver />} />
                  <Route path='edit/:receiverId' element={<EditReceiver />} />
                </Route>
              </Route>
              <Route path='reports/*' element={<Reports />} />
              <Route path='settings/*' element={<Settings />} />
              <Route path='shipments'>
                <Route path='create-bol' element={<NewBOL />} />
                <Route path='bols' element={<BOLs columnsSettingsKey={'allBolsColumns'} relevantStatuses={["NEW", "OPEN", "COVERED", "IN_TRANSIT", "DELIVERED"]} tableDescriptor={'BOLs'} pageSize={25} />} />
                <Route path='edit-bol/:bolNumber/*' element={<EditBOL />} />
                <Route path='loads' element={<LoadPostings />} />
                <Route path='lookup_rates' element={<RateLookup />} />
              </Route>
              <Route path='*' element={<Navigate to='/404' replace />} />
            </Routes>
          </Container>
        </div>
      </div>
    </AppContextProvider>
  )
}

export default TotalControl
