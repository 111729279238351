import _ from 'lodash'
import moment from 'moment'
import React, { Fragment, useMemo, useRef, useState } from 'react'
import { Button, Col, Collapse, FloatingLabel, Form, OverlayTrigger, Popover, Row, Spinner } from 'react-bootstrap'
import { Area, AreaChart, ErrorBar, Label, Legend, ReferenceArea, ReferenceLine, ResponsiveContainer, Text, Tooltip, XAxis, YAxis} from 'recharts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { convertParamsToSnakecase } from '../Api'
import { decimalCurrencyFormatter, integerCurrencyFormatter } from '../utils/CurrencyFormatter'
import EquipmentTypes from '../bol/EquipmentTypes'
import Graphql from '../Graphql'
import { graphqlApi } from '../Api'
import GenericForm from '../forms/GenericForm'
import StateOptions from '../utils/StateOptions'

const TIMEFRAME_MAP = {
  '3_DAYS': 5,
  '7_DAYS': 7,
  '15_DAYS': 15,
  '30_DAYS': 30,
  '60_DAYS': 60,
  '90_DAYS': 90,
  '180_DAYS': 180,
  '365_DAYS': 365,
  '1_MONTH': 30,
}

// Used when translating DAT rate response equipment type
const EQUIPMENT_TYPE_MAP = {
  FLATBED: 'FLATBED',
  REEFER: 'REFRIGERATED',
  VAN: 'DRY_VAN'
}
const BAR_SPACING = 5
const fromYearMonthMin = moment().subtract(13, 'months').format('YYYY-MM')
const toYearMonthMax = moment().subtract(1, 'months').format('YYYY-MM')

function RateLookup({initOriginCity, initOriginState, initOriginPostal, initDestinationCity, initDestinationState, initDestinationPostal, initEquipment}) {
  const [formData, setFormData] = useState({
    equipment: EQUIPMENT_TYPE_MAP[initEquipment] || initEquipment || 'DRY_VAN',
    lookupHistoric: false,
    lookupCurrent: true,
    lookupForecasts: false,
    fromYearMonth: fromYearMonthMin,
    toYearMonth: toYearMonthMax,
    forecastPeriod: '8DAYS',
    originCity: initOriginCity || '',
    originState: initOriginState || '',
    originPostal: initOriginPostal || '',
    destinationCity: initDestinationCity || '',
    destinationState: initDestinationState || '',
    destinationPostal: initDestinationPostal || ''})

  const [rateData, setRateData] = useState({})
  const [currentDataKey, setCurrentDataKey] = useState('avg')
  const chartRef = useRef()
  const [refAreaStart, setRefAreaStart] = useState(null)
  const [refAreaEnd, setRefAreaEnd] = useState(null)
  const [zoomDomain, setZoomDomain] = useState({})

  const dataForGraph = useMemo(() => {
    if (!_.isEmpty(rateData)) {
      let result = {}
      let xAxisOffset = 0
      let todaysOffset = undefined

      if (rateData.historic) {
        _.forIn(rateData.historic.response.rates, historicRateMonth => {
          const startDate = new Date(historicRateMonth.year, historicRateMonth.month - 1, 1)
          const endDate = new Date(historicRateMonth.year, historicRateMonth.month, 0)
          const middle = Math.ceil((startDate.getDate() + endDate.getDate()) / 2)

          for (let date = _.clone(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
            result[xAxisOffset] = {
              x: xAxisOffset,
              lowHistoric: historicRateMonth.lowestRatePerTrip + historicRateMonth.averageFuelSurchargePerTrip,
              avgHistoric: historicRateMonth.averageRatePerTrip + historicRateMonth.averageFuelSurchargePerTrip,
              highHistoric: historicRateMonth.highestRatePerTrip + historicRateMonth.averageFuelSurchargePerTrip,
              historicStartDate: startDate,
              historicEndDate: endDate,
              date: _.clone(date),
              middle: date.getDate() === middle
            }

            xAxisOffset++
          }

          _.times(BAR_SPACING, () => {
            result[xAxisOffset] = {x: xAxisOffset, offset: true}
            xAxisOffset++
          })
        })
      }

      if (rateData.current) {
        const currentRateData = rateData.current.response.rate
        const currentRateDaysCovered = TIMEFRAME_MAP[rateData.current.response.timeframe]
        const currentRateStart = moment().subtract(currentRateDaysCovered, 'days').startOf('day')
        const currentRateEnd = moment().subtract(1, 'days').endOf('day')
        const latestDateSeen = _.get(result, `${xAxisOffset - (BAR_SPACING + 1)}.date`)

        // Current rate data overlaps with historic
        if (latestDateSeen && currentRateStart <= latestDateSeen) {
          let relevantBarSpacing = 0
          _.every(_.reverse(_.values(result)), ({date, offset}) => {
            if (offset) relevantBarSpacing++
            return !currentRateStart.isSame(date)
          })
          let xAxisOffsetForOverlap = xAxisOffset - relevantBarSpacing - (Math.abs(currentRateStart.diff(latestDateSeen, 'days')) + 1)
          const middle = xAxisOffsetForOverlap + Math.floor((currentRateDaysCovered + relevantBarSpacing) / 2)

          for (let date = currentRateStart.toDate(); date <= currentRateEnd.toDate(); date.setDate(date.getDate() + 1)) {
            if (result[xAxisOffsetForOverlap]) {
              result[xAxisOffsetForOverlap] = {
                ...result[xAxisOffsetForOverlap],
                lowCurrent: currentRateData.lowestRatePerTrip + currentRateData.averageFuelSurchargePerTrip,
                avgCurrent: currentRateData.averageRatePerTrip + currentRateData.averageFuelSurchargePerTrip,
                highCurrent: currentRateData.highestRatePerTrip + currentRateData.averageFuelSurchargePerTrip,
                startDate: currentRateStart.toDate(),
                endDate: currentRateEnd.toDate(),
                currentMiddle: middle === xAxisOffsetForOverlap,
                end: currentRateEnd.startOf('day').isSame(date)
              }

              if (result[xAxisOffsetForOverlap].offset) {
                date.setDate(date.getDate() - 1)
              }
            } else {
              result[xAxisOffsetForOverlap] = {
                x: xAxisOffsetForOverlap,
                lowCurrent: currentRateData.lowestRatePerTrip + currentRateData.averageFuelSurchargePerTrip,
                avgCurrent: currentRateData.averageRatePerTrip + currentRateData.averageFuelSurchargePerTrip,
                highCurrent: currentRateData.highestRatePerTrip + currentRateData.averageFuelSurchargePerTrip,
                startDate: currentRateStart.toDate(),
                endDate: currentRateEnd.toDate(),
                date: _.clone(date),
                currentMiddle: middle === xAxisOffsetForOverlap,
                end: currentRateEnd.startOf('day').isSame(date)
              }
            }

            xAxisOffsetForOverlap++
          }

          xAxisOffset = xAxisOffsetForOverlap
        } else {
          const middle = xAxisOffset + Math.floor(currentRateDaysCovered / 2)
          for (let date = currentRateStart.toDate(); date <= currentRateEnd.toDate(); date.setDate(date.getDate() + 1)) {
            result[xAxisOffset] = {
              x: xAxisOffset,
              lowCurrent: currentRateData.lowestRatePerTrip + currentRateData.averageFuelSurchargePerTrip,
              avgCurrent: currentRateData.averageRatePerTrip + currentRateData.averageFuelSurchargePerTrip,
              highCurrent: currentRateData.highestRatePerTrip + currentRateData.averageFuelSurchargePerTrip,
              startDate: currentRateStart.toDate(),
              endDate: currentRateEnd.toDate(),
              date: _.clone(date),
              currentMiddle: middle === xAxisOffset,
              end: currentRateEnd.startOf('day').isSame(date)
            }

            xAxisOffset++
          }
        }

        todaysOffset = xAxisOffset - 1
      }

      if (rateData.forecast) {
        _.times(BAR_SPACING, () => {
          result[xAxisOffset] = {x: xAxisOffset, offset: true}
          xAxisOffset++
        })

        _.forIn(rateData.forecast.forecasts, forecast => {
          const barWidth = formData.forecastPeriod === '52WEEKS' ? 7 : BAR_SPACING
          const barMiddle = Math.floor(barWidth / 2)
          _.times(barWidth, (i) => {
            result[xAxisOffset] = {
              x: xAxisOffset++,
              lowForecasted: forecast.lowPerTrip,
              avgForecasted: forecast.ratePerTrip,
              highForecasted: forecast.highPerTrip,
              date: moment(forecast.date).toDate(),
              middle: i === barMiddle
            }
          })

          _.times(BAR_SPACING, () => {
            result[xAxisOffset] = {x: xAxisOffset, offset: true}
            xAxisOffset++
          })
        })
      }

      return [_.sortBy(_.values(result), 'x'), todaysOffset]
    }

    return [null, null]
  }, [rateData]) // eslint-disable-line react-hooks/exhaustive-deps
  const [graphData, todaysOffset] = dataForGraph

  const handleLaneRateDataFetchSuccess = res => {
    const { current_rate, historic_rate, forecasted_rate } = Graphql.transformData(res.data.data).datLaneSummary

    setRateData({
      ...(current_rate && {
        current: {response: current_rate.response, request: current_rate.request}
      }),
      ...(historic_rate && {
        historic: {response: historic_rate.response, request: historic_rate.request}
      }),
      ...(forecasted_rate && {
        forecast: forecasted_rate
      })
    })
  }

  const renderTickAndErrorBar = ({index, x, y}) => {
    const data = graphData[index + (zoomDomain.start || 0)]
    const yScale = chartRef.current ? chartRef.current.getYScaleByAxisId('0') : null

    if (data.middle && data.avgHistoric) {
      const errorData = [{x: x, value: data.avgHistoric, errorVal: [data.avgHistoric - data.lowHistoric, data.highHistoric - data.avgHistoric]}]
      return (
        <Fragment>
            <line orientation="bottom" stroke="#666" fill="none" x1={x} y1={y - 9} x2={x} y2={y + 5} />
            <Text x={x} y={y + 15} textAnchor="middle">{`${data.date.toLocaleString('default', { month: 'long' })}`}</Text>
            <Text x={x} y={y + 28} textAnchor="middle">{`${data.date.toLocaleString('default', { year: 'numeric' })}`}</Text>
            {currentDataKey === 'avg' && yScale && <ErrorBar width={6} strokeWidth={2} stroke="green" layout='horizontal' data={errorData} dataPointFormatter={_ => errorData[0]} yAxis={{ scale: val => yScale(val) }} />}
        </Fragment>
      )
    }

    if (currentDataKey === 'avg' && data.currentMiddle && yScale) {
      const errorData = [{x: x, value: data.avgCurrent, errorVal: [data.avgCurrent - data.lowCurrent, data.highCurrent - data.avgCurrent]}]
      return <ErrorBar width={6} strokeWidth={2} stroke="purple" layout='horizontal' data={errorData} dataPointFormatter={_ => errorData[0]} yAxis={{ scale: val => yScale(val) }} />
    }

    if (data.end) {
      return (
        <Fragment>
          <line orientation="bottom" stroke="#666" fill="none" x1={x - 1} y1={y - 9} x2={x - 1} y2={y + 5} />
          <Text angle={-70} x={x - 5} y={y + 52} textAnchor="middle">{`Past ${TIMEFRAME_MAP[rateData.current.response.timeframe]} Days`}</Text>
        </Fragment>
      )
    }

    if (data.middle && data.avgForecasted) {
      const errorData = [{x: x, value: data.avgForecasted, errorVal: [data.avgForecasted - data.lowForecasted, data.highForecasted - data.avgForecasted]}]
      return (
        <Fragment>
            <line orientation="bottom" stroke="#666" fill="none" x1={x} y1={y - 9} x2={x} y2={y + 5} />
            <Text angle={-70} x={x + 4} y={y + 30} textAnchor="middle">{`${data.date.toLocaleString('default', { month: 'short' })} ${data.date.getDate()}`}</Text>
            {currentDataKey === 'avg' && yScale && <ErrorBar width={3} strokeWidth={2} stroke="red" layout='horizontal' data={errorData} dataPointFormatter={_ => errorData[0]} yAxis={{ scale: val => yScale(val) }} />}
        </Fragment>
      )
    }
  }

  const renderTooltip = ({ active, label, payload, ...props }) => {
    if (active && payload && payload.length) {
      return (
        <div className="rate-lookup-graph-tooltip">
          {
            _.map(payload, data => {
              if (data.name === 'Historic Rate') {
                return (
                  <Fragment key={`historic-tt-${label}`}>
                    <p className="label" style={{ color: data.color }}>{data.name}</p>
                    <p className="intro">{data.payload.historicStartDate.toLocaleDateString("en-US")} - {data.payload.historicEndDate.toLocaleDateString("en-US")}</p>
                    <p className="desc">
                      High: {decimalCurrencyFormatter.format(data.payload.highHistoric)}<br/>
                      Average: {decimalCurrencyFormatter.format(data.payload.avgHistoric)}<br/>
                      Low: {decimalCurrencyFormatter.format(data.payload.lowHistoric)}
                    </p>
                  </Fragment>
                )
              } else if (data.name === 'Recent Rate') {
                return (
                  <Fragment key={`current-tt-${label}`}>
                    <p className="label" style={{ color: data.color }}>{data.name} (Past {TIMEFRAME_MAP[rateData.current.response.timeframe]} Days)</p>
                    <p className="intro">{data.payload.startDate.toLocaleDateString("en-US")} - {data.payload.endDate.toLocaleDateString("en-US")}</p>
                    <p className="desc">
                      High: {decimalCurrencyFormatter.format(data.payload.highCurrent)}<br/>
                      Average: {decimalCurrencyFormatter.format(data.payload.avgCurrent)}<br/>
                      Low: {decimalCurrencyFormatter.format(data.payload.lowCurrent)}<br/>
                      Reports: {rateData.current.response.reports}
                    </p>
                  </Fragment>
                )
              } else if (data.name === 'Forecasted Rate') {
                return (
                  <Fragment key={`forecast-tt-${label}`}>
                    <p className="label" style={{ color: data.color }}>{data.name}</p>
                    <p className="intro">{data.payload.date.toLocaleDateString("en-US")}</p>
                    <p className="desc">
                      High: {decimalCurrencyFormatter.format(data.payload.highForecasted)}<br/>
                      Average: {decimalCurrencyFormatter.format(data.payload.avgForecasted)}<br/>
                      Low: {decimalCurrencyFormatter.format(data.payload.lowForecasted)}
                    </p>
                  </Fragment>
                )
              }
            })
          }
        </div>
      )
    }

    return null
  }

  const yMax = useMemo(() => {
    if (!_.isEmpty(rateData)) {
      let maxes = []

      if (zoomDomain.start) {
        const slice = _.slice(graphData, zoomDomain.start, zoomDomain.end + 1)
        _.forIn(slice, entry => maxes.push(_.max([entry.highHistoric, entry.highCurrent, entry.highForecasted])))
      } else {
        if (rateData.historic) {
          const historicMax = _.maxBy(rateData.historic.response.rates, 'highestRatePerTrip')
          maxes.push(historicMax.highestRatePerTrip + historicMax.averageFuelSurchargePerTrip)
        }
        if (rateData.current) maxes.push(rateData.current.response.rate.highestRatePerTrip + rateData.current.response.rate.averageFuelSurchargePerTrip)
        if (rateData.forecast) maxes.push(_.maxBy(rateData.forecast.forecasts, 'highPerTrip').highPerTrip)
      }

      return Math.ceil(_.max(maxes) / 100) * 100
    }
  }, [rateData, zoomDomain]) // eslint-disable-line react-hooks/exhaustive-deps

  const zoom = () => {
    if (refAreaStart && refAreaEnd && refAreaStart !== refAreaEnd) {
      setZoomDomain({start: _.max([_.min([refAreaStart, refAreaEnd]), 0]), end: _.max([refAreaStart, refAreaEnd])})
    }
    setRefAreaStart(null)
    setRefAreaEnd(null)
  }

  return (
    <Fragment>
      <Row className="form-sub-header g-0">
        <h5>Lookup Rates</h5>
      </Row>
      <GenericForm
        formRequest={graphqlApi.execute}
        params={Graphql.datLaneSummary({filters: _.mapValues(convertParamsToSnakecase(formData), value => JSON.stringify(value))})}
        handleSuccess={handleLaneRateDataFetchSuccess}
        formContent={({handleFormChange, isSubmitting, submitForm}) => {
          return (
            <Fragment>
              <Row>
                <Col md={4}>
                  <FloatingLabel controlId="originCity" label="Origin City">
                    <Form.Control required type="text" placeholder="Origin City" value={formData.originCity || ''} onChange={e=>handleFormChange(setFormData, e)} />
                    <Form.Control.Feedback type="invalid">Origin City is required</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col md={4}>
                  <FloatingLabel controlId='originState' label="Origin State">
                    <Form.Select required value={formData.originState} onChange={e=>handleFormChange(setFormData, e)} >
                      <StateOptions />
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">Origin State is required</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel controlId="originPostal" label="Origin Postal">
                    <Form.Control type="text" placeholder="Origin Postal" value={formData.originPostal || ''} onChange={e=>handleFormChange(setFormData, e)} />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <FloatingLabel controlId="destinationCity" label="Destination City">
                    <Form.Control required type="text" placeholder="Destination City" value={formData.destinationCity || ''} onChange={e=>handleFormChange(setFormData, e)} />
                    <Form.Control.Feedback type="invalid">Destination City is required</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col md={4}>
                  <FloatingLabel controlId='destinationState' label="Destination State">
                    <Form.Select required value={formData.destinationState} onChange={e=>handleFormChange(setFormData, e)} >
                      <StateOptions />
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">Destination State is required</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel controlId="destinationPostal" label="Destination Postal">
                    <Form.Control type="text" placeholder="Destination Postal" value={formData.destinationPostal || ''} onChange={e=>handleFormChange(setFormData, e)} />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <FloatingLabel controlId="equipment" label="Equipment Type">
                    <Form.Select required value={formData.equipment || 'DRY_VAN'} onChange={e=>handleFormChange(setFormData, e)} >
                      <EquipmentTypes />
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col className="gen-form">
                  <Form.Switch id="lookupHistoric" label='Get Historic Data' checked={formData.lookupHistoric} onChange={e=>handleFormChange(setFormData, e)} />
                  <Form.Switch id="lookupCurrent" label='Get Recent Data' checked={formData.lookupCurrent} onChange={e=>handleFormChange(setFormData, e)} />
                  <Form.Switch id="lookupForecasts" label='Get Forecast Data' checked={formData.lookupForecasts} onChange={e=>handleFormChange(setFormData, e)} />
                </Col>
              </Row>
              <Collapse in={formData.lookupHistoric}>
                <Row>
                  <Col xl={4} sm={6}>
                    <FloatingLabel controlId="fromYearMonth" label='Historic "From" Month & Year'>
                      <Form.Control type="month" min={fromYearMonthMin} max={formData.toYearMonth} value={formData.fromYearMonth || ''} onChange={e=>handleFormChange(setFormData, e)} />
                    </FloatingLabel>
                  </Col>
                  <Col xl={4} sm={6}>
                    <FloatingLabel controlId="toYearMonth" label='Historic "To" Month & Year'>
                      <Form.Control type="month" min={formData.fromYearMonth} max={toYearMonthMax} value={formData.toYearMonth || ''} onChange={e=>handleFormChange(setFormData, e)} />
                    </FloatingLabel>
                  </Col>
                </Row>
              </Collapse>
              <Collapse in={formData.lookupForecasts}>
                <Row>
                  <Col md={4}>
                    <FloatingLabel controlId="forecastPeriod" label="Forecast Period">
                      <Form.Select required value={formData.forecastPeriod} onChange={e=>handleFormChange(setFormData, e)} >
                        <option value='8DAYS'>8 Days</option>
                        <option value='52WEEKS'>One Year</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">Forecast Period is required</Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                </Row>
              </Collapse>
              <Button disabled={!(formData.lookupHistoric || formData.lookupCurrent || formData.lookupForecasts)} onClick={() => submitForm()} className="indigo-button w-100">
                {
                  isSubmitting ? <Spinner size="sm" animation="border" variant="light" /> : 'Lookup'
                }
              </Button>
            </Fragment>
          )
        }}
      />
      {
        graphData &&
          <Fragment>
            <Row className="justify-content-end mt-4">
              <Col>
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Popover id='rate-source-popover'>
                      <Popover.Header as="h3">
                        Rate Source Info
                        <div className="small text-muted">
                          Description of <i>actual</i> geographic locations used when gathering rate data
                        </div>
                      </Popover.Header>
                      <Popover.Body>
                        {
                          rateData.historic &&
                            <Fragment>
                              <div>
                                <b>Historic Data:</b>
                              </div>
                              <div>
                                Origin Market Name: {rateData.historic.response.originName}
                              </div>
                              <div>
                                Origin Market Geography Type: {_.capitalize(_.startCase(rateData.historic.response.originGeoType))}
                              </div>
                              <div>
                                Destination Market Name: {rateData.historic.response.destinationName}
                              </div>
                              <div>
                                Destination Market Geography Type: {_.capitalize(_.startCase(rateData.historic.response.destinationGeoType))}
                              </div>
                              <div>
                                Trip Mileage: {rateData.historic.response.mileage}
                              </div>
                            </Fragment>
                        }
                        {
                          rateData.current &&
                            <Fragment>
                              <div>
                                <b>Recent Data:</b>
                              </div>
                              <div>
                                Origin Market Name: {rateData.current.response.originName}
                              </div>
                              <div>
                                Origin Market Geography Type: {_.capitalize(_.startCase(rateData.current.response.originGeoType))}
                              </div>
                              <div>
                                Destination Market Name: {rateData.current.response.destinationName}
                              </div>
                              <div>
                                Destination Market Geography Type: {_.capitalize(_.startCase(rateData.current.response.destinationGeoType))}
                              </div>
                              <div>
                                Trip Mileage: {rateData.current.response.mileage}
                              </div>
                            </Fragment>
                        }
                        {
                          rateData.forecast &&
                            <Fragment>
                              <div>
                                <b>Forecast Data:</b>
                              </div>
                              <div>
                                Origin Market Name: {rateData.forecast.originGeoDescription}
                              </div>
                              <div>
                                Destination Market Name: {rateData.forecast.destinationGeoDescription}
                              </div>
                              <div>
                                Trip Mileage: {rateData.forecast.mileage}
                              </div>
                            </Fragment>
                        }
                      </Popover.Body>
                    </Popover>
                  }>
                  <span>Rate Source Info<FontAwesomeIcon className="ms-1" icon={['far', 'question-circle']} /></span>
                </OverlayTrigger>
              </Col>
              <Col className="d-flex justify-content-end">
                <Button className="indigo-button" onClick={_ => setZoomDomain({}) }>
                  Zoom Out
                </Button>
                <Button className="indigo-button ms-2" onClick={_ => currentDataKey === 'avg' || currentDataKey === 'high' ? setCurrentDataKey('low') : setCurrentDataKey('avg') }>
                  Show {currentDataKey === 'avg' || currentDataKey === 'high' ? 'Lows Only' : 'Average'}
                </Button>
                <Button className="indigo-button ms-2" onClick={_ => currentDataKey === 'avg' || currentDataKey === 'low' ? setCurrentDataKey('high') : setCurrentDataKey('avg') }>
                  Show {currentDataKey === 'avg' || currentDataKey === 'low' ? 'Highs Only' : 'Average'}
                </Button>
              </Col>
            </Row>
            <ResponsiveContainer width="100%" height={690} className="mt-4">
              <AreaChart data={zoomDomain.start ? _.slice(graphData, zoomDomain.start, zoomDomain.end + 1) : graphData}
                         margin={{
                           bottom: 75
                         }}
                         ref={chartRef}
                         onMouseDown={e => e && setRefAreaStart(e.activeLabel)}
                         onMouseMove={e => refAreaStart && setRefAreaEnd(e.activeLabel)}
                         onMouseUp={_ => zoom()}>
                <XAxis dataKey='x' type='number' domain={[zoomDomain.start ? graphData[zoomDomain.start].x : graphData[0].x - 10, zoomDomain.end ? graphData[zoomDomain.end].x : _.last(graphData).x + (rateData.forecast ? 5 : 10)]} tickLine={false} tick={renderTickAndErrorBar} interval={0} scale='sequential' />
                <YAxis yAxisId='0' domain={[0, yMax]} ticks={_.concat(_.range(0, yMax, 500), yMax)} type='number' tickFormatter={(amount) => integerCurrencyFormatter.format(amount)} />
                <Area name="Historic Rate" type="monotone" dataKey={`${currentDataKey}Historic`} stroke="#6EDB8F" fillOpacity={0.7} fill="#6EDB8F" />
                <Area name="Recent Rate" type="monotone" dataKey={`${currentDataKey}Current`} stroke="#4545a1" fillOpacity={0.7} fill="#4545a1" />
                <Area name="Forecasted Rate" type="monotone" dataKey={`${currentDataKey}Forecasted`} stroke="#FA7D92" fillOpacity={0.7} fill="#FA7D92" />
                <Legend verticalAlign="top" height={35} iconType='square' />
                <ReferenceLine stroke='red' strokeDasharray='3 3' x={todaysOffset} isFront strokeWidth={2}>
                  <Label value="Today" position='top' />
                </ReferenceLine>
                <Tooltip content={renderTooltip} />
                {
                  refAreaStart && refAreaEnd && <ReferenceArea yAxisId="0" x1={refAreaStart} x2={refAreaEnd} strokeOpacity={0.3} />
                }
              </AreaChart>
            </ResponsiveContainer>
          </Fragment>
      }
    </Fragment>
  )
}

export default RateLookup
