import React, { Fragment, useState } from 'react'
import { Button, Col, FloatingLabel, Form, Row, Spinner } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import GenericForm from '../forms/GenericForm'
import { appendNotification } from '../notifications/notificationsSlice'
import { restApi } from '../Api'

function OnboardCarrier(props) {
  const dispatch = useDispatch()
  const [carrierEmail, setCarrierEmail] = useState('')

  const formContent = ({handleFormChange, isSubmitting}) => {
    return (
      <Fragment>
        <Row>
          <Col xl={3} xs={12}>
            <FloatingLabel controlId="carrierEmail" label="Carrier Email" className="mb-3">
              <Form.Control required placeholder="Carrier Email" type="email" value={carrierEmail} onChange={e=>setCarrierEmail(e.target.value)} />
              <Form.Control.Feedback type="invalid">A valid email address is required</Form.Control.Feedback>
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Button className="indigo-button w-100" type="submit">
              {
                isSubmitting ? <Spinner size="sm" animation="border" variant="light" /> : 'Submit'
              }
            </Button>
          </Col>
        </Row>
      </Fragment>
    )
  }

  const handleFormSuccess = (res) => {
    dispatch(appendNotification({type: 'EmailSent'}))
    setCarrierEmail('')
  }

  return (
    <Fragment>
      <Row className="form-sub-header g-0">
        <h5>Onboard a Carrier</h5>
      </Row>
      <GenericForm formContent={formContent} formRequest={restApi.onboardCarrier} params={{carrierEmail: carrierEmail}} handleSuccess={handleFormSuccess} />
    </Fragment>
  )
}

export default OnboardCarrier
