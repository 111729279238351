import _ from 'lodash'
import update from 'immutability-helper'
import React, { Fragment, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import GenericForm from '../forms/GenericForm'
import { restApi } from '../Api'

function RefinableListSettingsModal({selectableColumns, userPreferencedColumns, refinableListSettingsName}) {
  const [show, setShow] = useState(false)
  const [preferencedColumns, setPreferencedColumns] = useState(userPreferencedColumns)

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const draggedColumnName = preferencedColumns[result.source.index]

    setPreferencedColumns(
      update(preferencedColumns, {
        $splice: [
          [result.source.index, 1],
          [result.destination.index, 0, draggedColumnName],
        ],
      }),
    )
  }

  const formContent = ({isSubmitting}) => {
    return (
      <Fragment>
        <Modal.Body>
          <div className="mb-2">
            <h5 className="mb-0">Display Columns:</h5>
            <div className="text-muted" style={{fontSize: '0.8rem'}}>
              Drag and drop to reorder.
            </div>
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="user-column-preferences-droppable">
              {
                (droppableProvided, _snapshot) => {
                  return (
                    <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                      {
                        _.map(preferencedColumns, (columnAccessor, index) => {
                          return (
                            <Draggable draggableId={columnAccessor} key={columnAccessor} index={index}>
                              {
                                (draggableProvided, _snapshot) => {
                                  return (
                                    <div className="mb-2" ref={draggableProvided.innerRef} {...draggableProvided.draggableProps} {...draggableProvided.dragHandleProps}>
                                      <FontAwesomeIcon icon='minus' size="lg" onClick={() => {if (preferencedColumns.length > 1) setPreferencedColumns(_.without(preferencedColumns, columnAccessor)) }} />
                                      <span className="ms-2 cursor-pointer">{selectableColumns[columnAccessor].name}</span>
                                    </div>
                                  )
                                }
                              }
                            </Draggable>
                          )
                        })
                      }
                      {droppableProvided.placeholder}
                    </div>
                  )
                }
              }
            </Droppable>
          </DragDropContext>
          <div className="my-2">
            <h5>Hidden Columns:</h5>
          </div>
          <div>
            {
              _.map(selectableColumns, (column, columnAccessor) => {
                if (!_.includes(preferencedColumns, columnAccessor)) {
                  return (
                    <div key={columnAccessor} className="mb-2">
                      <FontAwesomeIcon className="ms-0" icon='plus' size="lg" onClick={() => {setPreferencedColumns(_.concat(preferencedColumns, columnAccessor)) }} />
                      <span className="ms-2">{column.name}</span>
                    </div>
                  )
                }
              })
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="indigo-button w-100" type="submit">
            {isSubmitting ? <Spinner size="sm" animation="border" variant="light" /> : 'Save Preferences'}
          </Button>
        </Modal.Footer>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <FontAwesomeIcon icon='cog' size="lg" className="float-end cursor-pointer" onClick={_ => setShow(true)} />

      <Modal className="refinable-list-settings-modal" show={show} onHide={_ => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div>
              Edit column layout
            </div>
            <div className="text-muted" style={{fontSize: '0.8rem'}}>
              Modify which columns display by default as well as their ordering.
            </div>
          </Modal.Title>
        </Modal.Header>
        <GenericForm formRequest={restApi.updateUserSettings} formContent={formContent} params={{[refinableListSettingsName]: preferencedColumns}} handleSuccess={_ => setShow(false)} />
      </Modal>
    </Fragment>
  )
}

export default RefinableListSettingsModal
