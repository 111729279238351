import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'

function CarrierAgreementEsignableCompleted({company_id, carrier_id, carrier_name, handleDismissal}) {
  return (
    <div className="pt-3">
      <div className="d-flex justify-content-center">
        <FontAwesomeIcon icon='file-signature' size='2x' />
      </div>
      <div className="d-flex justify-content-center text-center fw-bold mt-1">
        <div>
          Carrier Agreement for<br />
          <Link className="mx-1" to={`/company/${company_id}/carriers/view/${carrier_id}`} onClick={()=>handleDismissal()}>
            {carrier_name}
          </Link>
          <br />has been signed by all parties
        </div>
      </div>
    </div>
  )
}

export default CarrierAgreementEsignableCompleted
