import _ from 'lodash'
import React, { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import Receiver from './Receiver'
import { appendNotification } from '../notifications/notificationsSlice'
import { restApi } from '../Api'
import Graphql from '../Graphql'

const ReceiverFields = ['id', 'name', 'phone', 'email', 'address_1', 'address_2', 'city', 'state', 'postal', 'postal_ext', 'latitude', 'longitude', 'time_zone']
const ContactsFields = ['id', 'first_name', 'last_name', 'phone', 'primary']

function EditReceiver(props) {
  const { companyId, receiverId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [contactCount, setContactCount] = useState()

  const receiverDataQuery = Graphql.receiver({
                              filters: {id: receiverId},
                              fields: [
                                ...ReceiverFields,
                                Graphql.relatedContacts({fields: ContactsFields})
                              ]
                            })

  const populateReceiverData = ({setFormData}, res) => {
    const receiverData = Graphql.transformData(Graphql.getValue(res, 'receiver'), ReceiverFields)
    const contactsData = Graphql.transformData({...Graphql.getValue(res, 'receiver.contacts')})

    setContactCount(_.size(contactsData))
    setFormData({
      ...receiverData,
      contactsList: contactsData
    })
  }

  const handleReceiverUpdateSuccess = (res) => {
    dispatch(appendNotification({type: 'Receiver', companyId: companyId, receiverId: res.data.id, verb: 'updated'}))
    navigate(`/company/${companyId}/locations/receivers`)
  }

  return (
    <Fragment>
      <Receiver
        companyId={companyId}
        contactCount={contactCount}
        onMountFetchQuery={receiverDataQuery}
        onMountFetchCallback={populateReceiverData}
        formRequest={restApi.updateReceiver}
        handleSuccess={handleReceiverUpdateSuccess}
      />
    </Fragment>
  )
}

export default EditReceiver
