import _ from 'lodash'
import React from 'react'
import { Accordion, Col, Row } from 'react-bootstrap'

const CarrierContact = React.memo(({id, firstName, lastName, phone, email, kind, title}) => {
  return (
    <Row className="mb-3">
      <Col>
        <Accordion className="carrier-contact">
          <Accordion.Item eventKey={id}>
            <Accordion.Header>
              <span className="fw-bold px-3">{_.capitalize(firstName)} {_.capitalize(lastName)} - {_.capitalize(kind)}</span>
            </Accordion.Header>
            <Accordion.Body className="p-0">
              <div className="py-2 px-3 border-bottom"><span className="fw-bold">Title:</span><span className="float-end">{_.capitalize(title)}</span></div>
              <div className="py-2 px-3 border-bottom"><span className="fw-bold">Phone:</span><span className="float-end">{phone}</span></div>
              <div className="py-2 px-3"><span className="fw-bold">Email:</span><span className="float-end">{email}</span></div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Row>
  )
})

export default CarrierContact
