import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Form, InputGroup, ListGroup } from 'react-bootstrap'
import { InstantSearch, Configure, connectHits } from 'react-instantsearch-dom';

import ConfigurableSearchBox from './ConfigurableSearchBox'
import SearchClient from './Search'

// Custom Hits layout for FormDropdownSearch
function Hits(props) {
  const transformHit = (hit) => {
    return _.without(_.at(hit, props.relevantHitValues), null).join(', ')
  }

  return props.render({
    hits: props.hits,
    transformHit: transformHit
  })
}

const DropdownSearchHits = connectHits(Hits)

const areEqual = (prevProps, nextProps) => {
  return prevProps.controlledFormData === nextProps.controlledFormData
}

const FormDropdownSearch = React.memo((props) => {
  const [currentRefinement, setCurrentRefinement] = useState('')
  const [searchIsFocused, setSearchIsFocused] = useState(false)
  const [hitIsFocused, setHitIsFocused] = useState(false)

  useEffect(() => {
    if (_.isUndefined(props.initialRefinement)) return
    setCurrentRefinement(props.initialRefinement)
  }, [props.initialRefinement])

  const refineSearch = (refine, newRefinement) => {
    refine(newRefinement)
    setCurrentRefinement(newRefinement)

    if (_.isEmpty(newRefinement)) {
      props.handleHitSelection({})
    }
  }

  const handleHitSelection = (e, hit) => {
    e.preventDefault()
    setCurrentRefinement(hit[props.valueOnSelect])
    setHitIsFocused(false)
    props.handleHitSelection(_.pick(hit, props.relevantHitValues.concat('objectID')))
  }

  const handleSearchInput = (e) => {
    // Represents user tabbing to a rendered hit
    if (e.keyCode === 9 && !_.isEmpty(currentRefinement)) {
      setHitIsFocused(true)
    }
  }

  const handleHitFocusLoss = (e) => {
    if (!_.includes(_.get(e, 'relatedTarget.className'), 'dropdown-hit')) {
      setHitIsFocused(false)
    }
  }

  const renderHits = () => {
    if ((!searchIsFocused && !hitIsFocused) || _.isEmpty(currentRefinement)) {
      return null
    } else {
      return (
        <DropdownSearchHits
          relevantHitValues={props.relevantHitValues}
          render={
            ({hits, transformHit}) => {
              return (
                <InputGroup>
                  <ListGroup className="dropdown-search-hits">
                    {
                      _.map(hits, (hit) => {
                        return (
                          <ListGroup.Item className="dropdown-hit" key={hit.objectID} id={hit.objectID} action onBlur={e => handleHitFocusLoss(e)} onMouseDown={e => setHitIsFocused(true)} onClick={e => handleHitSelection(e, hit)}>
                            {transformHit(hit)}
                          </ListGroup.Item>
                        )
                      })
                    }
                  </ListGroup>
                </InputGroup>
              )
            }
          }
        />
      )
    }
  }

  return (
    <InstantSearch searchClient={SearchClient} indexName={props.indexName}>
      <Configure
        filters={props.filters}
      />
      <ConfigurableSearchBox
        render={
          ({refine}) => {
            return (
              <Form.Floating>
                <Form.Control
                  id={props.controlId}
                  type="search"
                  autoComplete="off"
                  required={props.required || false}
                  placeholder={props.label}
                  value={currentRefinement}
                  onFocus={() => setSearchIsFocused(true)}
                  onBlur={e => setSearchIsFocused(false)}
                  onKeyDown={e => handleSearchInput(e)}
                  onChange={e => refineSearch(refine, e.currentTarget.value)} />
                <label htmlFor={props.controlId} className="pe-auto opacity-100">
                  {props.label}
                </label>
                {
                  props.required &&
                    <Form.Control.Feedback type="invalid">{props.invalidFeedback}</Form.Control.Feedback>
                }
              </Form.Floating>
            )
          }
        }
      />
      {renderHits()}
    </InstantSearch>
  )
}, areEqual)

export default FormDropdownSearch
