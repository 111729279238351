import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'

function RefinableList(props) {
  const { Renderer,
          initialSelectedColumns,
          initialSortedColumn,
          initialSortDirection,
          selectableColumns,
          items,
          pageChangeCallback} = props

  const populateFiltersObj = () => {
    return _.transform(selectableColumns, (result, {name, defaultFilters}) => {
      result[name] = defaultFilters || []
    }, {})
  }

  const clearFilters = () => {
    setFilteredValues(populateFiltersObj())
  }

  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [selectedColumns, setSelectedColumns] = useState(initialSelectedColumns)
  const [sortedColumn, setSortedColumn] = useState(initialSortedColumn)
  // 'down' -> ASC, 'up' -> DESC
  const [sortDirection, setSortDirection] = useState(initialSortDirection || 'down')
  const [filteredItems, setFilteredItems] = useState(items)
  const [filteredValues, setFilteredValues] = useState(() => populateFiltersObj())
  const [searchRefinement, setSearchRefinement] = useState('')
  const [searchHits, setSearchHits] = useState([])

  const handlePageChange = (pageNumber) => {
    if (pageChangeCallback) {
      pageChangeCallback(pageNumber)
    }

    setCurrentPageNumber(pageNumber)
  }

  const addableColumns = useMemo(() => {
    return _.differenceBy(selectableColumns, selectedColumns, 'name')
  }, [selectedColumns]) // eslint-disable-line react-hooks/exhaustive-deps

  const filterableValues = useMemo(() => {
    const currentItems = searchRefinement ? searchHits : items

    return _.transform(selectableColumns, (result, column) => {
      if (column.filterable) {
        if (column.staticFilters) {
          result[column.name] = column.staticFilters
        } else {
          const currentItemsSortedByColumnAttr = column.converter ? _.sortBy(currentItems, (item) => column.converter(column.accessor(item))) : _.sortBy(currentItems, (item) => column.accessor(item))
          result[column.name] = new Set()
          _.forEach(currentItemsSortedByColumnAttr, (item) => {
            result[column.name].add(column.filterer ? column.filterer(column.accessor(item)) : column.accessor(item))
          })
          result[column.name] = Array.from(result[column.name])
        }
      }
    })
  }, [items, searchHits]) // eslint-disable-line react-hooks/exhaustive-deps

  const appliedFilterCount = _.sumBy(Object.keys(filteredValues), k => filteredValues[k].length)

  const applySort = (items) => {
    if (!sortedColumn) return items

    const relevantColumn = _.find(selectableColumns, {name: sortedColumn})
    const sortedItems = relevantColumn.converter ? _.sortBy(items, (item) => relevantColumn.converter(relevantColumn.accessor(item))) : _.sortBy(items, (item) => relevantColumn.accessor(item))
    return sortDirection === 'down' ? sortedItems : sortedItems.reverse()
  }

  // Sort/Filters/Search changed state, we need
  // to redraw the list being rendered
  useEffect(() => {
    if (items.length !== 0 || searchHits.length !== 0) {
      const currentItems = searchRefinement ? searchHits : items

      // Populate filtered items with items passed to component
      // when there are no filters currently applied
      if (filteredItems.length === 0 && appliedFilterCount === 0 && !sortedColumn && !searchRefinement) {
        setFilteredItems(items)
      } else {
        setFilteredItems(
          applySort(
            appliedFilterCount === 0 ?
              currentItems
              : _.filter(currentItems, (item) => {
                  return _.every(filteredValues, (selectedValues, columnName) => {
                    if (selectedValues.length !== 0) {
                      let column = _.find(selectableColumns, {'name': columnName})

                      if (column.complexFilterable) {
                        return column.filterer(item, selectedValues)
                      } else {
                        return _.includes(selectedValues,
                                column.filterer ? column.filterer(column.accessor(item)) : column.accessor(item)
                              )
                      }
                    }

                    return true
                  })
                })
          )
        )
      }

      if (currentPageNumber !== 1) {
        setCurrentPageNumber(1)
      }
    }
  }, [sortedColumn, sortDirection, appliedFilterCount, items, searchHits]) // eslint-disable-line react-hooks/exhaustive-deps

  const removeAppliedFilter = (columnName, value) => {
    setFilteredValues({...filteredValues, [columnName]: _.without(filteredValues[columnName], value)})
  }

  return <Renderer
           currentPageNumber={currentPageNumber}
           setCurrentPageNumber={handlePageChange}
           selectedColumns={selectedColumns}
           setSelectedColumns={setSelectedColumns}
           sortedColumn={sortedColumn}
           setSortedColumn={setSortedColumn}
           addableColumns={addableColumns}
           sortDirection={sortDirection}
           setSortDirection={setSortDirection}
           filteredItems={filteredItems}
           filteredValues={filteredValues}
           filterableValues={filterableValues}
           setFilteredValues={setFilteredValues}
           searchRefinement={searchRefinement}
           setSearchRefinement={setSearchRefinement}
           searchHits={searchHits}
           setSearchHits={setSearchHits}
           clearFilters={clearFilters}
           appliedFilterCount={appliedFilterCount}
           removeAppliedFilter={removeAppliedFilter}
           {...props}
         />
}

export default RefinableList
