import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'

function RateConfirmationEsignableCompleted({company_id, number, handleDismissal}) {
  return (
    <div className="pt-3">
      <div className="d-flex justify-content-center">
        <FontAwesomeIcon icon='file-signature' size='2x' />
      </div>
      <div className="d-flex justify-content-center text-center fw-bold mt-1">
        <div>
          Rate Confirmation for<br />
          <Link className="mx-1" to={`/company/${company_id}/shipments/edit-bol/${number}`} onClick={()=>handleDismissal()}>
            BOL #{number}
          </Link>
          <br />has been signed by all parties
        </div>
      </div>
    </div>
  )
}

export default RateConfirmationEsignableCompleted
