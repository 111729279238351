import React from 'react'
import { Route, Routes } from "react-router-dom";

import InternalServerError from './features/errorHandling/InternalServerError'
import CarrierInvoiceUpload from './features/invoices/CarrierInvoiceUpload'
import LandingPage from './features/app/LandingPage'
import PageNotFound from './features/errorHandling/PageNotFound'
import ProofOfDeliveryUpload from './features/bol/ProofOfDeliveryUpload'
import TotalControl from './features/app/TotalControl'
import './assets/FontAwesomeLibrary'

function App() {
  return (
    <Routes>
      <Route path="company/:companyId/*" element={<TotalControl />} />
      <Route path="500" element={<InternalServerError />} />
      <Route path="404" element={<PageNotFound />} />
      <Route path="invoice_upload/:bolId" element={<CarrierInvoiceUpload />} />
      <Route path="invoice_upload/:bolId/:mcNumber" element={<CarrierInvoiceUpload />} />
      <Route path="proof_of_delivery_upload/:bolId" element={<ProofOfDeliveryUpload />} />
      <Route path="proof_of_delivery_upload/:bolId/:mcNumber" element={<ProofOfDeliveryUpload />} />
      <Route path="/*" element={<LandingPage />} />
    </Routes>
  )
}

export default App
