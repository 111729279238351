import React, { Fragment } from 'react'

const TimeZoneOptions = React.memo(() => {
  return (
    <Fragment>
      <option value="US/Alaska">US/Alaska</option>
      <option value="US/Arizona">US/Arizona</option>
      <option value="US/Central">US/Central</option>
      <option value="US/East-Indiana">US/East-Indiana</option>
      <option value="US/Eastern">US/Eastern</option>
      <option value="US/Hawaii">US/Hawaii</option>
      <option value="US/Indiana-Starke">US/Indiana-Starke</option>
      <option value="US/Michigan">US/Michigan</option>
      <option value="US/Mountain">US/Mountain</option>
      <option value="US/Pacific">US/Pacific</option>
    </Fragment>
  )
})

export default TimeZoneOptions
