import { createSlice } from '@reduxjs/toolkit'

let initialState = {
  counter: 0
}

const interactableTableSlice = createSlice({
  name: 'interactableTable',
  initialState,
  reducers: {
    rerenderListeningColumns(state) {
      state.counter = state.counter + 1
    }
  },
  extraReducers: {

  }
})

export const {
  rerenderListeningColumns,
} = interactableTableSlice.actions

export default interactableTableSlice.reducer
