import _ from "lodash"
import React, { useState } from "react"
import { Button, Col, FloatingLabel, Form, Row, Spinner } from "react-bootstrap"
import { useDispatch } from 'react-redux'

import { appendNotification } from '../notifications/notificationsSlice'
import GenericForm from "../forms/GenericForm"
import Graphql from "../Graphql"
import { graphqlApi, restApi } from "../Api"
import Requestable from "../generic/Requestable"

export const companyLoadPostOptionsQuery = Graphql.company({
  fields: [
    Graphql.relatedSettings({
      fields: [
        'dat_default_comment_1',
        'dat_default_comment_2',
        'dat_default_commodity_description',
        'dat_preferred_contact_method'
      ]
    })
  ]
})

function LoadPostings() {
  const [formData, setFormData] = useState({})
  const [datContactMethodOptions, setDatContactMethodOptions] = useState([])
  const dispatch = useDispatch()

  const handleCompanyLoadPostSettingsFetchSuccess = ({data}) => setFormData(data.data.company.settings)

  const populateDatContactMethodOptions = ({data}) => {
    const transformedData = Graphql.transformData(data.data)
    setDatContactMethodOptions(transformedData.datLoadPostOptions.contact_methods)
  }

  const handleLoadPostingsSettingsUpdateSuccess = _res => {
    dispatch(appendNotification({type: 'GenericSuccess', text: 'Updated Load Postings Settings'}))
  }

  const formContent = ({handleFormChange, isSubmitting}) => {
    const datDefaultComment1Chars = _.size(formData.dat_default_comment_1)
    const datDefaultComment2Chars = _.size(formData.dat_default_comment_2)
    const datDefaultCommodityDescriptionChars = _.size(formData.dat_default_commodity_description)

    return (
      <>
        <h4><b>Load Postings</b></h4>
          <Row>
            <Col xl={4} xs={12}>
              <h5>DAT Load Postings</h5>
              <FloatingLabel controlId="dat_preferred_contact_method" label="Preferred Contact Method">
                <Form.Select aria-label="Preferred Contact Method" value={formData.dat_preferred_contact_method} onChange={e=>handleFormChange(setFormData, e)}>
                  {
                    _.map(datContactMethodOptions, (value, key) => {
                      return <option key={key} value={key}>{_.startCase(_.lowerCase(value))}</option>
                    })
                  }
                </Form.Select>
              </FloatingLabel>
              <FloatingLabel controlId="dat_default_comment_1">
                <Form.Control type="text" placeholder="Default Comment 1" value={formData.dat_default_comment_1 || ''} onChange={e=>handleFormChange(setFormData, e)} maxLength={70} />
                <label htmlFor="dat_default_comment_1" className="d-flex">
                  {
                    datDefaultComment1Chars === 0 ? "Default Comment 1"
                      : <><span>Default Comment 1</span><span className="ms-auto">{`${70 - datDefaultComment1Chars} characters remaining`}</span></>
                  }
                </label>
              </FloatingLabel>
              <FloatingLabel controlId="dat_default_comment_2">
                <Form.Control type="text" placeholder="Default Comment 2" value={formData.dat_default_comment_2 || ''} onChange={e=>handleFormChange(setFormData, e)} maxLength={70} />
                <label htmlFor="dat_default_comment_2" className="d-flex">
                  {
                    datDefaultComment2Chars === 0 ? "Default Comment 2"
                      : <><span>Default Comment 2</span><span className="ms-auto">{`${70 - datDefaultComment2Chars} characters remaining`}</span></>
                  }
                </label>
              </FloatingLabel>
              <FloatingLabel controlId="dat_default_commodity_description">
                <Form.Control type="text" placeholder="Default Freight Description" value={formData.dat_default_commodity_description || ''} onChange={e=>handleFormChange(setFormData, e)} maxLength={70} />
                <label htmlFor="dat_default_commodity_description" className="d-flex">
                  {
                    datDefaultCommodityDescriptionChars === 0 ? "Default Freight Description"
                      : <><span>Default Freight Description</span><span className="ms-auto">{`${70 - datDefaultCommodityDescriptionChars} characters remaining`}</span></>
                  }
                </label>
              </FloatingLabel>
              <Button className="indigo-button w-100" type="submit" disabled={isSubmitting}>
                {
                  isSubmitting ? <Spinner size="sm" animation="border" variant="light" /> : 'Save'
                }
              </Button>
            </Col>
          </Row>
      </>
    )
  }

  return <Requestable
    withErrorHandling
    onMountMultiFetch={[
      graphqlApi.execute(companyLoadPostOptionsQuery, handleCompanyLoadPostSettingsFetchSuccess),
      graphqlApi.execute(Graphql.datLoadPostOptions, populateDatContactMethodOptions)
    ]}
    render={({performRequest}) => {
      return <GenericForm formContent={formContent} formRequest={restApi.updateCompanyLoadPostingsSettings} params={formData} handleSuccess={handleLoadPostingsSettingsUpdateSuccess} />
    }}
  />
}

export default LoadPostings
