import _ from 'lodash'
import React, { Fragment, useState } from 'react'
import { Button, Col, FloatingLabel, Form, Row, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch } from 'react-redux'

import { appendNotification } from '../notifications/notificationsSlice'
import GenericForm from '../forms/GenericForm'
import { restApi } from '../Api'
import StateOptions from '../utils/StateOptions'
import TimeZoneOptions from '../utils/TimeZoneOptions'

function Location({dataKey, setLocations, ...locationData}) {
  const [locationId, setLocationId] = useState(locationData.id)
  const [editingLocation, setEditingLocation] = useState(false)
  const [formData, setFormData] = useState(locationData)
  const dispatch = useDispatch()

  const handleEditUndo = () => {
    _.isNil(locationId) ? setLocations(prevLocations => _.omit(prevLocations, dataKey)) : setEditingLocation(false)
  }

  const handleFormSubmission = (res) => {
    let action = res.config.method

    if (action === 'patch') {
      dispatch(appendNotification({type: 'Location', verb: 'updated'}))
      setEditingLocation(false)
    } else if (action === 'post') {
      dispatch(appendNotification({type: 'Location', verb: 'created'}))
      setLocationId(res.data.id)
      setEditingLocation(false)
    } else {
      dispatch(appendNotification({type: 'Location', verb: 'deleted'}))
      setLocations(prevLocations => _.omit(prevLocations, dataKey))
    }
  }

  const locationForm = () => {
    return (
      <GenericForm className="company-location-form"
        formRequests={
          {
            'create': restApi.createLocation,
            'update': restApi.updateLocation,
            'delete': restApi.deleteLocation
          }
        }
        params={formData}
        handleSuccess={handleFormSubmission}
        formContent={({handleFormChange, isSubmitting}) => {
          return (
            <Fragment>
              <Row className="mx-0 mb-2">
                <Col xl={5} xs={10} className="ps-0">
                  <h5 className="d-inline"><b>{_.isNil(locationId) ? 'Creating' : 'Editing'} Location - {formData.name}</b></h5>
                </Col>
                <Col xs={1} className="px-xl-3 px-0" >
                  <FontAwesomeIcon icon='undo-alt' className="float-end cursor-pointer" onClick={_ => handleEditUndo()} />
                </Col>
              </Row>
              <Row className="mb-2 mx-0">
                <Col xl={3} className="ps-0">
                  <FloatingLabel controlId='name' label="Name">
                    <Form.Control required type="text" placeholder="Name" value={formData.name} onChange={e=>handleFormChange(setFormData, e)} />
                    <Form.Control.Feedback type="invalid">Name is required</Form.Control.Feedback>
                  </FloatingLabel>
                  <FloatingLabel controlId='email' label="Email">
                    <Form.Control required type="email" placeholder="Email" value={formData.email} onChange={e=>handleFormChange(setFormData, e)} />
                    <Form.Control.Feedback type="invalid">Email is invalid</Form.Control.Feedback>
                  </FloatingLabel>
                  <FloatingLabel controlId="phone" label="Phone Number">
                    <Form.Control type="tel" placeholder="Phone Number" required value={formData.phone} onChange={e=>handleFormChange(setFormData, e)} />
                    <Form.Control.Feedback type="invalid">Phone Number is required</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col xl={3} className="ps-xl-3 ps-0">
                  <FloatingLabel controlId='address_1' label="Address 1">
                    <Form.Control required type="text" placeholder="Address 1" value={formData.address_1} onChange={e=>handleFormChange(setFormData, e)} />
                    <Form.Control.Feedback type="invalid">Primary Address is required</Form.Control.Feedback>
                  </FloatingLabel>
                  <FloatingLabel controlId='address_2' label="Address 2">
                    <Form.Control type="text" placeholder="Address 2" value={formData.address_2 || ''} onChange={e=>handleFormChange(setFormData, e)} />
                  </FloatingLabel>
                  <FloatingLabel controlId='city' label="City">
                    <Form.Control required type="text" placeholder="City" value={formData.city} onChange={e=>handleFormChange(setFormData, e)} />
                    <Form.Control.Feedback type="invalid">City is required</Form.Control.Feedback>
                  </FloatingLabel>
                  <FloatingLabel controlId='state' label="State">
                    <Form.Select value={formData.state} onChange={e=>handleFormChange(setFormData, e)} >
                      <StateOptions />
                    </Form.Select>
                  </FloatingLabel>
                  <FloatingLabel controlId="postal" label="Postal">
                    <Form.Control required type="text" placeholder="Postal" value={formData.postal} onChange={e=>handleFormChange(setFormData, e)} />
                    <Form.Control.Feedback type="invalid">Postal is required</Form.Control.Feedback>
                  </FloatingLabel>
                  <FloatingLabel controlId="time_zone" label="Time Zone">
                    <Form.Select value={formData.time_zone} onChange={e=>handleFormChange(setFormData, e)} >
                      <TimeZoneOptions />
                    </Form.Select>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row className="mb-4 mx-0 border-bottom">
                <Col lg={1} md={4} xs={6} className="mb-3 ps-0">
                  <Button type="submit" data-action={_.isNil(locationId) ? 'create' : 'update'} className="indigo-button w-100">
                    {
                      isSubmitting ? <Spinner size="sm" animation="border" variant="light" />
                        : _.isNil(locationId) ? 'Create'
                        : 'Update'
                    }
                  </Button>
                </Col>
                <Col lg={1} md={4} xs={6} className="ps-0">
                  {
                    _.isNil(locationId) ? null
                      : <Button type="submit" data-action="delete" variant="danger" className="w-100">
                          {isSubmitting ? <Spinner size="sm" animation="border" variant="light" /> : 'Delete'}
                        </Button>
                  }
                </Col>
              </Row>
            </Fragment>
          )
        }}
      />
    )
  }

  const existingLocation = () => {
    return (
      <Row className="mb-4">
        <Col xl={4}>
          <div className="pb-2 border-bottom">
            <FontAwesomeIcon icon={['far', 'edit']} className="float-end cursor-pointer" onClick={_ => setEditingLocation(true)} />
            <h5>{formData.name}</h5>
            <i>{`${_.trim(formData.address_1 + _.toString(formData.address_2))}`}, {formData.city}, {formData.state}  {formData.postal}</i>
          </div>
        </Col>
      </Row>
    )
  }

  return (
    _.isNil(locationId) || editingLocation ? locationForm() : existingLocation()
  )
}

export default Location
