import _ from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import { clearCurrentEsignable } from './esignSlice'
import { appendNotification } from '../notifications/notificationsSlice'
import PusherClient, { esignableDocumentChannel } from '../pusher/Pusher'
import usePrevious from '../utils/usePrevious'

let esignChannel = undefined

function EsignOverlay() {
  const dispatch = useDispatch()
  const esignableId = useSelector(state => state.esign.currentEsignableId)
  const esignableLink = useSelector(state => state.esign.currentEsignableLink)
  const currentUser = useSelector(state => state.user.currentUser)
  const previousEsignableId = usePrevious(esignableId)

  // Notify current user they completed the esign
  const notifyCompletion = useCallback((recipients) => {
    if (_.find(recipients, recipient => recipient.email === currentUser.email && recipient.has_completed)) {
      dispatch(clearCurrentEsignable())
      dispatch(appendNotification({type: 'EsignAction', verb: 'Completed'}))
    }
  }, [currentUser.email]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (esignableId) {
      esignChannel = PusherClient.channel(esignableDocumentChannel(esignableId))
      esignChannel.bind('recipients-changed', notifyCompletion)
    }

    if (_.isNil(esignableId) && previousEsignableId) {
      esignChannel.unbind('recipients-changed', notifyCompletion)
    }
  }, [esignableId]) // eslint-disable-line react-hooks/exhaustive-deps

  if (esignableId) {
    return (
      <Modal id="esign-modal" show size="xl" backdrop="static" onHide={_ => dispatch(clearCurrentEsignable())}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <iframe id="esign-iframe" className="w-100 h-100" src={esignableLink} title="Esign iframe"></iframe>
        </Modal.Body>
      </Modal>
    )
  } else {
    return null
  }
}

export default EsignOverlay
