import _ from 'lodash'
import React, { Fragment, useMemo, useState } from 'react'
import { CloseButton, Modal } from 'react-bootstrap'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import Requestable from '../generic/Requestable'
import NavAccordion from './NavAccordion'

import NavLinks from '../utils/NavLinks'
import { graphqlApi, restApi } from '../Api'
import Graphql from '../Graphql'
import useScreenWidth from '../utils/useScreenWidth'

function MainNav(props) {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { companyId } = useParams()
  const [companyName, setCompanyName] = useState('')
  const [accordionsState, setAccordionsState] = useState({
    shipments: false,
    locations: false,
    carriers: false,
  })
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const handleLinkSelection = _ => setMobileNavOpen(false)
  const screenWidth = useScreenWidth()

  const handleCompanyNameFetch = res => setCompanyName(res.data.data.company.name)

  const handleLogout = _ => navigate(`/`, { replace: true })

  const setAccordionsStateByName = (name, open) => {
    setAccordionsState({...accordionsState, [name]: open})
  }

  const renderNavRow = (name, rowTitle, rowPath) => {
    const shouldHighlight = _.includes(pathname, `/company/${companyId}/${name}`)

    return (
      <Link to={`/company/${companyId}/${rowPath || name}`} className={`nav-link d-flex ${shouldHighlight ? 'active' : ''}`} onClick={_ => setMobileNavOpen(false)}>
        <img style={{height: 35, width: 35}} className="nav-icon" src={require(`../../assets/images/sidebar_${name}_icon.svg`)} alt="" />
        <p className="ms-2 d-flex my-auto">{rowTitle || _.startCase(name)}</p>
      </Link>
    )
  }

  const navContent = useMemo(() => {
    return (
      <Fragment>
        {renderNavRow('dashboard')}
        <NavAccordion header={'shipments'} open={accordionsState.shipments} toggle={setAccordionsStateByName} handleLinkSelection={handleLinkSelection} links={NavLinks.shipments(companyId)} />
        {renderNavRow('invoices')}
        <NavAccordion header={'locations'} open={accordionsState.locations} toggle={setAccordionsStateByName} handleLinkSelection={handleLinkSelection} links={NavLinks.locations(companyId)} />
        <NavAccordion header={'carriers'} open={accordionsState.carriers} toggle={setAccordionsStateByName} handleLinkSelection={handleLinkSelection} links={NavLinks.carriers(companyId)} />
        {renderNavRow('esignables', 'E-Signables')}
        {renderNavRow('reports', null, 'reports/transactions')}
        {renderNavRow('settings', null, 'settings/company')}
        <Requestable
          withoutLoading
          render={({performRequest}) => {
            return (
              <div id="logout-container" className="nav-link d-flex mt-auto" onClick={ _ => performRequest(restApi.logoutUser({}, handleLogout)) }>
                <p className="d-flex my-auto">Logout</p>
                <img style={{width: "1.5rem", height: "1.5rem"}} className="nav-icon ms-auto" src={require('../../assets/images/logout.svg').default} alt="" />
              </div>
            )
          }}
        />
      </Fragment>
    )
  }, [accordionsState, pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  const renderSideNav = () => {
    return (
      <div id="side-nav" className="main-nav">
        <div id="side-nav-inner" className="position-absolute h-100">
          <div id="side-nav-content" className="h-100 d-flex flex-column">
            <div className="header d-flex">
              <div className="d-flex">
                <img style={{width: "1.5rem"}} id="hamburger-icon" src={require('../../assets/images/hamburger_menu.svg').default} alt="" />
              </div>
              <p className="d-flex my-auto">{companyName}</p>
            </div>
            <div id="header-border-bottom"></div>
            {navContent}
          </div>
        </div>
      </div>
    )
  }

  const renderTopNav = () => {
    return (
      <Fragment>
        <div id="top-nav" className="d-flex">
          <img style={{width: "2rem"}} id="hamburger-icon" className="ms-2 my-auto" src={require('../../assets/images/hamburger_menu.svg').default} onClick={_ => setMobileNavOpen(!mobileNavOpen)} alt="" />
          <p className="d-flex my-auto ms-2 fs-5">{companyName}</p>
        </div>
        <Modal show={mobileNavOpen} fullscreen id="top-nav-modal" dialogClassName="main-nav">
          <Modal.Body className="d-flex flex-column">
            <div id="top-nav-close" className="d-flex" onClick={_ => setMobileNavOpen(false)}>
              <CloseButton className="m-auto" />
            </div>
            {navContent}
          </Modal.Body>
        </Modal>
      </Fragment>
    )
  }

  return (
    <Requestable
      withoutLoading
      onMountFetch={graphqlApi.execute(Graphql.company({fields: ['name']}), handleCompanyNameFetch)}
      render={() => {
        return screenWidth >= 576 ? renderSideNav() : renderTopNav()
      }}
    />
  )
}

export default MainNav
