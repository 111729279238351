import _ from 'lodash'
import React, { Fragment, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import BOLColumns from './BOLRefinableColumns'
import CardsList from '../generic/CardsList'
import Graphql from '../Graphql'
import { graphqlApi } from '../Api'
import InteractableTable from '../generic/InteractableTable'
import RefinableList from '../generic/RefinableList'
import RefinableListSettingsModal from '../generic/RefinableListSettingsModal'
import Requestable from '../generic/Requestable'
import useScreenWidth from '../utils/useScreenWidth'
import { MINIMUM_SCREEN_WIDTH_FOR_TABLE_VIEW } from '../generic/interactableTableConstraints'

function FetchTodaysBOLsButton({companyId}) {
  return (
    <a target="_blank" rel="noopener noreferrer" href={`/company/${companyId}/documents/todays_bols/${companyId}`} className="w-100">
      <Button className="indigo-button w-100 mb-2 mt-2 mt-xl-0">
        Print Today's BOLs
      </Button>
    </a>
  )
}

const BOLFields = ['company_id', 'number', 'status', 'origin_id', 'origin_name', 'pick_up_date', 'pick_up_time', 'delivery_date', 'delivery_time', 'delivered_on', 'truck_number', 'total_cost']

function BOLs({pageSize, searchFilters, relevantStatuses, tableDescriptor, columnsSettingsKey, injectable, injectableQueryAdditions}) {
  const { companyId } = useParams()
  const [bols, setBOLs] = useState([])
  const preferencedColumns  = useSelector(state => _.get(state.user.currentUser, `settings.${columnsSettingsKey}`, []))
  const screenWidth = useScreenWidth()

  const selectableColumns = {
    number: BOLColumns.number(companyId),
    status: BOLColumns.status,
    carrier: BOLColumns.carrier(companyId),
    finalReceiver: BOLColumns.finalReceiver(companyId),
    totalStops: BOLColumns.totalStops,
    pickUpDate: BOLColumns.pickUpDate,
    pickUpTime: BOLColumns.pickUpTime,
    deliveryDate: BOLColumns.deliveryDate,
    deliveryTime: BOLColumns.deliveryTime,
    deliveredOn: BOLColumns.deliveredOn,
    origin: BOLColumns.origin,
    truckNumber: BOLColumns.truckNumber,
    totalCost: BOLColumns.totalCost
  }

  const companyBOLsQuery = Graphql.company({
                             fields: [
                               Graphql.relatedBOLs({
                                 filters: {status__in: relevantStatuses},
                                 fields: [
                                   ...BOLFields,
                                   Graphql.relatedCarrier({fields: ['id', 'name']}),
                                   Graphql.relatedOrigin({fields: ['name']}),
                                   Graphql.relatedReceivers({fields: ['id', 'name']}),
                                   ...(injectableQueryAdditions || [])
                                 ]
                               })
                             ]
                           })

  const companyBOLsLoadSuccess = (res) => {
    setBOLs(Graphql.getValue(res, 'company.bols'))
  }

  const renderTable = () => {
    return (
      <RefinableList
        Renderer={InteractableTable}
        initialSelectedColumns={_.values(_.pick(selectableColumns, preferencedColumns))}
        initialSortedColumn="BOL #"
        initialSortDirection='up'
        selectableColumns={_.values(selectableColumns)}
        draggableType="bolColumn"
        searchable
        searchPlaceholder="Search BOLs"
        searchIndex={`tc_bols_${process.env.NODE_ENV}`}
        searchFilters={`company_id = ${companyId} ${searchFilters || ''}`}
        items={bols}
        rightOfSearchContent={<Col xs={{ span: 2, offset: 6 }}><FetchTodaysBOLsButton companyId={companyId} /></Col>}
        settings={<RefinableListSettingsModal selectableColumns={selectableColumns} userPreferencedColumns={preferencedColumns} refinableListSettingsName={columnsSettingsKey} />}
        pageSize={pageSize}
      />
    )
  }

  const renderCards = () => {
    return (
      <RefinableList
        Renderer={CardsList}
        initialSelectedColumns={_.values(_.pick(selectableColumns, _.without(preferencedColumns, 'number')))}
        initialSortedColumn="BOL #"
        initialSortDirection='up'
        selectableColumns={_.values(selectableColumns)}
        cardHeaderColumn={selectableColumns.number}
        items={bols}
        searchable
        searchPlaceholder="Search BOLs"
        searchIndex={`tc_bols_${process.env.NODE_ENV}`}
        searchFilters={`company_id = ${companyId} ${searchFilters || ''}`}
        belowSearchContent={<FetchTodaysBOLsButton companyId={companyId} />}
        settings={<RefinableListSettingsModal selectableColumns={selectableColumns} userPreferencedColumns={preferencedColumns} refinableListSettingsName={columnsSettingsKey} />}
        pageSize={10}
      />
    )
  }

  return (
    <Requestable
      onMountFetch={graphqlApi.execute(companyBOLsQuery, companyBOLsLoadSuccess)}
      render={() => {
          if (preferencedColumns.length > 0) {
            return (
              <Fragment>
                <Row className="form-sub-header g-0">
                  <h5>{tableDescriptor}</h5>
                </Row>
                {screenWidth > MINIMUM_SCREEN_WIDTH_FOR_TABLE_VIEW ? renderTable() : renderCards()}
                {injectable ? injectable(bols) : null}
              </Fragment>
            )
          }
        }
      }
    />
  )
}

export default BOLs
