import React from 'react'
import { Pagination } from 'react-bootstrap'

const Paginator = ({totalItems, perPage, currentPage, setCurrentPage}) => {
  const totalPages = Math.ceil(totalItems / perPage)

  const displayPrevious = () => {
    return currentPage === 1 ? null : <Pagination.Item onClick={_=>setCurrentPage(currentPage - 1)}>{currentPage - 1}</Pagination.Item>
  }

  const displayNext = () => {
    return currentPage + 1 >= totalPages ? null : <Pagination.Item onClick={_=>setCurrentPage(currentPage + 1)}>{currentPage + 1}</Pagination.Item>
  }

  if (totalPages > 1) {
    return (
      <Pagination>
        {totalPages > 2 && currentPage !== 1 && <Pagination.First onClick={_=>setCurrentPage(1)} />}
        {totalPages > 1 && currentPage !== 1 && <Pagination.Prev onClick={_=>setCurrentPage(currentPage - 1)} />}
        {currentPage === totalPages && totalPages > 2 && <Pagination.Item onClick={_=>setCurrentPage(totalPages - 2)}>{totalPages - 2}</Pagination.Item>}
        {displayPrevious()}
        <Pagination.Item active>{currentPage}</Pagination.Item>
        {displayNext()}
        {currentPage === 1 && totalPages > 3 && <Pagination.Item onClick={_=>setCurrentPage(3)}>{3}</Pagination.Item>}
        {currentPage + 2 === totalPages - 1 && <Pagination.Item onClick={_=>setCurrentPage(totalPages - 1)}>{totalPages - 1}</Pagination.Item>}
        {currentPage + 1 < totalPages - 1 && currentPage + 2 !== totalPages - 1 && <Pagination.Ellipsis onClick={_=>setCurrentPage(currentPage + 3)} />}
        {currentPage !== totalPages && <Pagination.Item onClick={_=>setCurrentPage(totalPages)}>{totalPages}</Pagination.Item>}
        {totalPages > 1 && currentPage !== totalPages && <Pagination.Next onClick={_=>setCurrentPage(currentPage + 1)} />}
        {totalPages > 2 && currentPage !== totalPages && <Pagination.Last onClick={_=>setCurrentPage(totalPages)} />}
      </Pagination>
    )
  } else {
    return null
  }
}

export default Paginator
