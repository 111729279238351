import _ from 'lodash'
import React, { Fragment } from 'react'
import { Row } from 'react-bootstrap'
import { useParams } from "react-router-dom";

import BOLs from '../bol/BOLs'
import TrackingHistory from '../bol/TrackingHistory'
import DeliveryCountSummaryGraph from '../graphs/DeliveryCountSummaryGraph'
import DeliveryCostSummaryGraph from '../graphs/DeliveryCostSummaryGraph'
import Graphql from '../Graphql'

function Dashboard(props) {
  const { companyId } = useParams()

  return (
    <Fragment>
      <BOLs
        companyId={companyId}
        columnsSettingsKey={'inProgressBolsColumns'}
        searchFilters="AND NOT status:DELIVERED"
        relevantStatuses={["NEW", "OPEN", "COVERED", "IN_TRANSIT"]}
        tableDescriptor={'In Progress Shipments'}
        pageSize={10}
        injectable={
          bols => {
            return <TrackingHistory
                    initialLatestLocationsOnly
                    initialTrackingDatas={
                      _.transform(bols, (result, bol) => {
                        result[bol.number] = bol.tracking_updates
                      }, {})
                    }
                    singleBOL={false}
                   />
          }
        }
        injectableQueryAdditions={[Graphql.relatedTrackingUpdates({fields: ['id', 'bol_id', 'latitude', 'longitude', 'long_address', 'requested_at', 'responded_at', 'short_address', 'status_string']})]}
      />
      <Row className="mt-4">
        <DeliveryCountSummaryGraph companyId={companyId} />
        <DeliveryCostSummaryGraph companyId={companyId} />
      </Row>
    </Fragment>
  )
}

export default Dashboard
