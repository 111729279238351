import _ from 'lodash'
import React, { useState, Fragment } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import GenericForm from '../forms/GenericForm'
import useScreenWidth from '../utils/useScreenWidth'
import { restApi } from '../Api'

function ResetPassword(props) {
  const navigate = useNavigate()
  const { uidb64 } = useParams()
  const [password, setPassword] = useState('')
  const [confirmedPassword, setConfirmedPassword] = useState('')
  const screenWidth = useScreenWidth()
  const canSubmitForm = !_.isEmpty(password) && !_.isEmpty(confirmedPassword) && password === confirmedPassword

  const handlePasswordResetSuccess = (_res) => {
    navigate('/', { replace: true })
  }

  const formContent = ({handleFormChange, isSubmitting}) => {
    return (
      <Fragment>
        <div className="my-auto">
          <div className="input-container mb-2">
            <FontAwesomeIcon icon='unlock' />
            <Form.Control required type="password" className="d-inline" placeholder="New Password" value={password} onChange={e=>setPassword(e.target.value)} />
          </div>
          <div className="input-container">
            <FontAwesomeIcon icon='unlock' />
            <Form.Control required type="password" className="d-inline" placeholder="Confirm New Password" value={confirmedPassword} onChange={e=>setConfirmedPassword(e.target.value)} />
          </div>
        </div>
        <Button disabled={!canSubmitForm} className="mt-auto mb-3 w-100 indigo-button" type="submit">
          {isSubmitting ? <Spinner size="sm" animation="border" variant="light" /> : 'Reset Password'}
        </Button>
      </Fragment>
    )
  }

  const renderForLargeDevice = () => {
    return (
      <div className="w-100 h-100 d-flex flex-column">
        <h3 className="mt-4">Reset Password</h3>
        <GenericForm className="d-flex flex-column h-100" formContent={formContent} formRequest={restApi.resetPassword} params={{uidb64: uidb64, password: password, confirmedPassword: confirmedPassword}} handleSuccess={handlePasswordResetSuccess} />
      </div>
    )
  }

  const renderForSmallDevice = () => {
    return (
      <div className="mx-auto w-100">
        <h3>Reset Password</h3>
        <GenericForm className="d-flex flex-column h-100" formContent={formContent} formRequest={restApi.resetPassword} params={{uidb64: uidb64, password: password, confirmedPassword: confirmedPassword}} handleSuccess={handlePasswordResetSuccess} />
      </div>
    )
  }

  return screenWidth > 1199 ? renderForLargeDevice() : renderForSmallDevice()
}

export default ResetPassword
