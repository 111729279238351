// FontAwesome Icons
import { faAngleDown,
         faAngleUp,
         faAngleRight,
         faCircle,
         faFilter,
         faPlus,
         faMinus,
         faSearch,
         faTimes,
         faTimesCircle,
         faCheck,
         faCheckCircle,
         faExclamation,
         faExclamationCircle,
         faExclamationTriangle,
         faFillDrip,
         faUser,
         faUnlock,
         faSlash,
         faSortAmountUp,
         faSortAmountDownAlt,
         faFileSignature,
         faWeightHanging,
         faClock,
         faUndoAlt,
         faAt,
         faArrowLeft,
         faCog,
         faArrowRight,
         faQuestionCircle as fasQuestionCircle } from '@fortawesome/free-solid-svg-icons'

import {
  faEdit,
  faQuestionCircle as farQuestionCircle } from '@fortawesome/free-regular-svg-icons'

import { library } from '@fortawesome/fontawesome-svg-core'

library.add(
  faAngleDown,
  faAngleUp,
  faAngleRight,
  faCircle,
  faFilter,
  faPlus,
  faMinus,
  faSearch,
  faTimes,
  faTimesCircle,
  faCheck,
  faCheckCircle,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faFillDrip,
  faUser,
  faUnlock,
  faSlash,
  faSortAmountUp,
  faSortAmountDownAlt,
  faWeightHanging,
  fasQuestionCircle,
  farQuestionCircle,
  faFileSignature,
  faClock,
  faEdit,
  faUndoAlt,
  faAt,
  faArrowLeft,
  faCog,
  faArrowRight
)
