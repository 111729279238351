import _ from 'lodash'
import React, { Fragment, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import CardsList from '../generic/CardsList'
import CarrierColumns from './CarrierRefinableColumns'
import Graphql from '../Graphql'
import { graphqlApi } from '../Api'
import InteractableTable from '../generic/InteractableTable'
import RefinableList from '../generic/RefinableList'
import RefinableListSettingsModal from '../generic/RefinableListSettingsModal'
import Requestable from '../generic/Requestable'
import useScreenWidth from '../utils/useScreenWidth'
import { MINIMUM_SCREEN_WIDTH_FOR_TABLE_VIEW } from '../generic/interactableTableConstraints'

const carrierFields = ['id', 'name', 'mc_number', 'dot_number', 'prefixed_dot', 'city', 'state', 'postal', 'active', 'valid']

function Carriers(props) {
  const { companyId } = useParams()
  const [carriers, setCarriers] = useState([])
  const preferencedColumns  = useSelector(state => _.get(state.user.currentUser, 'settings.carriersColumns', []))
  const screenWidth = useScreenWidth()
  const [currentTab, setCurrentTab] = useState('active')
  const activeTab = currentTab === 'active'

  const selectableColumns = {
    name: CarrierColumns.name(companyId),
    valid: CarrierColumns.valid(companyId),
    mcNumber: CarrierColumns.mcNumber,
    usDOT: CarrierColumns.usDOT,
    city: CarrierColumns.city,
    state: CarrierColumns.state,
    postal: CarrierColumns.postal
  }

  const scopedSelectableColumns = activeTab ? selectableColumns : _.omit(selectableColumns, 'valid')

  const companyCarriersQuery = Graphql.company({
                                 fields: [
                                   'id',
                                   Graphql.relatedCarriers({
                                     fields: carrierFields
                                   })
                                 ]
                               })

  const companyCarriersLoadSuccess = (res) => {
    setCarriers(Graphql.getValue(res, 'company.carriers'))
  }

  const renderTable = () => {
    return (
      <RefinableList
        Renderer={InteractableTable}
        initialSelectedColumns={_.values(_.pick(scopedSelectableColumns, preferencedColumns))}
        initialSortedColumn="Name"
        selectableColumns={_.values(scopedSelectableColumns)}
        draggableType="carrierColumn"
        searchable
        searchPlaceholder="Search Carriers"
        searchIndex={`tc_carriers_${process.env.NODE_ENV}`}
        searchFilters={`related_companies_ids = ${companyId} AND active_for_companies_ids ${activeTab ? '=' : '!='} ${companyId}`}
        items={activeTab ? _.filter(carriers, 'active') : _.reject(carriers, 'active')}
        settings={<RefinableListSettingsModal selectableColumns={selectableColumns} userPreferencedColumns={preferencedColumns} refinableListSettingsName='carriersColumns' />}
        key={`${activeTab}_table`}
        pageSize={25}
      />
    )
  }

  const renderCards = () => {
    return (
      <RefinableList
        Renderer={CardsList}
        cardHeaderColumn={selectableColumns.name}
        initialSelectedColumns={_.values(_.pick(scopedSelectableColumns, _.without(preferencedColumns, 'name')))}
        initialSortedColumn="Name"
        selectableColumns={_.values(scopedSelectableColumns)}
        searchable
        searchPlaceholder="Search Carriers"
        searchIndex={`tc_carriers_${process.env.NODE_ENV}`}
        searchFilters={`related_companies_ids = ${companyId} AND active_for_companies_ids ${activeTab ? '=' : '!='} ${companyId}`}
        items={activeTab ? _.filter(carriers, 'active') : _.reject(carriers, 'active')}
        settings={<RefinableListSettingsModal selectableColumns={selectableColumns} userPreferencedColumns={preferencedColumns} refinableListSettingsName='carriersColumns' />}
        key={`${activeTab}_cards`}
        pageSize={10}
      />
    )
  }

  return (
    <Requestable
      onMountFetch={graphqlApi.execute(companyCarriersQuery, companyCarriersLoadSuccess)}
      render={() => {
          if (preferencedColumns.length > 0) {
            return (
              <Fragment>
                <Tabs activeKey={currentTab} className="carrier-tabs position-relative" id="carrier-tabs" onSelect={eventKey => setCurrentTab(eventKey)}>
                  <Tab title="Carriers" tabClassName="px-0" disabled style={{fontSize: '1.0625rem'}}>
                  </Tab>
                  <Tab eventKey='active' title="Active">
                  </Tab>
                  <Tab eventKey='inactive' title="Inactive">
                  </Tab>
                </Tabs>
                {screenWidth > MINIMUM_SCREEN_WIDTH_FOR_TABLE_VIEW ? renderTable() : renderCards()}
              </Fragment>
            )
          }
        }
      }
    />
  )
}

export default Carriers
