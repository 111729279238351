import React, { Fragment } from 'react'

const EquipmentTypes = React.memo(() => {
  return (
    <Fragment>
      <option value="DRY_VAN">Dry Van</option>
      <option value="FLATBED_HOTSHOT">Flatbed Hotshot</option>
      <option value="FLATBED">Flatbed</option>
      <option value="LARGE_STRAIGHT">Large Straight</option>
      <option value="REFRIGERATED">Refrigerated</option>
      <option value="REMOVABLE_GOOSENECK">Removable Gooseneck</option>
      <option value="SMALL_STRAIGHT">Small Straight</option>
      <option value="SPRINTER_VAN">Sprinter Van</option>
      <option value="STEP_DECK">Step Deck</option>
    </Fragment>
  )
})

export default EquipmentTypes
