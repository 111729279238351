import _ from 'lodash'
import { useCallback } from 'react'

function AppendableFieldsGroup(props) {
  const removeAppendedFields = useCallback((index, setAppendedCount) => {
    props.setState(prevState => {
      // If we're removing an appendage in the middle of an appended group,
      // we need to compact the list of keys within the appended group
      let i = 0

      return {
        ...prevState,
        [props.groupName]: _.mapKeys(_.omit(prevState[props.groupName], index), _ => i++)
      }
    })

    if (setAppendedCount) setAppendedCount(prevCount => prevCount - 1)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Set one
  const setAppendedFieldValue = useCallback((e, fieldName) => {
    let { id, value } = e.target
    value = e.target.type === 'checkbox' ? e.target.checked : value

    props.setState(prevState => {
      return {
        ...prevState,
        [props.groupName]: {
          ...props.group,
          [props.dataKey]: {
            ...props.group[props.dataKey],
            [fieldName || id]: value
          }
        }
      }
    })
  }, [props.group]) // eslint-disable-line react-hooks/exhaustive-deps

  // Set all appended fields
  const setAppendedFieldsValues = useCallback((fieldsObj) => {
    props.setState(prevState => {
      return {
        ...prevState,
        [props.groupName]: {
          ...props.group,
          [props.dataKey]: fieldsObj
        }
      }
    })
  }, [props.group]) // eslint-disable-line react-hooks/exhaustive-deps

  const getAppendedFieldValue = useCallback((fieldName) => {
    return _.get(props.group, [props.dataKey, fieldName]) || ''
  }, [props.group]) // eslint-disable-line react-hooks/exhaustive-deps

  return props.renderContent({
    getAppendableValue: getAppendedFieldValue,
    setAppendableValue: setAppendedFieldValue,
    setAppendableValues: setAppendedFieldsValues,
    removeAppendedFields: removeAppendedFields
  })
}

export default AppendableFieldsGroup
