import _ from 'lodash'
import React, { createContext, useCallback, useEffect, useRef, useState, Fragment } from 'react'
import { Badge, Button, Col, FloatingLabel, Form, Nav, Row, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, Route, Routes } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import BOLCarrierValidator from './BOLCarrierValidator'
import BOLCosts from './BOLCosts'
import BOLDescriptionsAndReferences from './BOLDescriptionsAndReferences'
import BOLHistory from './BOLHistory'
import BOLReceivers from './BOLReceivers'
import CurrencyInput from '../forms/CurrencyInput'
import EquipmentTypes from './EquipmentTypes'
import Esignable from '../esign/Esignable'
import FormDocument from '../forms/FormDocument'
import FormDropdownSearch from '../search/FormDropdownSearch'
import GenericForm from '../forms/GenericForm'
import Invoices from '../invoices/Invoices'
import LoadPostingEditor from '../loads/LoadPostingEditor'
import RateLookup from '../loads/RateLookup'
import Requestable from '../generic/Requestable'
import Tooltip from '../generic/Tooltip'
import TrackingHistory from './TrackingHistory'
import TruckSearch from '../truckSearch/TruckSearch'
import { appendNotification } from '../notifications/notificationsSlice'
import { graphqlApi} from '../Api'
import { restApi } from '../Api'
import { setConfirmableAction } from '../generic/confirmableActionSlice'

export const BOLContext = createContext({})

function BOL(props) {
  const initialFormState = () => {
    return {
      status: 'OPEN',
      equipmentType: 'DRY_VAN',
      descriptionsList: {},
      costsList: {},
      receiversList: {}
    }
  }

  const [formData, setFormData] = useState(() => initialFormState())
  const [BOLNumber, setBOLNumber] = useState('')
  const loadBoardsPostedTo = useRef({})
  const [signedRateConfirmationData, setSignedRateConfirmationData] = useState({})
  const [trackingUpdates, setTrackingUpdates] = useState(null)
  const [autoCoveredOn, setAutoCoveredOn] = useState(true)
  const [autoInTransitOn, setAutoInTransitOn] = useState(true)
  const [autoDeliveredOn, setAutoDeliveredOn] = useState(true)
  const [origin, setOrigin] = useState({})
  const lastReceiver = _.last(_.values(formData.receiversList)) || {}
  const companyId = useSelector(({user}) => user.currentUser.last_used_company_id)
  const dispatch = useDispatch()

  useEffect(() => {
    const calculatedCost = _.sumBy(_.values(formData.costsList), listEntry => listEntry.amount ? _.toNumber(listEntry.amount.replace(/,/g, '')) : 0.0)
    setFormData(prevState => { return {...prevState, totalCost: calculatedCost.toFixed(2)} })
  }, [formData.costsList]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
      let costPerMile = _.divide(_.toNumber(formData.totalCost), _.toNumber(formData.totalMiles)).toFixed(2)
      costPerMile = isNaN(costPerMile) || !isFinite(costPerMile) ? 0.0.toFixed(2) : costPerMile
      setFormData(prevState => { return {...prevState, costPerMile: costPerMile} })
  }, [formData.totalCost, formData.totalMiles]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (formData.carrierId && formData.status === 'OPEN') {
      setFormData(prevState => { return {...prevState, status: 'COVERED'} })
    }
  }, [formData.carrierId]) // eslint-disable-line react-hooks/exhaustive-deps

  const onMountFetchCallback = props.onMountFetchCallback.bind(
    undefined,
    {setBOLNumber: setBOLNumber, setFormData: setFormData, setOrigin: setOrigin, setSignedRateConfirmationData: setSignedRateConfirmationData, setTrackingUpdates: setTrackingUpdates, loadBoardsPostedTo: loadBoardsPostedTo}
  )

  const handleSuccess = props.handleSuccess.bind(
    undefined,
    {setFormData: setFormData}
  )

  const handleOriginSearchSelection = hitAttributes => {
    setFormData(prevState => {
      return {
        ...prevState,
        originId: hitAttributes.objectID
      }
    })
    setOrigin(hitAttributes)
  }

  const handleRateConfirmationResetResponse = (additionalAction) => {
    return (_res) => {
      setSignedRateConfirmationData({})
      dispatch(appendNotification({type: 'EsignAction', verb: 'reset'}))
      additionalAction && additionalAction()
    }
  }

  const resetRateConfirmation = (performRequest, esignableId, additionalAction) => {
    dispatch(setConfirmableAction(
                                   {performAction: performRequest,
                                    action: restApi.resetEsignable({esignable_document_id: esignableId}, handleRateConfirmationResetResponse(additionalAction)),
                                    details: {title: 'Reseting Rate Confirmation Esignable', confirmationBody: <div>You are attempting to reset this bill of lading's e-signable rate confirmation document. This is an <b>irreversible</b> action. You will have to generate a new rate confirmation and initiate the esign process again if you require a signed rate confirmation.</div>}}
                                ))
  }

  const rateConfirmationLabelExtra = useCallback((esignableId) => {
    if (formData.version && signedRateConfirmationData.name && formData.version > parseInt(_.last(_.split(signedRateConfirmationData.name , '_')))) {
      return (
        <Requestable
          withoutLoading
          render={({performRequest, isLoading}) => {
            return <Badge bg="danger" className="my-auto ms-auto cursor-pointer text-white" onClick={_ => resetRateConfirmation(performRequest, esignableId)}>
                     Reset Esignable Document
                   </Badge>
          }}
        />
      )
    } else if (_.isNil(esignableId) && (formData.status === 'IN_TRANSIT' || formData.status === 'DELIVERED')) {
      const handleRateConfirmationRegenResponse = (_res) => {
        dispatch(appendNotification({type: 'BOLUpdated', number: formData.number}))
      }

      const regenerateRateConfirmation = (performRequest) => {
        performRequest(restApi.regenerateRateConfirmation({'id': formData.id}, handleRateConfirmationRegenResponse))
      }

      return (
        <Requestable
          withoutLoading
          render={({performRequest, isLoading}) => {
            if (isLoading) {
              return <Badge bg="light" className="mb-auto ms-auto cursor-pointer loading" style={{width: '100px'}}>
                       <Spinner size="sm" animation="border" variant="secondary" />
                     </Badge>
            } else {
              return <Badge bg="light" className="my-auto ms-auto cursor-pointer" onClick={_ => regenerateRateConfirmation(performRequest)} style={{width: '100px'}}>
                       <div>Regenerate</div>
                     </Badge>
            }
          }}
        />
      )
    }
  }, [formData.status, formData.version, signedRateConfirmationData.name]) // eslint-disable-line react-hooks/exhaustive-deps

  const formContent = ({handleFormChange, handleFormSearchField, isSubmitting}) => {
    return (
      <Fragment>
        <Row>
          <Col xl={6} xs={12}>
            <Row className="g-0">
              <Col className="form-sub-header">
                <h5>Tracking</h5>
              </Col>
            </Row>
            <Row>
              <Col md={6} xs={12}>
                <Tooltip id="bol-number-tooltip" show={!formData.id} text="This field is auto-populated with the next available BOL Number">
                  <FloatingLabel controlId="number" label="BOL Number">
                    <Form.Control type="text" readOnly value={BOLNumber} />
                  </FloatingLabel>
                </Tooltip>
              </Col>
              <Col>
                <FloatingLabel controlId="status" label="Status">
                  <Form.Select value={formData.status} onChange={e=>handleFormChange(setFormData, e)} >
                    <option value="OPEN">Open</option>
                    <option value="COVERED">Covered</option>
                    <option value="IN_TRANSIT">In transit</option>
                    <option value="DELIVERED">Delivered</option>
                    <option value="CANCELLED">Cancelled</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col md={4} xs={12}>
                <Form.Floating>
                  <Form.Control id="coveredOn" type="date" disabled={autoCoveredOn} value={formData.coveredOn || ''} onChange={e=>handleFormChange(setFormData, e)} />
                  <label htmlFor="coveredOn" className="d-flex pe-auto w-100">
                    Covered Date
                    <Form.Switch
                      id="manualCoveredOn"
                      className="ms-2" checked={!autoCoveredOn}
                      onChange={e=> setAutoCoveredOn(!autoCoveredOn)}
                      label={
                        <Tooltip id="covered-on-tooltip" text="This date is automatically populated but can be manually specified if necessary">
                          <FontAwesomeIcon icon={['far', 'question-circle']} />
                        </Tooltip>
                      }
                    />
                  </label>
                </Form.Floating>
              </Col>
              <Col md={4} xs={12}>
                <Form.Floating>
                  <Form.Control id="inTransitOn" type="date" disabled={autoInTransitOn} value={formData.inTransitOn || ''} onChange={e=>handleFormChange(setFormData, e)} />
                  <label htmlFor="inTransitOn" className="d-flex pe-auto w-100">
                    In Transit Date
                    <Form.Switch
                      id="manualInTransitOn"
                      className="ms-2" checked={!autoInTransitOn}
                      onChange={e=> setAutoInTransitOn(!autoInTransitOn)}
                      label={
                        <Tooltip id="in-transit-on-tooltip" text="This date is automatically populated but can be manually specified if necessary">
                          <FontAwesomeIcon icon={['far', 'question-circle']} />
                        </Tooltip>
                      }
                    />
                  </label>
                </Form.Floating>
              </Col>
              <Col>
                <Form.Floating>
                  <Form.Control id="deliveredOn" type="date" disabled={autoDeliveredOn} value={formData.deliveredOn || ''} onChange={e=>handleFormChange(setFormData, e)} />
                  <label htmlFor="deliveredOn" className="d-flex pe-auto w-100">
                    Delivered Date
                    <Form.Switch
                      id="manualDeliveredOn"
                      className="ms-2" checked={!autoDeliveredOn}
                      onChange={e=> setAutoDeliveredOn(!autoDeliveredOn)}
                      label={
                        <Tooltip id="delivered-on-tooltip" text="This date is automatically populated but can be manually specified if necessary">
                          <FontAwesomeIcon icon={['far', 'question-circle']} />
                        </Tooltip>
                      }
                    />
                  </label>
                </Form.Floating>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormDropdownSearch
                  controlId='originId'
                  controlledFormData={formData.originId}
                  label="Location of Origin"
                  initialRefinement={origin.name}
                  valueOnSelect='name'
                  filters={`company_id = ${companyId}`}
                  relevantHitValues={['name', 'address_1', 'address_2', 'city', 'state', 'postal', 'phone', 'email']}
                  handleHitSelection={handleOriginSearchSelection}
                  indexName={`tc_origins_${process.env.NODE_ENV}`}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} xs={12}>
                <FloatingLabel controlId="pickUpDate" label="Pick-Up Date">
                  <Form.Control required type="date" value={formData.pickUpDate || ''} onChange={e=>handleFormChange(setFormData, e)} />
                  <Form.Control.Feedback type="invalid">Pick-Up Date is required</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col>
                <Form.Floating>
                  <Form.Control id="pickUpTime" type="time" disabled={formData.pickUpTimeNotSpecified} value={formData.pickUpTime || ''} onChange={e=>handleFormChange(setFormData, e)} />
                  <label htmlFor="pickUpTime" className="d-flex pe-auto">
                    Pick-Up Time
                    <Form.Switch id="pickUpTimeNotSpecified" className="ms-2" label="Upon Arrival" checked={formData.pickUpTimeNotSpecified || false} onChange={e=>handleFormChange(setFormData, e)} />
                  </label>
                </Form.Floating>
              </Col>
            </Row>
            <Row>
              <Col md={6} xs={12}>
                <FloatingLabel controlId="deliveryDate" label="Delivery Date">
                  <Form.Control required type="date" value={formData.deliveryDate || ''} onChange={e=>handleFormChange(setFormData, e)} />
                  <Form.Control.Feedback type="invalid">Delivery Date is required</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col>
                <Form.Floating>
                  <Form.Control id="deliveryTime" type="time" disabled={formData.deliveryTimeNotSpecified} value={formData.deliveryTime || ''} onChange={e=>handleFormChange(setFormData, e)} />
                  <label htmlFor="deliveryTime" className="d-flex pe-auto">
                    Delivery Time
                    <Form.Switch id="deliveryTimeNotSpecified" className="ms-2" label="Upon Arrival" checked={formData.deliveryTimeNotSpecified || false} onChange={e=>handleFormChange(setFormData, e)} />
                  </label>
                </Form.Floating>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormDropdownSearch
                  controlId='carrierId'
                  controlledFormData={formData.carrierId}
                  initialRefinement={props.carrierName}
                  valueOnSelect='name'
                  filters={`active_for_companies_ids = ${companyId}`}
                  relevantHitValues={['name', 'mc_number']}
                  handleHitSelection={handleFormSearchField(setFormData, 'carrierId', 'objectID')}
                  indexName={`tc_carriers_${process.env.NODE_ENV}`}
                  label={
                    <Fragment>
                      <span className="opacity-75">Carrier </span>
                      <BOLCarrierValidator carrierId={formData.carrierId} />
                      {formData.carrierId && <small><Link className="ms-1" to={`/company/${companyId}/carriers/view/${formData.carrierId}`}>Go to carrier page</Link></small>}
                    </Fragment>
                  } />
              </Col>
            </Row>
            <Row>
              <Col md={6} xs={12}>
                <FloatingLabel controlId="truckNumber" label="Truck/Trailer No.">
                  <Form.Control type="text" placeholder="Truck/Trailer Number" value={formData.truckNumber || ''} onChange={e=>handleFormChange(setFormData, e)} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="equipmentType" label="Equipment Type">
                  <Form.Select value={formData.equipmentType} onChange={e=>handleFormChange(setFormData, e)} >
                    <EquipmentTypes />
                  </Form.Select>
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col md={4} xs={12}>
                <FloatingLabel controlId="dispatchName" label="Dispatch Name">
                  <Form.Control type="text" placeholder="Dispatch Name" value={formData.dispatchName || ''} onChange={e=>handleFormChange(setFormData, e)} />
                </FloatingLabel>
              </Col>
              <Col md={4} xs={12}>
                <FloatingLabel controlId="dispatchPhone" label="Dispatch Phone Number">
                  <Form.Control type="tel" placeholder="Phone" value={formData.dispatchPhone || ''} onChange={e=>handleFormChange(setFormData, e)} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="dispatchEmail" label="Dispatch Email">
                  <Form.Control type="email" placeholder="Email" value={formData.dispatchEmail || ''} onChange={e=>handleFormChange(setFormData, e)} />
                  <Form.Control.Feedback type="invalid">Email is invalid</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col md={4} xs={12}>
                <FloatingLabel controlId="driverName" label="Driver Name">
                  <Form.Control type="text" placeholder="Driver Name" value={formData.driverName || ''} onChange={e=>handleFormChange(setFormData, e)} />
                </FloatingLabel>
              </Col>
              <Col md={4} xs={12}>
                <FloatingLabel controlId="driverPhone" label="Driver Phone Number">
                  <Form.Control type="tel" placeholder="Phone" value={formData.driverPhone || ''} onChange={e=>handleFormChange(setFormData, e)} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="driverEmail" label="Driver Email">
                  <Form.Control type="email" placeholder="Email" value={formData.driverEmail || ''} onChange={e=>handleFormChange(setFormData, e)} />
                  <Form.Control.Feedback type="invalid">Email is invalid</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row className="g-0">
              <Col className="form-sub-header">
                <h5>Freight Details</h5>
              </Col>
            </Row>
            <Row>
              <Col xl={6} xs={12}>
                <FloatingLabel controlId="weight" label="Total Weight">
                  <Form.Control type="number" placeholder="Weight" value={formData.weight || ''} onChange={e=>handleFormChange(setFormData, e)} />
                </FloatingLabel>
              </Col>
              <Col xl={6} xs={12}>
                <FloatingLabel controlId="equipmentLength" label="Required Truck Length (ft)">
                  <Form.Control type="number" placeholder="Length" value={formData.equipmentLength || ''} onChange={e=>handleFormChange(setFormData, e)} />
                </FloatingLabel>
              </Col>
            </Row>
            <h5>Cargo List</h5>
            <BOLDescriptionsAndReferences formData={formData.descriptionsList} setFormData={setFormData} />
          </Col>
        </Row>
        <Row>
          <Col xl={6} xs={12}>
            <Row className="g-0">
              <Col className="form-sub-header">
                <h5>Receivers</h5>
              </Col>
            </Row>
            <BOLReceivers companyId={companyId} formData={formData.receiversList} setFormData={setFormData} />
          </Col>
          <Col>
            <Row className="g-0">
              <Col className="form-sub-header">
                <h5>Notes</h5>
              </Col>
            </Row>
            <Row>
              <Col md={6} xs={12}>
                <FloatingLabel controlId="rateConfirmationNotes" label="Rate Confirmation Notes">
                  <Form.Control as="textarea" placeholder="Rate Confirmation Notes" value={formData.rateConfirmationNotes || ''} onChange={e=>handleFormChange(setFormData, e)} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="bolNotes" label="BOL Notes">
                  <Form.Control as="textarea" placeholder="BOL Notes" value={formData.bolNotes || ''} onChange={e=>handleFormChange(setFormData, e)} />
                </FloatingLabel>
              </Col>
            </Row>
            <Row className="g-0">
              <Col className="form-sub-header">
                <h5>Documents</h5>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12}>
                <FormDocument id="billOfLading"
                              fileExists={!_.isEmpty(formData.billOfLading)}
                              filePath={`/company/${companyId}/documents/bill_of_lading/${formData.id}`}
                              emailable
                              relatedObjId={formData.id}
                              relatedObjName="BOL"
                              disabled
                              fileLabel="Bill of Lading" />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12}>
                <Esignable As={FormDocument}
                           initialEsignableId={signedRateConfirmationData.id}
                           initialEsignRecipientsData={signedRateConfirmationData.vendorRecipients}
                           esignResetter={resetRateConfirmation}
                           id="rateConfirmation"
                           fileExists={!_.isEmpty(formData.rateConfirmation)}
                           filePath={`/company/${companyId}/documents/rate_confirmation/${formData.id}`}
                           emailable
                           externalSigners={['carrier']}
                           relatedObjId={formData.id}
                           relatedObjName="BOL"
                           disabled
                           fileLabel="Rate Confirmation"
                           labelExtra={rateConfirmationLabelExtra} />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12}>
                <FormDocument id="proofOfDelivery"
                              fileExists={!_.isEmpty(formData.proofOfDelivery)}
                              filePath={`/company/${companyId}/documents/proof_of_delivery/${formData.id}`}
                              emailable
                              relatedObjId={formData.id}
                              relatedObjName="BOL"
                              fileLabel="Proof of Delivery"
                              fileSource={formData.proofOfDelivery}
                              handleDocumentChange={e=>handleFormChange(setFormData, e)} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="g-0" style={{ paddingRight: '30px' }}>
          <Col xl={6} xs={12} className="form-sub-header">
            <h5>Financial</h5>
          </Col>
        </Row>
        <Row>
          <Col xl={6} xs={12}>
            <h5>Costs List</h5>
            <BOLCosts formData={formData.costsList} setFormData={setFormData} />
            <Row>
              <Col xs={12}>
                <FloatingLabel controlId="totalCost" label="Total Cost">
                  <CurrencyInput disabled placeholder="0.00" className="form-control" value={formData.totalCost} onChange={e=>handleFormChange(setFormData, e)} />
                </FloatingLabel>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12}>
            <Button className="indigo-button w-100 mb-2" type="submit">
              {
                isSubmitting ? <Spinner size="sm" animation="border" variant="light" />
                  : _.isNil(formData.id) ? 'Create'
                  : 'Update'
              }
            </Button>
          </Col>
          {_.isNil(formData.id) &&
            <Col xs={12}>
              <Button variant="secondary" className="w-100" onClick={e=>setFormData(initialFormState())}>
                Reset
              </Button>
            </Col>
          }
        </Row>
      </Fragment>
    )
  }

  return (
    <Requestable
      onMountFetch={graphqlApi.execute(props.onMountFetchQuery, onMountFetchCallback)}
      render={() => {
          return (
            <Fragment>
              <Row className="form-sub-header mb-2 g-0">
                <Col>
                  <h5 className="d-inline">
                    {
                      formData.id ? `Bill of Lading #${BOLNumber}` : 'New Bill of Lading'
                    }
                  </h5>
                </Col>
              </Row>
              <BOLContext.Provider value={formData}>
                <Nav variant="pills" className="mb-2">
                  <Nav.Item>
                    <NavLink className='nav-link' to='.' end>Details</NavLink>
                  </Nav.Item>
                  {
                    formData.id && formData.status === 'OPEN' &&
                    <Fragment>
                      <Nav.Item>
                        <NavLink className='nav-link' to='post-load'>{loadBoardsPostedTo.current[formData.id] ? 'Edit Load Posting(s)' : 'Post to Load Board(s)'}</NavLink>
                      </Nav.Item>
                      <Nav.Item>
                        <NavLink className='nav-link' to='search-trucks'>Search for Trucks</NavLink>
                      </Nav.Item>
                    </Fragment>
                  }
                  {
                    formData.id &&
                      <>
                        <Nav.Item>
                          <NavLink className='nav-link' to='invoices'>Invoices</NavLink>
                        </Nav.Item>
                        <Nav.Item>
                          <NavLink className='nav-link' to='tracking'>Tracking History</NavLink>
                        </Nav.Item>
                      </>
                  }
                  <Nav.Item>
                    <NavLink className='nav-link' to='rate-data'>Rate Data</NavLink>
                  </Nav.Item>
                </Nav>
                <Routes>
                  <Route index element={
                    <>
                      <GenericForm id="bol-form" formContent={formContent} formRequest={props.formRequest} params={formData} handleSuccess={handleSuccess} />
                      {formData.version > 1 && <BOLHistory bolId={formData.id} latestVersion={formData.version} />}
                    </>
                  } />
                  {
                    formData.id &&
                    <>
                      <Route path='post-load' element={<LoadPostingEditor initialSelectedPostables={[formData.id]} postablesLoadBoardsMap={loadBoardsPostedTo} />} />
                      <Route path='invoices' element={<Invoices bolId={formData.id} />} />
                      <Route path='rate-data' element={
                        <RateLookup initOriginCity={origin.city}
                          initOriginState={origin.state}
                          initOriginPostal={origin.postal}
                          initDestinationCity={lastReceiver.city}
                          initDestinationState={lastReceiver.state}
                          initDestinationPostal={lastReceiver.postal}
                          initEquipment={formData.equipmentType} />
                        }
                      />
                      <Route path='tracking' element={<TrackingHistory initialTrackingDatas={{[formData.number]: trackingUpdates}} />} />
                      <Route path='search-trucks' element={<TruckSearch bolId={formData.id} />} />
                    </>
                  }
                </Routes>
              </BOLContext.Provider>
            </Fragment>
          )
        }
      }
    />
  )
}

export default BOL
