import _ from "lodash"
import React, { useEffect, useRef, useState } from "react"
import { Alert, Button, CloseButton, FloatingLabel, Form, Modal, Overlay, Placeholder, Popover, Spinner } from "react-bootstrap"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { decimalCurrencyFormatter } from "../utils/CurrencyFormatter"
import GenericForm from "../forms/GenericForm"
import Graphql from "../Graphql"
import { graphqlApi, restApi } from "../Api"
import Requestable from "../generic/Requestable"

const PaymentDetailPopover = React.forwardRef(({ hidePopover, invoice, popper, postCancellationCallback, ...props }, ref) => {
  const [transferDetails, setTransferDetails] = useState(null)

  useEffect(() => {
    popper.scheduleUpdate()
  }, [transferDetails, popper])

  const transferDetailsQuery = Graphql.company({
    fields: [
      Graphql.relatedDwollaCustomer({
        fields: [
          `transfer_details(transfer_id: "${invoice.transfer_id}")`
        ]
      })
    ]
  })

  const fetchedTransferDetailsSuccess = ({data}) => {
    setTransferDetails(JSON.parse(data.data.company.dwolla_customer.transfer_details))
  }

  const handleTransferCancelledSuccess = res => {
    hidePopover()
    postCancellationCallback(res, invoice.id, invoice.number, 'Transfer cancellation')
  }

  return (
    <Popover id={`invoice-payment-${invoice.id}`} ref={ref} {...props}>
      <Requestable
        withoutLoading
        onMountFetch={graphqlApi.execute(transferDetailsQuery, fetchedTransferDetailsSuccess)}
        render={() => {
          if (_.isNull(transferDetails)) {
            return (
              <>
                <Popover.Header as="h3" style={{minWidth: '16rem'}}>
                  <Placeholder animation="glow">
                    <Placeholder xs={5} className="rounded" />
                  </Placeholder>
                </Popover.Header>
                <Popover.Body>
                  <Placeholder animation="glow">
                    <Placeholder xs={5} className="rounded" />
                    <Placeholder xs={12} className="rounded" />
                    <Placeholder xs={12} className="rounded" />
                    <Placeholder xs={12} className="rounded" />
                    <Placeholder xs={12} className="rounded" />
                  </Placeholder>
                </Popover.Body>
              </>
            )
          } else {
            return (
              <>
                <Popover.Header as="h3" className="d-flex">
                  Payment Details
                  <CloseButton onClick={hidePopover} className="ms-auto" />
                </Popover.Header>
                <Popover.Body>
                  <div><b>Amount:</b> {decimalCurrencyFormatter.format(transferDetails.transfer.amount.value)}</div>
                  <div><b>Receiving Account:</b> {transferDetails.destination_funding_source.name}</div>
                  <div className="text-muted">Held by: {transferDetails.transfer.metadata.carrier_name}</div>
                  <div><b>Sending Account:</b> {transferDetails.source_funding_source.name}</div>
                  <div><b>Status: </b> {_.capitalize(transferDetails.transfer.status)}</div>
                  {
                    _.get(transferDetails.transfer, '_links.cancel') &&
                    <GenericForm
                      params={{uuid: transferDetails.transfer.id}}
                      formRequest={restApi.cancelTransfer}
                      handleSuccess={handleTransferCancelledSuccess}
                      formContent={({ isSubmitting }) => {
                        return (
                          <Button variant="danger" className="w-100 mt-2" disabled={isSubmitting} type="submit">
                            {
                              isSubmitting ? <Spinner animation="border" size="sm" /> : 'Cancel Pending Transfer'
                            }
                          </Button>
                        )
                      }}
                    />
                  }
                </Popover.Body>
              </>
            )
          }
        }}
      />
    </Popover>
  )
})

function PayInvoiceModal({ bol, invoice, postPaymentCallback }) {
  const companyId = useSelector(({user}) => user.currentUser.last_used_company_id)
  const paymentDetailOverlayTarget = useRef(null)
  const [show, setShow] = useState(false)
  const [shipperFundingSources, setShipperFundingSources] = useState([])
  const [carrierFundingSources, setCarrierFundingSources] = useState([])
  const [formData, setFormData] = useState({amount: invoice.total, invoiceId: invoice.id})

  const fundingSourcesQuery = Graphql.fundingSourceOptions({filters: {invoice_id: `"${invoice.id}"`}})
  const fetchedFundingSourcesSuccess = ({data}) => {
    const fundingSourceOptions = JSON.parse(data.data.funding_source_options)
    setShipperFundingSources(fundingSourceOptions.shipper_funding_sources)
    setCarrierFundingSources(fundingSourceOptions.carrier_funding_sources)
    setFormData(prevFormData => {
      return {
        ...prevFormData,
        shipperFundingSourceId: _.get(fundingSourceOptions, 'shipper_funding_sources[0].id'),
        carrierFundingSourceId: _.get(fundingSourceOptions, 'carrier_funding_sources[0].id')
      }
    })
  }

  const payInvoiceSuccess = res => {
    setShow(false)
    postPaymentCallback(res, invoice.id, invoice.number)
  }

  if (invoice.paid) {
    return (
      <>
        <Button ref={paymentDetailOverlayTarget} onClick={_ => setShow(!show)} variant='success'>Paid</Button>
        <Overlay target={paymentDetailOverlayTarget.current} show={show} placement="auto" flip>
          { props => <PaymentDetailPopover invoice={invoice} postCancellationCallback={postPaymentCallback} hidePopover={_ => setShow(false)} {...props} /> }
        </Overlay>
      </>
    )
  }

  return (
    <>
      <Button variant='outline-secondary' onClick={() => setShow(true)} >
        Pay Invoice
      </Button>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Pay Invoice <b>{invoice.number}</b> for BOL #{bol.number}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Requestable
          withoutLoading
          onMountFetch={graphqlApi.execute(fundingSourcesQuery, fetchedFundingSourcesSuccess)}
          render={({ isLoading }) => {
            if (isLoading) return (
              <>
                <Placeholder animation="glow">
                  <Placeholder xs={12} className="mb-3 rounded" style={{height: '3.5rem'}} />
                  <Placeholder xs={12} className="mb-3 rounded" style={{height: '3.5rem'}} />
                  <Placeholder xs={12} className="mb-3 rounded" style={{height: '3.5rem'}} />
                  <Placeholder.Button xs={12} className="rounded" size="lg" bg="dark" />
                </Placeholder>
              </>
            )
            return <GenericForm
              formRequest={restApi.payInvoice}
              handleSuccess={payInvoiceSuccess}
              params={formData}
              formContent={({ handleFormChange, isSubmitting }) => {
                return (
                  <>
                    {
                      shipperFundingSources.length === 0 ?
                      <Alert variant="danger">
                        You have not linked any bank accounts to pay from. Please&nbsp;
                        <Link to={`/company/${companyId}/settings/payments`}>link a bank account(s)</Link>
                        &nbsp;if you want to pay carriers from within the application.
                      </Alert>
                      :
                      <FloatingLabel controlId="shipperFundingSourceId" label="Choose one of your bank accounts to pay from" className="mb-3">
                        <Form.Select aria-label="Choose one of your bank accounts to pay from">
                          {
                            _.map(shipperFundingSources, fundingSource => {
                              return <option key={fundingSource.id} value={fundingSource.id}>{fundingSource.name}</option>
                            })
                          }
                        </Form.Select>
                      </FloatingLabel>
                    }
                    {
                      carrierFundingSources.length === 0 ?
                      <Alert variant="danger">
                        This carrier has no receive-only bank account(s) linked to its profile. Please&nbsp;
                        <Link to={`/company/${companyId}/carriers/view/${bol.carrier.id}`}>link a bank account(s)</Link>
                        &nbsp;to the carrier's profile if you want to pay them from within the application.
                      </Alert>
                      :
                      <FloatingLabel controlId="carrierFundingSourceId" label="Choose one of the carrier's bank account to pay to" className="mb-3">
                        <Form.Select aria-label="Choose one of the carrier's bank account to pay to">
                          {
                            _.map(carrierFundingSources, fundingSource => {
                              return <option key={fundingSource.id} value={fundingSource.id}>{fundingSource.name}</option>
                            })
                          }
                        </Form.Select>
                      </FloatingLabel>
                    }
                    <FloatingLabel controlId="amount" label="Amount">
                      <Form.Control required type="number" placeholder="Amount" value={formData.amount} onChange={e=>handleFormChange(setFormData, e)} />
                      <Form.Control.Feedback type="invalid">Amount is required</Form.Control.Feedback>
                      {
                        invoice.total !== bol.total_cost &&
                        <Form.Text className="text-danger">
                          The invoice total is <b>${invoice.total}</b> but the cost recorded on the BOL is <b>${bol.total_cost}</b>. Use extra care to ensure the amount entered to pay is correct.
                        </Form.Text>
                      }
                    </FloatingLabel>
                    <Button className="indigo-button w-100" type="submit" disabled={!formData.shipperFundingSourceId || !formData.carrierFundingSourceId}>
                      {
                        isSubmitting ? <Spinner size="sm" animation="border" variant="light" /> : 'Pay'
                      }
                    </Button>
                  </>
                )
              }}
            />
          }}
        />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default PayInvoiceModal
