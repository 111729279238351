import { createSlice } from '@reduxjs/toolkit'

let initialState = {
  currentEsignableId: null,
  currentEsignableLink: null
}

const esignSlice = createSlice({
  name: 'esign',
  initialState,
  reducers: {
    setCurrentEsignable(state, action) {
      state.currentEsignableId = action.payload.currentEsignableId
      state.currentEsignableLink = action.payload.currentEsignableLink
    },
    clearCurrentEsignable(state, _action) {
      state.currentEsignableId = null
      state.currentEsignableLink = null
    }
  },
  extraReducers: {

  }
})

export const {
  setCurrentEsignable,
  clearCurrentEsignable
} = esignSlice.actions

export default esignSlice.reducer
