import axios from 'axios'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

// Submit CSRF token in header of every call for Django's CSRF protection
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
// Add arbitrary header to determine axios request
axios.defaults.headers.common['Axios-Request'] = 'true'

const REST_API = '/api/'
const GRAPHQL_API = '/graphql'

export const restApi = buildRequests({
  // Auth
  loginUser: [REST_API, 'POST', 'login'],
  logoutUser: [REST_API, 'LOGOUT', 'logout'],
  checkAuth: [REST_API, 'PING', 'ping'],
  resetPassword: [REST_API, 'POST', 'reset_password'],
  forgotPassword: [REST_API, 'POST', 'forgot_password'],
  // BOL
  createBOL: [REST_API, 'POST', 'bol', {'Content-Type': 'multipart/form-data'}],
  updateBOL: [REST_API, 'PATCH', 'bol', {'Content-Type': 'multipart/form-data'}],
  regenerateRateConfirmation: [REST_API, 'REGENERATE_RATE_CONFIRMATION', 'bol'],
  uploadProofOfDelivery: [REST_API, 'PROOF_OF_DELIVERY', 'bol_document_upload', {'Content-Type': 'multipart/form-data'}],
  // Carriers
  onboardCarrier: [REST_API, 'POST', 'carrier'],
  removeCarrier: [REST_API, 'DELETE', 'carrier'],
  // Company
  updateCompany: [REST_API, 'PATCH', 'company', {'Content-Type': 'multipart/form-data'}],
  // Company Settings
  updateCompanySettings: [REST_API, 'PATCH', 'company_settings'],
  updateCompanyLoadPostingsSettings: [REST_API, 'LOAD_POSTINGS', 'company_settings'],
  // Invoices
  cancelTransfer: [REST_API, 'CANCEL_TRANSFER', 'invoice'],
  deleteInvoice: [REST_API, 'DELETE', 'invoice'],
  payInvoice: [REST_API, 'PAY', 'invoice'],
  updateInvoice: [REST_API, 'PATCH', 'invoice', {'Content-Type': 'multipart/form-data'}],
  uploadInvoice: [REST_API, 'POST', 'invoice', {'Content-Type': 'multipart/form-data'}],
  uploadCarrierInvoice: [REST_API, 'INVOICE', 'bol_document_upload', {'Content-Type': 'multipart/form-data'}],
  // Loads
  createLoads: [REST_API, 'CREATE_LOADS', 'load'],
  createDATLoads: [REST_API, 'CREATE_DAT_LOADS', 'load'],
  updateLoads: [REST_API, 'UPDATE_LOADS', 'load'],
  updateDATLoads: [REST_API, 'UPDATE_DAT_LOADS', 'load'],
  deleteLoads: [REST_API, 'DELETE_LOADS', 'load'],
  deleteDATLoads: [REST_API, 'DELETE_DAT_LOADs', 'load'],
  // Locations
  createLocation: [REST_API, 'POST', 'location'],
  updateLocation: [REST_API, 'PATCH', 'location'],
  deleteLocation: [REST_API, 'DELETE', 'location'],
  // Emails
  emailDocument: [REST_API, 'EMAIL_DOCUMENT', 'email'],
  // Esign
  initiateEsign: [REST_API, 'INITIATE_ESIGN', 'esign'],
  getSignableLink: [REST_API, 'GENERATE_SIGNABLE_LINK', 'esign'],
  requestEsignature: [REST_API, 'REQUEST_ESIGNATURE', 'esign'],
  resetEsignable: [REST_API, 'RESET_ESIGN', 'esign'],
  // Notifications
  updateNotification: [REST_API, 'PATCH', 'notification'],
  //Origins
  createOrigin: [REST_API, 'POST', 'origin'],
  updateOrigin: [REST_API, 'PATCH', 'origin'],
  // Payments
  ackDwollaCustomerCreation: [REST_API, 'ACK_CUSTOMER_CREATION', 'payment'],
  checkDwollaCustomerOnboardingStatus: [REST_API, 'CHECK_CUSTOMER_STATUS', 'payment'],
  createDwollaClientToken: [REST_API, 'POST', 'create_dwolla_client_token'],
  getPlaidPublicToken: [REST_API, 'CREATE_PLAID_PUBLIC_TOKEN', 'payment'],
  createFundingSource: [REST_API, 'CREATE_FUNDING_SOURCE', 'payment'],
  removeFundingSource: [REST_API, 'REMOVE_FUNDING_SOURCE', 'payment'],
  updateFundingSourceName: [REST_API, 'UPDATE_FUNDING_SOURCE', 'payment'],
  // Receivers
  createReceiver: [REST_API, 'POST', 'receiver'],
  updateReceiver: [REST_API, 'PATCH', 'receiver'],
  // TextLocate
  sendLocationRequest: [REST_API, 'SEND_LOCATION_REQUEST', 'text_locate'],
  // TruckQuery
  createTruckQuery: [REST_API, 'POST', 'truck_query'],
  makeSearchResultDecision: [REST_API, 'RESULT_DECISION', 'truck_query'],
  upsertSearchResultNote: [REST_API, 'UPSERT_NOTE', 'truck_query'],
  // Users
  createUser: [REST_API, 'POST', 'user'],
  toggleUserStatus: [REST_API, 'TOGGLE_STATUS', 'user'],
  // User Settings
  updateUserSettings: [REST_API, 'PATCH', 'user_settings'],
})

export const graphqlApi = buildRequests({
  execute: [GRAPHQL_API, 'POST']
})

function buildRequests(requestsOpts) {
  let requests = {}

  _.each(requestsOpts, (options, reqName) => {
    requests[reqName] = buildRequest(...options)
  })

  return requests
}

function buildRequest(base_url, method, endpoint, additionalHeaders) {
  return (params, callback) => {
    return (pendingRequests, handleRequestSuccess, handleRequestError) => {
      let reqId = uuidv4()
      return axios(
        _({
          reqId: reqId,
          url: endpoint,
          baseURL: base_url,
          headers: additionalHeaders,
          method: method,
          cancelToken: new axios.CancelToken((c) => {
            pendingRequests.push({
              reqId: reqId,
              cancelToken: c})
          })
        }).tap(config => {
          if (_.isUndefined(params)) return config

          let paramsKey = method === 'GET' ? 'params' : 'data'
          config[paramsKey] = transformRequestParams(method, base_url, params)
        }).value()
      ).then(res => {
        _.remove(pendingRequests, ['reqId', reqId])
        return base_url === GRAPHQL_API && res.data.errors ? handleRequestError(res.data.errors) : handleRequestSuccess(callback, res)
      }).catch(error => {
        _.remove(pendingRequests, ['reqId', reqId])
        if (process.env.NODE_ENV === 'development') console.log(error)
        if (!axios.isCancel(error)) handleRequestError(error)
      })
    }
  }
}

function transformRequestParams(method, base_url, params) {
  return base_url === GRAPHQL_API ? params
      :  method === 'GET' ? convertParamsToSnakecase(params)
      :  paramsToFormData(params)
}

export function convertParamsToSnakecase(params) {
  return _({}).tap(newParams => {
    _.forIn(params, (value, key) => {
      newParams[_.snakeCase(key)] = value
    })
  }).value()
}

function paramsToFormData(params) {
  return _(new FormData()).tap(formData => {
    _.forIn(convertParamsToSnakecase(params), (value, key) => {
      if (!(value instanceof Object) || value instanceof File || value instanceof Blob) {
        formData.append(key, value)
      } else if (value instanceof Array) {
        formData.append(key, `${value}`)
      } else {
        formData.append(key, JSON.stringify(value))
      }
    })
  }).value()
}
