import React, { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import Receiver from './Receiver'
import { appendNotification } from '../notifications/notificationsSlice'
import { restApi } from '../Api'

function NewReceiver(props) {
  const { companyId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleReceiverCreationSuccess = (res) => {
    dispatch(appendNotification({type: 'Receiver', companyId: companyId, receiverId: res.data.id, verb: 'created'}))
    navigate(`/company/${companyId}/locations/receivers`)
  }

  return (
    <Fragment>
      <Receiver
        formRequest={restApi.createReceiver}
        handleSuccess={handleReceiverCreationSuccess}
      />
    </Fragment>
  )
}

export default NewReceiver
