import _ from 'lodash'
import React, { Fragment, useRef, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Requestable from '../generic/Requestable'
import { graphqlApi} from '../Api'
import Graphql from '../Graphql'
import Tooltip from '../generic/Tooltip'

const areEqual = (prevProps, nextProps) => {
  return prevProps.carrierId === nextProps.carrierId
}

const BOLCarrierValidator = React.memo(({carrierId}) => {
  const [valid, setValid] = useState()
  const fetchedCarrierId = useRef()

  const handleCarrierResponse = (res) => {
    setValid(Graphql.getValue(res, 'carrier.valid'))
  }

  const fetchCarrierValidity = (performRequest) => {
    if (carrierId && carrierId !== fetchedCarrierId.current) {
      fetchedCarrierId.current = carrierId
      performRequest(graphqlApi.execute(Graphql.carrier({filters: {id: carrierId}, fields: ['valid']}), handleCarrierResponse))
    }
  }

  return (
    <Requestable
      withoutLoading
      render={({performRequest, isLoading}) => {
        fetchCarrierValidity(performRequest)

        if (isLoading) {
          return <Spinner size="sm" animation="border" variant="dark" />
        } else if (carrierId && !_.isUndefined(valid)) {
          return (
            <Fragment>
              <Tooltip id="carrier-coverage-tooltip" text={`This carrier is ${valid ? 'valid' : 'invalid'}`} >
                {
                  valid ? <FontAwesomeIcon className="success-green" icon='check-circle' size='lg' /> : <FontAwesomeIcon className="error-red" icon='exclamation-circle' size='lg' />
                }
              </Tooltip>
            </Fragment>
          )
        } else {
          return null
        }
      }}
    />
  )
}, areEqual)

export default BOLCarrierValidator
