import BOLCreated from './BOLCreated'
import BOLUpdated from './BOLUpdated'
import CarrierAction from './CarrierAction'
import CarrierAgreementEsignableCompleted from './CarrierAgreementEsignableCompleted'
import CompanyUpdated from './CompanyUpdated'
import EmailFailed from './EmailFailed'
import EmailSent from './EmailSent'
import EsignAction from './EsignAction'
import InsuranceConstraintAction from './InsuranceConstraintAction'
import LoadAction from './LoadAction'
import LocationAction from './LocationAction'
import NotificationUpdated from './NotificationUpdated'
import OriginAction from './OriginAction'
import RateConfirmationEsignableCompleted from './RateConfirmationEsignableCompleted'
import ReceiverAction from './ReceiverAction'
import SuccessfulAction from './SuccessfulAction'
import UserCreated from './UserCreated'

const DEFAULT_SPACING = 8
const DEFAULT_EXPIRATION = 2400

const PopupNotificationsMap = {
  BOLCreated: {component: BOLCreated, defaultExpiration: 3400, spacing: DEFAULT_SPACING},
  BOLUpdated: {component: BOLUpdated, defaultExpiration: DEFAULT_EXPIRATION, spacing: DEFAULT_SPACING},
  CarrierAction: {component: CarrierAction, defaultExpiration: 8000, spacing: DEFAULT_SPACING},
  CarrierAgreementEsignableCompleted: {component: CarrierAgreementEsignableCompleted, defaultExpiration: 8000, spacing: 9.5},
  CompanyUpdated: {component: CompanyUpdated, defaultExpiration: DEFAULT_EXPIRATION, spacing: DEFAULT_SPACING},
  EmailFailed: {component: EmailFailed, defaultExpiration: DEFAULT_EXPIRATION, spacing: DEFAULT_SPACING},
  EmailSent: {component: EmailSent, defaultExpiration: DEFAULT_EXPIRATION, spacing: DEFAULT_SPACING},
  EsignAction: {component: EsignAction, defaultExpiration: DEFAULT_EXPIRATION, spacing: DEFAULT_SPACING},
  InsuranceConstraint: {component: InsuranceConstraintAction, defaultExpiration: DEFAULT_EXPIRATION, spacing: 9},
  Location: {component: LocationAction, defaultExpiration: DEFAULT_EXPIRATION, spacing: DEFAULT_SPACING},
  Load: {component: LoadAction, defaultExpiration: DEFAULT_EXPIRATION, spacing: DEFAULT_SPACING},
  Notification: {component: NotificationUpdated, defaultExpiration: DEFAULT_EXPIRATION, spacing: DEFAULT_SPACING},
  Origin: {component: OriginAction, defaultExpiration: 3000, spacing: DEFAULT_SPACING},
  RateConfirmationEsignableCompleted: {component: RateConfirmationEsignableCompleted, defaultExpiration: 8000, spacing: 9.5},
  Receiver: {component: ReceiverAction, defaultExpiration: 3000, spacing: DEFAULT_SPACING},
  GenericSuccess: {component: SuccessfulAction, defaultExpiration: DEFAULT_EXPIRATION, spacing: DEFAULT_SPACING},
  UserCreated: {component: UserCreated, defaultExpiration: DEFAULT_EXPIRATION, spacing: DEFAULT_SPACING},
}

export default PopupNotificationsMap
