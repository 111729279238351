import { createSlice } from '@reduxjs/toolkit'

let initialState = {
  currentUser: null
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      state.currentUser = action.payload
    }
  },
  extraReducers: {

  }
})

export const {
  setCurrentUser
} = userSlice.actions

export default userSlice.reducer
