import React from 'react'
import { Row, Col, ListGroup, Container } from 'react-bootstrap'
import { Navigate, Link, Route, Routes } from 'react-router-dom'

import TransactionsReport from './TransactionsReport'

function Reports() {
  return (
    <Row className='reports g-0'>
      <Col xl={2} xs={12} className='reports-nav'>
        <ListGroup variant='flush'>
          <Link to='transactions'>
            <ListGroup.Item action>
                Transactions
            </ListGroup.Item>
          </Link>
        </ListGroup>
      </Col>
      <Col xl={10} xs={12} className='reports-body'>
        <Container fluid>
          <Routes>
            <Route path='transactions' element={<TransactionsReport />} />
            <Route path='*' element={<Navigate to='/404' replace />} />
          </Routes>
        </Container>
      </Col>
    </Row>
  )
}

export default Reports
