import React from 'react'
import { Row, Col, ListGroup, Container } from 'react-bootstrap'
import { Navigate, Link, Route, Routes } from 'react-router-dom'

import CompanySettings from './CompanySettings'
import LoadPostings from './LoadPostings'
import NotificationsSettings from './NotificationsSettings'
import PaymentsSettings from './PaymentsSettings'
import TrackingSettings from './TrackingSettings'
import Users from './Users'

function Settings(props) {
  return (
    <Row className='company-settings g-0'>
      <Col xl={2} xs={12} className='settings-nav'>
        <ListGroup variant='flush'>
          <Link to='company'>
            <ListGroup.Item action>
              Company
            </ListGroup.Item>
          </Link>
          <Link to='load-postings'>
            <ListGroup.Item action>
              Load Postings
            </ListGroup.Item>
          </Link>
          <Link to='notifications'>
            <ListGroup.Item action>
              Notifications
            </ListGroup.Item>
          </Link>
          <Link to='payments'>
            <ListGroup.Item action>
              Payments
            </ListGroup.Item>
          </Link>
          <Link to='tracking'>
            <ListGroup.Item action>
              Tracking
            </ListGroup.Item>
          </Link>
          <Link to='users'>
            <ListGroup.Item action>
              Users
            </ListGroup.Item>
          </Link>
        </ListGroup>
      </Col>
      <Col xl={10} xs={12} className='settings-body'>
        <Container fluid>
          <Routes>
            <Route path='company' element={<CompanySettings />} />
            <Route path='load-postings' element={<LoadPostings />} />
            <Route path='notifications' element={<NotificationsSettings />} />
            <Route path='payments' element={<PaymentsSettings />} />
            <Route path='tracking' element={<TrackingSettings />} />
            <Route path='users' element={<Users />} />
            <Route path='*' element={<Navigate to='/404' replace />} />
          </Routes>
        </Container>
      </Col>
    </Row>
  )
}

export default Settings
