import _ from 'lodash'
import React from 'react'
import { Button, ButtonGroup, OverlayTrigger, Spinner } from 'react-bootstrap'

import Requestable from '../generic/Requestable'

function EsignableActions({esignableType, currentUserHasNotSigned, anyNonUsersHaveNotSigned, esignableIsComplete, getEsignableLink, sendEsignRequestReminder, signedDocumentIsDownloading, esignersStatusPopover, filePath}) {
  if (esignableIsComplete) {
    if (signedDocumentIsDownloading) {
      return <Button variant="outline-secondary" size="sm">Downloading Signed Document<Spinner size="sm" animation="border" className="spinner-indigo ms-2" /></Button>
    } else {
      return (
        <a target="_blank" rel="noopener noreferrer" href={filePath} className="w-100">
          <Button variant="outline-success" size="sm">Download Signed Document</Button>
        </a>
      )
    }
  } else {
    return (
      <ButtonGroup className="ms-0">
        {
          currentUserHasNotSigned &&
          <Requestable
            withoutLoading
            render={({performRequest, isLoading}) => {
                return (
                  <Button size="sm" className="outline-indigo" onClick={_ => getEsignableLink(performRequest)}>
                    {
                      isLoading ? <Spinner size="sm" animation="border" className="spinner-indigo mx-auto" />
                        : 'E-Sign'
                    }
                  </Button>
                )
              }
            }
          />
        }
        {
          anyNonUsersHaveNotSigned &&
          <Requestable
            withoutLoading
            render={({performRequest, isLoading}) => {
                return (
                  <Button size="sm" className="outline-indigo" onClick={() => sendEsignRequestReminder(performRequest, _.startCase(esignableType.replace('Esignable', '')))}>
                    {
                      isLoading ? <Spinner size="sm" animation="border" className="spinner-indigo mx-auto" />
                        : 'Request Signature'
                    }
                  </Button>
                )
              }
            }
          />
        }
        <OverlayTrigger overlay={esignersStatusPopover} placement="auto">
          <Button size="sm" className="outline-indigo">
            Status
          </Button>
        </OverlayTrigger>
      </ButtonGroup>
    )
  }
}

export default EsignableActions
