import _ from 'lodash'
import React, { Fragment, useEffect, useMemo, useState} from 'react'
import { Badge, Button, Card, Col, Collapse, ListGroup, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Paginator from '../generic/Paginator'
import GenericSearch from '../search/GenericSearch'

function CardsList({cardHeaderColumn, selectedColumns, sortedColumn, addableColumns, sortDirection, filteredItems, filteredValues, filterableValues, appliedFilterCount, searchable, searchPlaceholder, searchIndex, searchFilters, searchRefinement, pageSize, currentPageNumber, removeAppliedFilter, belowSearchContent, setCurrentPageNumber, setSelectedColumns, setSortedColumn, setSortDirection, setFilteredValues, clearFilters, setSearchRefinement, setSearchHits, settings}) {
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [attrsOpen, setAttrsOpen] = useState(false)
  const [sortOpen, setSortOpen] = useState(false)
  const [accordionsMap, setAccordionsMap] = useState({})

  useEffect(() => {
    setAccordionsMap(
      _.transform(selectedColumns, (result, {name}) => {
        result[name] = accordionsMap[name] || false
      })
    )
  }, [selectedColumns.length]) // eslint-disable-line react-hooks/exhaustive-deps

  const renderItemValue = useMemo(() => {
    return (item, column) => {
      return column.displayer ? column.displayer(item)
              : column.filterer ? column.filterer(column.accessor(item))
              : column.accessor(item)
    }
  }, [])

  const handleAccordionStateChange = (columnName) => {
    setAccordionsMap(prevMap => {
      return {
        ...prevMap,
        [columnName]: !prevMap[columnName]
      }
    })
  }

  const handleFilterSelection = (columnName, value) => {
    const selected = filteredValues[columnName].includes(value)

    if (!selected) {
      setFilteredValues(prevFilteredValues => {
        return {
          ...prevFilteredValues,
          [columnName]: [...filteredValues[columnName], value]
        }
      })
    } else {
      setFilteredValues(prevFilteredValues => {
        return {
          ...prevFilteredValues,
          [columnName]: _.without(filteredValues[columnName], value)
        }
      })
    }
  }

  const filtersAccordion = useMemo(() => {
    return (
      <div className="filters-container mb-3">
        <Card>
          <Card.Header className="cursor-pointer" onClick={_ => setFiltersOpen(!filtersOpen)}>
            <FontAwesomeIcon className="me-2 refinable-attr-icon" icon='filter' size="lg" />
            <h6 className="d-inline">Filters</h6>
            <Button className="indigo-button float-end" size="sm" onClick={e => {e.stopPropagation(); clearFilters();} }>
              Clear Filters
            </Button>
          </Card.Header>
          <Collapse in={filtersOpen}>
            <Card.Body className="pt-3 pb-2">
              {
                _.map(selectedColumns, (column) => {
                  if (column.filterable) {
                    return (
                      <div key={column.name} className="mb-3">
                        <div onClick={() => {handleAccordionStateChange(column.name)} }>
                          <h5 className="mb-0 d-inline">
                            <Badge className="refinable-title-pill cursor-pointer" pill>
                              {column.name}
                            </Badge>
                          </h5>
                          {
                            filteredValues[column.name].length > 0 &&
                            <span className="ms-2 applied-filter-count">({filteredValues[column.name].length})</span>
                          }
                        </div>
                        <Collapse in={accordionsMap[column.name]}>
                          <div className="filterable-values-container">
                            {
                              _.map(filterableValues[column.name], (value, index) => {
                                return (
                                  <h6 key={index} className="d-inline me-2">
                                    <Badge pill className={filteredValues[column.name].includes(value) ? 'filtered mt-2 cursor-pointer' : 'unfiltered mt-2 cursor-pointer'} onClick={_ => { handleFilterSelection(column.name, value) }}>{value || 'Empty'}</Badge>
                                  </h6>
                                )
                              })
                            }
                          </div>
                        </Collapse>
                      </div>
                    )
                  }
                })
              }
            </Card.Body>
          </Collapse>
        </Card>
      </div>
    )
  }, [filtersOpen, filterableValues, selectedColumns.length, appliedFilterCount, _.countBy(accordionsMap, isExpanded => isExpanded === true).true]) // eslint-disable-line

  const columnSelector = useMemo(() => {
    return (
      <Card className="mb-3">
        <Card.Header className="cursor-pointer" onClick={_ => setAttrsOpen(!attrsOpen)}>
          <FontAwesomeIcon icon='plus' className="refinable-attr-icon" />
          <FontAwesomeIcon icon='slash' transform={{ rotate: 90 }} className="refinable-attr-icon" />
          <FontAwesomeIcon icon='minus' className="me-2 refinable-attr-icon" />
          <h6 className="d-inline">Add/Remove Attributes</h6>
        </Card.Header>
        <Collapse in={attrsOpen}>
          <Card.Body className="pt-3 pb-2">
            {
              _.map(selectedColumns, (column) => {
                return (
                  <div className="mb-3" key={column.name} onClick={() => { setSelectedColumns(_.without(selectedColumns, column)) } }>
                    <FontAwesomeIcon icon='minus' className="me-2 refinable-attr-icon" size="lg" />
                    {column.name}
                  </div>
                )
              })
            }
            {
              _.map(_.without(addableColumns, cardHeaderColumn) , (column) => {
                return (
                  <div className="mb-3" key={column.name} onClick={() => { setSelectedColumns(_.concat(selectedColumns, column)) } }>
                    <FontAwesomeIcon icon='plus' className="me-2 refinable-attr-icon" size="lg" />
                    {column.name}
                  </div>
                )
              })
            }
          </Card.Body>
        </Collapse>
      </Card>
    )
  }, [attrsOpen, selectedColumns.length]) // eslint-disable-line react-hooks/exhaustive-deps

  const sortColumn = (column) => {
    const sortIsAscendingAndCurrentCol = sortDirection === 'down' && column.name === sortedColumn
    setSortedColumn(column.name)
    setSortDirection(sortIsAscendingAndCurrentCol ? 'up' : 'down')
  }

  const renderSortableAttribute = (column) => {
    return (
      <div className="mb-3" key={column.name}>
        <h5 className="d-inline" onClick={_ => { sortColumn(column) } }>
          <Badge className="refinable-title-pill cursor-pointer" pill>
            {column.name}
          </Badge>
        </h5>
        {column.name === sortedColumn && sortDirection === 'down' && <FontAwesomeIcon icon='angle-down' className="refinable-attr-icon ms-2" />}
        {column.name === sortedColumn && sortDirection === 'up' && <FontAwesomeIcon icon='angle-up' className="refinable-attr-icon ms-2" />}
      </div>
    )
  }

  const columnSorter = useMemo(() => {
    return (
      <Card className="mb-3">
        <Card.Header className="cursor-pointer" onClick={_ => setSortOpen(!sortOpen)}>
          {sortDirection === 'down' && <FontAwesomeIcon icon='sort-amount-down-alt' className="refinable-attr-icon me-2" size="lg" />}
          {sortDirection === 'up' && <FontAwesomeIcon icon='sort-amount-up' className="refinable-attr-icon me-2" size="lg" />}
          <h6 className="d-inline">Sort (Current - {sortedColumn})</h6>
        </Card.Header>
        <Collapse in={sortOpen}>
          <Card.Body className="pt-3 pb-2">
            {renderSortableAttribute(cardHeaderColumn)}
            {
              _.map(selectedColumns, (column) => {
                if (column.sortable) {
                  return renderSortableAttribute(column)
                }
              })
            }
          </Card.Body>
        </Collapse>
      </Card>
    )
  }, [sortOpen, sortedColumn, sortDirection, selectedColumns.length]) // eslint-disable-line react-hooks/exhaustive-deps

  const renderCards = () => {
    return (
      <div className="gen-cards-list">
        {_.map(filteredItems.slice((currentPageNumber - 1) * pageSize, currentPageNumber * pageSize), (item, i) => {
          return (
            <Row key={i} className="mb-4">
              <Col xs={12}>
                <Card>
                  <Card.Header as={cardHeaderColumn.asCardHeader} tableItem={item}>
                    <Fragment>{cardHeaderColumn.name}: {cardHeaderColumn.filterer ? cardHeaderColumn.filterer(cardHeaderColumn.accessor(item)) : cardHeaderColumn.accessor(item)}</Fragment>
                  </Card.Header>
                  <ListGroup variant="flush">
                    {
                      _.map(selectedColumns, (column, j) => {
                        return (
                          <ListGroup.Item key={j}>{column.name}: <span className="float-end">{renderItemValue(item, column)}</span></ListGroup.Item>
                        )
                      })
                    }
                  </ListGroup>
                </Card>
              </Col>
            </Row>
          )
        })}
      </div>
    )
  }

  return (
    <Fragment>
      {searchable &&
        <Row>
          <Col xs={12}>
            <GenericSearch
              placeholder={searchPlaceholder}
              indexName={searchIndex}
              filters={searchFilters}
              currentRefinement={searchRefinement}
              setCurrentRefinement={setSearchRefinement}
              setSearchHits={setSearchHits}
            />
          </Col>
        </Row>
      }
      {belowSearchContent &&
        <Row className="my-3">
          <Col xs={12}>
            {belowSearchContent}
          </Col>
        </Row>
      }
      <Row className="mb-2 mt-2 mt-xl-0">
        <Col xs={12}>
          {settings}
        </Col>
      </Row>
      {filtersAccordion}
      {columnSelector}
      {columnSorter}
      <div className="d-flex my-1 justify-content-center">
        <Paginator totalItems={filteredItems.length} perPage={pageSize} currentPage={currentPageNumber} setCurrentPage={setCurrentPageNumber} />
      </div>
      {renderCards()}
      <div className="d-flex my-1 justify-content-center">
        <Paginator totalItems={filteredItems.length} perPage={pageSize} currentPage={currentPageNumber} setCurrentPage={setCurrentPageNumber} />
      </div>
    </Fragment>
  )
}

export default CardsList
