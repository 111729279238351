import _ from 'lodash'
import React, { Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CurrentUser from '../users/CurrentUser'

function PageNotFound(props) {
  const navigate = useNavigate()
  const currentUser = useSelector(state => state.user.currentUser)

  return (
    <Container>
      <CurrentUser />
      <Row className="mt-4">
        <Col className="d-flex justify-content-center">
          <div>
            <div className="d-flex justify-content-center">
              <FontAwesomeIcon icon='search' size='6x' className="mb-3" />
            </div>
            <h4>Whoops, that page doesn't exist!</h4>
          </div>
        </Col>
      </Row>
      <div className="text-muted d-flex justify-content-center">
        <span className="text-muted cursor-pointer text-decoration-underline" onClick={_ => navigate(-1)}>Go back</span>
        {
          !_.isEmpty(currentUser) &&
            <Fragment>
              <span className="mx-2">—</span>
              <Link to={`/company/${currentUser.last_used_company_id}/dashboard`} className="text-muted">Return to Dashboard</Link>
            </Fragment>
        }
        <span className="mx-2">—</span>
        <span className="text-muted cursor-pointer text-decoration-underline">Contact Support</span>
      </div>
    </Container>
  )
}

export default PageNotFound
