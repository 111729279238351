import _ from 'lodash'
import React, { Fragment, useState } from 'react'
import { Button, Col, FloatingLabel, Form, Spinner } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { appendNotification } from '../notifications/notificationsSlice'
import GenericForm from '../forms/GenericForm'
import Graphql from '../Graphql'
import { graphqlApi} from '../Api'
import { restApi } from '../Api'
import Requestable from '../generic/Requestable'
import Tooltip from '../generic/Tooltip'

const companyTrackingSettingsQuery = Graphql.company({
  fields: [
    Graphql.relatedSettings({
      fields: [
        'location_request_quiet_hours_start_time',
        'location_request_quiet_hours_end_time',
        'hours_between_driver_location_requests',
        'minutes_to_alert_on_ad_hoc_nonresponse',
        'minutes_to_alert_on_in_transit_nonresponse',
        'minutes_to_alert_on_pre_pickup_nonresponse',
        'minutes_to_track_driver_before_pickup'
      ]
    })
  ]
})

function TrackingSettings() {
  const [formData, setFormData] = useState({})
  const dispatch = useDispatch()

  const companyTrackingSettingsLoadSuccess = ({data}) => {
    setFormData(data.data.company.settings)
  }

  const handleCompanyUpdateSuccess = _ => {
    dispatch(appendNotification({type: 'GenericSuccess', text: 'Tracking Settings Updated'}))
  }

  return (
    <Requestable
      onMountFetch={graphqlApi.execute(companyTrackingSettingsQuery, companyTrackingSettingsLoadSuccess)}
      render={() => {
        return <GenericForm
          formRequest={restApi.updateCompanySettings}
          params={formData}
          handleSuccess={handleCompanyUpdateSuccess}
          formContent={({handleFormChange, isSubmitting}) => {
            return (
              <Fragment>
                <h4><b>Tracking</b></h4>
                <div className="d-flex">
                  <Col lg={4} xs={12}>
                    <FloatingLabel controlId="minutes_to_track_driver_before_pickup" label="Minutes to track drivers pre-pickup">
                      <Form.Control type="number" placeholder="Minutes to track drivers pre-pickup" value={formData.minutes_to_track_driver_before_pickup || ''} onChange={e=>handleFormChange(setFormData, e)} />
                    </FloatingLabel>
                  </Col>
                </div>
                <div>
                  <Col lg={4} xs={12}>
                    <Form.Floating>
                      <Form.Control id="minutes_to_alert_on_pre_pickup_nonresponse" type="number" placeholder="Minutes to consider pre-pickup tracking delayed" required={_.isNumber(parseInt(formData.minutes_to_track_driver_before_pickup))} value={formData.minutes_to_alert_on_pre_pickup_nonresponse || ''} onChange={e=>handleFormChange(setFormData, e)} />
                      <label htmlFor="minutes_to_alert_on_pre_pickup_nonresponse" className="pe-auto">
                        Minutes to consider pre-pickup tracking delayed
                        <Tooltip id="pre-pickup-alert-minutes-tt" text="Recognizing pre-pickup tracking updates as delayed helps to identify if/when a driver is at risk for making their pickup time">
                          <FontAwesomeIcon icon={['far', 'question-circle']} className="ms-1" />
                        </Tooltip>
                      </label>
                      <Form.Control.Feedback type="invalid">This field is required when minutes to track driver pre-pickup is specified</Form.Control.Feedback>
                    </Form.Floating>
                  </Col>
                </div>
                <div>
                  <Col lg={4} xs={12}>
                    <FloatingLabel controlId="hours_between_driver_location_requests" label="Hours between in-transit driver location requests">
                      <Form.Control type="number" placeholder="Hours between in-transit driver location requests" value={formData.hours_between_driver_location_requests || ''} onChange={e=>handleFormChange(setFormData, e)} />
                    </FloatingLabel>
                  </Col>
                </div>
                <div>
                  <Col lg={4} xs={12}>
                    <Form.Floating>
                      <Form.Control id="minutes_to_alert_on_in_transit_nonresponse" type="number" placeholder="Minutes to consider in-transit tracking delayed" required={_.isNumber(parseInt(formData.hours_between_driver_location_requests))} value={formData.minutes_to_alert_on_in_transit_nonresponse || ''} onChange={e=>handleFormChange(setFormData, e)} />
                      <label htmlFor="minutes_to_alert_on_in_transit_nonresponse" className="pe-auto">
                        Minutes to consider in-transit tracking delayed
                        <Tooltip id="in-transit-alert-minutes-tt" text="Recognizing in-transit tracking updates as delayed helps to identify drivers that are not responding to location requests">
                          <FontAwesomeIcon icon={['far', 'question-circle']} className="ms-1" />
                        </Tooltip>
                      </label>
                      <Form.Control.Feedback type="invalid">This field is required when hours between in-transit driver location requests is specified</Form.Control.Feedback>
                    </Form.Floating>
                  </Col>
                </div>
                <div>
                  <Col lg={4} xs={12}>
                    <Form.Floating>
                      <Form.Control id="minutes_to_alert_on_ad_hoc_nonresponse" type="number" placeholder="Minutes to consider user-issued tracking delayed" value={formData.minutes_to_alert_on_ad_hoc_nonresponse || ''} onChange={e=>handleFormChange(setFormData, e)} />
                      <label htmlFor="minutes_to_alert_on_ad_hoc_nonresponse" className="pe-auto">
                        Minutes to consider user-issued tracking delayed
                        <Tooltip id="ad-hoc-alert-minutes-tt" text="User-issued tracking updates correspond to manually issued location requests and NOT auto-scheduled pre-pickup/in-transit tracking attempts">
                          <FontAwesomeIcon icon={['far', 'question-circle']} className="ms-1" />
                        </Tooltip>
                      </label>
                    </Form.Floating>
                  </Col>
                </div>
                <div>
                  <Col lg={4} xs={12}>
                    <Form.Floating>
                      <Form.Control id="location_request_quiet_hours_start_time" type="time" placeholder="Tracking quiet hours start time" value={formData.location_request_quiet_hours_start_time || ''} onChange={e=>handleFormChange(setFormData, e)} />
                      <label htmlFor="location_request_quiet_hours_start_time" className="pe-auto">
                        Tracking quiet hours start time
                        <Tooltip id="tracking-quiet-hours-start-tt" text="Specify the start time for which drivers should not receive location requests. This time should likely be specified toward the END of usual driving hours">
                          <FontAwesomeIcon icon={['far', 'question-circle']} className="ms-1" />
                        </Tooltip>
                      </label>
                    </Form.Floating>
                  </Col>
                </div>
                <div>
                  <Col lg={4} xs={12}>
                    <Form.Floating>
                      <Form.Control id="location_request_quiet_hours_end_time" type="time" placeholder="Tracking quiet hours end time" value={formData.location_request_quiet_hours_end_time || ''} onChange={e=>handleFormChange(setFormData, e)} />
                      <label htmlFor="location_request_quiet_hours_end_time" className="pe-auto">
                        Tracking quiet hours end time
                        <Tooltip id="tracking-quiet-hours-end-tt" text="Specify the end time for which drivers should not receive location requests. This time should likely be specified toward the START of usual driving hours">
                          <FontAwesomeIcon icon={['far', 'question-circle']} className="ms-1" />
                        </Tooltip>
                      </label>
                    </Form.Floating>
                  </Col>
                </div>
                <div>
                  <Col lg={4} xs={12}>
                    <Button className="indigo-button w-100 mb-2" type="submit">
                      {
                        isSubmitting ? <Spinner size="sm" animation="border" variant="light" /> : 'Save'
                      }
                    </Button>
                  </Col>
                </div>
              </Fragment>
            )
          }}
        />
      }}
    />
  )
}

export default TrackingSettings
