import { connectSearchBox } from 'react-instantsearch-dom';

// Use to render a searchbox in whatever layout you want
function SearchBox(props) {
  return props.render({
    refine: props.refine
  })
}

const ConfigurableSearchBox = connectSearchBox(SearchBox)

export default ConfigurableSearchBox
