import _ from 'lodash'
import moment from 'moment'
import React, { Fragment, useState } from 'react'
import { Alert, Badge, Button, Col, ListGroup, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CarrierBOLs from './CarrierBOLs'
import CarrierContact from './CarrierContact'
import CarrierCoverage from './CarrierCoverage'
import ConfirmCarrierRemovalModal from './ConfirmCarrierRemovalModal'
import Graphql from '../Graphql'
import { graphqlApi } from '../Api'
import Requestable from '../generic/Requestable'
import Tooltip from '../generic/Tooltip'

const carrierFields = ['id', 'rmis_id', 'active', 'name', 'dot_number', 'mc_number', 'address_1', 'address_2', 'city', 'state', 'postal', 'postal_ext', 'valid', 'last_validated_at', 'validation_messages', 'contacts', 'coverages', 'original_authority_grant_date']

function ViewCarrier(props) {
  const { companyId, carrierId } = useParams()
  const [viewData, setViewData] = useState({})
  const [showRemovalModal, setShowRemovalModal]= useState(false)

  const carrierQuery = Graphql.carrier({
                         filters: {id: carrierId},
                         fields: [
                           ...carrierFields
                         ]
                       })

  const populateCarrierData = (res) => {
    const carrierData = Graphql.transformData(Graphql.getValue(res, 'carrier'), carrierFields)
    const contactsData = Graphql.transformData(Graphql.getValue(res, 'carrier'), 'contacts').contacts
    const coveragesData = Graphql.transformData(Graphql.getValue(res, 'carrier'), 'coverages').coverages

    setViewData(prevState => {
      return {
        ...prevState,
        ...carrierData,
        contactsList: contactsData,
        coveragesList: coveragesData,
        carrierAgreement: Graphql.transformData(Graphql.getValue(res, 'carrier.signed_carrier_agreement'))
      }
    })
  }

  const renderContactsList = () => {
    if (!_.isEmpty(viewData.contactsList)) {
      return _.map(viewData.contactsList, (contactData, i) => {
        return <CarrierContact key={i} id={i} {...contactData} />
      })
    } else {
      return (
        <Row className="mb-3">
          <Col>
            <Alert variant='dark'>
              {viewData.active ? 'There are no contacts for this carrier' : 'Contact information is unavailable for inactive carriers'}
            </Alert>
          </Col>
        </Row>
      )
    }
  }

  const renderCoverages = () => {
    if (!_.isEmpty(viewData.coveragesList)) {
      return _.map(viewData.coveragesList, ({description, ...coverageData}) => {
        return <CarrierCoverage key={description} type={description} {...coverageData} />
      })
    } else {
      return (
        <Row className="mb-3">
          <Col>
            <Alert variant='dark'>
              {viewData.active ? 'There is no insurance coverage information for this carrier' : 'Insurance coverage information is unavailable for inactive carriers'}
            </Alert>
          </Col>
        </Row>
      )
    }
  }

  return (
    <Requestable
      onMountFetch={graphqlApi.execute(carrierQuery, populateCarrierData)}
      render={() => {
          return (
            <Fragment>
              <Row className="form-sub-header g-0">
                <h3>{viewData.name}<Badge bg={viewData.active ? 'success' : 'danger'} className="ms-2 align-top">{viewData.active ? 'Active' : 'Inactive'}</Badge></h3>
              </Row>
              {
                !viewData.active &&
                <Row>
                  <Col>
                    <Alert variant='danger'>Inactive carriers are not actively monitored to determine whether or not they meet your operational requirements.</Alert>
                  </Col>
                </Row>
              }
              <Row>
                <Col xl={6} xs={12}>
                  <Row className="g-0">
                    <Col className="form-sub-header">
                      <h4>General Information</h4>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <ListGroup>
                        <ListGroup.Item><span className="fw-bold">Name:</span><span className="float-end">{viewData.name}</span></ListGroup.Item>
                        {
                          viewData.active &&
                          <ListGroup.Item>
                            <span>
                              <span className="fw-bold">Valid:</span>
                              <Tooltip id="coverage-valid-tooltip" text="Whether or not this carrier has been deemed valid by the insurance monitoring service" >
                                <FontAwesomeIcon className="ms-1" icon={['far', 'question-circle']} />
                              </Tooltip>
                            </span>
                            <span className="float-end carrier-coverage">
                              {
                                viewData.valid ? <span><FontAwesomeIcon className="success-green me-2" icon='check-circle' size='lg' /><b>Yes</b></span>
                                  : <span><FontAwesomeIcon className="error-red me-2" icon='exclamation-circle' size='lg' /><b>No</b></span>
                              }
                            </span>
                          </ListGroup.Item>
                        }
                        <ListGroup.Item><span className="fw-bold">Last validated at:</span><span className="float-end">{viewData.lastValidatedAt ? moment(viewData.lastValidatedAt).format('M/D/YYYY HH:mm:ss') : 'Never'}</span></ListGroup.Item>
                        <ListGroup.Item><span className="fw-bold">Motor Carrier Number:</span><span className="float-end">{viewData.mcNumber}</span></ListGroup.Item>
                        <ListGroup.Item><span className="fw-bold">USDOT Number:</span><span className="float-end">{viewData.dotNumber}</span></ListGroup.Item>
                        {viewData.active && <ListGroup.Item><span className="fw-bold">Original Authority Grant Date:</span><span className="float-end">{moment(viewData.originalAuthorityGrantDate).format('M/D/YYYY')}</span></ListGroup.Item>}
                      </ListGroup>
                    </Col>
                  </Row>
                  <Row className="g-0">
                    <Col className="form-sub-header">
                      <h4>Location Information</h4>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <ListGroup>
                        <ListGroup.Item><span className="fw-bold">Address 1:</span><span className="float-end">{viewData.address1}</span></ListGroup.Item>
                        {viewData.address2 && <ListGroup.Item><span className="fw-bold">Address 2:</span><span className="float-end">{viewData.address2}</span></ListGroup.Item>}
                        <ListGroup.Item><span className="fw-bold">City:</span><span className="float-end">{viewData.city}</span></ListGroup.Item>
                        <ListGroup.Item><span className="fw-bold">State:</span><span className="float-end">{viewData.state}</span></ListGroup.Item>
                        <ListGroup.Item><span className="fw-bold">Postal:</span><span className="float-end">{viewData.postal}</span></ListGroup.Item>
                      </ListGroup>
                    </Col>
                  </Row>
                  <Row className="g-0">
                    <Col className="form-sub-header carrier-coverage">
                      <h4 className="d-inline">Coverages</h4>
                      <span className="float-end">
                        <Tooltip id="coverage-is-valid-tooltip" text="Insurance coverage is in good standing" >
                          <FontAwesomeIcon className="success-green me-2" icon='check-circle' size='lg' />
                        </Tooltip>
                        <Tooltip id="coverage-is-expiring-tooltip" text="Insurance coverage expires soon" >
                          <FontAwesomeIcon className="warning-yellow me-2" icon='clock' size='lg' />
                        </Tooltip>
                        <Tooltip id="coverage-invalid-tooltip" text="Insurance coverage is invalid" >
                          <FontAwesomeIcon className="error-red" icon='exclamation-circle' size='lg' />
                        </Tooltip>
                      </span>
                    </Col>
                  </Row>
                  {renderCoverages()}
                </Col>
                <Col>
                  <Row className="g-0">
                    <Col className="form-sub-header">
                      <h4>Contacts</h4>
                    </Col>
                  </Row>
                  {renderContactsList()}
                </Col>
              </Row>
              {!_.isEmpty(viewData) && <CarrierBOLs companyId={companyId} carrierId={carrierId} />}
              <Row className="mt-4">
                <Col xs={12}>
                  {
                    viewData.active &&
                    <Fragment>
                      <Button variant="danger" className="w-100" onClick={_ => setShowRemovalModal(true)}>Remove Carrier</Button>
                      <ConfirmCarrierRemovalModal isShowing={showRemovalModal} handleClose={setShowRemovalModal} carrierName={viewData.name} carrierId={viewData.rmisId} companyId={companyId} />
                    </Fragment>
                  }
                </Col>
              </Row>
            </Fragment>
          )
        }
      }
    />
  )
}

export default ViewCarrier
