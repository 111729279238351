import _ from 'lodash'
import React, { Fragment, useState } from 'react'
import { Button, Col, FloatingLabel, Form, Row, Spinner } from 'react-bootstrap'

import Contacts from '../shared/Contacts'
import ReceiverBOLs from './ReceiverBOLs'
import GenericForm from '../forms/GenericForm'
import Requestable from '../generic/Requestable'
import StateOptions from '../utils/StateOptions'
import { graphqlApi} from '../Api'

function Receiver({companyId, ...props}) {
  const initialFormState = {
    contactsList: {}
  }

  const [formData, setFormData] = useState(initialFormState)

  const onMountFetchCallback = props.onMountFetchCallback ? props.onMountFetchCallback.bind(undefined, {setFormData: setFormData}) : null
  const onMountFetch = props.onMountFetchQuery ? graphqlApi.execute(props.onMountFetchQuery, onMountFetchCallback) : null

  const formContent = ({handleFormChange, isSubmitting}) => {
    return (
      <Fragment>
        <Row className="form-sub-header g-0">
          {formData.id ? <h5>Editing Receiver - {formData.name}</h5> : <h5>Add a new receiver</h5>}
        </Row>
        <Row>
          <Col xl={6} xs={12}>
            <Row className="g-0">
              <Col xl={10} xs={12} className="form-sub-header">
                <h5>General Information</h5>
              </Col>
            </Row>
            <Row>
              <Col md={4} xs={12}>
                <FloatingLabel controlId="name" label="Name">
                  <Form.Control required type="text" placeholder="Name" value={formData.name || ''} onChange={e=>handleFormChange(setFormData, e)} />
                  <Form.Control.Feedback type="invalid">Name is required</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={4} xs={12}>
                <FloatingLabel controlId="phone" label="Phone">
                  <Form.Control required type="tel" placeholder="Phone" value={formData.phone || ''} onChange={e=>handleFormChange(setFormData, e)} />
                  <Form.Control.Feedback type="invalid">Phone is required</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="email" label="Email">
                  <Form.Control required type="email" placeholder="Email" value={formData.email || ''} onChange={e=>handleFormChange(setFormData, e)} />
                  <Form.Control.Feedback type="invalid">Email is invalid</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>
            <Row className="g-0">
              <Col xl={10} xs={12} className="form-sub-header">
                <h5>Location Information</h5>
              </Col>
            </Row>
            <Row>
              <Col md={6} xs={12}>
                <FloatingLabel controlId='address1' label="Address 1">
                  <Form.Control required type="text" placeholder="Address 1" value={formData.address1 || ''} onChange={e=>handleFormChange(setFormData, e)} />
                  <Form.Control.Feedback type="invalid">Primary Address is required</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId='address2' label="Address 2">
                  <Form.Control type="text" placeholder="Address 2" value={formData.address2 || ''} onChange={e=>handleFormChange(setFormData, e)} />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col md={4} xs={12}>
                <FloatingLabel controlId="city" label="City">
                  <Form.Control required type="text" placeholder="City" value={formData.city || ''} onChange={e=>handleFormChange(setFormData, e)} />
                  <Form.Control.Feedback type="invalid">City is required</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={4} xs={12}>
                <FloatingLabel controlId='state' label="State">
                  <Form.Select value={formData.state} onChange={e=>handleFormChange(setFormData, e)} >
                    <StateOptions />
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col md={2} className="pe-1">
                <FloatingLabel controlId="postal" label="Postal">
                  <Form.Control required type="text" placeholder="Postal" value={formData.postal || ''} onChange={e=>handleFormChange(setFormData, e)} />
                  <Form.Control.Feedback type="invalid">Postal is required</Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={2} className="ps-1">
                <FloatingLabel controlId="postalExt" label="Postal Ext">
                  <Form.Control type="text" placeholder="Postal Ext" value={formData.postalExt || ''} onChange={e=>handleFormChange(setFormData, e)} />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col md={4} xs={12}>
                <FloatingLabel controlId="latitude" label="Latitude">
                  <Form.Control disabled required type="text" placeholder="Latitude" value={formData.latitude || ''} />
                </FloatingLabel>
              </Col>
              <Col md={4} xs={12}>
                <FloatingLabel controlId="longitude" label="Longitude">
                  <Form.Control disabled required type="text" placeholder="Longitude" value={formData.longitude || ''} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="time_zone" label="Time Zone">
                  <Form.Control disabled required type="text" placeholder="Time Zone" value={formData.timeZone || ''} />
                </FloatingLabel>
              </Col>
            </Row>
          </Col>
        </Row>
        <Contacts initialCount={props.contactCount} formData={formData} setFormData={setFormData} />
        <Row>
          <Col xs={12}>
            <Button className="indigo-button w-100 mb-3" type="submit">
              {
                isSubmitting ? <Spinner size="sm" animation="border" variant="light" />
                  : _.isNil(formData.id) ? 'Create'
                  : 'Update'
              }
            </Button>
          </Col>
        </Row>
        {_.isNil(formData.id) &&
          <Row>
            <Col xs={12}>
              <Button variant="secondary" className="w-100" onClick={e=>setFormData(initialFormState)}>
                Reset
              </Button>
            </Col>
          </Row>
        }
      </Fragment>
    )
  }

  return (
    <Requestable
      onMountFetch={onMountFetch}
      render={() => {
          return (
            <Fragment>
              <GenericForm formContent={formContent} formRequest={props.formRequest} params={formData} handleSuccess={props.handleSuccess} />
              {formData.id && <ReceiverBOLs receiverId={formData.id} companyId={companyId} />}
            </Fragment>
          )
        }
      }
    />
  )
}

export default Receiver
