import _ from 'lodash'
import React, { useRef, useState } from 'react'
import { Button, FloatingLabel, Form, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'

import GenericForm from '../forms/GenericForm'
import { restApi } from '../Api'

function InvoiceForm({ deleteHandler, formData, formRequest, setFormData, successHandler, confirmMC = false, showHeader=true }) {
  const companyId = useSelector(({user}) => _.get(user.currentUser, 'last_used_company_id'))
  const [confirmationText, setConfirmationText] = useState('')
  const fileRef = useRef()

  const resetFileInput = () => {
    setFormData(prevState => _.omit(prevState, ['document']))
    fileRef.current.value = ''
  }

  return (
    <div className='mb-3'>
      <GenericForm
        formRequest={formRequest}
        params={formData}
        handleSuccess={successHandler}
        formContent={({ handleFormChange, isSubmitting }) => {
          return (
            <>
              { showHeader && <h4 className="my-3">Upload Invoice</h4> }
              {
                confirmMC &&
                <>
                  <FloatingLabel controlId="mcNumber" label="Carrier MC Number">
                    <Form.Control required type="text" placeholder="Carrier MC Number" value={formData.mcNumber || ''} onChange={e => handleFormChange(setFormData, e)} />
                    <Form.Control.Feedback type="invalid">MC Number is required</Form.Control.Feedback>
                  </FloatingLabel>
                  <FloatingLabel controlId="uploaded_by_email" label="Your Email Address">
                    <Form.Control required type="email" placeholder="Your Email Address" value={formData.uploaded_by_email || ''} onChange={e => handleFormChange(setFormData, e)} />
                    <Form.Control.Feedback type="invalid">Email Address is required</Form.Control.Feedback>
                  </FloatingLabel>
                </>
              }
              <FloatingLabel controlId="number" label="Invoice Number">
                <Form.Control required type="text" placeholder="Invoice Number" value={formData.number || ''} onChange={e => handleFormChange(setFormData, e)} />
                <Form.Control.Feedback type="invalid">Invoice Number is required</Form.Control.Feedback>
              </FloatingLabel>
              <FloatingLabel controlId="total" label="Invoice Total">
                <Form.Control required type="number" placeholder="Invoice Total" value={formData.total || ''} onChange={e => handleFormChange(setFormData, e)} />
                <Form.Control.Feedback type="invalid">Invoice Total is required</Form.Control.Feedback>
              </FloatingLabel>
              <FloatingLabel controlId="due_date" label="Invoice Due Date">
                <Form.Control required type="date" placeholder="Invoice Due Date" value={formData.due_date || ''} onChange={e => handleFormChange(setFormData, e)} />
                <Form.Control.Feedback type="invalid">Invoice Due Date is required</Form.Control.Feedback>
              </FloatingLabel>
              <Form.Group controlId="document" className="mb-3">
                <Form.Label>Invoice Document</Form.Label>
                <Form.Control type="file" required={!formData.id} onChange={e => handleFormChange(setFormData, e)} ref={fileRef} />
                <Form.Control.Feedback type="invalid">Invoice Document is required</Form.Control.Feedback>
                { formData.id && formData.document &&
                  <Form.Text className="text-danger">
                    <b>A&nbsp;
                    <a target="_blank" rel="noopener noreferrer" href={`/company/${companyId}/documents/invoice/${formData.id}`}>
                      document
                    </a>
                    &nbsp;is already uploaded for this invoice. Uploading a new document will replace the existing one.
                    <span className='cursor-pointer text-primary' onClick={resetFileInput}>
                      &nbsp;Remove newly provided document
                    </span></b>
                  </Form.Text>
                }
              </Form.Group>
              <Button className="indigo-button w-100" type="submit">
                {
                  isSubmitting ? <Spinner size="sm" animation="border" variant="light" />
                  : formData.id ? 'Update' : 'Upload'
                }
              </Button>
            </>
          )
        }}
      />
      {
        formData.id &&
        <GenericForm
          formRequest={restApi.deleteInvoice}
          params={{ id: formData.id }}
          handleSuccess={deleteHandler}
          formContent={({ isSubmitting }) => {
            if (formData.paid) {
              return <Button variant='danger' className='w-100 mt-2' disabled>Cannot delete invoices that are paid</Button>
            }
            else if (confirmationText === 'DELETE') {
              return <Button variant='danger' className='w-100 mt-2' type="submit">
                {
                  isSubmitting ? <Spinner size="sm" animation="border" variant="light" /> : 'Delete'
                }
              </Button>
            } else {
              return <Form.Control
                type="text"
                placeholder="Type DELETE to delete this invoice"
                value={confirmationText}
                onChange={e => setConfirmationText(e.target.value)}
                className='mt-2 border-danger'
              />
            }
          }}
        />
      }
    </div>
  )
}

export default InvoiceForm
