import Pusher from 'pusher-js'

export const companyTrackingUpdatesChannel = companyId => `private-company-${companyId}-tracking`
export const esignableDocumentChannel = esignableId => `private-esignable-${esignableId}`
export const userEsignablesChannel = userId => `private-user-${userId}-esignables`

class PusherCli {
  cli = {}

  initialize(csrf_token) {
    this.cli = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      channelAuthorization: {
        endpoint: '/api/pusher_auth',
        headers: {
          'X-CSRFTOKEN': csrf_token
        }
      },
    })
  }
}

const target = new PusherCli()

const handler = {
  get(target, prop, receiver) {
    if (prop === 'initialize') {
      return (csrf_token) => {
        target.initialize(csrf_token)
      }
    } else {
      const value = target.cli[prop]
      if (value instanceof Function) {
        return (...args) => {
          return target.cli[prop](...args)
        }
      }

      return value
    }
  }
}

const PusherClient = new Proxy(target, handler)

export default PusherClient
