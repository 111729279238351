import React from "react"
import { useDispatch } from "react-redux"

import Graphql from '../Graphql'
import { graphqlApi } from '../Api'
import Requestable from "../generic/Requestable"
import { setCurrentCompany } from "./companySlice"

const companyFields = ['demo']

const currentCompanyQuery = Graphql.company({
  fields: [
    ...companyFields
  ]
})

function CurrentCompany() {
  const dispatch = useDispatch()

  const handleCurrentCompanyResponse = ({data}) => {
    dispatch(setCurrentCompany(data.data.company))
  }

  return <Requestable
    withoutLoading
    requestOnly
    onMountFetch={graphqlApi.execute(currentCompanyQuery, handleCurrentCompanyResponse)}
  />
}

export default CurrentCompany
