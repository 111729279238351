import React, { Fragment, useState } from 'react'
import { Button, Col, Container, FloatingLabel, Form, Row, Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import GenericForm from '../forms/GenericForm'
import HeaderErrors from '../errorHandling/HeaderErrors'
import { restApi } from '../Api'

const ProofOfDeliveryUpload = () => {
  const [formData, setFormData] = useState(useParams())
  const [uploadComplete, setUploadComplete] = useState(false)

  return (
    <Container id="landing-page" fluid className="d-flex flex-column">
      <HeaderErrors />
      <Row className="justify-content-center h-100">
        <Col xl={4} xs={11} className="bg-light my-auto rounded">
          {
            uploadComplete ?
            <div className="d-flex flex-column p-4 gap-2">
              <h4 className="mx-auto"><b>Proof of Delivery uploaded successfully</b></h4>
              <FontAwesomeIcon className="success-green fa-beat" icon="check-circle" size="5x" style={{'--fa-animation-iteration-count': 1}} />
            </div>
            :
            <GenericForm
              formRequest={restApi.uploadProofOfDelivery}
              params={formData}
              handleSuccess={_ => setUploadComplete(true)}
              formContent={
                ({handleFormChange, isSubmitting}) => {
                  return (
                    <Fragment>
                      <h4 className="my-3">Upload Proof of Delivery</h4>
                      <FloatingLabel controlId="mcNumber" label="Carrier MC Number">
                        <Form.Control required type="text" placeholder="Carrier MC Number" value={formData.mcNumber || ''} onChange={e=>handleFormChange(setFormData, e)} />
                        <Form.Control.Feedback type="invalid">MC Number is required</Form.Control.Feedback>
                      </FloatingLabel>
                      <Form.Group controlId="proofOfDelivery" className="mb-3">
                        <Form.Label>Proof of Delivery</Form.Label>
                        <Form.Control type="file" required onChange={e=>handleFormChange(setFormData, e)} />
                        <Form.Control.Feedback type="invalid">Proof of Delivery is required</Form.Control.Feedback>
                      </Form.Group>
                      <Button className="indigo-button w-100 mb-3" type="submit">
                        {
                          isSubmitting ? <Spinner size="sm" animation="border" variant="light" /> : 'Upload'
                        }
                      </Button>
                    </Fragment>
                  )
                }
              }
            />
          }
        </Col>
      </Row>
    </Container>
  )
}

export default ProofOfDeliveryUpload
