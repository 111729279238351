import _ from 'lodash'
import React, { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import Origin from './Origin'
import { appendNotification } from '../notifications/notificationsSlice'
import { restApi } from '../Api'
import Graphql from '../Graphql'

const OriginFields = ['id', 'name', 'phone', 'email', 'address_1', 'address_2', 'city', 'state', 'postal', 'postal_ext', 'latitude', 'longitude', 'time_zone']
const ContactsFields = ['id', 'first_name', 'last_name', 'phone', 'primary']

function EditOrigin(props) {
  const { companyId, originId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [contactCount, setContactCount] = useState()

  const originDataQuery = Graphql.origin({
                              filters: {id: originId},
                              fields: [
                                ...OriginFields,
                                Graphql.relatedContacts({fields: ContactsFields})
                              ]
                            })

  const populateOriginData = ({setFormData}, res) => {
    const originData = Graphql.transformData(Graphql.getValue(res, 'origin'), OriginFields)
    const contactsData = Graphql.transformData({...Graphql.getValue(res, 'origin.contacts')})

    setContactCount(_.size(contactsData))
    setFormData({
      ...originData,
      contactsList: contactsData
    })
  }

  const handleOriginUpdateSuccess = (res) => {
    dispatch(appendNotification({type: 'Origin', companyId: companyId, originId: res.data.id, verb: 'updated'}))
    navigate(`/company/${companyId}/locations/origins`)
  }

  return (
    <Fragment>
      <Origin
        companyId={companyId}
        contactCount={contactCount}
        onMountFetchQuery={originDataQuery}
        onMountFetchCallback={populateOriginData}
        formRequest={restApi.updateOrigin}
        handleSuccess={handleOriginUpdateSuccess}
      />
    </Fragment>
  )
}

export default EditOrigin
