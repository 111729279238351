import { configureStore } from '@reduxjs/toolkit'

import companyReducer from '../features/company/companySlice'
import confirmableActionReducer from '../features/generic/confirmableActionSlice'
import errorsReducer from '../features/errorHandling/errorsSlice'
import esignReducer from '../features/esign/esignSlice'
import interactableTableReducer from '../features/generic/interactableTableSlice'
import notificationsReducer from '../features/notifications/notificationsSlice'
import userReducer from '../features/users/userSlice'

const store  = configureStore({
  reducer: {
    company: companyReducer,
    confirmableAction: confirmableActionReducer,
    errors: errorsReducer,
    esign: esignReducer,
    interactableTable: interactableTableReducer,
    notifications: notificationsReducer,
    user: userReducer
  }
})

export default store
