import _ from 'lodash'
import React, { Fragment, useEffect, useState } from 'react'
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AppendableFieldsGroup from '../forms/AppendableFieldsGroup'

const areEqual = (prevProps, nextProps) => {
  return prevProps.initialCount === nextProps.initialCount && prevProps.formData.contactsList === nextProps.formData.contactsList
}

const Contacts = React.memo(({initialCount, formData, setFormData}) => {
  const [contactCount, setContactCount] = useState(0)

  useEffect(() => {
    if (_.isUndefined(initialCount) || initialCount === 0) return
    setContactCount(initialCount)
  }, [initialCount])

  return (
    <>
      <Col xl={6} xs={12}>
        <Row className="g-0">
          <Col xl={10} xs={12} className="form-sub-header">
            <h5>Contacts</h5>
          </Col>
        </Row>
        {
          _.times(contactCount, i => {
            return (
              <AppendableFieldsGroup
                key={i}
                dataKey={i}
                groupName="contactsList"
                group={formData.contactsList}
                setState={setFormData}
                renderContent={({getAppendableValue, setAppendableValue, removeAppendedFields}) => {
                    return (
                      <Fragment>
                        <Row className="mb-1">
                          <Col md={4} xs={12}>
                            <FloatingLabel controlId="first_name" label={`Contact ${i+1} First Name`} className="mb-md-0 mb-3">
                              <Form.Control required type="text" placeholder="First Name" value={getAppendableValue('first_name')} onChange={e=>setAppendableValue(e)} />
                              <Form.Control.Feedback type="invalid">First Name is required</Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                          <Col md={4} xs={12}>
                            <FloatingLabel controlId="last_name" label={`Contact ${i+1} Last Name`} className="mb-md-0 mb-3">
                              <Form.Control required type="text" placeholder="Last Name" value={getAppendableValue('last_name')} onChange={e=>setAppendableValue(e)} />
                              <Form.Control.Feedback type="invalid">Last Name is required</Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                          <Col>
                            <FloatingLabel controlId="phone" label={`Contact ${i+1} Phone`} className="mb-0">
                              <Form.Control type="tel" placeholder="Contact Phone" value={getAppendableValue('phone')} onChange={e=>setAppendableValue(e)} />
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md={4} xs={12}>
                            <Form.Switch id={`primary-contact-checkbox-${i}`} label="Primary contact" checked={getAppendableValue('primary') || false} onChange={e=>setAppendableValue(e, 'primary')} />
                          </Col>
                          <Col className="mt-md-0 mt-1">
                            <FontAwesomeIcon className="remove-appendable error-red cursor-pointer" icon='times' size="lg" onClick={() => removeAppendedFields(i, setContactCount)} />
                            <span className="ms-md-2 ms-4">Remove Contact</span>
                          </Col>
                        </Row>
                      </Fragment>
                    )
                  }
                }
              />
            )

          })
        }
        <Row className="mb-3">
          <Col>
            <FontAwesomeIcon icon='minus' size="lg" onClick={() => setContactCount(contactCount - 1)} />
            <FontAwesomeIcon icon='plus' size="lg" onClick={() => setContactCount(contactCount + 1)} />
          </Col>
        </Row>
      </Col>
    </>
  )
}, areEqual)

export default Contacts
