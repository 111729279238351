import _ from 'lodash'
import React, { Fragment, useState } from 'react'
import { Button, FloatingLabel, Form, Modal, Spinner } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import GenericForm from '../forms/GenericForm'
import Graphql from '../Graphql'
import { restApi } from '../Api'
import { appendNotification } from '../notifications/notificationsSlice'

const NewUser = ({isShowing, handleClose, setUsers}) => {
  const [userData, setUserData] = useState({})
  const dispatch = useDispatch()

  const handleUserCreationSuccess = (res) => {
    setUsers(prevUsers => _.concat(Graphql.transformData(res.data), prevUsers))
    setUserData({})
    handleClose()
    dispatch(appendNotification({type: 'UserCreated'}))
  }

  return (
    <Modal id="remove-carrier-modal" show={isShowing} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create New User</Modal.Title>
      </Modal.Header>
      <GenericForm
        formRequest={restApi.createUser}
        params={userData}
        handleSuccess={handleUserCreationSuccess}
        formContent={({isSubmitting, handleFormChange}) => {
          return (
            <Fragment>
              <Modal.Body>
                <FloatingLabel controlId="firstName" label="First Name">
                  <Form.Control required type="text" placeholder="First Name" value={userData.firstName || ''} onChange={e=>handleFormChange(setUserData, e)} />
                  <Form.Control.Feedback type="invalid">First Name is required</Form.Control.Feedback>
                </FloatingLabel>
                <FloatingLabel controlId="lastName" label="Last Name">
                  <Form.Control required type="text" placeholder="Last Name" value={userData.lastName || ''} onChange={e=>handleFormChange(setUserData, e)} />
                  <Form.Control.Feedback type="invalid">Last Name is required</Form.Control.Feedback>
                </FloatingLabel>
                <FloatingLabel controlId="username" label="Username">
                  <Form.Control required type="text" placeholder="Username" value={userData.username || ''} onChange={e=>handleFormChange(setUserData, e)} />
                  <Form.Control.Feedback type="invalid">Username is required</Form.Control.Feedback>
                </FloatingLabel>
                <FloatingLabel controlId="email" label="Email">
                  <Form.Control required type="email" placeholder="Email" value={userData.email || ''} onChange={e=>handleFormChange(setUserData, e)} />
                  <Form.Control.Feedback type="invalid">Email is required</Form.Control.Feedback>
                </FloatingLabel>
              </Modal.Body>
              <Modal.Footer>
                <Button className="indigo-button w-100" type="submit">
                  {isSubmitting ? <Spinner size="sm" animation="border" variant="light" /> : 'Create'}
                </Button>
              </Modal.Footer>
            </Fragment>
          )
        }}
      />
    </Modal>
  )
}

export default NewUser
