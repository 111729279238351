import _ from 'lodash'
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { Badge, Button, Dropdown, Form } from 'react-bootstrap'
import { Draggable } from '@hello-pangea/dnd'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const columnSelectorToggle = React.forwardRef(({ children, onClick }, ref) => (
  <span
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  >
    {children}
  </span>
))

const columnSelectorMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('')

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value),
          )}
        </ul>
      </div>
    )
  },
)

const columnFilterMenu = React.forwardRef(
  ({ children, style, className, columnName, setFilteredValues, setFilterMenuIsOpen, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('')

    const clearFilters = () => {
      React.Children.toArray(children).forEach(
        (child) => {
          const element = document.getElementById(`filter-value-${child.props.eventKey}`)

          if (element) {
            element.checked = false
          }
        }
      )

      setFilteredValues(prevFilteredValues => {
        return {
          ...prevFilteredValues,
          [columnName]: []
        }
      })

      setFilterMenuIsOpen(false)
    }

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || (child.props.eventKey || 'empty').toLowerCase().startsWith(value),
          )}
        </ul>
        <div className="d-flex justify-content-center">
          <Button className="indigo-button my-2 clear-filters w-100 mx-3" onClick={_ => clearFilters()}>Clear Filters</Button>
        </div>
      </div>
    )
  },
)

function InteractableTableColumn(props) {
  const {
    accessor,
    actionable,
    addableColumns,
    addColumn,
    appliedFilterCount,
    columnItems,
    currentDraggable,
    displayer,
    draggingOverIndex,
    dragStartIndex,
    filterable,
    filterableValues,
    filteredValues,
    filterer,
    headerDisplay,
    id,
    index,
    isFirstColumn,
    isLastColumn,
    listensToRerender,
    noWrapHeader,
    removeAppliedFilter,
    removeColumn,
    setFilteredValues,
    setSortDirection,
    setSortedColumn,
    sortable,
    sortDirection,
    sortedColumn
  } = props

  const sorted = id === sortedColumn
  const renderFiltersRow = appliedFilterCount > 0
  const rowOffset = renderFiltersRow ? 2 : 1
  const [filterMenuIsOpen, setFilterMenuIsOpen] = useState(false)
  const [counter, setCounter] = useState(1)

  const columnElements = useRef({})
  const tcContainer = document.getElementById('tc-container')
  useEffect(() => {
    _.forIn(columnElements.current, (element, _key) => {
      if (element) element.dataset.offsetTop = element.offsetTop
    })
  }, [columnItems, addableColumns])

  const sortColumn = () => {
    if (!sortable) return

    if (id !== sortedColumn) setSortedColumn(id)
    const sortIsAscendingAndCurrentCol = sortDirection === 'down' && id === sortedColumn
    setSortDirection(sortIsAscendingAndCurrentCol ? 'up' : 'down')
  }

  if (listensToRerender) {
    // eslint-disable-next-line
    const columnRerenderer = useSelector(state => state.interactableTable.counter)
  }

  const handleFilterSelection = (eventKey, _event) => {
    const selected = filteredValues.includes(eventKey)

    if (!selected) {
      setFilteredValues(prevFilteredValues => {
        return {
          ...prevFilteredValues,
          [id]: [...filteredValues, eventKey]
        }
      })
    } else {
      removeAppliedFilter(id, eventKey)
    }
  }

  const filtersDropdown = useMemo(() => {
    if (filterable) {
      return (
        <Dropdown className="position-static" autoClose="outside" onToggle={setFilterMenuIsOpen} onSelect={handleFilterSelection} show={filterMenuIsOpen}>
          <Dropdown.Toggle as={columnSelectorToggle}>
            <FontAwesomeIcon className="ms-2 column-filter-icon" icon='filter' size="lg" />
          </Dropdown.Toggle>
          <Dropdown.Menu as={columnFilterMenu} columnName={id} setFilteredValues={setFilteredValues} setFilterMenuIsOpen={setFilterMenuIsOpen}>
            {
              _.map(filterableValues, (value) => {
                return (
                  <Dropdown.Item key={value} eventKey={value}>
                    <Form.Group controlId={`filter-value-${value}`}>
                      <Form.Check type="checkbox" label={value || 'Empty'} readOnly checked={filteredValues.includes(value)} />
                    </Form.Group>
                  </Dropdown.Item>
                )
              })
            }
          </Dropdown.Menu>
        </Dropdown>
      )
    }

    return null
  }, [filterMenuIsOpen, filteredValues.length]) // eslint-disable-line react-hooks/exhaustive-deps

  const renderAddableColumnsDropdown = () => {
    return (
      <span className="ms-auto px-2 d-flex">
        <FontAwesomeIcon className="cursor-pointer" icon='minus' size="lg" onClick={_=> {removeColumn()}} />
        <Dropdown className="position-static">
          <Dropdown.Toggle as={columnSelectorToggle}>
            <FontAwesomeIcon className="ms-2 cursor-pointer" icon='plus' size="lg" />
          </Dropdown.Toggle>
          <Dropdown.Menu as={columnSelectorMenu}>
            {
              _.map(addableColumns, (column) => {
                return (
                  <Dropdown.Item key={column.name} eventKey={column.name} onClick={_ => { addColumn(column) }}>{column.name}</Dropdown.Item>
                )
              })
            }
          </Dropdown.Menu>
        </Dropdown>
      </span>
    )
  }

  const styleFromDraggableProps = (draggableStyle, columnElement) => {
    if (!_.isInteger(dragStartIndex)) return {}

    if (dragStartIndex === index) {
      return _(_.clone(draggableStyle)).tap(style => {
        style.top = columnElements.current[columnElement].dataset.offsetTop - tcContainer.scrollTop
        style.height = columnElements.current[columnElement].offsetHeight
      }).value()
    }

    let translateAmount = 0
    if (!_.isNil(currentDraggable.current)) {
      // If column is being dragged left, else right
      if (draggingOverIndex < dragStartIndex) {
        if (index >= draggingOverIndex && index <= dragStartIndex) translateAmount = currentDraggable.current.offsetWidth
      } else if (index <= draggingOverIndex && index >= dragStartIndex) {
        translateAmount = -currentDraggable.current.offsetWidth
      }
    }

    return _(_.clone(draggableStyle)).tap(style => {
      style.transform = `translate(${translateAmount}px, 0px)`
      style.height = columnElements.current[columnElement].offsetHeight
    }).value()
  }

  return (
    <Draggable draggableId={`${index}-${id}-draggable`} index={index}>
      {(provided, _snapshot) => {
        return (
          <Fragment>
            {
              renderFiltersRow &&
              <div
                className="column-filters d-flex align-items-end flex-wrap"
                ref={el => columnElements.current['columnFilters'] = el}
                style={{gridArea: `1 / ${index + 1} / span 1 / span 1`, ...styleFromDraggableProps(provided.draggableProps.style, 'columnFilters')}}
              >
                {
                  _.map(filteredValues, value => {
                    return (
                      <Badge key={value} pill bg="secondary" className="me-2 mb-1">
                        {value}
                        <FontAwesomeIcon className="ms-2 cursor-pointer" icon="times" onClick={_ => {removeAppliedFilter(id, value)}} />
                      </Badge>
                    )
                  })
                }
              </div>
            }
            <div
              className={`table-header d-flex align-items-center ${isFirstColumn ? 'border-start rounded-top-left' : ''} ${isLastColumn ? 'border-end rounded-top-right' : ''}`}
              ref={el => { provided.innerRef(el); columnElements.current['tableHeader'] = el }}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{gridArea: `${rowOffset} / ${index + 1} / span 1 / span 1`, ...styleFromDraggableProps(provided.draggableProps.style, 'tableHeader')}}
            >
              <span onClick={_=>{ if (sortable) { sortColumn() } }} className={`ps-2 ${sortable ? 'sortable' : null} ${noWrapHeader ? 'text-nowrap' : ''}`}>
                {actionable && actionable(columnItems, () => setCounter(counter + 1))}
                {headerDisplay || id}
              </span>
              {sorted && <FontAwesomeIcon className="ms-1" icon={`angle-${sortDirection}`} />}
              {filtersDropdown}
              {renderAddableColumnsDropdown()}
            </div>
            {
              _.map(columnItems, (item, rowIndex) => {
                const isLastRow = rowIndex === columnItems.length - 1
                return (
                  <div
                    key={`${id}-${index}-${rowIndex}-data`}
                    ref={el => columnElements.current[rowIndex] = el}
                    className={`table-data d-flex align-items-center ${rowIndex % 2 === 0 ? 'striped' : ''} ${isFirstColumn ? 'border-start' : ''} ${isLastColumn ? 'border-end' : ''} ${isLastRow ? 'border-bottom' : ''} ${isFirstColumn && isLastRow ? 'rounded-bottom-left' : ''} ${isLastColumn && isLastRow ? 'rounded-bottom-right' : ''}`}
                    style={{gridArea: `${rowIndex + rowOffset + 1} / ${index + 1} / span 1 / span 1`, ...styleFromDraggableProps(provided.draggableProps.style, rowIndex)}}
                  >
                    {
                      displayer ? displayer(item)
                        : filterer ? filterer(accessor(item))
                        : accessor(item)
                    }
                  </div>
                )
              })
            }
          </Fragment>
        )
      }}
    </Draggable>
  )
}

export default InteractableTableColumn
