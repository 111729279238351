import React, { useEffect } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { InstantSearch, Configure, connectHits } from 'react-instantsearch-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ConfigurableSearchBox from './ConfigurableSearchBox'
import SearchClient from './Search'

function Hits({hits, refinement, setSearchHits}) {
  useEffect(() => {
    if (refinement) {
      setSearchHits(hits)
    } else {
      setSearchHits([])
    }
  }, [refinement, hits.length]) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

const SearchHits = connectHits(Hits)

function GenericSearch({indexName, filters, placeholder, currentRefinement, setCurrentRefinement, setSearchHits}) {
  const refineSearch = (refine, newRefinement) => {
    refine(newRefinement)
    setCurrentRefinement(newRefinement)
  }

  return (
    <InstantSearch searchClient={SearchClient} indexName={indexName}>
      <Configure
        filters={filters}
      />
      <ConfigurableSearchBox
        render={
          ({refine}) => {
            return (
              <Form.Group>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon='search' />
                  </InputGroup.Text>
                  <Form.Control
                    type="search"
                    autoComplete="off"
                    placeholder={placeholder}
                    onChange={e => refineSearch(refine, e.currentTarget.value)} />
                </InputGroup>
              </Form.Group>
            )
          }
        }
      />
      <SearchHits
        refinement={currentRefinement}
        setSearchHits={setSearchHits}
      />
    </InstantSearch>
  )
}

export default GenericSearch
