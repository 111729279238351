import _ from 'lodash'
import React, { Fragment, useState } from 'react'
import { Button, Col, Collapse, FloatingLabel, Form, Row, Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { appendNotification } from '../notifications/notificationsSlice'
import FormDocument from '../forms/FormDocument'
import GenericForm from '../forms/GenericForm'
import Graphql from '../Graphql'
import { graphqlApi} from '../Api'
import Location from './Location'
import { restApi } from '../Api'
import Requestable from '../generic/Requestable'
import StateOptions from '../utils/StateOptions'

const companySettingsFields = ['name', 'legal_name', 'email', 'phone', 'address_1', 'address_2', 'city', 'postal', 'state', 'logo', 'rmis_website_url']
const companyLocationsFields = ['id', 'name', 'phone', 'email', 'address_1', 'address_2', 'city', 'postal', 'state', 'time_zone']

function CompanySettings(props) {
  const { companyId } = useParams()
  const [companyData, setCompanyData] = useState({})
  const [locations, setLocations] = useState([])
  const [addressExpanded, setAddressExpanded] = useState(false)
  const [locationsExpanded, setLocationsExpanded] = useState(false)
  const dispatch = useDispatch()

  const companySettingsQuery = Graphql.company({
                                 fields: [
                                   ...companySettingsFields,
                                   Graphql.relatedLocations({
                                     filters: {deleted: false},
                                     fields: companyLocationsFields
                                   })
                                 ]
                               })

  const companySettingsLoadSuccess = (res) => {
    setCompanyData(Graphql.transformData(Graphql.getValue(res, 'company'), companySettingsFields))
    setLocations(Graphql.transformData({...Graphql.getValue(res, 'company.locations')}))
  }

  const handleCompanyUpdateSuccess = (_) => {
    dispatch(appendNotification({type: 'CompanyUpdated'}))
  }

  const generalInfoFormContent = ({handleFormChange, isSubmitting}) => {
    return (
      <Fragment>
        <Row>
          <Col xl={4}>
            <FloatingLabel controlId="name" label="Name">
              <Form.Control disabled value={companyData.name} />
            </FloatingLabel>
            <FloatingLabel controlId="legalName" label="Legal Name">
              <Form.Control disabled value={companyData.legalName} />
            </FloatingLabel>
            <FloatingLabel controlId="email" label="Email">
              <Form.Control type="email" placeholder="Email" required value={companyData.email} onChange={e=>handleFormChange(setCompanyData, e)} />
              <Form.Control.Feedback type="invalid">Email is required</Form.Control.Feedback>
            </FloatingLabel>
            <FloatingLabel controlId="phone" label="Phone Number">
              <Form.Control type="tel" placeholder="Phone Number" required value={companyData.phone} onChange={e=>handleFormChange(setCompanyData, e)} />
              <Form.Control.Feedback type="invalid">Phone Number is required</Form.Control.Feedback>
            </FloatingLabel>
            <FloatingLabel controlId="rmisWebsiteUrl" label="RMIS Website">
              <Form.Control type="url" placeholder="RMIS Website" value={companyData.rmisWebsiteUrl} onChange={e=>handleFormChange(setCompanyData, e)} />
              <Form.Control.Feedback type="invalid">Url is not formatted correctly</Form.Control.Feedback>
            </FloatingLabel>
            <FormDocument id="logo"
                          fileExists={!_.isEmpty(companyData.logo)}
                          filePath={`/company/${companyId}/documents/logo/${companyId}`}
                          fileLabel="Logo"
                          fileSource={companyData.logo}
                          handleDocumentChange={e=>handleFormChange(setCompanyData, e)} />
          </Col>
        </Row>
        <Row className="border-bottom">
          <Col xl={1} xs={12}>
            <Button type="submit" className="indigo-button w-100 mb-3">
              {isSubmitting ? <Spinner size="sm" animation="border" variant="light" /> : 'Update'}
            </Button>
          </Col>
        </Row>
      </Fragment>
    )
  }

  const addressInfoFormContent = ({handleFormChange, isSubmitting}) => {
    return (
      <Row className="mt-4 pb-3 border-bottom">
        <Col xs={4}>
          <h5><b>Address</b></h5>
        </Col>
        <Col xl={{ span: 1, offset: 7 }} xs={{ span: 4, offset: 4 }}>
          <Button className="indigo-button w-100" onClick={_ => setAddressExpanded(!addressExpanded)}>{addressExpanded ? 'Collapse' : 'Expand'}</Button>
        </Col>
        <Col xl={4}>
          <Collapse in={addressExpanded}>
            <div className="mt-3">
              <FloatingLabel controlId='address1' label="Address 1">
                <Form.Control required type="text" placeholder="Address 1" value={companyData.address1} onChange={e=>handleFormChange(setCompanyData, e)} />
                <Form.Control.Feedback type="invalid">Primary Address is required</Form.Control.Feedback>
              </FloatingLabel>
              <FloatingLabel controlId='address2' label="Address 2">
                <Form.Control type="text" placeholder="Address 2" value={companyData.address2 || ''} onChange={e=>handleFormChange(setCompanyData, e)} />
              </FloatingLabel>
              <FloatingLabel controlId='city' label="City">
                <Form.Control required type="text" placeholder="City" value={companyData.city} onChange={e=>handleFormChange(setCompanyData, e)} />
                <Form.Control.Feedback type="invalid">City is required</Form.Control.Feedback>
              </FloatingLabel>
              <FloatingLabel controlId='state' label="State">
                <Form.Select value={companyData.state} onChange={e=>handleFormChange(setCompanyData, e)}>
                  <StateOptions />
                </Form.Select>
              </FloatingLabel>
              <FloatingLabel controlId="postal" label="Postal">
                <Form.Control required type="text" placeholder="Postal" value={companyData.postal} onChange={e=>handleFormChange(setCompanyData, e)} />
                <Form.Control.Feedback type="invalid">Postal is required</Form.Control.Feedback>
              </FloatingLabel>
              <Row>
                <Col xl={3} xs={12}>
                  <Button type="submit" className="indigo-button w-100">
                    {isSubmitting ? <Spinner size="sm" animation="border" variant="light" /> : 'Update'}
                  </Button>
                </Col>
              </Row>
            </div>
          </Collapse>
        </Col>
      </Row>
    )
  }

  const companyLocations = () => {
    return (
      <Fragment>
        <Row className="mt-4">
          <Col xl={4} xs={8}>
            <h5><b>Locations</b></h5>
          </Col>
          <Col xl={{ span: 1, offset: 7 }} xs={{ span: 4, offset: 0 }}>
            <Button className="indigo-button w-100" onClick={_ => setLocationsExpanded(!locationsExpanded)}>{locationsExpanded ? 'Collapse' : 'Expand'}</Button>
          </Col>
        </Row>
        <Collapse in={locationsExpanded}>
          <div className="mt-4">
            {
              _.map(locations, (location, key) => <Location key={key} dataKey={key} setLocations={setLocations} {...location} />)
            }
            <Row>
              <Col xl={4} xs={12}>
                <Button className="indigo-button w-100" onClick={() => setLocations({...locations, [_.size(locations) + 1]: {}})}>Add New Location</Button>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Fragment>
    )
  }

  return (
    <Requestable
      onMountFetch={graphqlApi.execute(companySettingsQuery, companySettingsLoadSuccess)}
      render={() => {
          return (
            <Fragment>
              <h4><b>Company</b></h4>
              <GenericForm id="company-general-info-form" formContent={generalInfoFormContent} formRequest={restApi.updateCompany} params={companyData} handleSuccess={handleCompanyUpdateSuccess} />
              <GenericForm id="company-address-form" formContent={addressInfoFormContent} formRequest={restApi.updateCompany} params={companyData} handleSuccess={handleCompanyUpdateSuccess} />
              {companyLocations()}
            </Fragment>
          )
        }
      }
    />
  )
}

export default CompanySettings
