import _ from 'lodash'
import React, { Fragment, useState } from 'react'
import { Accordion, Col, Form, Row } from 'react-bootstrap'

import Graphql from '../Graphql'
import { graphqlApi} from '../Api'
import Notification from './Notification'
import Requestable from '../generic/Requestable'

function NotificationsSettings({companyId}) {
  const [notificationsList, setNotificationsList] = useState([])
  const [filter, setFilter] = useState('')
  const [users, setUsers] = useState([])

  const companyNotificationsQuery = Graphql.company({
                                      fields: [
                                        Graphql.relatedNotifications({
                                          fields: [
                                            'id', 'kind',
                                            Graphql.relatedUsers({fields: ['id']})
                                          ]
                                        }),
                                        Graphql.relatedUsers({
                                          filters: {is_active: true},
                                          fields: ['id', 'first_name', 'last_name']
                                        })
                                      ]
                                    })

  const companyNotificationsLoadSuccess = (res) => {
    setUsers(Graphql.transformData(Graphql.getValue(res, 'company.users')))
    setNotificationsList(Graphql.transformData(Graphql.getValue(res, 'company.notifications')))
  }

  return (
    <Requestable
      onMountFetch={graphqlApi.execute(companyNotificationsQuery, companyNotificationsLoadSuccess)}
      render={() => {
        return (
          <>
            <h4><b>Notifications</b></h4>
            <Row className="my-3">
              <Col xl={4} xs={12}>
                <Form.Control
                  type="search"
                  autoComplete="off"
                  placeholder="Type to filter..."
                  onChange={(e) => setFilter(e.target.value)}
                  value={filter}
                />
              </Col>
            </Row>
            <Accordion>
              {
                _.map(notificationsList, (notification) => {
                  if (_.isEmpty(filter)) {
                    return <Notification key={notification.id} id={notification.id} kind={notification.kind} users={users} subscribedUsers={notification.users} />
                  } else {
                    if (_.includes(_.lowerCase(notification.kind), _.lowerCase(filter))) {
                      return <Notification key={notification.id} id={notification.id} kind={notification.kind} users={users} subscribedUsers={notification.users} />
                    }
                  }
                })
              }
            </Accordion>
          </>
        )
        }
      }
    />
  )
}

export default NotificationsSettings
