import deepEqual from 'deep-equal'
import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { Fragment, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Alert, Button, Card, Col, Form, ListGroup, Row, Spinner } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { GoogleMap, InfoWindow, Marker, Polyline, useLoadScript } from '@react-google-maps/api'
import { faTruckMoving } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from '../generic/Tooltip'

import { appendNotification } from '../notifications/notificationsSlice'
import { BOLContext } from './BOL'
import PusherClient, { companyTrackingUpdatesChannel } from '../pusher/Pusher'
import { restApi } from '../Api'
import Requestable from '../generic/Requestable'

const containerStyle = {
  width: '100%',
  height: '40rem',
  borderRadius: '0.25rem'
}

const mapOptions = {
  center: {lat: 39.828099, lng: -98.579186},
  gestureHandling: 'cooperative',
  mapTypeControl: false,
  streetViewControl: false,
  zoom: 4,
}

const COLOR_OPTIONS = [
  '#237bfa',
  '#ea5010',
  '#499500',
  '#7c11bd',
  '#e06a12',
  '#daba18',
  '#a2a68f',
  '#9b064a',
  '#73bcf0',
  '#ec67cc',
  '#9fad03',
  '#68dcc4',
  '#b08754',
  '#3694a3',
  '#f38f58',
  '#da97ef',
  '#6eae76',
  '#dd6c96',
  '#f29f34'
]

const buildIconData = (color) => {
  return {
    path: faTruckMoving.icon[4],
    fillColor: color,
    fillOpacity: 1,
    anchor: new google.maps.Point( // eslint-disable-line
      faTruckMoving.icon[0] / 2, // width
      faTruckMoving.icon[1] / 2, // height
    ),
    strokeWeight: 1,
    strokeColor: '#fff',
    scale: 0.05,
    labelOrigin: new google.maps.Point( // eslint-disable-line
      faTruckMoving.icon[0] / 2.5, // x position
      faTruckMoving.icon[1] / 2.5, // y position
    )
  }
}

const BOLTrackingDrawings = React.memo(({trackingUpdates, map, color, activeMarkerId, holdMarkerOpen, setActiveMarkerId, setHoldMarkerOpen, hidePolylines, latestLocationsOnly, companyId, bolNumber}) => {
  const successfulTrackingUpdates = _.filter(trackingUpdates, trackingUpdate => trackingUpdate.status_string === 'Success')
  const trackingCoordinates = []

  return (
    <Fragment>
      {
        _.map((latestLocationsOnly ? _.takeRight(successfulTrackingUpdates, 1) : successfulTrackingUpdates), ({id, latitude, longitude, long_address, requested_at, responded_at, short_address}, index) => {
          const coords = {lat: parseFloat(latitude), lng: parseFloat(longitude)}
          trackingCoordinates.push(coords)

          return (
            <Marker
              key={`marker-${id}`}
              position={coords}
              label={latestLocationsOnly ? '' : `${index + 1}`}
              icon={buildIconData(color)}
              onMouseOver={() => {setActiveMarkerId(id); setHoldMarkerOpen(false)} }
              onMouseOut={() => { if (!holdMarkerOpen) setActiveMarkerId(null)} }
              onClick={() => {setActiveMarkerId(id); setHoldMarkerOpen(true)} }
              onDblClick={() => {map.current.setZoom(17); map.current.panTo(coords)} }
            >
              {
                activeMarkerId === id &&
                <InfoWindow onCloseClick={() => {setActiveMarkerId(null); setHoldMarkerOpen(false)} }>
                  <Fragment>
                    <h6 className="mb-1">
                      Location Update - <Link to={`/company/${companyId}/shipments/edit-bol/${bolNumber}`}>BOL #{bolNumber}</Link>
                    </h6>
                    <div className="mb-1">{long_address}</div>
                    <div><b>Received at:</b> {DateTime.fromISO(responded_at).toFormat('MM-dd-yyyy h:mm:ss a')}</div>
                    <div><b>Requested at:</b> {DateTime.fromISO(requested_at).toFormat('MM-dd-yyyy h:mm:ss a')}</div>
                  </Fragment>
                </InfoWindow>
              }
            </Marker>
          )
        })
      }
      {!hidePolylines && <Polyline path={trackingCoordinates} options={{strokeColor: color, strokeOpacity: 1, strokeWeight: 2, geodesic: true}} />}
    </Fragment>
  )
}, (prevProps, nextProps) => {
  if (prevProps.hidePolylines !== nextProps.hidePolylines) return false
  if (prevProps.latestLocationsOnly !== nextProps.latestLocationsOnly) return false
  const markerIds = _.map(nextProps.trackingUpdates, 'id')
  const affectsMarkers =  _.intersection(markerIds, [prevProps.activeMarkerId, nextProps.activeMarkerId]).length > 0
  if (affectsMarkers) return false
  return deepEqual(prevProps.trackingUpdates, nextProps.trackingUpdates)
})

const buildDelayedDurationString = (considered_delayed_at, scheduled_at) => {
  const diffObject = DateTime.fromISO(considered_delayed_at).diff(DateTime.fromISO(scheduled_at), ['hours', 'minutes']).toObject()
  return _.join(_([]).tap(arr => {
    if (diffObject.hours) arr.push(`${diffObject.hours} hours`)
    if (diffObject.minutes) arr.push(`${diffObject.minutes} minutes`)
  }).value(), 'and ')
}

const renderPendingUpdate = ({id, considered_delayed_at, delayed_response_detected, scheduled_at}) => {
  return (
    <Card key={id} className="opacity-75">
      <Card.Header className={`${delayed_response_detected && 'alert mb-0 py-2 alert-warning'}`}>
        {delayed_response_detected &&
          <Tooltip id={`delayed-response-tt-${id}`} text={`This tracking update has not been responded to within the expected threshold (${buildDelayedDurationString(considered_delayed_at, scheduled_at)})`}>
            <FontAwesomeIcon className="cursor-pointer me-2" icon='exclamation-circle' size='lg' />
          </Tooltip>
        }
        Pending Tracking Update
      </Card.Header>
      <Card.Body>
        <Fragment><b>Scheduled At:</b> {DateTime.fromISO(scheduled_at).toFormat('MM-dd-yyyy h:mm a')}</Fragment>
      </Card.Body>
    </Card>
  )
}

const renderSuccessfulUpdate = ({id, short_address, responded_at}, setActiveMarkerId, successfulUpdateNumber) => {
  return (
    <Card key={id} className="cursor-pointer" onClick={() => setActiveMarkerId(id) }>
      <Card.Header className="alert mb-0 py-2 alert-success">
        {`Tracking Update #${successfulUpdateNumber}`}
      </Card.Header>
      <Card.Body>
        <div><b>Location:</b> {short_address}</div>
        <div><b>Received At:</b> {DateTime.fromISO(responded_at).toFormat('MM-dd-yyyy h:mm:ss a')}</div>
      </Card.Body>
    </Card>
  )
}

const renderUnsuccessfulUpdate = ({id, scheduled_at, status_string}) => {
  return (
    <Card key={id}>
      <Card.Header className="alert mb-0 py-2 alert-danger">
        {`Tracking Unsuccessful - ${status_string}`}
      </Card.Header>
      <Card.Body>
        <div><b>Originally Scheduled At:</b> {DateTime.fromISO(scheduled_at).toFormat('MM-dd-yyyy h:mm a')}</div>
      </Card.Body>
    </Card>
  )
}

const TrackingHistoryList = React.memo(({trackingDatas, setTrackingDatas, setActiveMarkerId}) => {
  const { number, status } = useContext(BOLContext)
  const [pastUpdates, futureUpdates] = _.partition(trackingDatas[number], ({scheduled_at}) => DateTime.fromISO(scheduled_at) <= DateTime.now().plus({ minutes: 1 }))
  const [showFutureUpdates, setShowFutureUpdates] = useState(false)
  let successfulUpdatesCount = _.sumBy(pastUpdates, trackingUpdate => trackingUpdate.status_string === 'Success')
  const trackingCards = []
  const dispatch = useDispatch()

  const handleLocationRequestSuccess = ({data}) => {
    dispatch(appendNotification({type: 'Location', verb: 'requested'}))
    data.id = data.id.toString()
    setTrackingDatas(prevState => {
      return {
        ...prevState,
        [number]: _.sortBy(_.concat(prevState[number], data), 'scheduled_at')
      }
    })
  }

  _.forEachRight(showFutureUpdates ? pastUpdates.concat(futureUpdates) : pastUpdates, trackingUpdate => {
    trackingUpdate.status_string === 'Pending' ? trackingCards.push(renderPendingUpdate(trackingUpdate))
    : trackingUpdate.status_string === 'Success' ? trackingCards.push(renderSuccessfulUpdate(trackingUpdate, setActiveMarkerId, successfulUpdatesCount--))
    : trackingCards.push(renderUnsuccessfulUpdate(trackingUpdate))
  })

  return (
    <Fragment>
      <div className="mb-2 d-flex">
        {
          status !== 'DELIVERED' &&
          <Requestable
            withoutLoading
            withErrorHandling
            render={({performRequest, isLoading}) => {
              return (
                <Button className="outline-indigo flex-fill" onClick={() => performRequest(restApi.sendLocationRequest({'id': number}, handleLocationRequestSuccess))}>
                  {
                    isLoading ? <Spinner size="sm" animation="border" variant="light" /> : 'Request Location'
                  }
                </Button>
              )
            }}
          />
        }
      </div>
      {
        !_.isEmpty(futureUpdates) &&
        <div className="d-flex justify-content-center mb-2">
          <Button variant="link" onClick={_ => setShowFutureUpdates(!showFutureUpdates)}>{showFutureUpdates ? 'Hide' : 'Show'} Future Tracking Schedule</Button>
        </div>
      }
      <div className="d-flex flex-column gap-3">
        {!_.isEmpty(trackingCards) && [trackingCards]}
        {_.isEmpty(trackingDatas[number]) && <span className="mx-auto">There is no tracking history or scheduled updates for this load</span>}
      </div>
    </Fragment>
  )
}, (prevProps, nextProps) => {
  return deepEqual(prevProps.trackingDatas, nextProps.trackingDatas)
})

const TrackingLegendTable = React.memo(({trackingDatas, iconColors, setActiveMarkerId}) => {
  return (
    <ListGroup id="tracking-legend-table">
      <ListGroup.Item className="d-flex fw-bold bg-light">
        <Col xs={1}>
          <svg width="1rem" height="1rem">
            <circle cx="0.5rem" cy="0.5rem" r="50%" fill='#808080' />
          </svg>
        </Col>
        <Col xs={2}>
          BOL #
        </Col>
        <Col>
          Latest Location
        </Col>
        <Col>
          Received At
        </Col>
      </ListGroup.Item>
      <div id="tracking-legend-table-data" className="tc-scroll">
        {
          _.size(trackingDatas) ?
            _.map(trackingDatas, (trackingUpdates, bolNumber) => {
              const lastKnownLocation = _.findLast(trackingUpdates, ({status_string}) => status_string === 'Success')
              if (lastKnownLocation) {
                return (
                  <ListGroup.Item key={bolNumber} className="d-flex cursor-pointer" onClick={() => setActiveMarkerId(lastKnownLocation.id) }>
                    <Col xs={1}>
                      <svg width="1rem" height="1rem">
                        <circle cx="0.5rem" cy="0.5rem" r="50%" fill={iconColors[bolNumber]} />
                      </svg>
                    </Col>
                    <Col xs={2}>
                      {bolNumber}
                    </Col>
                    <Col>
                      {lastKnownLocation.short_address}
                    </Col>
                    <Col>
                      {DateTime.fromISO(lastKnownLocation.responded_at).toFormat('MM-dd-yyyy h:mm a')}
                    </Col>
                  </ListGroup.Item>
                )
              }
            }) : <ListGroup.Item>No tracking history to display</ListGroup.Item>
        }
      </div>
    </ListGroup>
  )
}, (prevProps, nextProps) => {
  return deepEqual(prevProps.trackingDatas, nextProps.trackingDatas)
})

const setMapBounds = (trackingDatas, mapInstance) => {
  let points = 0
  const bounds = new google.maps.LatLngBounds() // eslint-disable-line
  _.forEach(trackingDatas, (trackingUpdates, _bolNumber) => {
    _.forEach(trackingUpdates, ({latitude, longitude, status_string}) => {
      if (status_string === 'Success') {
        bounds.extend({lat: parseFloat(latitude), lng: parseFloat(longitude)})
        points++
      }
    })
  })

  if (points === 0) return

  if (points < 2) {
     let extension1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 1.5, bounds.getNorthEast().lng() + 1.5) // eslint-disable-line
     let extension2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 1.5, bounds.getNorthEast().lng() - 1.5) // eslint-disable-line
     bounds.extend(extension1)
     bounds.extend(extension2)
  }

  mapInstance.setCenter(bounds.getCenter())
  mapInstance.fitBounds(bounds)
}

const updateTrackingUpdate = (bolNumber, trackingUpdate, setTrackingDatas, singleBOL) => {
  trackingUpdate.id = trackingUpdate.id.toString()
  setTrackingDatas(prevState => {
    if (singleBOL && !prevState[bolNumber]) return prevState
    if (prevState[bolNumber]) _.remove(prevState[bolNumber], { id: trackingUpdate.id })

    return {
      ...prevState,
      [bolNumber]: _.sortBy(_.concat(prevState[bolNumber], trackingUpdate), 'scheduled_at') || [trackingUpdate]
    }
  })
}

// TODO singleBOL should really be provided via a context
function TrackingHistory({initialTrackingDatas, initialLatestLocationsOnly, singleBOL = true}) {
  const [trackingDatas, setTrackingDatas] = useState(initialTrackingDatas)
  const [activeMarkerId, setActiveMarkerId] = useState(null)
  const [holdMarkerOpen, setHoldMarkerOpen] = useState(false)
  const [hidePolylines, setHidePolylines] = useState(false)
  const [iconColors, setIconColors] = useState()
  const [latestLocationsOnly, setLatestLocationsOnly] = useState(initialLatestLocationsOnly || false)
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  })
  const map = useRef()
  const { companyId } = useParams()

  useEffect(() => {
    if (_.isNil(trackingDatas)) setTrackingDatas(initialTrackingDatas)
  }, [initialTrackingDatas]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let i = 0
    setIconColors(
      _.transform(trackingDatas, (result, _value, bolNumber) => {
        result[bolNumber] = COLOR_OPTIONS[i]
        i + 1 === COLOR_OPTIONS.length ? i = 0 : i++
      }, {})
    )
  }, [trackingDatas])

  useEffect(() => {
    if (map.current) setMapBounds(trackingDatas, map.current)
  }, [trackingDatas])

  useEffect(() => {
    const pusherChannel = PusherClient.subscribe(companyTrackingUpdatesChannel(companyId))
    pusherChannel.bind('update-received', ({bol_number, tracking_update}) => updateTrackingUpdate(bol_number, tracking_update, setTrackingDatas, singleBOL))
  }, [companyId]) // eslint-disable-line react-hooks/exhaustive-deps

  const onLoad = useCallback((mapInstance) => {
    map.current = mapInstance
    setMapBounds(trackingDatas, mapInstance)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const resetActiveMarker = useCallback(() => {
    setActiveMarkerId(null)
    setHoldMarkerOpen(false)
  }, [])

  if (loadError) {
    return <Alert variant="danger">An issue occurred trying to load Google Maps</Alert>
  }

  return (
    <Row>
      {
        isLoaded ?
        <Fragment>
          <Col lg={4} xs={12}>
            {
              singleBOL ?
              <TrackingHistoryList trackingDatas={trackingDatas} setTrackingDatas={setTrackingDatas} setActiveMarkerId={setActiveMarkerId} />
              : <TrackingLegendTable trackingDatas={trackingDatas} iconColors={iconColors} setActiveMarkerId={setActiveMarkerId} />
            }
          </Col>
          <Col>
            <div className="d-flex mb-2 mt-4 mt-lg-0">
              <div className="d-flex flex-column ms-auto">
                <Form.Switch
                  id="showLatestOnly"
                  className="my-auto custom-switch-lg"
                  checked={latestLocationsOnly}
                  onChange={e=> {setLatestLocationsOnly(!latestLocationsOnly); resetActiveMarker()} }
                  label="Latest Locations Only"
                />
              </div>
              <div className="d-flex flex-column">
                <Form.Switch
                  id="hidePolylinesSwitch"
                  className="ms-2 my-auto"
                  checked={hidePolylines}
                  onChange={e=> setHidePolylines(!hidePolylines)}
                  label="Hide Lines"
                />
              </div>
              <Button className="outline-indigo ms-2" onClick={() => setMapBounds(trackingDatas, map.current)}>
                Reset Zoom
              </Button>
            </div>
            <GoogleMap
              mapContainerStyle={containerStyle}
              options={mapOptions}
              onLoad={onLoad}
              onClick={resetActiveMarker}
            >
              {
                _.map(trackingDatas, (trackingUpdates, bolNumber) => {
                  return <BOLTrackingDrawings
                           key={bolNumber}
                           bolNumber={bolNumber}
                           trackingUpdates={trackingUpdates}
                           map={map}
                           color={iconColors[bolNumber]}
                           activeMarkerId={activeMarkerId}
                           holdMarkerOpen={holdMarkerOpen}
                           setActiveMarkerId={setActiveMarkerId}
                           setHoldMarkerOpen={setHoldMarkerOpen}
                           hidePolylines={hidePolylines}
                           latestLocationsOnly={latestLocationsOnly}
                           companyId={companyId}
                         />
                })
              }
            </GoogleMap>
          </Col>
        </Fragment>
        : <Spinner animation="border" variant="secondary" className='mx-auto' />
      }
    </Row>
  )
}

export default TrackingHistory
