import _ from 'lodash'
import { DateTime } from 'luxon'
import { Fragment } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SearchResultActions from './SearchResultActions'
import Tooltip from '../generic/Tooltip'

function PosterCompanyPopover({matchData, children}) {
  const {
    comments,
    matchId,
    posterDotIds,
    posterInfo
  } = matchData

  const popoverContent = (
    <Popover id={`poster-company-popover-${matchId}`} placement="auto">
      <Popover.Header>Company Details</Popover.Header>
      <Popover.Body className="px-0 py-2">
        <div className="px-2">
          <div className="fw-bold">MC Number</div>
          {posterDotIds.carrierMcNumber}
        </div>
        <div className="px-2 pt-2 mt-2 border-top">
          <div className="fw-bold">USDOT Number</div>
          {posterDotIds.dotNumber}
        </div>
        <div className="px-2 pt-2 mt-2 border-top">
          <div className="fw-bold">Post Comments</div>
          {comments || 'None'}
        </div>
        <div className="px-2 pt-2 mt-2 border-top">
          <div className="fw-bold">Home Location</div>
          {posterInfo.city}, {posterInfo.carrierHomeState}
        </div>
      </Popover.Body>
    </Popover>
  )

  return (
    <OverlayTrigger trigger="click" overlay={popoverContent} rootClose>
      {children}
    </OverlayTrigger>
  )
}

function StatesAndZonesPopover({matchId, area}) {
  const { states, zones } = area

  const popoverContent = (
    <Popover id={`states-zones-popover-${matchId}`} placement="auto">
      <Popover.Header>States & Zones</Popover.Header>
      <Popover.Body className="px-0 py-2">
        <div className="px-2">
          <div className="fw-bold">States</div>
          {_.isEmpty(states) ? 'None specified' : _.join(states, ', ')}
        </div>
        <div className="px-2 pt-2 mt-2 border-top">
          <div className="fw-bold">Zones</div>
          {_.isEmpty(zones) ? 'None specified' : _.join(zones, ', ')}
        </div>
      </Popover.Body>
    </Popover>
  )

  return (
    <OverlayTrigger trigger="click" overlay={popoverContent} rootClose>
      <span className="text-primary">
        States & Zones
      </span>
    </OverlayTrigger>
  )
}

const RefinableColumns = {
  actions: {
    name: 'Actions',
    filterable: true,
    complexFilterable: true,
    filterer: (tableItem, selectedValues) => {
      return _.every(selectedValues, value => {
        if (value === 'Not Rejected/Hidden') return !tableItem.rejected
        if (value === 'Rejected/Hidden') return tableItem.rejected
        if (value === 'Accepted') return tableItem.accepted
        if (value === 'Has Notes') return !_.isNil(tableItem.notes)
      })
    },
    staticFilters: ['Not Rejected/Hidden', 'Rejected/Hidden', 'Has Notes', 'Accepted'],
    defaultFilters: ['Not Rejected/Hidden'],
    displayer: tableItem => <SearchResultActions key={tableItem.postingId} {...tableItem} />,
    flexFactor: 0.15
  },
  postAge: {
    name: 'Post Age',
    sortable: true,
    accessor: tableItem => tableItem.servicedWhen,
    converter: dateTimeStr => DateTime.fromISO(dateTimeStr, {zone: 'utc'}),
    displayer: tableItem => {
      const diff = DateTime.now().diff(DateTime.fromISO(tableItem.servicedWhen, {zone: 'utc'}), ['hours', 'minutes'])
      return `${diff.hours ? `${diff.hours} hr/` : ''}${Math.trunc(diff.minutes)} min`
    },
    noWrapHeader: true,
    flexFactor: 0.15
  },
  availabilityIntersection: {
    name: 'Available On',
    sortable: true,
    filterable: true,
    accessor: tableItem => tableItem.presentationDate,
    converter: dateTimeStr => DateTime.fromISO(dateTimeStr, {zone: 'utc'}),
    filterer: dateTimeStr => DateTime.fromISO(dateTimeStr, {zone: 'US/Pacific'}).toFormat('MM-dd'),
    flexFactor: 0.15,
    noWrapHeader: true,
  },
  posterCompany: {
    name: 'Company Name',
    filterable: true,
    sortable: true,
    accessor: tableItem => tableItem.posterInfo.companyName,
    displayer: tableItem => <PosterCompanyPopover key={tableItem.matchId} matchData={tableItem}><span className="text-primary">{tableItem.posterInfo.companyName}</span></PosterCompanyPopover>,
    asCardHeader: ({children, tableItem}) => <PosterCompanyPopover key={tableItem.matchId} matchData={tableItem}><div className="card-header text-decoration-underline">{children}</div></PosterCompanyPopover>
  },
  equipmentType: equipmentTypes => {
    return (
      {
        name: 'Truck Type',
        filterable: true,
        sortable: true,
        accessor: tableItem => tableItem.matchingAssetInfo.equipmentType,
        filterer: equipmentTypeStr => _.startCase(_.lowerCase(equipmentTypes[equipmentTypeStr])),
        flexFactor: 0.75
      }
    )
  },
  origin: {
    name: 'Truck Origin',
    filterable: true,
    accessor: tableItem => tableItem.matchingAssetInfo.origin,
    filterer: originData => `${originData.city}, ${originData.stateProv}`
  },
  originDeadheadMiles: {
    name: 'Miles from Origin',
    sortable: true,
    accessor: tableItem => tableItem.originDeadheadMiles.miles,
    displayer: tableItem => tableItem.originDeadheadMiles.miles.toString(),
    flexFactor: 0.65
  },
  destination: {
    name: 'Desired Destination',
    headerDisplay: <Fragment>Desired Destination<Tooltip id="desired-destination-tt" text="The destination/area the truck owner prefers to end up after delivery of freight"><FontAwesomeIcon icon={['far', 'question-circle']} className="ms-1" /></Tooltip></Fragment>,
    filterable: true,
    accessor: tableItem => tableItem.matchingAssetInfo.destination,
    filterer: destinationData => {
      return destinationData.open ? 'Anywhere'
              : destinationData.place ? `${destinationData.place.city}, ${destinationData.place.stateProv}`
              : destinationData.area ? 'States & Zones'
              : 'KnownPlaceId' // TOOD
    },
    displayer: tableItem => {
      const destinationData = tableItem.matchingAssetInfo.destination
      return destinationData.open ? 'Anywhere'
              : destinationData.place ? `${destinationData.place.city}, ${destinationData.place.stateProv}`
              : destinationData.area ? <StatesAndZonesPopover matchId={tableItem.matchId} area={destinationData.area} />
              : 'KnownPlaceId' // TOOD
    },
    flexFactor: 0.20
  },
  destinationDeadheadMiles: {
    name: 'Miles from Destination',
    sortable: true,
    accessor: tableItem => tableItem.destinationDeadheadMiles ? tableItem.destinationDeadheadMiles.miles : 'N/A',
    displayer: tableItem => tableItem.destinationDeadheadMiles ? tableItem.destinationDeadheadMiles.miles.toString() : 'N/A',
    flexFactor: 0.15
  },
  length: {
    name: 'Truck Length',
    accessor: tableItem => tableItem.matchingAssetInfo.capacity.truck.availableLengthFeet,
    flexFactor: 0.15
  },
  weight: {
    name: 'Weight Capacity',
    accessor: tableItem => tableItem.matchingAssetInfo.capacity.truck.availableWeightPounds,
    flexFactor: 0.15
  },
  contact: {
    name: 'Contact Method',
    displayer: tableItem => {
      const { email, phone } = tableItem.posterInfo.contact
      return email ? <a href={`mailto:${email}`} target="_blank" rel="noreferrer">{email}</a> : <a href={`tel:${phone}`}>{phone}</a>
    }
  }
}

export default RefinableColumns
