import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, FloatingLabel, Form, Spinner, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AppendableFieldsGroup from '../forms/AppendableFieldsGroup'
import { appendNotification } from '../notifications/notificationsSlice'
import FormDropdownSearch from '../search/FormDropdownSearch'
import GenericForm from '../forms/GenericForm'
import { restApi } from '../Api'
import StateOptions from '../utils/StateOptions'
import Tooltip from '../generic/Tooltip'

function BOLReceiver({companyId, dataKey, formData, setFormData, receiverCount, setReceiverCount}) {
  const receiverIsPersisted = formData[dataKey] && (formData[dataKey].id || formData[dataKey].objectID)
  const [editingReceiver, setEditingReceiver] = useState(false)
  const receiver = formData[dataKey]
  const dispatch = useDispatch()

  const handleSearchHitSelection = (setAppendableValues) => {
    return (receiverObj) => {
      setAppendableValues(receiverObj)
      setEditingReceiver(false)
    }
  }

  const handleReceiverUpdateSuccess = (res) => {
    dispatch(appendNotification({type: 'Receiver', companyId: companyId, receiverId: res.data.id, verb: 'updated'}))
    setEditingReceiver(false)
  }

  const cancelReceiverEdit = (initialFormData, setAppendableValues) => {
    setAppendableValues(initialFormData)
    setEditingReceiver(false)
  }

  return (
    <AppendableFieldsGroup
      dataKey={dataKey}
      groupName='receiversList'
      group={formData}
      setState={setFormData}
      renderContent={
        ({getAppendableValue, setAppendableValue, setAppendableValues, removeAppendedFields}) => {
          return (
            <>
              { receiverCount > 1 &&
                  <Row className="mb-1">
                    <Col>
                      <h5>Destination {dataKey+1}</h5>
                    </Col>
                  </Row>
              }
              {
                receiverIsPersisted && !editingReceiver &&
                <Card className="mb-3">
                  <Card.Body className="d-flex">
                    <b className="me-1">{receiver.name}</b>{_.join(_.compact([receiver.address_1, receiver.address_2, receiver.city, receiver.state]), ', ')} {receiver.postal}
                    <Tooltip id="edit-stop-or-receiver-tt" text="Change this destination or update receiver details">
                      <FontAwesomeIcon icon={['far', 'edit']} className="ms-auto cursor-pointer" size="lg" onClick={_ => setEditingReceiver(true)} />
                    </Tooltip>
                    <Tooltip id="remove-stop-tt" text="Remove this destination">
                      <FontAwesomeIcon className="cursor-pointer error-red ms-3" icon='times' size="lg" onClick={() => removeAppendedFields(dataKey, setReceiverCount)} />
                    </Tooltip>
                  </Card.Body>
                </Card>
              }
              {
                (!receiverIsPersisted || editingReceiver) &&
                <>
                  <Row>
                    <Col xs={12}>
                      <FormDropdownSearch
                        controlId={`receiver${dataKey}`}
                        controlledFormData={formData}
                        label='Search Receivers'
                        valueOnSelect='name'
                        filters={`company_id = ${companyId}`}
                        relevantHitValues={['name', 'address_1', 'address_2', 'city', 'state', 'postal', 'phone', 'email']}
                        handleHitSelection={handleSearchHitSelection(setAppendableValues)}
                        indexName={`tc_receivers_${process.env.NODE_ENV}`} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} xs={12}>
                      <FloatingLabel controlId='name' label={`Receiver ${dataKey+1} Name`}>
                        <Form.Control required type="text" placeholder="Receiver Name" value={getAppendableValue('name')} onChange={e=>setAppendableValue(e)} />
                      </FloatingLabel>
                    </Col>
                    <Col md={4} xs={12}>
                      <FloatingLabel controlId='phone' label={`Receiver ${dataKey+1} Phone Number`}>
                        <Form.Control required type="tel" placeholder="Phone" value={getAppendableValue('phone')} onChange={e=>setAppendableValue(e)} />
                      </FloatingLabel>
                    </Col>
                    <Col>
                      <FloatingLabel controlId='email' label={`Receiver ${dataKey+1} Email`}>
                        <Form.Control required type="email" placeholder="Email" value={getAppendableValue('email')} onChange={e=>setAppendableValue(e)} />
                        <Form.Control.Feedback type="invalid">Email is invalid</Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} xs={12}>
                      <FloatingLabel controlId='address_1' label="Address 1">
                        <Form.Control required type="text" placeholder="Receiver Address 1" value={getAppendableValue('address_1')} onChange={e=>setAppendableValue(e)} />
                      </FloatingLabel>
                    </Col>
                    <Col>
                      <FloatingLabel controlId='address_2' label="Address 2">
                        <Form.Control type="text" placeholder="Receiver Address 2" value={getAppendableValue('address_2')} onChange={e=>setAppendableValue(e)} />
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} xs={12}>
                      <FloatingLabel controlId='city' label="City">
                        <Form.Control required type="text" placeholder="City" value={getAppendableValue('city')} onChange={e=>setAppendableValue(e)} />
                      </FloatingLabel>
                    </Col>
                    <Col md={4} xs={12}>
                      <FloatingLabel controlId='state' label="State">
                        <Form.Select required value={getAppendableValue('state')} onChange={e=>setAppendableValue(e)} >
                          <StateOptions />
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <Col>
                      <FloatingLabel controlId='postal' label="Postal">
                        <Form.Control required type="text" placeholder="Postal" value={getAppendableValue('postal')} onChange={e=>setAppendableValue(e)} />
                      </FloatingLabel>
                    </Col>
                  </Row>
                  {
                    receiverIsPersisted && editingReceiver &&
                    <GenericForm
                      formRequest={restApi.updateReceiver}
                      params={receiver}
                      handleSuccess={handleReceiverUpdateSuccess}
                      withDirtyTracking
                      formContent={({submitForm, isDirty, isSubmitting, resetToInitialFormData}) => {
                        return (
                          <>
                            <Button disabled={!isDirty} className="indigo-button w-100 mb-2" onClick={() => submitForm()}>
                              {
                                isSubmitting ? <Spinner size="sm" animation="border" variant="light" /> : 'Update Receiver'
                              }
                            </Button>
                            <Button variant="secondary" className="w-100 mb-3" onClick={() => resetToInitialFormData(cancelReceiverEdit, [setAppendableValues])}>
                              Cancel
                            </Button>
                          </>
                        )
                      }}
                    />
                  }
                </>
              }
              {
                dataKey === receiverCount - 1 &&
                <div className='d-flex mb-3'>
                  <FontAwesomeIcon icon='minus' size="lg" onClick={() => removeAppendedFields(dataKey, setReceiverCount)} />
                  <FontAwesomeIcon icon='plus' size="lg" onClick={() => setReceiverCount(receiverCount + 1)} />
                </div>
              }
            </>
          )
        }
      }
    />
  )
}

const BOLReceivers = React.memo(props => {
  const { formData } = props
  const [receiverCount, setReceiverCount]= useState(1)

  useEffect(() => {
    if (_.isEmpty(formData)) return
    setReceiverCount(_.size(formData))
  }, [formData])

  return _.times(receiverCount, i => {
          return <BOLReceiver key={i} dataKey={i} receiverCount={receiverCount} setReceiverCount={setReceiverCount} {...props} />
        })
})

export default BOLReceivers
