import _ from 'lodash'
import React, { Fragment, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CardsList from '../generic/CardsList'
import Graphql from '../Graphql'
import { graphqlApi } from '../Api'
import InteractableTable from '../generic/InteractableTable'
import OriginColumns from './OriginRefinableColumns'
import RefinableList from '../generic/RefinableList'
import RefinableListSettingsModal from '../generic/RefinableListSettingsModal'
import Requestable from '../generic/Requestable'
import useScreenWidth from '../utils/useScreenWidth'
import { MINIMUM_SCREEN_WIDTH_FOR_TABLE_VIEW } from '../generic/interactableTableConstraints'

const originFields = ['id', 'name', 'phone', 'city', 'state', 'postal', 'address_1', 'address_2']

const companyOriginsQuery = Graphql.company({
                              fields: [
                                'id',
                                Graphql.relatedOrigins({
                                  fields: originFields
                                })
                              ]
                            })

function Origins(props) {
  const { companyId } = useParams()
  const [origins, setOrigins] = useState([])
  const preferencedColumns  = useSelector(state => _.get(state.user.currentUser, 'settings.originsColumns', []))
  const screenWidth = useScreenWidth()

  const selectableColumns = {
    name: OriginColumns.name(companyId),
    phone: OriginColumns.phone,
    city: OriginColumns.city,
    state: OriginColumns.state,
    postal: OriginColumns.postal,
    address1: OriginColumns.address1,
    address2: OriginColumns.address2
  }

  const companyOriginsLoadSuccess = (res) => {
    setOrigins(
      _.map(Graphql.getValue(res, 'company.origins'), (origin) => {
        return Graphql.transformData(origin)
      })
    )
  }

  const newOriginButton = <Link to={`/company/${companyId}/locations/origins/create`}>
    <Button className="indigo-button w-100">
      <FontAwesomeIcon className="plus me-2" icon='plus' />
      Create New Origin
    </Button>
  </Link>

  const renderTable = () => {
    return (
      <RefinableList
        Renderer={InteractableTable}
        initialSelectedColumns={_.values(_.pick(selectableColumns, preferencedColumns))}
        initialSortedColumn="Name"
        selectableColumns={_.values(selectableColumns)}
        draggableType="originColumn"
        searchable
        searchPlaceholder="Search Origins"
        searchIndex={`tc_origins_${process.env.NODE_ENV}`}
        searchFilters={`company_id = ${companyId}`}
        items={origins}
        settings={<RefinableListSettingsModal selectableColumns={selectableColumns} userPreferencedColumns={preferencedColumns} refinableListSettingsName='originsColumns' />}
        pageSize={25}
        rightOfSearchContent={<Col xs={2}>{newOriginButton}</Col>}
      />
    )
  }

  const renderCards = () => {
    return (
      <RefinableList
        Renderer={CardsList}
        cardHeaderColumn={selectableColumns.name}
        initialSelectedColumns={_.values(_.pick(selectableColumns, _.without(preferencedColumns, 'name')))}
        initialSortedColumn="Name"
        selectableColumns={_.values(selectableColumns)}
        searchable
        searchPlaceholder="Search Origins"
        searchIndex={`tc_origins_${process.env.NODE_ENV}`}
        searchFilters={`company_id = ${companyId}`}
        items={origins}
        settings={<RefinableListSettingsModal selectableColumns={selectableColumns} userPreferencedColumns={preferencedColumns} refinableListSettingsName='originsColumns' />}
        pageSize={10}
        belowSearchContent={newOriginButton}
      />
    )
  }

  return (
    <Requestable
      onMountFetch={graphqlApi.execute(companyOriginsQuery, companyOriginsLoadSuccess)}
      render={() => {
          if (preferencedColumns.length > 0) {
            return (
              <Fragment>
                <Row className="form-sub-header g-0">
                  <h5>Origins</h5>
                </Row>
                {screenWidth > MINIMUM_SCREEN_WIDTH_FOR_TABLE_VIEW ? renderTable() : renderCards()}
              </Fragment>
            )
          }
        }
      }
    />
  )
}

export default Origins
