import deepEqual from 'deep-equal'
import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { createContext, Fragment, useEffect, useMemo, useState } from 'react'
import { Button, Col, FloatingLabel, Form, InputGroup, Spinner, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CardsList from '../generic/CardsList'
import { setErrors } from '../errorHandling/errorsSlice'
import GenericForm from '../forms/GenericForm'
import Graphql from '../Graphql'
import { graphqlApi } from '../Api'
import InteractableTable from '../generic/InteractableTable'
import RefinableList from '../generic/RefinableList'
import RefinableListSettingsModal from '../generic/RefinableListSettingsModal'
import Requestable from '../generic/Requestable'
import { restApi } from '../Api'
import StateOptions from '../utils/StateOptions'
import Tooltip from '../generic/Tooltip'
import TruckSearchResultColumns from './TruckSearchRefinableColumns'
import useScreenWidth from '../utils/useScreenWidth'
import { MINIMUM_SCREEN_WIDTH_FOR_TABLE_VIEW } from '../generic/interactableTableConstraints'

const truckSearchDataQuery = bolId => {
  return Graphql.BOL({
    filters: {id: bolId},
    fields: [
      'dat_asset_query',
      Graphql.relatedOrigin({fields: ['city', 'state', 'longitude', 'latitude', 'time_zone']}),
      Graphql.relatedReceivers({fields: ['city', 'state', 'longitude', 'latitude']}),
      Graphql.relatedTruckQuery({fields: [
        'dat_asset_query', 'dat_matches', 'total_matches', 'dat_api_errors',
        Graphql.relatedQueryMatches({fields: ['accepted', 'notes', 'post_id', 'rejected']})
      ]})
    ]
  })
}

const truckSearchMatchesQuery = bolId => {
  return Graphql.BOL({
    filters: {id: bolId},
    fields: [
      Graphql.relatedOrigin({fields: ['time_zone']}),
      Graphql.relatedTruckQuery({fields: [
        'dat_asset_query', 'dat_matches', 'total_matches', 'dat_api_errors',
        Graphql.relatedQueryMatches({fields: ['accepted', 'notes', 'post_id', 'rejected']})
      ]})
    ]
  })
}

const transformAssetQuery = assetQuery => {
  const availableAfter = DateTime.fromISO(assetQuery.availability.earliestWhen, {zone: 'utc'})
  const availableBefore = DateTime.fromISO(assetQuery.availability.latestWhen, {zone: 'utc'})

  return {
    destinationCity: assetQuery.lane.destination.place.city,
    destinationDeadheadMiles: assetQuery.maxDestinationDeadheadMiles,
    destinationState: assetQuery.lane.destination.place.stateProv,
    equipmentClass: assetQuery.lane.equipment.classes[0],
    length: assetQuery.capacity.truck.availableLengthFeet,
    maxPostAge: assetQuery.maxAgeMinutes ? assetQuery.maxAgeMinutes / 60 : 1,
    originCity: assetQuery.lane.origin.place.city,
    originDeadheadMiles: assetQuery.maxOriginDeadheadMiles,
    originState: assetQuery.lane.origin.place.stateProv,
    truckAvailableAfterDate: availableAfter.toFormat('yyyy-MM-dd'),
    truckAvailableBeforeDate: availableBefore.toFormat('yyyy-MM-dd'),
    weight: assetQuery.capacity.truck.availableWeightPounds
  }
}

export const TruckSearchContext = createContext({})

function TruckSearchResults({bolId, matchesProp, equipmentTypes}) {
  const preferencedColumns  = useSelector(state => state.user.currentUser.settings.truckSearchColumns)
  const [matchesObj, setMatchesObj] = useState({})
  const [matchesArray, setMatchesArray] = useState([])
  const screenWidth = useScreenWidth()

  useEffect(() => {
    setMatchesObj(
      _.transform(matchesProp, (result, match) => {
        result[match.postingId] = match
      }, {})
    )
  }, [matchesProp])

  useEffect(() => {
    setMatchesArray(_.values(matchesObj))
  }, [matchesObj])

  const selectableColumns = useMemo(() => {
    return {
      actions: TruckSearchResultColumns.actions,
      availabilityIntersection: TruckSearchResultColumns.availabilityIntersection,
      contact: TruckSearchResultColumns.contact,
      destination: TruckSearchResultColumns.destination,
      destinationDeadheadMiles: TruckSearchResultColumns.destinationDeadheadMiles,
      equipmentType: TruckSearchResultColumns.equipmentType(equipmentTypes),
      length: TruckSearchResultColumns.length,
      origin: TruckSearchResultColumns.origin,
      originDeadheadMiles: TruckSearchResultColumns.originDeadheadMiles,
      postAge: TruckSearchResultColumns.postAge,
      posterCompany: TruckSearchResultColumns.posterCompany,
      weight: TruckSearchResultColumns.weight,
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const renderCards = () => {
    return (
      <RefinableList
        Renderer={CardsList}
        cardHeaderColumn={selectableColumns.posterCompany}
        initialSelectedColumns={_.values(_.pick(selectableColumns, _.without(preferencedColumns, 'posterCompany')))}
        initialSortedColumn="Post Age"
        selectableColumns={_.values(selectableColumns)}
        items={matchesArray}
        settings={<RefinableListSettingsModal selectableColumns={selectableColumns} userPreferencedColumns={preferencedColumns} refinableListSettingsName={'truckSearchColumns'} />}
        pageSize={10}
      />
    )
  }

  const renderTable = () => {
    return (
      <RefinableList
        Renderer={InteractableTable}
        initialSelectedColumns={_.values(_.pick(selectableColumns, preferencedColumns))}
        initialSortedColumn="Post Age"
        initialSortDirection='up'
        selectableColumns={_.values(selectableColumns)}
        draggableType="searchResultColumn"
        items={matchesArray}
        settings={<RefinableListSettingsModal selectableColumns={selectableColumns} userPreferencedColumns={preferencedColumns} refinableListSettingsName={'truckSearchColumns'} />}
        pageSize={25}
      />
    )
  }

  return (
    <div className="mt-2">
      <TruckSearchContext.Provider value={{bolId: bolId, setMatchesObj: setMatchesObj}}>
        {screenWidth > MINIMUM_SCREEN_WIDTH_FOR_TABLE_VIEW ? renderTable() : renderCards()}
      </TruckSearchContext.Provider>
    </div>
  )
}

function TruckSearch({bolId}) {
  const [assembledAssetQuery, setAssembledAssetQuery] = useState()
  const [persistedAssetQuery, setPersistedAssetQuery] = useState()
  const [formData, setFormData] = useState()
  const [matches, setMatches] = useState()
  const [totalMatchCount, setTotalMatchCount] = useState() // eslint-disable-line no-unused-vars
  const [datEquipmentClasses, setDatEquipmentClasses] = useState([])
  const [datEquipmentTypes, setDatEquipmentTypes] = useState([])
  const dispatch = useDispatch()

  const onlyRefreshResults = useMemo(() => {
    return persistedAssetQuery && deepEqual(persistedAssetQuery, formData)
  }, [persistedAssetQuery, formData])

  const handleTruckSearchFetchSuccess = res => {
    const bol = Graphql.transformData(Graphql.getValue(res, 'bol'))
    const transformedAssembledAssetQuery = transformAssetQuery(bol.datAssetQuery.criteria)
    setAssembledAssetQuery(transformedAssembledAssetQuery)

    if (bol.truckQuery) {
      const truckQuery = Graphql.transformData(bol.truckQuery)

      if (truckQuery.datApiErrors) {
        dispatch(setErrors(['The following error(s) occurred while fetching search results. Please execute a new search'].concat(truckQuery.datApiErrors)))
        setFormData(transformedAssembledAssetQuery)
      } else {
        const transformedPersistedAssetQuery = transformAssetQuery(truckQuery.datAssetQuery.criteria)
        setPersistedAssetQuery(transformedPersistedAssetQuery)
        setMatches(truckQuery.datMatches)
        setTotalMatchCount(truckQuery.totalMatches)
        setFormData(transformedPersistedAssetQuery)
      }
    } else {
      setFormData(transformedAssembledAssetQuery)
    }
  }

  const handleDATEquipmentOptionsFetchSuccess = res => {
    const optionsData = Graphql.transformData(res.data.data).datEquipmentQueryOptions
    setDatEquipmentClasses(optionsData.equipment_classes)
    setDatEquipmentTypes(optionsData.equipment_types)
  }

  const handleSearchCreatedSuccess = res => {
    setMatches(res.data.dat_matches)
    setTotalMatchCount(res.data.total_matches)
    const transformedAssetQuery = transformAssetQuery(res.data.dat_asset_query.criteria)
    setPersistedAssetQuery(transformedAssetQuery)
    setFormData(transformedAssetQuery)
  }

  const handleMatchesFetchedSuccess = res => {
    const truckQuery = Graphql.transformData(Graphql.getValue(res, 'bol.truck_query'))

    if (truckQuery.datApiErrors) {
      dispatch(setErrors(['The following error(s) occurred while fetching search results. Please execute a new search'].concat(truckQuery.datApiErrors)))
      setFormData(assembledAssetQuery)
      setPersistedAssetQuery()
    } else {
      setMatches(truckQuery.datMatches)
      setTotalMatchCount(truckQuery.totalMatches)
    }
  }

  const handleTruckAvailableAfterDateChange = newValue => {
    setFormData(prevState => {
      return {
        ...prevState,
        truckAvailableAfterDate: newValue,
        truckAvailableBeforeDate: new Date(newValue) > new Date(prevState.truckAvailableBeforeDate) ? newValue : prevState.truckAvailableBeforeDate
      }
    })
  }

  const handleTruckAvailableBeforeDateChange = newValue => {
    setFormData(prevState => {
      return {
        ...prevState,
        truckAvailableAfterDate: new Date(newValue) < new Date(prevState.truckAvailableAfterDate) ? newValue : prevState.truckAvailableAfterDate,
        truckAvailableBeforeDate: newValue
      }
    })
  }

  const renderSearchForm = () => {
    return (
      <GenericForm
        formRequest={onlyRefreshResults ? graphqlApi.execute : restApi.createTruckQuery}
        params={onlyRefreshResults ? truckSearchMatchesQuery(bolId) : {bolId: bolId, ...formData}}
        handleSuccess={onlyRefreshResults ? handleMatchesFetchedSuccess : handleSearchCreatedSuccess}
        formContent={({handleFormChange, isSubmitting}) => {
          return (
            <Row className="mt-3">
              <Col xs={12} lg={4} xl={3}>
                <InputGroup>
                  <FloatingLabel controlId="originCity" label="Origin City">
                    <Form.Control required type="text" placeholder="Origin City" value={formData.originCity} onChange={e=>handleFormChange(setFormData, e)} />
                    <Form.Control.Feedback type="invalid">Origin City is required</Form.Control.Feedback>
                  </FloatingLabel>
                  <FloatingLabel controlId="originState" label="Origin State">
                    <Form.Select required value={formData.originState} onChange={e=>handleFormChange(setFormData, e)} >
                      <StateOptions />
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">Origin State is required</Form.Control.Feedback>
                  </FloatingLabel>
                </InputGroup>
                <InputGroup>
                  <FloatingLabel controlId="destinationCity" label="Destination City">
                    <Form.Control required type="text" placeholder="Destination City" value={formData.destinationCity} onChange={e=>handleFormChange(setFormData, e)} />
                    <Form.Control.Feedback type="invalid">Destination City is required</Form.Control.Feedback>
                  </FloatingLabel>
                  <FloatingLabel controlId="destinationState" label="Destination State">
                    <Form.Select required value={formData.destinationState} onChange={e=>handleFormChange(setFormData, e)} >
                      <StateOptions />
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">Destination State is required</Form.Control.Feedback>
                  </FloatingLabel>
                </InputGroup>
              </Col>
              <Col xs={12} lg={4} xl={3}>
                <InputGroup>
                  <FloatingLabel controlId="truckAvailableAfterDate" label="Available On or After">
                    <Form.Control required type="date" value={formData.truckAvailableAfterDate} min={DateTime.now().toFormat('yyyy-MM-dd')} onChange={e=>handleTruckAvailableAfterDateChange(e.target.value)} />
                    <Form.Control.Feedback type="invalid">Cannot be a date in the past</Form.Control.Feedback>
                  </FloatingLabel>
                  <FloatingLabel controlId="truckAvailableBeforeDate" label="Available On or Before">
                    <Form.Control required type="date" value={formData.truckAvailableBeforeDate} min={DateTime.now().toFormat('yyyy-MM-dd')} onChange={e=>handleTruckAvailableBeforeDateChange(e.target.value)} />
                    <Form.Control.Feedback type="invalid">Cannot be a date in the past</Form.Control.Feedback>
                  </FloatingLabel>
                </InputGroup>
              </Col>
              <Col xs={12} lg={4} xl={2}>
                <InputGroup>
                  <FloatingLabel controlId="equipmentClass" label="Equipment Type Required">
                    <Form.Select required value={formData.equipmentClass} onChange={e=>handleFormChange(setFormData, e)} >
                      {
                        _.map(datEquipmentClasses, (value, key) => {
                          return <option key={key} value={key}>{_.startCase(_.lowerCase(value))}</option>
                        })
                      }
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">Equipment Type is required</Form.Control.Feedback>
                  </FloatingLabel>
                </InputGroup>
                <InputGroup>
                  <FloatingLabel controlId="weight" label="Weight">
                    <Form.Control required type="number" placeholder="Weight" value={formData.weight} onChange={e=>handleFormChange(setFormData, e)} />
                    <Form.Control.Feedback type="invalid">Weight is required</Form.Control.Feedback>
                  </FloatingLabel>
                  <FloatingLabel controlId="length" label="Length">
                    <Form.Control required type="number" placeholder="Length" value={formData.length} onChange={e=>handleFormChange(setFormData, e)} />
                    <Form.Control.Feedback type="invalid">Length is required</Form.Control.Feedback>
                  </FloatingLabel>
                </InputGroup>
              </Col>
              <Col xs={12} lg={4} xl={2}>
                <FloatingLabel controlId="originDeadheadMiles">
                  <Form.Control type="number" placeholder="Max Miles from Origin" value={formData.originDeadheadMiles || ''} onChange={e=>handleFormChange(setFormData, e)} />
                  <label htmlFor="originDeadheadMiles" className="pe-auto">
                    Max Miles from Origin
                    <Tooltip id="deadhead-origin-tt" text="The maximum distance a carrier is willing to travel without cargo in order to get to the pickup/origin. Generally, this represents unpaid travel mileage.">
                      <FontAwesomeIcon icon={['far', 'question-circle']} className="ms-1" />
                    </Tooltip>
                  </label>
                </FloatingLabel>
                <FloatingLabel controlId="destinationDeadheadMiles">
                  <Form.Control type="number" placeholder="Max Miles from Destination" value={formData.destinationDeadheadMiles || ''} onChange={e=>handleFormChange(setFormData, e)} />
                  <label htmlFor="destinationDeadheadMiles" className="pe-auto">
                    Max Miles from Destination
                    <Tooltip id="deadhead-destination-tt" text="The maximum distance a carrier is willing to deviate from their desired destination on their truck posting.">
                      <FontAwesomeIcon icon={['far', 'question-circle']} className="ms-1" />
                    </Tooltip>
                  </label>
                </FloatingLabel>
              </Col>
              <Col xs={12} lg={4} xl={2}>
                <FloatingLabel controlId="maxPostAge">
                  <Form.Control required type="number" placeholder="Max Post Age" value={formData.maxPostAge} onChange={e=>handleFormChange(setFormData, e)} />
                  <label htmlFor="maxPostAge" className="pe-auto">
                    Max Post Age
                    <Tooltip id="post-age-tt" text="Posts older than this (in hours) will not be returned">
                      <FontAwesomeIcon icon={['far', 'question-circle']} className="ms-1" />
                    </Tooltip>
                  </label>
                </FloatingLabel>
              </Col>
              <Col xs={12}>
                <Button className="indigo-button w-100" type="submit">
                  {
                    isSubmitting ? <Spinner size="sm" animation="border" variant="light" /> : 'Search'
                  }
                </Button>
              </Col>
            </Row>
          )
        }}
      />
    )
  }

  return (
    <Requestable
      onMountMultiFetch={[
        graphqlApi.execute(truckSearchDataQuery(bolId), handleTruckSearchFetchSuccess),
        graphqlApi.execute(Graphql.datEquipmentQueryOptions, handleDATEquipmentOptionsFetchSuccess)
      ]}
      withErrorHandling
      render={() => {
        if (assembledAssetQuery) {
          return (
            <>
              {renderSearchForm()}
              {matches && <TruckSearchResults bolId={bolId} matchesProp={matches} equipmentTypes={datEquipmentTypes} />}
            </>
          )
        }
      }}
    />
  )
}

export default TruckSearch
