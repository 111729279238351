const NavLinks = {
  shipments: shipmentLinksDataMap(),
  locations: locationLinksDataMap(),
  carriers: carrierLinksDataMap(),
}

function shipmentLinksDataMap() {
  return (companyId) => {
    return (
      [
        {text: 'All BOLs', path: `/company/${companyId}/shipments/bols`},
        {text: 'Create BOL', path: `/company/${companyId}/shipments/create-bol`},
        {text: 'Post & View Loads', path: `/company/${companyId}/shipments/loads`},
        {text: 'Lookup Rates', path: `/company/${companyId}/shipments/lookup_rates`},
      ]
    )
  }
}

function locationLinksDataMap() {
  return (companyId) => {
    return (
      [
        {text: 'View Origins', path: `/company/${companyId}/locations/origins/`},
        {text: 'View Receivers', path: `/company/${companyId}/locations/receivers/`},
      ]
    )
  }
}

function carrierLinksDataMap() {
  return (companyId) => {
    return (
      [
        {text: 'View Carriers', path: `/company/${companyId}/carriers/`},
        {text: 'Onboard New Carrier', path: `/company/${companyId}/carriers/onboard`},
      ]
    )
  }
}

export default NavLinks


