import React from 'react'
import { OverlayTrigger, Tooltip as ReactBootstrapTooltip } from 'react-bootstrap'

function Tooltip({children, id, placement, text, trigger, show}) {
  if (show === undefined || show) {
    return (
      <OverlayTrigger
        placement={placement || 'auto'}
        trigger={trigger || ['hover', 'focus']}
        overlay={
          <ReactBootstrapTooltip id={id}>
            {text}
          </ReactBootstrapTooltip>
        }
      >
        {children}
      </OverlayTrigger>
    )
  } else {
    return children
  }
}

export default Tooltip
