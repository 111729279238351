import React, { useState } from 'react'
import { Button, ButtonGroup, Col } from 'react-bootstrap'
import {ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip} from 'recharts'

import Graphql from '../Graphql'
import { graphqlApi } from '../Api'
import Requestable from '../generic/Requestable'

function DeliveryCostSummaryGraph({companyId}) {
  const [data, setData] = useState(null)
  const [refinedData, setRefinedData] = useState(null)

  const handleFetch = (res) => {
    const transformedData = Graphql.transformData(Graphql.getValue(res, 'company')).monthlyDeliveryCostSummary
    setData(transformedData)
    setRefinedData(transformedData.slice(-6))
  }

  return (
    <Requestable
      onMountFetch={graphqlApi.execute(Graphql.company({fields: ['monthly_delivery_cost_summary']}), handleFetch)}
      render={() => {
        return (
          <Col xl={6} xs={12}>
            <div className="mb-3">
              <h5 className="fw-bold d-inline-block">Monthly Shipping Cost Summary</h5>
              <ButtonGroup className="d-inline-block float-end mb-1">
                <Button className="outline-indigo" onClick={_ => setRefinedData(data.slice(-3))}>3 Months</Button>
                <Button className="outline-indigo" onClick={_ => setRefinedData(data.slice(-6))}>6 Months</Button>
                <Button className="outline-indigo" onClick={_ => setRefinedData(data.slice(-12))}>12 Months</Button>
              </ButtonGroup>
            </div>
            <ResponsiveContainer width="100%" height={400} minWidth="0">
              <AreaChart data={refinedData}>
                <defs>
                  <linearGradient id="costColor" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="20%" stopColor="#4545a1" stopOpacity={0.8}/>
                    <stop offset="99%" stopColor="#4545a1" stopOpacity={0.3}/>
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <YAxis label={{ value: '$ Spent', angle: -90, position: 'insideLeft' }} />
                <XAxis dataKey="month" label={{ value: 'Month', position: 'insideBottom', offset: -3 }} />
                <Tooltip />
                <Area name="$ Spent" type="monotone" dataKey="cost" stroke="#4545a1" fillOpacity={1} fill="url(#costColor)" />
              </AreaChart>
            </ResponsiveContainer>
          </Col>
        )
      }}
    />
  )
}

export default DeliveryCostSummaryGraph
