import React, { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import BOL from './BOL'
import { appendNotification } from '../notifications/notificationsSlice'
import { restApi } from '../Api'
import Graphql from '../Graphql'

function NewBOL(props) {
  const { companyId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const fetchCompanyNextBOLNumber = Graphql.company({
                                      fields: [
                                        'next_bol_number'
                                      ]
                                    })

  const setInitialBOLValues = ({setBOLNumber}, res) => {
    setBOLNumber(Graphql.getValue(res, 'company.next_bol_number'))
  }

  const handleBOLCreationSuccess = (_boundMethodsObj, res) => {
    dispatch(appendNotification({type: 'BOLCreated', companyId: companyId, number: res.data.number}))
    navigate(`/company/${companyId}/shipments/edit-bol/${res.data.number}`)
  }

  return (
    <Fragment>
      <BOL onMountFetchQuery={fetchCompanyNextBOLNumber}
           onMountFetchCallback={setInitialBOLValues}
           formRequest={restApi.createBOL}
           handleSuccess={handleBOLCreationSuccess}
           companyId={companyId}
      />
    </Fragment>
  )
}

export default NewBOL
