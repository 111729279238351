import React from "react"
import { useSelector } from "react-redux"

import CurrentCompany from "../company/CurrentCompany"
import CurrentUser from "../users/CurrentUser"

function AppContextProvider({children}) {
    const currentUser = useSelector(state => state.user.currentUser)
    const currentCompany = useSelector(state => state.company.currentCompany)

    return (
        <>
            <CurrentCompany />
            <CurrentUser />
            {
                currentCompany &&
                currentUser &&
                children
            }
        </>
    )
}

export default AppContextProvider
