import Cookies from 'js-cookie'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import Requestable from '../generic/Requestable'
import { appendNotification } from '../notifications/notificationsSlice'
import { setCurrentUser } from './userSlice'
import { graphqlApi } from '../Api'
import Graphql from '../Graphql'
import PusherClient, { userEsignablesChannel } from '../pusher/Pusher'

const userFields = ['id', 'first_name', 'last_name', 'full_name', 'email', 'last_used_company_id']
const userSettingsFields = [
  'all_bols_columns',
  'bol_invoices_columns',
  'carriers_columns',
  'carrier_bols_columns',
  'in_progress_bols_columns',
  'invoices_columns',
  'load_postings_columns',
  'origins_columns',
  'origin_bols_columns',
  'receivers_columns',
  'receiver_bols_columns',
  'truck_search_columns',
  'users_columns'
]

const currentUserQuery = Graphql.currentUser({
                           fields: [
                             ...userFields,
                             Graphql.relatedSettings({
                               fields: userSettingsFields
                             })
                           ]
                         })

function CurrentUser() {
  const dispatch = useDispatch()
  const csrfTokenCookie = Cookies.get('csrftoken')

  useEffect(() => {
    PusherClient.initialize(csrfTokenCookie)
  }, [csrfTokenCookie])

  const handleCurrentUserResponse = (res) => {
    const userData = Graphql.getValue(res, 'current_user')
    userData.settings = Graphql.transformData(userData.settings)

    dispatch(setCurrentUser(userData))

    // Subscribe user to notifications
    let pusherChannel = PusherClient.subscribe(userEsignablesChannel(userData.id))
    pusherChannel.bind('esign-completed', ({esignable_kind, ...relevantData}) => dispatch(appendNotification({type: `${esignable_kind}Completed`, ...relevantData})))
  }

  return (
    <Requestable
      withoutLoading
      requestOnly
      onMountFetch={graphqlApi.execute(currentUserQuery, handleCurrentUserResponse)}
    />
  )
}

export default CurrentUser
