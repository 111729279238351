import _ from 'lodash'

import React, { Fragment, useEffect, useState } from 'react'
import { Col, CloseButton } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import PopupNotificationsMap from './PopupNotificationsMap'
import { removeNotification } from './notificationsSlice'

function PopupNotification(props) {
  const dispatch = useDispatch()
  const [hidden, setHidden] = useState(true)

  useEffect(() => {
    setHidden(false)
    expireNotification()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const expireNotification = () => {
    if (props.expireIn === 'never') {
      return
    }

    setTimeout(() => {
      dismissNotification()
    }, props.expireIn || PopupNotificationsMap[props.type].defaultExpiration)
  }

  const dismissNotification = () => {
    setHidden(true)
    setTimeout(() => {
      dispatch(removeNotification(props.notificationId))
    }, 800)
  }

  const renderNotification = () => {
    const Notification = PopupNotificationsMap[props.type].component

    return (
      <Fragment>
        <Col xs={2}>
        </Col>
        <Col style={{marginTop: '10px'}} xs={9}>
          <Notification handleDismissal={dismissNotification} {...props} />
        </Col>
        <Col xs={1}>
          <CloseButton className="mt-1 shadow-none" onClick={() => dismissNotification()} />
        </Col>
      </Fragment>
    )
  }

  return (
    <div className="d-flex justify-content-center">
      <Col style={{top: `${props.spacing}rem`}} className={`popup-notification ${hidden ? 'hidden' : ''}`} xl={2} md={8} xs={10}>
        <div className="d-flex">
          {renderNotification()}
        </div>
      </Col>
    </div>
  )
}

function PopupNotificationsList(props) {
  const notifications = useSelector(state => state.notifications.notifications)

  let i = 0
  let spacing = 0

  if (_.isEmpty(notifications)) {
    return null
  } else {
    return _.map(notifications, ({type, ...props}, notificationId) => {
      spacing += i > 0 ? PopupNotificationsMap[type].spacing : 0
      i++
      return <PopupNotification key={notificationId} notificationId={notificationId} type={type} spacing={spacing} {...props} />
    })
  }
}

export default PopupNotificationsList
