import moment from 'moment'

const RefinableColumns = {
  name: {
    name: 'Name',
    sortable: true,
    accessor: tableItem => `${tableItem.firstName} ${tableItem.lastName}`,
  },
  email: {
    name: 'Email',
    accessor: tableItem => tableItem.email,
  },
  lastLogin: {
    name: 'Last Login',
    accessor: tableItem => tableItem.lastLogin,
    converter: dateStr => Date.parse(dateStr),
    displayer: tableItem => tableItem.lastLogin ? moment(tableItem.lastLogin).format('M/D/YYYY @ hh:mm:ss a') : 'Never',
    sortable: true
  },
  dateJoined: {
    name: 'Created Date',
    accessor: tableItem => tableItem.dateJoined,
    converter: dateStr => Date.parse(dateStr),
    displayer: tableItem => moment(tableItem.dateJoined).format('M/D/YYYY'),
    sortable: true
  }
}

export default RefinableColumns
