import _ from "lodash"
import React, { useState } from "react"
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap"

import CardsList from "../generic/CardsList"
import { decimalCurrencyFormatter } from "../utils/CurrencyFormatter"
import Graphql from "../Graphql"
import { graphqlApi } from "../Api"
import InteractableTable from "../generic/InteractableTable"
import { MINIMUM_SCREEN_WIDTH_FOR_TABLE_VIEW } from "../generic/interactableTableConstraints"
import RefinableList from "../generic/RefinableList"
import Requestable from "../generic/Requestable"
import useScreenWidth from "../utils/useScreenWidth"

const transactionColumns = {
  amount: {
    name: "Amount",
    accessor: transaction => decimalCurrencyFormatter.format(transaction.amount.value)
  },
  createdDate: {
    name: "Initiated Date",
    sortable: true,
    accessor: transaction => transaction.metadata.company_processed_datetime,
    displayer: transaction => transaction.metadata.company_processed_date
  },
  recipient: {
    name: "Recipient",
    accessor: transaction => transaction.metadata.carrier_name,
    filterable: true
  },
  status: {
    name: "Status",
    accessor: transaction => transaction.status,
    filterable: true,
    staticFilters: ['cancelled', 'failed', 'pending', 'processed'],
    displayer: transaction => _.capitalize(transaction.status)
  }
}

const companyTransactionsQuery = Graphql.company({
  fields: [
    Graphql.relatedDwollaCustomer({
      fields: [
        'outbound_transfers'
      ]
    })
  ]
})

function TransactionsReport() {
  const [transactions, setTransactions] = useState([])
  const screenWidth = useScreenWidth()

  const selectableColumns = {
    recipient: transactionColumns.recipient,
    amount: transactionColumns.amount,
    status: transactionColumns.status,
    createdDate: transactionColumns.createdDate,
  }

  const companyTransactionsFetchSuccess = ({data}) => {
    if (!data.data.company.dwolla_customer) return
    const transactions = JSON.parse(data.data.company.dwolla_customer.outbound_transfers)
    setTransactions(transactions['transfers'])
  }

  const renderTable = () => {
    return (
      <RefinableList
        Renderer={InteractableTable}
        initialSelectedColumns={_.values(selectableColumns)}
        initialSortedColumn="Initiated Date"
        initialSortDirection="up"
        selectableColumns={_.values(selectableColumns)}
        draggableType="transactionColumn"
        items={transactions}
        pageSize={25}
      />
    )
  }

  const renderCards = () => {
    return (
      <RefinableList
        Renderer={CardsList}
      />
    )
  }

  return <Requestable
    onMountFetch={graphqlApi.execute(companyTransactionsQuery, companyTransactionsFetchSuccess)}
    render={() => {
      return (
        <>
          <h5>Transactions Report</h5>
          <Row>
            <Col lg={3} xs={12}>
              <FloatingLabel controlId="startDate" label="Occurring on or after date" className="mb-3">
                <Form.Control type="date" />
              </FloatingLabel>
            </Col>
            <Col lg={3} xs={12}>
              <FloatingLabel controlId="endDate" label="Occurring on or before date" className="mb-3">
                <Form.Control type="date" />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={12}>
              <Button className="indigo-button w-100 mb-2">Apply</Button>
            </Col>
          </Row>
          {screenWidth > MINIMUM_SCREEN_WIDTH_FOR_TABLE_VIEW ? renderTable() : renderCards()}
        </>
      )
    }}
  />
}

export default TransactionsReport
