import _ from 'lodash'
import React, { Fragment } from 'react'
import { Accordion, Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { decimalCurrencyFormatter } from '../utils/CurrencyFormatter'
import Tooltip from '../generic/Tooltip'

const dueToExpireStatus = 'Due-To-Expire'

const CarrierCoverage = React.memo(({type, status, underwriterRating, effectiveDate, expirationDate, limits}) => {
  const renderCoverageStatusIcon = () => {
    return status === 'Valid' ? <FontAwesomeIcon className="success-green ms-auto" icon='check-circle' size='lg' />
      : status === dueToExpireStatus ? <FontAwesomeIcon className="warning-yellow ms-auto" icon='clock' size='lg' />
      : <FontAwesomeIcon className="error-red ms-auto" icon='exclamation-circle' size='lg' />
  }

  return (
    <Row className="mb-3">
      <Col>
        <Accordion className="carrier-coverage">
          <Accordion.Item eventKey={type}>
            <Accordion.Header>
              <div className="px-3 d-flex w-100">
                <span className="fw-bold">{type}</span>
                {renderCoverageStatusIcon()}
              </div>
            </Accordion.Header>
            <Accordion.Body className="p-0">
              <div className="py-2 px-3 border-bottom">
                <span className="fw-bold">Coverage Status:</span><span className="float-end">{status}</span>
                <Tooltip id="coverage-status-tt" text="The status for this coverage in the carrier monitoring database">
                  <FontAwesomeIcon className="ms-1" icon={['far', 'question-circle']} />
                </Tooltip>
              </div>
              <div className="py-2 px-3 border-bottom"><span className="fw-bold">Underwriter Rating:</span><span className="float-end">{underwriterRating}</span></div>
              <div className="py-2 px-3 border-bottom"><span className="fw-bold">Effective Date:</span><span className="float-end">{effectiveDate || 'None'}</span></div>
              <div className="py-2 px-3 border-bottom"><span className="fw-bold">Expiration Date:</span><span className="float-end">{expirationDate || 'None'}</span></div>
              <div className="py-2 px-3"><span className="fw-bold">Limit Details:</span></div>
              {
                _.map(limits, ({description, amount}) => {
                  return (
                    <Fragment key={description}>
                      <div className="border-top py-2"><span className="fw-bold ps-4">Description:</span><span className="float-end pe-3">{description || 'None'}</span></div>
                      <div className="border-top py-2"><span className="fw-bold ps-4">Amount:</span><span className="float-end pe-3">{decimalCurrencyFormatter.format(amount) || 'None'}</span></div>
                    </Fragment>
                  )
                })
              }
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Row>
  )
})

export default CarrierCoverage
