import { createSlice } from '@reduxjs/toolkit'

let initialState = {
  currentCompany: null
}

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCurrentCompany(state, action) {
      state.currentCompany = action.payload
    }
  },
  extraReducers: {

  }
})

export const {
  setCurrentCompany
} = companySlice.actions

export default companySlice.reducer
