import _ from 'lodash'
import React, { Fragment, useEffect, useState } from 'react'
import { Button, Card, Col, ListGroup, Row, Table } from 'react-bootstrap'

import Graphql from '../Graphql'
import Paginator from '../generic/Paginator'
import Requestable from '../generic/Requestable'
import usePrevious from '../utils/usePrevious'
import { graphqlApi } from '../Api'

const bolHistoryFields = ['id', 'version', 'bill_of_lading', 'rate_confirmation', 'proof_of_delivery']
const pageSize = 10

const BOLHistory = React.memo(({bolId, latestVersion}) => {
  const [windowWidth, setWindowWidth] = useState(() => window.innerWidth)
  window.addEventListener('resize', _ => setWindowWidth(window.innerWidth))

  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [previousVersions, setPreviousVersions] = useState([])
  const [totalVersions, setTotalVersions] = useState(0)
  const previousLatestVersion = usePrevious(latestVersion)

  const previousBOLVersionsQuery = (pageNumber) => {
    return Graphql.BOLPreviousVersions({
             filters: {bol_id: bolId, page: pageNumber, page_size: pageSize},
             fields: [
               ...bolHistoryFields,
               Graphql.relatedCreator({fields: ['first_name', 'last_name']})
             ]
           })
  }

  const previousBOLVersionQuery = Graphql.BOLPreviousVersion({
                                    filters: {bol_id: bolId, version: latestVersion - 1},
                                    fields: [
                                      ...bolHistoryFields,
                                      Graphql.relatedCreator({fields: ['first_name', 'last_name']})
                                    ]
                                  })

  const populatePreviousVersions = (res) => {
    setPreviousVersions(
      _.map(Graphql.getValue(res, 'bol_histories'), (previousVersion) => {
        return Graphql.transformData(previousVersion)
      })
    )
  }

  const fetchPreviousBOLVersions = (performRequest) => {
    return (pageNumber) => {
      performRequest(graphqlApi.execute(previousBOLVersionsQuery(pageNumber), populatePreviousVersions))
      setCurrentPageNumber(pageNumber)
    }
  }

  const appendPreviousVersion = (res) => {
    setPreviousVersions(
      prevVersions => {
        if (prevVersions.length === pageSize) {
          return _.concat([Graphql.transformData(Graphql.getValue(res, 'previous_bol_version'))], _.dropRight(prevVersions))
        } else {
          return _.concat([Graphql.transformData(Graphql.getValue(res, 'previous_bol_version'))], prevVersions)
        }
      }
    )
  }

  const fetchLatestVersion = (performRequest) => {
    if (currentPageNumber === 1 && previousLatestVersion && previousLatestVersion !== latestVersion) {
      performRequest(graphqlApi.execute(previousBOLVersionQuery, appendPreviousVersion))
    }
  }

  useEffect(() => {
    setTotalVersions(latestVersion - 1)
  }, [latestVersion])

  const renderViewDocumentButton = (bolHistoryId, documentType, document) => {
    if (!_.isEmpty(document)) {
      return (
        <a target="_blank" rel="noopener noreferrer" href={`/company/1/documents/previous_${documentType}/${bolId}_${bolHistoryId}`}>
          <Button size="sm" className="outline-indigo">View</Button>
        </a>
      )
    } else {
      return 'Not present'
    }
  }

  const renderForLargeDevice = () => {
    return (
      <Table id="bol-history-table" striped bordered hover>
        <thead className="thead-gray">
          <tr>
            <th>Version</th>
            <th>User</th>
            <th>Bill of Lading</th>
            <th>Rate Confirmation</th>
            <th>Proof of Delivery</th>
          </tr>
        </thead>
        <tbody>
          {
            _.map(previousVersions, ({id, version, createdBy, billOfLading, rateConfirmation, proofOfDelivery}) => {
              return (
                <tr key={version}>
                  <td>{version}</td>
                  <td>{`${createdBy['first_name']} ${createdBy['last_name']}`}</td>
                  <td>{renderViewDocumentButton(id, 'bill_of_lading', billOfLading)}</td>
                  <td>{renderViewDocumentButton(id, 'rate_confirmation', rateConfirmation)}</td>
                  <td>{renderViewDocumentButton(id, 'proof_of_delivery', proofOfDelivery)}</td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
    )
  }

  const renderForSmallDevice = () => {
    return _.map(previousVersions, ({id, version, createdBy, billOfLading, rateConfirmation, proofOfDelivery}) => {
              return (
                <div key={version}>
                  <Row className="bol-history-card-container">
                    <Col xs={12}>
                      <Card>
                        <Card.Header>Version: {version}</Card.Header>
                        <ListGroup variant="flush">
                          <ListGroup.Item>Created By: <span className="float-end">{`${createdBy['first_name']} ${createdBy['last_name']}`}</span></ListGroup.Item>
                          <ListGroup.Item>Bill of Lading: <span className="float-end">{renderViewDocumentButton(id, 'bill_of_lading', billOfLading)}</span></ListGroup.Item>
                          <ListGroup.Item>Rate Confirmation: <span className="float-end">{renderViewDocumentButton(id, 'rate_confirmation', rateConfirmation)}</span></ListGroup.Item>
                          <ListGroup.Item>Proof of Delivery: <span className="float-end">{renderViewDocumentButton(id, 'proof_of_delivery', proofOfDelivery)}</span></ListGroup.Item>
                        </ListGroup>
                      </Card>
                    </Col>
                  </Row>
                </div>
              )
            })
  }

  /*
    Responsive tables just don't work with a sidenav,
    render table layout if working on a device > 1037px
  */
  return (
    <Requestable
      onMountFetch={graphqlApi.execute(previousBOLVersionsQuery(currentPageNumber), populatePreviousVersions)}
      withoutLoading
      render={({performRequest, isLoading}) => {
          if (!isLoading) fetchLatestVersion(performRequest)

          return (
            <Fragment>
              <div className="d-flex justify-content-center">
                <Paginator totalItems={totalVersions} perPage={pageSize} currentPage={currentPageNumber} setCurrentPage={fetchPreviousBOLVersions(performRequest)} />
              </div>
              {windowWidth > 1037 ? renderForLargeDevice() : renderForSmallDevice()}
              <div className="d-flex justify-content-center">
                <Paginator totalItems={totalVersions} perPage={pageSize} currentPage={currentPageNumber} setCurrentPage={fetchPreviousBOLVersions(performRequest)} />
              </div>
            </Fragment>
          )
        }
      }
    />
  )
})

export default BOLHistory
