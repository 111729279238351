import { v4 as uuidv4 } from 'uuid'

import { createSlice } from '@reduxjs/toolkit'

let initialState = {
  notifications: {}
}

// https://redux-toolkit.js.org/api/createAsyncThunk

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    appendNotification(state, action) {
      state.notifications[uuidv4()] = action.payload
    },
    removeNotification(state, action) {
      delete state.notifications[action.payload]
    }
  },
  extraReducers: {

  }
})

export const {
  appendNotification,
  removeNotification
} = notificationsSlice.actions

export default notificationsSlice.reducer
