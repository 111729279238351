import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function SuccessfulAction({text}) {
  return (
    <div className="pt-3">
      <div className="d-flex justify-content-center">
        <FontAwesomeIcon className="success-green success-check-circle" icon='check-circle' size='2x' />
      </div>
      <div className="d-flex justify-content-center text-center fw-bold mt-1">
        {text}
      </div>
    </div>
  )
}

export default SuccessfulAction
