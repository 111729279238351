import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import { resetConfirmableAction } from './confirmableActionSlice'

function ConfirmActionModal() {
  const dispatch = useDispatch()
  const [confirmed, setConfirmed] = useState(false)
  const performConfirmableAction = useSelector(state => state.confirmableAction.performAction)
  const confirmableAction = useSelector(state => state.confirmableAction.action)
  const confirmableActionDetails = useSelector(state => state.confirmableAction.actionDetails)

  useEffect(() => {
    if (confirmed) {
      performConfirmableAction(confirmableAction)
      dispatch(resetConfirmableAction())
      setConfirmed(false) // Reset state for next confirmable action
    }
  }, [confirmed]) // eslint-disable-line react-hooks/exhaustive-deps

  if (confirmableAction) {
    return (
      <Modal show backdrop="static" onHide={_ => dispatch(resetConfirmableAction())}>
        <Modal.Header closeButton>
          <Modal.Title>{confirmableActionDetails.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {confirmableActionDetails.confirmationBody}
        </Modal.Body>
        <Modal.Footer>
          <Button className="outline-indigo" onClick={_ => dispatch(resetConfirmableAction())}>
            Cancel
          </Button>
          <Button className="indigo-button" onClick={_ => setConfirmed(true)}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    )
  } else {
    return null
  }
}

export default ConfirmActionModal
