import { createSlice } from '@reduxjs/toolkit'

// performAction & action are stored as stringified functions
let initialState = {
  performAction: null,
  action: null,
  actionDetails: {},
}

const confirmableActionSlice = createSlice({
  name: 'confirmableAction',
  initialState,
  reducers: {
    setConfirmableAction(state, action) {
      state.performAction = action.payload.performAction
      state.action = action.payload.action
      state.actionDetails = action.payload.details
    },
    resetConfirmableAction(state, _action) {
      state.performAction = null
      state.action = null
      state.actionDetails = {}
      state.confirmed = false
    }
  },
  extraReducers: {

  }
})

export const {
  setConfirmableAction,
  resetConfirmableAction
} = confirmableActionSlice.actions

export default confirmableActionSlice.reducer
