import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useState } from 'react'
import { Form, Nav, OverlayTrigger, Popover } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import { decimalCurrencyFormatter } from '../utils/CurrencyFormatter'
import PayInvoiceModal from '../invoices/PayInvoiceModal'
import Tooltip from '../generic/Tooltip'

function PostableCheckbox({tableItem, selectedPostablesMap, setSelectedPostablesCount, postablesLoadBoardsMap, setShowEditor}) {
  const [counter, setCounter] = useState(1) // Only used to force re-render
  const tableItemId = tableItem.id || tableItem.objectID
  const loadBoardsPostedTo = _.get(postablesLoadBoardsMap.current[tableItemId], 'load_boards', [])

  const handleCheckboxChange = (isChecked) => {
    isChecked ? selectedPostablesMap.current[tableItemId] = true : _.unset(selectedPostablesMap.current, tableItemId)
    setCounter(counter + 1)
    setSelectedPostablesCount(_.size(selectedPostablesMap.current))
  }

  const handleLabelClick = () => {
    selectedPostablesMap.current = {[tableItemId]: true}
    setShowEditor(uuidv4())
    setSelectedPostablesCount(1)
  }

  const renderCheckboxLabel = () => {
    const labelContent = <Nav.Link className="p-0" onClick={_ => handleLabelClick()}>{_.size(loadBoardsPostedTo)}</Nav.Link>
    if (loadBoardsPostedTo.length) {
      const overlayContent = <Popover id={`load-posting-${tableItemId}`}>
                               <Popover.Header>Posting Details</Popover.Header>
                               <Popover.Body>
                                {
                                  _.map(_.sortBy(loadBoardsPostedTo), loadBoardName => {
                                    let postingDetails = postablesLoadBoardsMap.current[tableItemId]
                                    let offerRate = postingDetails.offer_rate || postingDetails[`${_.toLower(loadBoardName)}_offer_rate`]
                                    return <div key={`${tableItemId}-${loadBoardName}`}><b>{loadBoardName} -</b> ${offerRate}</div>
                                  })
                                }
                               </Popover.Body>
                             </Popover>
      return (
        <OverlayTrigger overlay={overlayContent} placement="auto">
          {labelContent}
        </OverlayTrigger>
      )
    } else {
      return labelContent
    }
  }

  return <Form.Check type="checkbox" className="table-check ms-0" checked={selectedPostablesMap.current[tableItemId] || false} label={renderCheckboxLabel()} onChange={e => handleCheckboxChange(e.target.checked)} />
}

const RefinableColumns = {
  number: (companyId) => {
    return (
      {
        name: 'BOL #',
        accessor: tableItem => tableItem.number,
        sortable: true,
        converter: txt => parseInt(txt),
        displayer: tableItem => <Link to={`/company/${companyId}/shipments/edit-bol/${tableItem.number}`}>{tableItem.number}</Link>,
        asCardHeader: ({children, tableItem}) => <Link to={`/company/${companyId}/shipments/edit-bol/${tableItem.number}`} className="card-header">{children}</Link>,
        flexFactor: 0.10,
        noWrapHeader: true
      }
    )
  },
  carrier: (companyId) => {
    return (
      {
        name: 'Carrier',
        accessor: tableItem => tableItem.carrier_name || _.get(tableItem, 'carrier.name', null),
        sortable: true,
        filterable: true,
        displayer: tableItem => {
          const carrierId = tableItem.carrier_id || _.get(tableItem, 'carrier.id', null)
          return carrierId ? <Link to={`/company/${companyId}/carriers/view/${carrierId}`}>{tableItem.carrier_name || tableItem.carrier.name}</Link> : <span>&nbsp;</span>
        }
      }
    )
  },
  finalReceiver: (companyId) => {
    return (
      {
        name: 'Final Receiver',
        accessor: tableItem => tableItem.last_receiver_name || _.get(_.last(tableItem.receivers), 'name', null),
        sortable: true,
        filterable: true,
        displayer: tableItem => {
          const lastReceiver = _.last(tableItem.receivers)
          const receiverId = tableItem.last_receiver_id || _.get(lastReceiver, 'id', null)
          return receiverId ? <Link to={`/company/${companyId}/locations/receivers/edit/${receiverId}`}>{tableItem.last_receiver_name || lastReceiver.name}</Link> : <span>&nbsp;</span>
        }
      }
    )
  },
  receiverStopOrder: (receiverId) => {
    return (
      {
        name: 'Stop #',
        accessor: tableItem => {
          if (tableItem.receiver_ids) {
            return _.indexOf(tableItem.receiver_ids, receiverId) + 1
          } else {
            return _.findIndex(tableItem.receivers, item => parseInt(item.id) === receiverId) + 1
          }
        }
      }
    )
  },
  totalStops: {
    name: 'Total Stops',
    accessor: tableItem => {
      return tableItem.receiver_ids ? tableItem.receiver_ids.length : tableItem.receivers.length
    },
    flexFactor: 0.20,
    noWrapHeader: true
  },
  status: {
    name: 'Status',
    accessor: tableItem => tableItem.status,
    sortable: true,
    filterable: true,
    filterer: txt =>  _.capitalize(_.startCase(txt)),
    flexFactor: 0.20,
  },
  origin: {
    name: 'Location of Origin',
    accessor: tableItem => tableItem.origin_name,
    sortable: true,
    filterable: true,
    displayer: tableItem => <Link to={`/company/${tableItem.company_id}/locations/origins/edit/${tableItem.origin_id}`}>{tableItem.origin_name}</Link>
  },
  pickUpDate: {
    name: 'Pick Up Date',
    accessor: tableItem => tableItem.pick_up_date,
    sortable: true,
    converter: dateStr => Date.parse(dateStr),
    filterer: date => moment(date).format('M/D/YYYY, ddd')
  },
  pickUpTime: {
    name: 'Pick Up Time',
    accessor: tableItem => tableItem.pick_up_time,
    sortable: true,
    converter: data => data ? moment(data, 'HH:mm:ss') : 'Upon Arrival',
    filterer: data => data ? moment(data, 'HH:mm:ss').format('LT') : 'Upon Arrival'
  },
  deliveryDate: {
    name: 'Scheduled Delivery Date',
    accessor: tableItem => tableItem.delivery_date,
    sortable: true,
    converter: dateStr => Date.parse(dateStr),
    filterer: date => moment(date).format('M/D/YYYY, ddd')
  },
  deliveryTime: {
    name: 'Delivery Time',
    accessor: tableItem => tableItem.delivery_time,
    sortable: true,
    converter: data => data ? moment(data, 'HH:mm:ss') : 'Upon Arrival',
    filterer: data => data ? moment(data, 'HH:mm:ss').format('LT') : 'Upon Arrival'
  },
  deliveredOn: {
    name: 'Delivered Date',
    accessor: tableItem => tableItem.delivered_on,
    sortable: true,
    converter: data => data ? Date.parse(data) : null,
    filterer: data => data ? moment(data).format('M/D/YYYY, ddd') : null
  },
  truckNumber: {
    name: 'Truck Number',
    accessor: tableItem => tableItem.truck_number,
  },
  invoiceNumber: (companyId, editInvoice) => {
    return {
      name: 'Invoice #',
      accessor: ({invoice}) => invoice.number,
      sortable: true,
      asCardHeader: ({children, _}) => <div className="card-header">{children}</div>,
      displayer: ({invoice}) => {
        return (
          <>
            <a target="_blank" rel="noopener noreferrer" href={`/company/${companyId}/documents/invoice/${invoice.id}`}>
              {invoice.number}
            </a>
            <FontAwesomeIcon icon={['far', 'edit']} className="cursor-pointer ms-2" onClick={_ => editInvoice(invoice.id)} />
          </>
        )
      }
    }
  },
  invoiceDueDate: {
    name: 'Due Date',
    accessor: ({invoice}) => invoice.due_date,
    sortable: true,
    converter: data => Date.parse(data),
    filterer: data => moment(data).format('M/D/YYYY')
  },
  totalCost: {
    name: 'BOL Total',
    accessor: tableItem => tableItem.total_cost,
    displayer: tableItem => decimalCurrencyFormatter.format(tableItem.total_cost),
    flexFactor: 0.60
  },
  invoiceAmount: {
    name: 'Invoice Total',
    accessor: ({invoice}) => invoice.total,
    displayer: ({invoice}) => decimalCurrencyFormatter.format(invoice.total),
    flexFactor: 0.70
  },
  numLoadBoards: (selectedPostablesMap, setSelectedPostablesCount, selectAllPostablesCheckbox, postablesLoadBoardsMap, setShowEditor) => {
    const accessor = tableItem => tableItem.load_boards_posted_to || _.get(tableItem.load, 'load_boards')

    return (
      {
        name: 'Load Boards',
        actionable: selectAllPostablesCheckbox,
        listensToRerender: true,
        filterable: true,
        complexFilterable: true,
        staticFilters: ['Not Posted', 'DAT'],
        accessor: tableItem => accessor(tableItem),
        filterer: (tableItem, selectedValues) => (_.includes(selectedValues, 'Not Posted') && _.size(accessor(tableItem)) === 0) || _.intersection(accessor(tableItem), selectedValues).length > 0,
        displayer: tableItem => <PostableCheckbox key={tableItem.number} selectedPostablesMap={selectedPostablesMap} setSelectedPostablesCount={setSelectedPostablesCount} tableItem={tableItem} postablesLoadBoardsMap={postablesLoadBoardsMap} setShowEditor={setShowEditor} />
      }
    )
  },
  loadPostedAt: {
    name: 'Posted At',
    sortable: true,
    accessor: tableItem => tableItem.load_created_at || _.get(tableItem.load, 'created_at'),
    displayer: tableItem => {
      const createdAt = tableItem.load_created_at || _.get(tableItem.load, 'created_at')
      return createdAt ? moment(createdAt).format('M/D/YYYY @ hh:mm:ss a') : <span>&nbsp;</span>
    },
  },
  loadCreatedBy: {
    name: 'Posted By',
    filterable: true,
    accessor: tableItem => tableItem.load_created_by_name || _.get(tableItem.load, 'created_by'),
    filterer: createdBy => {
      return _.isObject(createdBy) ? `${createdBy.first_name} ${createdBy.last_name}`
          :  _.isString(createdBy) ? createdBy
          : 'None'
    },
    displayer: tableItem => {
      const createdBy = tableItem.load_created_by_name || _.get(tableItem.load, 'created_by')
      return _.isObject(createdBy) ? `${createdBy.first_name} ${createdBy.last_name}`
          :  _.isString(createdBy) ? createdBy
          : <span>&nbsp;</span>
    }
  },
  loadOfferRate: {
    name: 'Offer Rate',
    filterable: true,
    staticFilters: ['Multiple'],
    accessor: tableItem => tableItem,
    filterer: tableItem => tableItem.load && !tableItem.load.offer_rate && 'Multiple',
    displayer: tableItem => {
      if (tableItem.load) {
        return tableItem.load.offer_rate ? decimalCurrencyFormatter.format(tableItem.load.offer_rate) : <Tooltip id="multiple-rates-tooltip" text="This load has been posted to multiple load boards with different rates" ><span>Multiple <FontAwesomeIcon icon={['far', 'question-circle']} /></span></Tooltip>
      }

      return <span>&nbsp;</span>
    }
  },
  payInvoice: postPaymentCallback => {
    return {
      name: 'Paid / Unpaid',
      accessor: ({invoice}) => invoice,
      displayer: tableItem => <PayInvoiceModal key={tableItem.invoice.id} bol={tableItem} invoice={tableItem.invoice} postPaymentCallback={postPaymentCallback} />,
      filterable: true,
      staticFilters: ['Paid', 'Unpaid'],
      filterer: invoice => invoice.paid ? 'Paid' : 'Unpaid',
      sortable: true
    }
  },
  invoicePaidAt: {
    name: 'Paid At',
    accessor: ({invoice}) => invoice.paid_at,
    sortable: true,
    displayer: ({invoice}) => invoice.paid_at && moment(invoice.paid_at).format('M/D/YYYY @ hh:mm a'),
  },
  invoiceAmountPaid: {
    name: 'Amount Paid',
    accessor: ({invoice}) => invoice.amount_paid,
    displayer: ({invoice}) => decimalCurrencyFormatter.format(invoice.amount_paid || 0),
    flexFactor: 0.70
  },
  carrierUploaded: {
    name: 'Carrier Uploaded',
    accessor: ({invoice}) => invoice.carrier_uploaded ? 'Yes' : 'No',
    sortable: true,
    filterable: true,
    staticFilters: ['Yes', 'No'],
    displayer: ({invoice}) => invoice.carrier_uploaded ? <FontAwesomeIcon icon='check' /> : <FontAwesomeIcon icon='minus' />,
  }
}

export default RefinableColumns
