import _ from 'lodash'
import React, { Fragment, useState } from 'react'
import { Row } from 'react-bootstrap'

import CardsList from '../generic/CardsList'
import Graphql from '../Graphql'
import { graphqlApi } from '../Api'
import EsignableColumns from './EsignableRefinableColumns'
import InteractableTable from '../generic/InteractableTable'
import RefinableList from '../generic/RefinableList'
import Requestable from '../generic/Requestable'
import useScreenWidth from '../utils/useScreenWidth'
import { MINIMUM_SCREEN_WIDTH_FOR_TABLE_VIEW } from '../generic/interactableTableConstraints'

const esignableFields = ['id', 'kind', 'owner_data', 'vendor_recipients', 'company_id']

function Esignables(props) {
  const [esignables, setEsignables] = useState([])
  const screenWidth = useScreenWidth()

  const selectableColumns = [
    EsignableColumns.type,
    EsignableColumns.owner,
    EsignableColumns.actions,
  ]

  const companyEsignablesQuery = Graphql.company({
                                   fields: [
                                    'id',
                                     Graphql.relatedEsignables({
                                       filters: {status__not_in: ['document.completed', 'document.incomplete']},
                                       fields: esignableFields
                                     })
                                   ]
                                 })

  const companyEsignablesLoadSuccess = (res) => {
    setEsignables(
      _.map(Graphql.getValue(res, 'company.esignable_documents'), esignable_document => {
        return {
          ...esignable_document,
          'owner_data': JSON.parse(esignable_document.owner_data),
          'vendor_recipients': JSON.parse(esignable_document.vendor_recipients)
        }
      })
    )
  }

  const renderTable = () => {
    return (
      <RefinableList
        Renderer={InteractableTable}
        initialSelectedColumns={selectableColumns.slice(0,3)}
        initialSortedColumn="Document"
        selectableColumns={selectableColumns}
        draggableType="esignableColumn"
        items={esignables}
        key='table'
        pageSize={25}
      />
    )
  }

  const renderCards = () => {
    return (
      <RefinableList
        Renderer={CardsList}
        cardHeaderColumn={selectableColumns[0]}
        initialSelectedColumns={selectableColumns.slice(1,3)}
        initialSortedColumn="Document"
        selectableColumns={selectableColumns}
        items={esignables}
        key='cards'
        pageSize={10}
      />
    )
  }

  return (
    <Requestable
      onMountFetch={graphqlApi.execute(companyEsignablesQuery, companyEsignablesLoadSuccess)}
      render={() => {
          return (
            <Fragment>
              <Row className="form-sub-header g-0">
                <h5>Pending E-Signable Documents</h5>
              </Row>
              {screenWidth > MINIMUM_SCREEN_WIDTH_FOR_TABLE_VIEW ? renderTable() : renderCards()}
            </Fragment>
          )
        }
      }
    />
  )
}

export default Esignables
