import _ from 'lodash'
import React, { Fragment, useState } from 'react'
import { Accordion, Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import { appendNotification } from '../notifications/notificationsSlice'
import GenericForm from '../forms/GenericForm'
import { restApi } from '../Api'

const createSubscribedUsersList = (users, subscribedUsers) => {
  return _.transform(users, (result, user) => {
    result[user.id] = !_.isNil(_.find(subscribedUsers, ['id', user.id]))
  }, {})
}

function Notification({id, kind, users, subscribedUsers}) {
  const [formData, setFormData] = useState(() => {
      return {id: id, subscribedUsers: createSubscribedUsersList(users, subscribedUsers)}
    }
  )
  const turnedOnForAllUsers = _.every(users, (user) => formData.subscribedUsers[user.id])
  const dispatch = useDispatch()

  const handleUpdateSuccess = (res) => {
    dispatch(appendNotification({type: 'Notification'}))
  }

  const toggleNotificationForAllUsers = () => {
    const subscribedUsersList =  _.transform(users, (result, user) => {result[user.id] = !turnedOnForAllUsers}, {})
    setFormData({...formData, subscribedUsers: subscribedUsersList})
  }

  return (
    <Accordion.Item eventKey={id}>
      <Accordion.Header>
        <b>{_.startCase(_.lowerCase(kind))}</b>
      </Accordion.Header>
      <Accordion.Body>
        <GenericForm
          formRequest={restApi.updateNotification}
          handleSuccess={handleUpdateSuccess}
          params={formData}
          formContent={({isSubmitting}) => {
            return (
              <Fragment>
                <div className="mb-2">
                  Select users that should receive notifications about this event.
                </div>
                {
                  _.map(users, user => {
                    return (
                      <Form.Switch key={`${kind}-${user.id}`} id={`${kind}-${user.id}`} label={`${user.first_name} ${user.last_name}`} checked={formData.subscribedUsers[user.id]} onChange={e => setFormData({...formData, subscribedUsers: {...formData.subscribedUsers, [user.id]: e.target.checked}})} />
                    )
                  })
                }
                <Form.Switch id={`${kind}-set-all`} className="text-danger mt-4" label="Toggle notification for all users" checked={turnedOnForAllUsers} onChange={_ => toggleNotificationForAllUsers()} />
                <Row className="mt-3">
                  <Col xl={2} xs={12}>
                    <Button type="submit" className="indigo-button w-100">
                      {isSubmitting ? <Spinner size="sm" animation="border" variant="light" /> : 'Update'}
                    </Button>
                  </Col>
                </Row>
              </Fragment>
            )
          }}
        />
      </Accordion.Body>
    </Accordion.Item>
  )
}

export default Notification
