import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { Popover } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { restApi } from '../Api'
import { setConfirmableAction } from '../generic/confirmableActionSlice'
import { appendNotification } from '../notifications/notificationsSlice'
import { setCurrentEsignable } from '../esign/esignSlice'
import { esignersStatusHtml, unsignedSignersHtml } from '../esign/utils'
import PusherClient, { esignableDocumentChannel } from '../pusher/Pusher'

let esignChannel = undefined

function Esignable({initialEsignableId, initialEsignRecipientsData, As, ...props}) {
  const [esignableId, setEsignableId] = useState()
  const [esignRecipientsData, setEsignRecipientsData] = useState()
  const [hasDownloaded, setHasDownloaded] = useState(false)
  const currentUser = useSelector(state => state.user.currentUser)
  const dispatch = useDispatch()

  const esignableIsProcessing = esignableId && !esignRecipientsData
  const esignableIsComplete = !_.isEmpty(esignRecipientsData) && _.every(esignRecipientsData, recipient => recipient.has_completed)
  const signedDocumentIsDownloading = !hasDownloaded && esignableIsComplete

  const currentUserHasNotSigned = useMemo(() => {
    if (esignRecipientsData && esignRecipientsData.length > 0) {
      let currentUserSigner = _.find(esignRecipientsData, recipient => recipient.email === currentUser.email)
      return currentUserSigner ? !currentUserSigner.has_completed : false
    }
  }, [esignRecipientsData]) // eslint-disable-line react-hooks/exhaustive-deps

  const anyNonUsersHaveNotSigned = useMemo(() => {
    if (esignRecipientsData && esignRecipientsData.length > 0) {
      return _.find(esignRecipientsData, recipient => recipient.email !== currentUser.email && !recipient.has_completed) ? true : false
    }
  }, [esignRecipientsData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setEsignableId(initialEsignableId)
  }, [initialEsignableId])

  useEffect(() => {
    setEsignRecipientsData(initialEsignRecipientsData)
    setHasDownloaded(initialEsignRecipientsData && _.every(initialEsignRecipientsData, recipient => recipient.has_completed))
  }, [`${initialEsignRecipientsData}`]) // eslint-disable-line

  useEffect(() => {
    if (esignableId && !esignableIsComplete) {
      esignChannel = PusherClient.subscribe(esignableDocumentChannel(esignableId))
      esignChannel.bind('recipients-changed', (recipients) => setEsignRecipientsData(recipients))
      esignChannel.bind('document-downloaded', (_) => setHasDownloaded(true))
    }
  }, [esignableId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasDownloaded && PusherClient.channel(esignableDocumentChannel(esignableId))) {
      PusherClient.unsubscribe(esignableDocumentChannel(esignableId))
    }
  }, [hasDownloaded]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleGetEsignableLinkResponse = (res) => {
    dispatch(setCurrentEsignable({currentEsignableId: esignableId, currentEsignableLink: res.data.signing_url}))
  }

  const getEsignableLink = (performRequest) => {
    const params = {esignable_document_id: esignableId}
    performRequest(restApi.getSignableLink(params, handleGetEsignableLinkResponse))
  }

  const handleSendEsignRequestReminderResponse = (res) => {
    dispatch(appendNotification({type: 'EsignAction', verb: 'request sent'}))
  }

  const sendEsignRequestReminder = (performRequest, esignableName) => {
    const params = {esignable_document_id: esignableId}
    dispatch(setConfirmableAction({performAction: performRequest, action: restApi.requestEsignature(params, handleSendEsignRequestReminderResponse), details: {title: `Requesting E-Signature(s) for: ${esignableName}`, confirmationBody: <div><div className="mb-1">This action will email the following unsigned e-signers:</div>{unsignedSignersHtml(esignRecipientsData, currentUser.email)}</div>}}))
  }

  const esignersStatusPopover = useMemo(() => {
    return (
      <Popover id={esignableId} className="esigners-status-popover">
        <Popover.Header>E-Signers Status</Popover.Header>
        <Popover.Body>
          <div className="mb-3">
            <FontAwesomeIcon className="success-green success-check-circle me-1" icon='check-circle' size='lg' /><b>Signed</b>
            <FontAwesomeIcon className="error-red ms-4 me-1" icon='exclamation-circle' size='lg' /><b>Unsigned</b>
          </div>
          {esignersStatusHtml(esignRecipientsData)}
        </Popover.Body>
      </Popover>
    )
  }, [esignRecipientsData]) // eslint-disable-line react-hooks/exhaustive-deps

  return <As
           esignable
           esignableId={esignableId}
           setEsignableId={setEsignableId}
           esignableIsProcessing={esignableIsProcessing}
           esignableIsComplete={esignableIsComplete}
           currentUserHasNotSigned={currentUserHasNotSigned}
           anyNonUsersHaveNotSigned={anyNonUsersHaveNotSigned}
           getEsignableLink={getEsignableLink}
           sendEsignRequestReminder={sendEsignRequestReminder}
           signedDocumentIsDownloading={signedDocumentIsDownloading}
           esignersStatusPopover={esignersStatusPopover}
           {...props}
         />
}

export default Esignable
