import _ from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'

import Esignable from './Esignable'
import EsignableActions from './EsignableActions'

const generateLinkToDocumentOwner = (tableItem) => {
  if (tableItem.kind === 'RateConfirmationEsignable') {
    return <Link to={`/company/1/shipments/edit-bol/${tableItem.owner_data.number}`}>Bill of Lading #{tableItem.owner_data.number}</Link>
  } else if (tableItem.kind === 'CarrierAgreementEsignable') {
    return <Link to={`/company/1/carriers/view/${tableItem.owner_data.carrier_id}`}>{tableItem.owner_data.carrier_name}</Link>
  }
}

const RefinableColumns = {
  type: {
    name: 'Document',
    accessor: tableItem => tableItem.kind,
    filterer: kindString => _.startCase(kindString.replace('Esignable', '')),
    displayer: tableItem => <a target="_blank" rel="noopener noreferrer" href={`/company/${tableItem.company_id}/documents/${_.snakeCase(tableItem.kind.replace('Esignable', ''))}/${tableItem.owner_data.id}`}>{_.startCase(tableItem.kind.replace('Esignable', ''))}</a>,
    asCardHeader: ({children, tableItem}) => <Link to={'/'} className="card-header">{children}</Link>,
    sortable: true,
    filterable: true
  },
  owner: {
    name: 'Document Owner',
    accessor: tableItem => tableItem.owner_data,
    displayer: generateLinkToDocumentOwner
  },
  actions: {
    name: 'Actions',
    displayer: tableItem => <Esignable As={EsignableActions} initialEsignableId={tableItem.id} initialEsignRecipientsData={tableItem.vendor_recipients} esignableType={tableItem.kind} filePath={`/company/${tableItem.company_id}/documents/${_.snakeCase(tableItem.kind.replace('Esignable', ''))}/${tableItem.owner_data.id}`} />,
  }
}

export default RefinableColumns
