import _ from 'lodash'
import React, { useEffect, useState, Fragment } from 'react'
import { Row, Col, FloatingLabel, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AppendableFieldsGroup from '../forms/AppendableFieldsGroup'

const BOLDescriptionsAndReferences = React.memo(({formData, setFormData}) => {
  const [descriptionCount, setDescriptionCount]= useState(4)

  useEffect(() => {
    if (_.isEmpty(formData)) return
    setDescriptionCount(_.size(formData))
  }, [formData])

  return (
    _.times(descriptionCount, i => {
      return <AppendableFieldsGroup
        key={i}
        dataKey={i}
        groupName='descriptionsList'
        group={formData}
        setState={setFormData}
        renderContent={
          ({getAppendableValue, removeAppendedFields, setAppendableValue}) => {
            return (
              <>
                <Row>
                  <Col md={6} xs={12}>
                    <FloatingLabel controlId='description' label={`Description ${i+1}`}>
                      <Form.Control type="text" placeholder="Description" value={getAppendableValue('description')} onChange={e=>setAppendableValue(e)} />
                    </FloatingLabel>
                  </Col>
                  <Col>
                    <FloatingLabel controlId='reference' label={`Reference ${i+1}`}>
                      <Form.Control type="text" placeholder="Reference" value={getAppendableValue('reference')} onChange={e=>setAppendableValue(e)} />
                    </FloatingLabel>
                  </Col>
                </Row>
                {
                  i === descriptionCount - 1 &&
                  <div className='d-flex mb-2'>
                    <FontAwesomeIcon icon='minus' size="lg" onClick={() => removeAppendedFields(i, setDescriptionCount)} />
                    <FontAwesomeIcon icon='plus' size="lg" onClick={() => setDescriptionCount(descriptionCount + 1)} />
                  </div>
                }
              </>
            )
          }
        }
      />
    })
  )
})

export default BOLDescriptionsAndReferences
