import _ from 'lodash'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function reviewableSignersHtml(signerRoles, recipientsObj) {
  return _.map(signerRoles, role => {
    return (
      <div key={role} className="mt-1">
        <div><b>Signer Role:</b> {_.startCase(role)}</div>
        <div><b>First Name:</b> {recipientsObj[role].first_name}</div>
        <div><b>Last Name:</b> {recipientsObj[role].last_name}</div>
        <div><b>Email:</b> {recipientsObj[role].email}</div>
      </div>
    )
  })
}

export function unsignedSignersHtml(esignRecipientsData, signerEmailToExclude) {
  return _.reduce(esignRecipientsData, (result, recipient) => {
    if (!recipient.has_completed && recipient.email !== signerEmailToExclude) {
      result.push(<div key={recipient.email}>{recipient.first_name} {recipient.last_name} <b>({_.startCase(recipient.role)})</b>: {recipient.email}</div>)
    }

    return result
  }, [])
}

export function esignersStatusHtml(esignRecipientsData) {
  return _.map(esignRecipientsData, (recipient) => {
    return <div key={recipient.email} className="mb-2">
             {recipient.first_name} {recipient.last_name} <b>({_.startCase(recipient.role)})</b>:
             {recipient.has_completed && <FontAwesomeIcon className="success-green ms-2" icon='check-circle' size='lg' />}
             {!recipient.has_completed && <FontAwesomeIcon className="error-red ms-2" icon='exclamation-circle' size='lg' />}
           </div>
  })
}
