import _ from 'lodash'
import React, { Fragment, useEffect, useState } from 'react'
import { Card, Col, Form, InputGroup, Modal, Row, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { v4 as uuidv4 } from 'uuid'

import CurrencyInput from '../forms/CurrencyInput'
import { decimalCurrencyFormatter } from '../utils/CurrencyFormatter'
import Graphql from '../Graphql'
import { graphqlApi } from '../Api'
import { handleFormChange } from '../forms/GenericForm'
import RateLookup from './RateLookup'
import Requestable from '../generic/Requestable'
import Tooltip from '../generic/Tooltip'

function LaneRate({formData, setFormData, bols, bolIdsByLane, laneKey, request, response}) {
  const [showMore, setShowMore] = useState(false)
  const [showRateLookupModal, setShowRateLookupModal] = useState(false)
  const bolIdsString = _.join(bolIdsByLane[laneKey], ',')
  const specifiedRate = _.get(formData.rateSpecifications, bolIdsString)
  const specifiedRateAsNumber = _.isString(specifiedRate) ? _.toNumber(specifiedRate.replace(/,/g, '')) : specifiedRate

  const setRateForLane = (rate) => {
    setFormData(prevState => {
      return {
               ...prevState,
               rateSpecifications: {...prevState.rateSpecifications, [bolIdsString]: rate}
             }
    })
  }

  // On mount, set default auto-assigned rate for lane
  useEffect(() => {
    if (!specifiedRate) {
      setRateForLane(response.rate.averageRatePerTrip)
    }
  })

  return (
    <Fragment>
      <Modal show={showRateLookupModal} onHide={setShowRateLookupModal} dialogClassName="modal-90w" backdrop='static'>
        <Modal.Header closeButton />
        <Modal.Body>
          <RateLookup initOriginCity={request.origin.city} initOriginState={request.origin.stateOrProvince} initOriginPostal={request.origin.postalCode} initDestinationCity={request.destination.city} initDestinationState={request.destination.stateOrProvince} initDestinationPostal={request.destination.postalCode} initEquipment={request.equipment} />
        </Modal.Body>
      </Modal>
      <Col xl={6} className="my-2">
        <Card className="w-100">
          <Card.Header className="p-3">
            <b>{request.origin.city}, {request.origin.stateOrProvince}</b>
            <FontAwesomeIcon className="mx-2" icon='arrow-right' />
            <b>{request.destination.city}, {request.destination.stateOrProvince}</b>
            <span> {_.capitalize(request.equipment)}</span>
            <span className="float-end">Past {_.capitalize(_.startCase(response.timeframe))}</span>
            <div>
              <span className="text-primary cursor-pointer" onClick={() => setShowRateLookupModal(true)}>View Detailed Lane Rate Data</span>
            </div>
            <div className="text-muted small">
              <Tooltip id="relevant-bols-tooltip" text={`BOLs numbered: ${_.join(_.map(bolIdsByLane[laneKey], bolId => bols[bolId].number),', ')}`}>
                <span className="cursor-pointer">Relevant BOLs</span>
              </Tooltip>
            </div>
          </Card.Header>
          <Card.Body className="p-3">
            <Form.Group className="mb-2">
              <InputGroup>
                <InputGroup.Text>$</InputGroup.Text>
                <CurrencyInput placeholder="0.00" className="form-control" value={specifiedRate || response.rate.averageRatePerTrip} onChange={e=> setRateForLane(e.target.value)} />
              </InputGroup>
            </Form.Group>
            <Card.Text as='div'>
              <div>
                Total Reports: {response.reports}
                <Tooltip id="num-reports-tooltip" text="The number of individual trips used to calculate the average">
                  <FontAwesomeIcon className="ms-1" icon={['far', 'question-circle']} />
                </Tooltip>
              </div>
              <Form.Switch id={`high-${laneKey}-${uuidv4()}`} label={`High: ${decimalCurrencyFormatter.format(response.rate.highestRatePerTrip)} ${showMore ? '(' + decimalCurrencyFormatter.format(response.rate.highestRatePerMile) + '/mile)' : ''}`} checked={response.rate.highestRatePerTrip === specifiedRateAsNumber} onChange={e=> setRateForLane(response.rate.highestRatePerTrip)} />
              <Form.Switch id={`avg-${laneKey}-${uuidv4()}`} label={`Average: ${decimalCurrencyFormatter.format(response.rate.averageRatePerTrip)} ${showMore ? '(' + decimalCurrencyFormatter.format(response.rate.averageRatePerMile) + '/mile)' : ''}`} checked={response.rate.averageRatePerTrip === specifiedRateAsNumber} onChange={e=> setRateForLane(response.rate.averageRatePerTrip)} />
              <Form.Switch id={`low-${laneKey}-${uuidv4()}`} label={`Low: ${decimalCurrencyFormatter.format(response.rate.lowestRatePerTrip)} ${showMore ? '(' + decimalCurrencyFormatter.format(response.rate.lowestRatePerMile) + '/mile)' : ''}`} checked={response.rate.lowestRatePerTrip === specifiedRateAsNumber} onChange={e=> setRateForLane(response.rate.lowestRatePerTrip)} />
              {
                showMore &&
                  <Fragment>
                    <div>
                      Trip Mileage: {response.mileage}
                    </div>
                    <div>
                      Origin Market Name: {response.originName}
                    </div>
                    <div>
                      Origin Market Geography Type: {_.capitalize(_.startCase(response.originGeoType))}
                    </div>
                    <div>
                      Destination Market Name: {response.destinationName}
                    </div>
                    <div>
                      Destination Market Geography Type: {_.capitalize(_.startCase(response.destinationGeoType))}
                    </div>
                  </Fragment>
              }
            </Card.Text>
            <div>
              <span className="text-primary cursor-pointer" onClick={() => setShowMore(!showMore)}>
                {showMore ? 'Show less' : 'Show More'}
              </span>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Fragment>
  )
}

function OfferRateEditor({ratesData, setRatesData, formData, setFormData, editingExistingLoads, bols}) {
  const [previewRateInfo, setPreviewRateInfo] = useState(false)

  // If posting a new load, default auto assign rate to true
  useEffect(() => {
    setFormData(prevState => {
      return {
        ...prevState,
        autoAssignRate: !editingExistingLoads
      }
    })
  }, [editingExistingLoads]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleCurrentRatesFetchSuccess = (res) => {
    const transformedData = Graphql.transformData(res.data.data).currentDatRates
    _.forEach(transformedData, (value, key) => {
      const avgFuelPerTrip = transformedData[key].response.rate.averageFuelSurchargePerTrip
      const avgFuelPerMile = transformedData[key].response.rate.averageFuelSurchargePerMile
      transformedData[key].response.rate.lowestRatePerTrip += avgFuelPerTrip
      transformedData[key].response.rate.lowestRatePerMile += avgFuelPerMile
      transformedData[key].response.rate.averageRatePerTrip += avgFuelPerTrip
      transformedData[key].response.rate.averageRatePerMile += avgFuelPerMile
      transformedData[key].response.rate.highestRatePerTrip += avgFuelPerTrip
      transformedData[key].response.rate.highestRatePerMile += avgFuelPerMile
    })
    setRatesData(transformedData)
  }

  return (
    <Row>
      <Col className="d-flex flex-column order-xl-0 order-1" xl={8}>
        <Row>
          <Col xl={6}>
            <div className="mb-1">
              <h5 className="d-inline"><b>Offer Rate</b></h5>
              <Tooltip id="auto-update-rate-tooltip" text="Automatically re-assign load posting(s) the most up-to-date rate data">
                <FontAwesomeIcon className={`ms-1 float-end ${editingExistingLoads ? '' : 'd-none'}`} style={{ paddingTop: '0.1rem' }} icon={['far', 'question-circle']} />
              </Tooltip>
              <Form.Switch id={uuidv4()} className="float-end" label={editingExistingLoads ? 'Auto-Update Rate' : 'Auto-Assign Rate'} checked={Boolean(formData.autoAssignRate)} onChange={e=> setFormData(prevState => { return {...prevState, autoAssignRate: e.target.checked}})} />
            </div>
            {
              !formData.autoAssignRate &&
                <Form.Group className="mb-3">
                  <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <CurrencyInput id="offerRate" placeholder="0.00" className="form-control" value={formData.offerRate} onChange={e=>handleFormChange(setFormData, e)} />
                  </InputGroup>
                </Form.Group>
            }
            {
              formData.autoAssignRate && !previewRateInfo &&
                <Form.Group className="mb-3">
                  <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <CurrencyInput disabled id="offerRate" placeholder="0.00" className="form-control" value={formData.offerRate} onChange={e=>handleFormChange(setFormData, e)} />
                  </InputGroup>
                  <Form.Text>
                    <span className="text-primary cursor-pointer" onClick={() => setPreviewRateInfo(true)}>
                      Preview rate information
                    </span>
                  </Form.Text>
                </Form.Group>
            }
          </Col>
        </Row>
        {
          formData.autoAssignRate && previewRateInfo &&
            <Requestable
              withoutLoading
              render={({isLoading, performRequest}) => {
                const bolIdsByLane = {}

                // eslint-disable-next-line
                useEffect(() => {
                  if (_.isEmpty(ratesData)) {
                    performRequest(graphqlApi.execute(Graphql.currentDatRates({filters: {bol_ids: _.map(formData.bolIds, n => parseInt(n, 10))}}), handleCurrentRatesFetchSuccess))
                  }
                }, []) // eslint-disable-line react-hooks/exhaustive-deps

                if (isLoading) {
                  return <Spinner size="sm" animation="border" variant="secondary" className="mb-2" />
                }

                return (
                  <Fragment>
                    <div>
                      <span className="text-primary cursor-pointer" onClick={() => setPreviewRateInfo(false)}>
                        Collapse rate information
                      </span>
                    </div>
                    <Row id="lane-rate-cards">
                      {
                        _.compact(_.map(ratesData, ({request, response}, bolId) => {
                          const laneKey = `${JSON.stringify(request.origin)}-${JSON.stringify(request.destination)}-${request.equipment}`

                          if (!bolIdsByLane[laneKey]) {
                            bolIdsByLane[laneKey] = [bolId]
                            return <LaneRate key={laneKey} formData={formData} setFormData={setFormData} bols={bols} bolIdsByLane={bolIdsByLane} laneKey={laneKey} request={request} response={response} />
                          } else {
                            bolIdsByLane[laneKey].push(bolId)
                          }
                        }))
                      }
                    </Row>
                  </Fragment>
                )
              }}
            />
        }
      </Col>
      <Col xl={4} className="d-flex order-xl-1 order-0 mb-2">
        <i className="ms-auto">* Any information <b>not</b> manually specified will be automatically populated</i>
      </Col>
    </Row>
  )
}

export default OfferRateEditor
