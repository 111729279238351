import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { Row, Col, FloatingLabel, Form, Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { appendNotification } from '../notifications/notificationsSlice'
import BOLColumns from '../bol/BOLRefinableColumns'
import CardsList from '../generic/CardsList'
import Graphql from '../Graphql'
import { graphqlApi } from '../Api'
import InteractableTable from '../generic/InteractableTable'
import InvoiceForm from './InvoiceForm'
import RefinableList from '../generic/RefinableList'
import RefinableListSettingsModal from '../generic/RefinableListSettingsModal'
import Requestable from '../generic/Requestable'
import useScreenWidth from '../utils/useScreenWidth'
import { MINIMUM_SCREEN_WIDTH_FOR_TABLE_VIEW } from '../generic/interactableTableConstraints'
import { restApi } from '../Api'

const InvoiceEditor = ({ bolId, close, invoice, onUpsertSuccess, onDeleteSuccess }) => {
  const [formData, setFormData] = useState({bolId: bolId})

  useEffect(() => {
    if (invoice) setFormData(invoice.invoice)
  }, [invoice])

  return <Modal show onHide={close} size="lg">
    <Modal.Header closeButton >
      <Modal.Title>{ invoice ? 'Edit' : 'Upload' } Invoice</Modal.Title>
    </Modal.Header>
    <Modal.Body className='pb-0'>
      <InvoiceForm
        deleteHandler={onDeleteSuccess}
        formData={formData}
        formRequest={formData.id ? restApi.updateInvoice : restApi.uploadInvoice}
        showHeader={false}
        setFormData={setFormData}
        successHandler={onUpsertSuccess}
      />
    </Modal.Body>
  </Modal>
}

const invoiceFields = [
  'amount_paid',
  'carrier_uploaded',
  'due_date',
  'id',
  'number',
  'paid',
  'paid_at',
  'total',
  'transfer_id'
]

const BOLFields = [
  'delivered_on',
  'id',
  'number',
  'total_cost',
]

function Invoices({bolId}) {
  const [invoices, setInvoices] = useState({})
  const invoicesList = useMemo(() => { return _.values(invoices) }, [invoices])
  const { companyId } = useParams()
  const dispatch = useDispatch()
  const [endDate, setEndDate] = useState()
  const preferencedColumns  = useSelector(({user}) => _.get(user.currentUser.settings, bolId ? 'bolInvoicesColumns' : 'invoicesColumns' ))
  const screenWidth = useScreenWidth()
  const [startDate, setStartDate] = useState()
  const [showInvoiceEditor, setShowInvoiceEditor] = useState(false)
  const [invoiceId, setInvoiceId] = useState(null)

  const editInvoice = invoiceId => {
    console.log(invoiceId)
    setInvoiceId(invoiceId)
    setShowInvoiceEditor(true)
  }

  const closeInvoiceEditor = () => {
    setInvoiceId()
    setShowInvoiceEditor(false)
  }

  const postPaymentCallback = (res, invoiceId, invoiceNumber, text='Payment') => {
    dispatch(appendNotification({type: 'GenericSuccess', text: `${text} for invoice #${invoiceNumber} was successful`}))
    setInvoices(prevInvoices => {
      return {
        ...prevInvoices,
        [invoiceId]: {
          ...prevInvoices[invoiceId],
          invoice: {
            ...prevInvoices[invoiceId].invoice,
            ...res.data
          }
        }
      }
    })
  }

  const selectableColumns = {
    invoiceAmount: BOLColumns.invoiceAmount,
    invoiceAmountPaid: BOLColumns.invoiceAmountPaid,
    invoiceNumber: BOLColumns.invoiceNumber(companyId, editInvoice),
    invoiceDueDate: BOLColumns.invoiceDueDate,
    invoicePaidAt: BOLColumns.invoicePaidAt,
    payInvoice: BOLColumns.payInvoice(postPaymentCallback),
    number: BOLColumns.number(companyId),
    carrier: BOLColumns.carrier(companyId),
    carrierUploaded: BOLColumns.carrierUploaded,
    totalCost: BOLColumns.totalCost,
    finalReceiver: BOLColumns.finalReceiver(companyId),
    deliveredOn: BOLColumns.deliveredOn
  }

  const companyInvoicesQuery = Graphql.company({
                                fields: [
                                  Graphql.relatedInvoices({
                                    filters: {
                                      ...(bolId && {bol_id: bolId}),
                                      ...(startDate && {due_date__gte: `"${startDate}"`}),
                                      ...(endDate && {due_date__lte: `"${endDate}"`})
                                    },
                                    fields: [
                                      ...invoiceFields,
                                      Graphql.relatedBOL({
                                        fields: [
                                          ...BOLFields,
                                          Graphql.relatedCarrier({fields: ['id', 'name']}),
                                          Graphql.relatedReceivers({fields: ['id', 'name']})
                                        ]
                                      })
                                    ]
                                  })
                                ]
                              })

  // eslint-disable-next-line
  const dateFilterString = useMemo(() => {
    if (startDate && endDate) {
      return `AND due_date_int >= ${startDate.replaceAll('-', '')} AND due_date_int <= ${endDate.replaceAll('-', '')}`
    } else {
      return ''
    }
  }, [startDate, endDate])

  const companyInvoicesLoadSuccess = res => {
    setInvoices(
      _.transform(Graphql.getValue(res, 'company.invoices'), (result, invoice) => {
        result[invoice.id] = {
          invoice: invoice,
          ...invoice.bol, // TODO - nest me under bol key when we refactor refinable column accessors
        }
      }, {})
    )
  }

  const handleInvoiceUpsertSuccess = ({data}) => {
    const invoice = data.data || data
    setInvoices(prevInvoices => {
      return {
        ...prevInvoices,
        [invoice.id]: {
          ...(invoice.bol || prevInvoices[invoice.id]), // TODO - nest under bol key when we refactor refinable column accessors, only works on invoice creation because we're not returning bol data on update!
          invoice: {
            ..._.get(prevInvoices[invoice.id], 'invoice'),
            ...invoice,
          },
        }
      }
    })
    dispatch(appendNotification({type: 'GenericSuccess', text: `Invoice ${invoice.bol ? 'created' : 'updated'}`}))
    setInvoiceId()
    setShowInvoiceEditor(false)
  }

  const handleInvoiceDeleteSuccess = _res => {
    setInvoices(prevInvoices => {
      return _.omit(prevInvoices, invoiceId)
    })
    dispatch(appendNotification({type: 'GenericSuccess', text: 'Invoice deleted'}))
    setInvoiceId()
    setShowInvoiceEditor(false)
  }

  const renderTable = () => {
    if (preferencedColumns.length === 0) return null

    return (
      <RefinableList
        Renderer={InteractableTable}
        initialSelectedColumns={_.values(_.pick(selectableColumns, preferencedColumns))}
        initialSortedColumn="Due Date"
        selectableColumns={_.values(selectableColumns)}
        draggableType="invoiceColumn"
        items={invoicesList}
        settings={<RefinableListSettingsModal selectableColumns={selectableColumns} userPreferencedColumns={preferencedColumns} refinableListSettingsName='invoicesColumns' />}
        pageSize={25}
      />
    )
  }

  const renderCards = () => {
    if (preferencedColumns.length === 0) return null

    return (
      <RefinableList
        Renderer={CardsList}
        cardHeaderColumn={selectableColumns.invoiceNumber}
        initialSelectedColumns={_.values(_.pick(selectableColumns, _.without(preferencedColumns, 'invoiceNumber')))}
        initialSortedColumn="Due Date"
        selectableColumns={_.values(selectableColumns)}
        items={invoicesList}
        settings={<RefinableListSettingsModal selectableColumns={selectableColumns} userPreferencedColumns={preferencedColumns} refinableListSettingsName='invoicesColumns' />}
        pageSize={10}
      />
    )
  }

  return (
    <Requestable
      withoutLoading
      onMountFetch={bolId && graphqlApi.execute(companyInvoicesQuery, companyInvoicesLoadSuccess)}
      render={({isLoading, performRequest}) => {
          return (
            <>
              {
                !bolId &&
                <>
                  <Row className="form-sub-header g-0">
                    <h5>Search for Invoices</h5>
                  </Row>
                  <Row>
                    <Col xl={2} md={3} xs={12}>
                      <FloatingLabel controlId="startDate" label="Due on or after date" className="mb-3">
                        <Form.Control type="date" value={startDate || ''} onChange={e=>setStartDate(e.target.value)} />
                      </FloatingLabel>
                    </Col>
                    <Col xl={2} md={3} xs={12}>
                      <FloatingLabel controlId="endDate" label="Due on or before date" className="mb-3">
                        <Form.Control type="date" value={endDate || ''} onChange={e=>setEndDate(e.target.value)} />
                      </FloatingLabel>
                    </Col>
                    <Col xl={1} md={3} xs={12}>
                      <Button className="indigo-button w-100 mb-3" onClick={_ => {performRequest(graphqlApi.execute(companyInvoicesQuery, companyInvoicesLoadSuccess))}}>Apply</Button>
                    </Col>
                    <Col xl={1} md={3} xs={12}>
                      <Button className="indigo-button w-100 mb-3" onClick={_ => {setStartDate(); setEndDate()}}>Clear</Button>
                    </Col>
                  </Row>
                </>
              }
              {
                bolId &&
                <Button className='indigo-button mb-3 mb-lg-0 w-100 w-lg-auto' onClick={_ => setShowInvoiceEditor(true)}>
                  <FontAwesomeIcon className="plus me-2" icon='plus' />
                  Upload New Invoice
                </Button>
              }
              {
                showInvoiceEditor &&
                  <InvoiceEditor
                    bolId={bolId} invoice={invoices[invoiceId]}
                    close={_ => closeInvoiceEditor()}
                    onUpsertSuccess={handleInvoiceUpsertSuccess}
                    onDeleteSuccess={handleInvoiceDeleteSuccess}
                  />
              }
              {screenWidth > MINIMUM_SCREEN_WIDTH_FOR_TABLE_VIEW ? renderTable() : renderCards()}
            </>
          )
        }
      }
    />
  )
}

export default Invoices
