import _ from 'lodash'

import React, { Fragment } from 'react'
import { Collapse } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

function NavAccordion({handleLinkSelection, header, links, open, toggle}) {
  const { companyId } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const shouldHighlightCategory = _.includes(pathname, `/company/${companyId}/${header}`)

  const renderAccordionContent = () => {
    return (
      <Collapse in={open}>
        <div className={`accordion-body py-0 ${open ? 'open' : 'closed'}`}>
          {
            _.map(links, ({text, path}, i) => {
              const shouldHighlight = pathname === path
              return (
                <div key={i} className="d-flex">
                  <span className={`cursor-pointer p-2 mx-2 w-100 rounded ${shouldHighlight ? 'active' : ''}`} onClick={() => {navigate(path); handleLinkSelection()}}>
                    {text}
                  </span>
                </div>
              )
            })
          }
        </div>
      </Collapse>
    )
  }

  return (
    <Fragment>
      <div className={`nav-accordion d-flex ${shouldHighlightCategory ? 'active' : ''}`} onClick={() => {toggle(header, !open)}}>
        <div className="accordion-header d-flex">
          <img style={{height: 35, width: 35}} className="nav-icon" src={require(`../../assets/images/sidebar_${header}_icon.svg`)} alt="" />
          <p className="ms-2 d-flex my-auto">{_.startCase(header)}</p>
          <FontAwesomeIcon className={`accordion-arrow ms-2 ${open ? 'rotated' : ''}`} icon='angle-right' />
        </div>
      </div>
      {renderAccordionContent()}
    </Fragment>
  )
}

export default NavAccordion
