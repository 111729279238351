import React, { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import Origin from './Origin'
import { appendNotification } from '../notifications/notificationsSlice'
import { restApi } from '../Api'

function NewOrigin(props) {
  const { companyId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleOriginCreationSuccess = (res) => {
    dispatch(appendNotification({type: 'Origin', companyId: companyId, originId: res.data.id, verb: 'created'}))
    navigate(`/company/${companyId}/locations/origins`)
  }

  return (
    <Fragment>
      <Origin
        contactCount={1}
        formRequest={restApi.createOrigin}
        handleSuccess={handleOriginCreationSuccess}
      />
    </Fragment>
  )
}

export default NewOrigin
