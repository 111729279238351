import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";

import Login from '../auth/Login'
import ForgotPassword from '../auth/ForgotPassword'
import HeaderErrors from '../errorHandling/HeaderErrors'
import ResetPassword from '../auth/ResetPassword'
import Tooltip from '../generic/Tooltip'
import useScreenWidth from '../utils/useScreenWidth'

const tcLogo = require('../../assets/images/tc_logo.svg').default
const tctmsTire = require('../../assets/images/tire_with_tctms.svg').default

function LandingPage(props) {
  const screenWidth = useScreenWidth()
  const location = useLocation()

  const landingNav = () => {
    return (
      <Routes>
        <Route path="/">
          <Route index element={<Login />} />
          <Route path="referer/:postLoginPath" element={<Login />} />
        </Route>
        <Route path="reset_password/:uidb64" element={<ResetPassword />} />
        <Route path="forgot_password" element={<ForgotPassword />} />
        <Route path="*" element={<Navigate to="404" replace />} />
      </Routes>
    )
  }

  const renderForLargeDevice = () => {
    return (
      <Container id="landing-page" fluid>
        <HeaderErrors />
        <Row id="landing-form-container" className="justify-content-center">
          <Col id="landing-left-pane" xs={3} className="d-flex">
            <img id="logo" className="mx-auto" src={tcLogo} alt="Total Control TMS Logo" />
          </Col>
          <Col id="landing-right-pane" className="d-flex flex-column" xs={2}>
            <div>
              {
                location.pathname.match(/^\/(referer\/.+)?$/) === null &&
                  <Tooltip id="landing-back-button" text="Return to login" >
                    <Link to={`/`} style={{color: 'inherit'}} >
                      <FontAwesomeIcon icon='arrow-left' size='lg' className="mt-2 cursor-pointer" />
                    </Link>
                  </Tooltip>
              }
              <img id="tc-tire" className="float-end mt-2" src={tctmsTire} alt="Total Control TMS Tiny Logo" />
            </div>
            {landingNav()}
          </Col>
        </Row>
      </Container>
    )
  }

  const renderForSmallDevice = () => {
    return (
      <Container fluid>
        <HeaderErrors />
        <Row id="mobile-landing-form-container" className="flex-column">
          <Col id="landing-top-pane" className="d-flex">
            <img id="logo" className="mx-auto my-auto" src={tcLogo} alt="Total Control TMS Logo" />
          </Col>
          <Col id="landing-bottom-pane" className="d-flex mt-4">
            {landingNav()}
          </Col>
        </Row>
      </Container>
    )
  }

  return screenWidth > 1199 ? renderForLargeDevice() : renderForSmallDevice()
}

export default LandingPage
