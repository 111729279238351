import _ from 'lodash'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Alert, Button, Card, CloseButton, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { usePlaidLink } from 'react-plaid-link'

import { appendNotification } from '../notifications/notificationsSlice'
import GenericForm from '../forms/GenericForm'
import Graphql from '../Graphql'
import { graphqlApi} from '../Api'
import { restApi } from '../Api'
import Requestable from '../generic/Requestable'
import { setConfirmableAction } from '../generic/confirmableActionSlice'
import { setErrors } from '../errorHandling/errorsSlice'
import Tooltip from '../generic/Tooltip'

const dwollaCSSStyles = `
  #dwolla-initia-info, #dwolla-business-info, #dwolla-customer-info, #dwolla-beneficial-owners {
    height: 100%;
  }

  .dwolla-width-1, .dwolla-width-2, .dwolla-width-3 {
    width: 100% !important;
  }

  .dwolla-input-container {
    display: block;
    margin: 1rem 0px 1rem !important;
  }

  input:not(:disabled)[type='submit'] {
    cursor: pointer !important;
    color: #fff !important;
    background-color: #4545a1 !important;
  }

  input:not([type='checkbox']), .dwolla-input-container input:not([type='checkbox']), .dwolla-input-container select, .dwolla-document-type, .dwolla-document-type select, .dwolla-document-name-display, .dwolla-document-chooser, .shift-left input, .shift-right input {
    border-radius: 0.375rem !important;
    width: 100% !important;
  }

  .dwolla-input-container input:not([type='button'],[type='submit']), .dwolla-input-container select, .dwolla-document-type select, .dwolla-document-name-display {
    padding: 1rem 0.75rem !important;
    box-sizing: border-box !important;
  }

  #dwolla-initial-info-submit, #dwolla-business-info-submit, #dwolla-business-vcr-submit, #noOwners-certify-submit, #hasOwners-certify-submit, #add-owner-submit {
    margin-top: 0.5rem !important;
  }

  .dwolla-document-chooser {
    box-sizing: border-box !important;
    margin-left: 0px;
  }

  .tooltip {
    display: flex;
    position: relative;
    margin-left: auto;
    margin-right: 5px;
  }

  .shift-left, .shift-right {
    float: none;
  }

  .dwolla-owner {
    flex-wrap: nowrap;
  }

  .hide-class {
    display: none !important;
  }

  .dwolla-status-collapse {
    margin-top: 0px;
  }
`

const dwollaConstructedStyleSheet = new CSSStyleSheet()
dwollaConstructedStyleSheet.replaceSync(dwollaCSSStyles)

const attachStyleSheet = async() => {
  const elements = document.getElementsByClassName('dwolla-drop-in')

  if (elements.length) {
    _.forEach(elements, element => element.shadowRoot.adoptedStyleSheets = [ dwollaConstructedStyleSheet ])
  } else {
    await new Promise(_ => setTimeout(attachStyleSheet, 100))
  }
}

const companyPaymentsSettingsQuery = Graphql.company({
  fields: [
    Graphql.relatedDwollaCustomer({
      fields: [
        'active_funding_sources',
        'customer_id',
        'onboarding_complete',
        'needs_beneficial_ownership_certification',
        'needs_customer_documents',
        'needs_customer_info_retry',
      ]
    })
  ]
})

const dwollaBalanceQuery = Graphql.company({
  fields: [
    Graphql.relatedDwollaCustomer({
      fields: [
        'dwolla_balance'
      ]
    })
  ]
})

function PlaidLinkWrapper({isLoading, performRequest, publicToken, setPlaidPublicToken, setFundingSources}) {
  const dispatch = useDispatch()

  const handleCreateFundingSourceResponse = ({data}) => {
    const [failures, successes] = _.partition(data, 'error')

    if (successes.length) {
      _.forIn(successes, ({mask, name, bankAccountType}) => dispatch(appendNotification({type: 'GenericSuccess', text: `Added ${bankAccountType} account: ${name} (ending in ${mask})`})))
      setFundingSources(prevState => _.concat(prevState, successes))
    }

    if (failures.length) {
      dispatch(setErrors(_.map(failures, 'error')))
    }

    setPlaidPublicToken()
  }

  const config = {
    onSuccess: (publicToken, metadata) => { performRequest(restApi.createFundingSource({publicToken: publicToken, metadata: metadata}, handleCreateFundingSourceResponse)) },
    onExit: (_err, _metadata) => setPlaidPublicToken(),
    token: publicToken
  }

  const { exit, open } = usePlaidLink(config)

  if (isLoading) {
    exit()
  } else {
    open()
  }
}

function FundingSource({id, name, bankAccountType, setFundingSources}) {
  const dispatch = useDispatch()
  const [updatingName, setUpdatingName] = useState(false)
  const [fundingSourceName, setFundingSourceName] = useState(name)

  const cancelNameUpdate = initialFormData => {
    setFundingSourceName(initialFormData.name)
    setUpdatingName(false)
  }

  const handleFundingSourceUpdateSuccess = _res => {
    dispatch(appendNotification({type: 'GenericSuccess', text: `Updated funding source name to: ${fundingSourceName}`}))
    setUpdatingName(false)
  }

  const handleFundingSourceRemovalSuccess = _res => {
    dispatch(appendNotification({type: 'GenericSuccess', text: `Deleted funding source: ${fundingSourceName}`}))
    setFundingSources(prevState => _.reject(prevState, {id: id}))
  }

  const confirmFundingSourceRemoval = (event, submitForm) => {
    dispatch(
      setConfirmableAction({
        performAction: submitForm,
        action: event,
        details: {
          title: 'Confirm Bank Account Removal',
          confirmationBody: <div>Please confirm that you would like to remove account named: <b>{fundingSourceName}</b></div>
        }
      })
    )
  }

  return (
    <Card className="mt-4">
      <Card.Body>
        <Card.Title>
          {!updatingName && fundingSourceName}
          {
            updatingName &&
            <GenericForm
              params={{uuid: id, name: fundingSourceName}}
              formRequest={restApi.updateFundingSourceName}
              handleSuccess={handleFundingSourceUpdateSuccess}
              formContent={({isSubmitting, resetToInitialFormData}) => {
                return (
                  <InputGroup>
                    <Form.Control autoFocus placeholder="Enter account name" value={fundingSourceName} onChange={e => setFundingSourceName(e.target.value)} />
                    <Button className='outline-indigo' onClick={_ => resetToInitialFormData(cancelNameUpdate)}>
                      <FontAwesomeIcon icon="times" />
                    </Button>
                    <Button type='submit' data-action='update' className='outline-indigo'>
                      {
                        isSubmitting ? <Spinner animation="border" size="sm" /> : <FontAwesomeIcon icon="check" />
                      }
                    </Button>
                  </InputGroup>
                )
              }}
            />
          }
        </Card.Title>
        <Card.Subtitle className='text-muted'>Account Type: {bankAccountType}</Card.Subtitle>
        <div className='mt-2 d-flex gap-2'>
          <Button className='outline-indigo' onClick={_ => setUpdatingName(true)}>Rename</Button>
          <GenericForm
            params={{uuid: id}}
            formRequest={restApi.removeFundingSource}
            handleSuccess={handleFundingSourceRemovalSuccess}
            formContent={({isSubmitting, submitForm}) => {
              return (
                <Button variant="outline-danger"
                  data-action='remove'
                  disabled={updatingName}
                  onClick={event => confirmFundingSourceRemoval(event, submitForm)}>
                  { isSubmitting ? <Spinner animation="border" size="sm" /> : 'Delete' }
                </Button>
              )
            }}
          />
        </div>
      </Card.Body>
    </Card>
  )
}

/*global dwolla*/
function PaymentsSettings() {
  const [beneficialOwnersNeeded, setBeneficialOwnersNeeded] = useState(false)
  const isDemoCompany = useSelector(state => state.company.currentCompany.demo)
  const currentUser = useSelector(state => state.user.currentUser)
  const [currentAction, setCurrentAction] = useState()
  const [customerDocumentsNeeded, setCustomerDocumentsNeeded] = useState(false)
  const [customerId, setCustomerId] = useState()
  const [customerRetryNeeded, setCustomerRetryNeeded] = useState(false)
  const dispatch = useDispatch()
  const [dwollaBalance, setDwollaBalance] = useState()
  const dwollaDropIn = useRef()
  const [dwollaDropInLoaded, setDwollaDropInLoaded] = useState(false)
  const [fundingSources, setFundingSources] = useState([])
  const [onboardingComplete, setOnboardingComplete] = useState(false)
  const [plaidPublicToken, setPlaidPublicToken] = useState()

  useEffect(() => {
    return () => {
      if (dwollaDropIn.current) {
        try {
          document.body.removeChild(dwollaDropIn.current)
        } catch {}
      }
    }
  }, [])

  useEffect(() => {
    if (customerId && !(customerRetryNeeded || customerDocumentsNeeded || beneficialOwnersNeeded)) setOnboardingComplete(true)
  }, [customerId, customerRetryNeeded, customerDocumentsNeeded, beneficialOwnersNeeded])

  useEffect(() => {
    if (dwollaDropInLoaded) {
      attachStyleSheet()
    } else if (dwollaDropIn.current) {
      document.body.removeChild(dwollaDropIn.current)
      dwollaDropIn.current = null
    }
  }, [dwollaDropInLoaded])

  const companyPaymentsSettingsLoadSuccess = ({data}) => {
    const {
      active_funding_sources,
      customer_id,
      onboarding_complete,
      needs_beneficial_ownership_certification,
      needs_customer_documents,
      needs_customer_info_retry
    } = data.data.company.dwolla_customer || {}

    setCustomerId(customer_id || null)
    if (customer_id) {
      setFundingSources(JSON.parse(active_funding_sources))
      setOnboardingComplete(onboarding_complete)
      setBeneficialOwnersNeeded(needs_beneficial_ownership_certification)
      setCustomerDocumentsNeeded(needs_customer_documents)
      setCustomerRetryNeeded(needs_customer_info_retry)
    }
  }

  const handleDwollaBalanceFetchSuccess = ({data}) => {
    setDwollaBalance(JSON.parse(data.data.company.dwolla_customer.dwolla_balance))
  }

  const renderDwollaBalance = () => {
    if (dwollaBalance) {
      return (
        <Card className='mb-4'>
          <Card.Body>
            <Card.Title className='d-flex'>
              Dwolla Account Balance
              <CloseButton className='ms-auto' onClick={_ => setDwollaBalance()} />
            </Card.Title>
            <Card.Subtitle as={'h3'}>${dwollaBalance.total.value}</Card.Subtitle>
            <Card.Text className='text-muted'>Total Balance</Card.Text>
            <Card.Subtitle as={'h3'}>${dwollaBalance.balance.value}</Card.Subtitle>
            <Card.Text className='text-muted'>Available Balance</Card.Text>
          </Card.Body>
        </Card>
      )
    }

    return (
      <Requestable
        withErrorHandling
        withoutLoading
        render={({isLoading, performRequest}) => {
          return (
            <Button className='indigo-button mb-2' onClick={_ => performRequest(graphqlApi.execute(dwollaBalanceQuery, handleDwollaBalanceFetchSuccess))}>
              {
                isLoading ?
                <Spinner animation="border" size="sm" className="mx-auto" />
                :
                <>
                  View Dwolla Balance
                  <Tooltip id="view-dwolla-balance-tt" text={<div>View the balance of your account within Dwolla. This should <b>always</b> be $0</div>}>
                    <FontAwesomeIcon className="ms-1" icon={['far', 'question-circle']} />
                  </Tooltip>
                </>
              }
            </Button>
          )
        }}
      />
    )
  }

  const renderFundingSources = () => {
    return (
      <Fragment>
        <h5>Linked Accounts</h5>
        <Requestable
          withErrorHandling
          withoutLoading
          render={({isLoading, performRequest}) => {
            return (
              <Fragment>
                <Button className="indigo-button w-100" onClick={_ => performRequest(restApi.getPlaidPublicToken({id: currentUser.id}, ({data}) => setPlaidPublicToken(data.link_token)))}>
                  {
                    isLoading ? <Spinner animation="border" size="sm" className="mx-auto" />
                    : <Fragment>
                      <FontAwesomeIcon icon="plus" className='me-2' />
                      Link a bank account
                    </Fragment>
                  }
                </Button>
                {plaidPublicToken &&
                  <PlaidLinkWrapper
                    isLoading={isLoading}
                    performRequest={performRequest}
                    publicToken={plaidPublicToken}
                    setPlaidPublicToken={setPlaidPublicToken}
                    setFundingSources={setFundingSources}
                  />
                }
                {
                  fundingSources.length > 0 &&
                  _.map(fundingSources, fundingSource => <FundingSource key={fundingSource.id} setFundingSources={setFundingSources} {...fundingSource} />)
                }
              </Fragment>
            )
          }}
        />
      </Fragment>
    )
  }

  const ackDwollaCustomerCreationSuccess = ({data}) => {
    setCustomerId(data.customer_id)
    setOnboardingComplete(data.onboarding_complete)
    setBeneficialOwnersNeeded(data.needs_beneficial_ownership_certification)
    setCustomerDocumentsNeeded(data.needs_customer_documents)
    setCustomerRetryNeeded(data.needs_customer_info_retry)
    resetCurrentAction()
    dispatch(appendNotification({type: 'GenericSuccess', text: 'Customer Profile Created'}))
  }

  const ackDwollaCustomerCreation = (response, performRequest) => {
    if (response.location) {
      const dwollaCustomerUuid = _.split(response.location, '/').slice(-1)[0]
      performRequest(restApi.ackDwollaCustomerCreation({uuid: dwollaCustomerUuid}, ackDwollaCustomerCreationSuccess))
    } else {
      return response
    }
  }

  const ackDwollaBeneficialOwnerAction = (response, performRequest) => {
    if (response.status === 'certified') {
      performRequest(
        restApi.checkDwollaCustomerOnboardingStatus({uuid: customerId},
        ({data}) => {
          setOnboardingComplete(data.onboarding_complete)
          setBeneficialOwnersNeeded(false)
          resetCurrentAction()
          dispatch(appendNotification({type: 'GenericSuccess', text: 'Beneficial Owners Certified'}))
        })
      )
    }

    return response
  }

  const ackDwollaCustomerDocuments = (response, _performRequest) => {
    if (response.location) {
      resetCurrentAction()
      setCustomerDocumentsNeeded(false)
      dispatch(appendNotification({type: 'GenericSuccess', text: 'Document(s) Submitted'}))
    }

    return response
  }

  const prepareDwollaDropIn = (performRequest, dwollaSuccessCallback) => {
    if (dwollaDropIn.current) return
    const script = dwollaDropIn.current = document.createElement("script")
    script.src = "//cdn.dwolla.com/v2.1.9/dwolla-web.js"
    script.async = true
    script.onload = () => {
      dwolla.configure({
        environment: isDemoCompany ? "sandbox" : "production",
        token: req => Promise.resolve(performRequest(restApi.createDwollaClientToken({request_body: req}, ({data}) => data))),
        success: ({response}) => Promise.resolve(dwollaSuccessCallback(response, performRequest)),
        error: err => Promise.resolve(err),
      })
      setDwollaDropInLoaded(true)
    }
    document.body.appendChild(script)
  }

  const resetCurrentAction = () => {
    setCurrentAction()
    setDwollaDropInLoaded(false)
  }

  const renderDwollaCustomerCreation = () => {
    if (currentAction === "createCustomer") {
      return (
        <Requestable
          withoutLoading
          withErrorHandling
          render={({performRequest, isLoading}) => {
            prepareDwollaDropIn(performRequest, ackDwollaCustomerCreation)

            if (!dwollaDropInLoaded) return
            return (
              <Fragment>
                { isLoading && <Spinner animation="border" variant="secondary" className="mx-auto" /> }
                <div style={{display: isLoading ? 'none' : ''}}>
                  <dwolla-business-vcr
                    class="dwolla-drop-in"
                    correlationId={`company-${currentUser.last_used_company_id}`}
                    firstName={currentUser.first_name}
                    lastName={currentUser.last_name}
                    email={currentUser.email}
                  />
                  <Button variant='outline-danger' className="w-100 mt-2" onClick={_ => resetCurrentAction()}>
                    Cancel
                  </Button>
                </div>
              </Fragment>
            )
          }}
        />
      )
    } else {
      return (
        <Button className="indigo-button w-100" onClick={_ => setCurrentAction("createCustomer")}>
          Create Verified Business Account
          <Tooltip id="create-customer-tt" text="Create a Dwolla 'Verified Business Account' in order to pay carriers directly from Total Control">
            <FontAwesomeIcon className="ms-1" icon={['far', 'question-circle']} />
          </Tooltip>
        </Button>
      )
    }
  }

  const renderBeneficialOwnersDropIn = () => {
    if (currentAction === "beneficialOwners") {
      return (
        <Requestable
          withoutLoading
          withErrorHandling
          render={({performRequest, isLoading}) => {
            prepareDwollaDropIn(performRequest, ackDwollaBeneficialOwnerAction)

            if (!dwollaDropInLoaded) return
            return (
              <Fragment>
                { isLoading && <Spinner animation="border" variant="secondary" className="mx-auto" /> }
                <div style={{display: isLoading ? 'none' : ''}}>
                  <dwolla-beneficial-owners
                    class="dwolla-drop-in"
                    customerId={customerId}
                  />
                </div>
                <Button variant='outline-danger' className="w-100" onClick={_ => resetCurrentAction()}>
                  Cancel
                </Button>
              </Fragment>
            )
          }}
        />
      )
    } else {
      return (
        <Button className="indigo-button w-100 mb-2" onClick={_ => setCurrentAction("beneficialOwners")}>
          Certify Beneficial Owners
          <Tooltip id="beneficial-owners-tt" text="Corporations, LLCs, and Partnerships are required to verify the identity of any individuals with at least 25% ownership of the business. If this situation does not apply to you, please click this button to personally certify that there is no individual that meets this criteria. Otherwise, use this button to begin specifying those individuals.">
            <FontAwesomeIcon className="ms-1" icon={['far', 'question-circle']} />
          </Tooltip>
        </Button>
      )
    }
  }

  const renderCustomerDocumentsDropIn = () => {
    if (currentAction === "customerDocuments") {
      return (
        <Requestable
          withoutLoading
          render={({performRequest, isLoading}) => {
            prepareDwollaDropIn(performRequest, ackDwollaCustomerDocuments)

            if (!dwollaDropInLoaded) return
            return (
              <Fragment>
                { isLoading && <Spinner animation="border" variant="secondary" className="mx-auto" /> }
                <div style={{display: isLoading ? 'none' : ''}}>
                  <dwolla-document-upload
                    class="dwolla-drop-in"
                    customerId={customerId}
                  />
                </div>
                <Button variant='outline-danger' className="w-100 mt-2" onClick={_ => resetCurrentAction()}>
                  Cancel
                </Button>
              </Fragment>
            )
          }}
        />
      )
    } else {
      return (
        <Button className="indigo-button w-100" onClick={_ => setCurrentAction("customerDocuments")}>
          Upload Identifying Documents
          <Tooltip id="customer-documents-tt" text="Additional documents are needed to verify the identity of person(s) associated with this business account. Please upload these documents or review the status of previously uploaded documents here.">
            <FontAwesomeIcon className="ms-1" icon={['far', 'question-circle']} />
          </Tooltip>
        </Button>
      )
    }
  }

  return (
    <Fragment>
      <h4><b>Payments</b></h4>
      <Requestable
        onMountFetch={graphqlApi.execute(companyPaymentsSettingsQuery, companyPaymentsSettingsLoadSuccess)}
        render={() => {
          return (
            <Row className="g-0">
              <Col xs={12} lg={4} className="d-flex flex-column">
                {
                  !onboardingComplete &&
                  <Fragment>
                    {!_.isNull(customerId) && <Alert variant="warning">Account Creation Incomplete</Alert>}
                    {_.isNull(customerId) && renderDwollaCustomerCreation()}
                    {beneficialOwnersNeeded && renderBeneficialOwnersDropIn()}
                    {customerDocumentsNeeded && renderCustomerDocumentsDropIn()}
                  </Fragment>
                }
                {
                  onboardingComplete && renderDwollaBalance()
                }
                {
                  onboardingComplete && renderFundingSources()
                }
              </Col>
            </Row>
          )
        }}
      />
    </Fragment>
  )
}

export default PaymentsSettings
