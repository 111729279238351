import _ from 'lodash'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const RefinableColumns = {
  name: (companyId) => {
    return (
      {
        name: 'Name',
        accessor: tableItem => tableItem.name,
        sortable: true,
        converter: txt => _.lowerCase(txt),
        displayer: tableItem => <Link to={`/company/${companyId}/carriers/view/${tableItem.id || tableItem.objectID}`}>{tableItem.name}</Link>,
        asCardHeader: ({children, tableItem}) => <Link to={`/company/${companyId}/carriers/view/${tableItem.id || tableItem.objectID}`} className="card-header">{children}</Link>
      }
    )
  },
  valid: (companyId) => {
    return (
      {
        name: 'Valid',
        accessor: tableItem => _.has(tableItem, 'valid') ? tableItem.valid : tableItem.valid_for_companies_ids.includes(parseInt(companyId)),
        filterer: valid => valid ? 'Yes' : 'No',
        displayer: tableItem => {
          const valid = _.has(tableItem, 'valid') ? tableItem.valid : tableItem.valid_for_companies_ids.includes(parseInt(companyId))
          return valid ? <Fragment><FontAwesomeIcon className="success-green me-2" icon='check-circle' size='lg' /><b>Yes</b></Fragment> : <Fragment><FontAwesomeIcon className="error-red me-2" icon='exclamation-circle' size='lg' /><b>No</b></Fragment>
        },
        sortable: true,
        filterable: true,
        staticFilters: ['Yes', 'No']
      }
    )
  },
  mcNumber: {
    name: 'MC #',
    accessor: tableItem => tableItem.mc_number,
  },
  usDOT: {
    name: 'USDOT #',
    accessor: tableItem => tableItem.prefixed_dot,
  },
  city: {
    name: 'City',
    accessor: tableItem => tableItem.city,
    sortable: true,
    filterable: true
  },
  state: {
    name: 'State',
    accessor: tableItem => tableItem.state,
    sortable: true,
    filterable: true,
  },
  postal: {
    name: 'Postal',
    accessor: tableItem => tableItem.postal,
    sortable: true,
    filterable: true,
    converter: txt => parseInt(txt)
  }
}

export default RefinableColumns
