import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { FloatingLabel, Form, Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AppendableFieldsGroup from '../forms/AppendableFieldsGroup'
import CurrencyInput from '../forms/CurrencyInput'

const BOLCosts = React.memo(({formData, setFormData}) => {
  const [costCount, setCostCount] = useState(1)

  useEffect(() => {
    if (_.isEmpty(formData)) return
    setCostCount(_.size(formData))
  }, [formData])

  return (
    _.times(costCount, i => {
      return <AppendableFieldsGroup
        key={i}
        dataKey={i}
        groupName='costsList'
        group={formData}
        setState={setFormData}
        renderContent={
          ({getAppendableValue, removeAppendedFields, setAppendableValue}) => {
            return (
              <>
                <Row>
                  <Col md={6} xs={12}>
                    <FloatingLabel controlId='description' label="Description">
                      <Form.Control type="text" placeholder="Description" value={getAppendableValue('description')} onChange={e=>setAppendableValue(e)} />
                    </FloatingLabel>
                  </Col>
                  <Col>
                    <FloatingLabel controlId='amount' label="Amount $">
                      <CurrencyInput placeholder="Amount $" id="amount" className="form-control" value={getAppendableValue('amount')} onChange={e=>setAppendableValue(e)} />
                    </FloatingLabel>
                  </Col>
                </Row>
                {
                  i === costCount - 1 &&
                  <div className='d-flex mb-2'>
                    <FontAwesomeIcon icon='minus' size="lg" onClick={() => removeAppendedFields(i, setCostCount)} />
                    <FontAwesomeIcon icon='plus' size="lg" onClick={() => setCostCount(costCount + 1)} />
                  </div>
                }
              </>
            )
          }
        }
      />
    })
  )
})

export default BOLCosts
