import _ from 'lodash'
import React, { Fragment, useContext, useState } from 'react'
import { CloseButton, Button, Form, FloatingLabel, OverlayTrigger, Popover, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import GenericForm from '../forms/GenericForm'
import { restApi } from '../Api'
import { TruckSearchContext } from './TruckSearch'

function SearchResultActions({accepted, notes, postingId, rejected}) {
  const { bolId, setMatchesObj } = useContext(TruckSearchContext)
  const [editableNotes, setEditableNotes] = useState(notes)
  const [showNotesPopover, setShowNotesPopover] = useState(false)

  const handleSearchResultDecisionSuccess = (accepted, rejected) => {
    setMatchesObj(prevState => {
      return {
        ...prevState,
        [postingId]: {
          ...prevState[postingId],
          accepted: accepted,
          rejected: rejected
        }
      }
    })
  }

  const handleSearchResultNotesSuccess = () => {
    setShowNotesPopover(false)
    setMatchesObj(prevState => {
      return {
        ...prevState,
        [postingId]: {
          ...prevState[postingId],
          notes: editableNotes
        }
      }
    })
  }

  const notesPopover = (
    <Popover id={`search-result-${postingId}`}>
      <Popover.Header className="d-flex">
        {notes ? 'Edit' : 'Create'} Notes
        <CloseButton className="ms-auto" onClick={_ => setShowNotesPopover(false)} />
      </Popover.Header>
      <Popover.Body>
        <GenericForm
          formRequest={restApi.upsertSearchResultNote}
          params={{bolId: bolId, postId: postingId, notes: editableNotes}}
          handleSuccess={handleSearchResultNotesSuccess}
          formContent={({submitForm, isSubmitting}) => {
            return (
              <Fragment>
                <FloatingLabel
                  controlId="searchResultNotes"
                  label="Notes"
                >
                  <Form.Control autoFocus as="textarea" placeholder="Notes" value={editableNotes || ''} onChange={e=>setEditableNotes(e.target.value)} style={{height: '6rem'}} />
                </FloatingLabel>
                <Button disabled={_.isEmpty(editableNotes) && _.isEmpty(notes)} className="indigo-button w-100 mt-2" onClick={_ => submitForm()}>
                  {
                    isSubmitting ? <Spinner size="sm" animation="border" variant="light" />
                      : notes ? 'Update'
                      : 'Create'
                  }
                </Button>
              </Fragment>
            )
          }}
        />
      </Popover.Body>
    </Popover>
  )

  return (
    <div className="d-flex gap-3">
      <OverlayTrigger trigger="click" placement="auto" overlay={notesPopover} show={showNotesPopover} onToggle={setShowNotesPopover} rootClose>
        <span className="cursor-pointer position-relative d-flex align-items-center justify-content-center" style={{width: '1.35rem'}}>
          <FontAwesomeIcon className={`${notes ? 'fa-inverse' : ''}`} icon={['far', 'edit']} style={{fontSize: notes ? '0.9rem' : '1.35rem', zIndex: 1000}} />
          {notes &&  <FontAwesomeIcon className="position-absolute text-black" icon="circle" style={{fontSize: '1.35rem'}} />}
        </span>
      </OverlayTrigger>
      <GenericForm
        formRequest={restApi.makeSearchResultDecision}
        params={{bolId: bolId, postId: postingId, rejected: !rejected, accepted: false}}
        handleSuccess={() => handleSearchResultDecisionSuccess(false, !rejected)}
        formContent={({submitForm, isSubmitting}) => {
          return <FontAwesomeIcon className="cursor-pointer fa-fw error-red align-middle" icon={rejected ? "times-circle" : "times"} style={{fontSize: '1.35rem'}} onClick={_ => submitForm()} />
        }}
      />
      <GenericForm
        formRequest={restApi.makeSearchResultDecision}
        params={{bolId: bolId, postId: postingId, accepted: !accepted, rejected: false}}
        handleSuccess={() => handleSearchResultDecisionSuccess(!accepted, false)}
        formContent={({submitForm, isSubmitting}) => {
          return <FontAwesomeIcon className="cursor-pointer fa-fw success-green align-middle" icon={accepted ? "check-circle" : "check"} style={{fontSize: '1.35rem'}} onClick={_ => submitForm()} />
        }}
      />
    </div>
  )
}

export default SearchResultActions
