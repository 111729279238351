import _ from 'lodash'
import React, { Fragment, useMemo, useRef, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import BOLColumns from '../bol/BOLRefinableColumns'
import CardsList from '../generic/CardsList'
import Graphql from '../Graphql'
import { graphqlApi } from '../Api'
import InteractableTable from '../generic/InteractableTable'
import LoadPostingEditor from './LoadPostingEditor'
import RefinableList from '../generic/RefinableList'
import RefinableListSettingsModal from '../generic/RefinableListSettingsModal'
import Requestable from '../generic/Requestable'
import useScreenWidth from '../utils/useScreenWidth'
import { MINIMUM_SCREEN_WIDTH_FOR_TABLE_VIEW } from '../generic/interactableTableConstraints'
import Tooltip from '../generic/Tooltip'

const companyOpenBOLsQuery = Graphql.company({
                               fields: [
                                 Graphql.relatedBOLs({
                                   filters: {status__in: ['OPEN']},
                                   fields: [
                                     ...['id', 'company_id', 'number', 'origin_id', 'origin_name', 'pick_up_date', 'pick_up_time', 'delivery_date', 'delivery_time'],
                                     Graphql.relatedLoad({fields: [
                                                            'id',
                                                            'created_at',
                                                            'load_boards',
                                                            'offer_rate',
                                                            'dat_offer_rate',
                                                            Graphql.relatedCreator({fields: ['first_name', 'last_name']})
                                                          ]}),
                                     Graphql.relatedOrigin({fields: ['name', 'time_zone']}),
                                     Graphql.relatedReceivers({fields: ['id', 'name']})
                                   ]
                                 })
                               ]
                             })

function LoadPostings(props) {
  const { companyId } = useParams()
  const [loadPostings, setLoadPostings] = useState([])
  const postablesLoadBoardsMap = useRef({})
  const preferencedColumns  = useSelector(state => _.get(state.user.currentUser, 'settings.loadPostingsColumns', []))
  const selectedPostables = useRef({})
  const [selectedPostablesCount, setSelectedPostablesCount] = useState(0)
  const [showEditor, setShowEditor] = useState(false)
  const screenWidth = useScreenWidth()

  const selectAllPostablesCheckbox = (currentlyViewedPostables, columnRerenderCallback) => {
    const updateSelectedPostables = (isChecked) => {
      if (isChecked) {
        _.forIn(currentlyViewedPostables, postable => {
          selectedPostables.current[postable.id || postable.objectID] = true
        })
        setSelectedPostablesCount(currentlyViewedPostables.length)
      } else {
        selectedPostables.current = {}
        setSelectedPostablesCount(0)
      }
      columnRerenderCallback()
    }

    return <Form.Check type="checkbox" className="select-all-check" onChange={e => updateSelectedPostables(e.target.checked)} />
  }

  const selectableColumns = {
    number: BOLColumns.number(companyId),
    numLoadBoards: BOLColumns.numLoadBoards(selectedPostables, setSelectedPostablesCount, selectAllPostablesCheckbox, postablesLoadBoardsMap, setShowEditor),
    loadOfferRate: BOLColumns.loadOfferRate,
    loadPostedAt: BOLColumns.loadPostedAt,
    origin: BOLColumns.origin,
    finalReceiver: BOLColumns.finalReceiver(companyId),
    loadCreatedBy: BOLColumns.loadCreatedBy,
    pickUpDate: BOLColumns.pickUpDate,
    pickUpTime: BOLColumns.pickUpTime,
    deliveryDate: BOLColumns.deliveryDate,
    deliveryTime: BOLColumns.deliveryTime
  }

  const companyOpenBOLsLoadSuccess = (res) => {
    postablesLoadBoardsMap.current = _.transform(Graphql.getValue(res, 'company.bols'), (result, bol) => {
                                  let load = bol.load
                                  if (load) {
                                    result[bol.id] = {load_boards: load.load_boards, offer_rate: load.offer_rate, dat_offer_rate: load.dat_offer_rate}
                                  }
                                }, {})
    setLoadPostings(Graphql.getValue(res, 'company.bols'))
  }

  const loadEditorButton = useMemo(() => {
    const noneSelected = selectedPostablesCount === 0
    let editing = false
    let creating = false
    const likeLoadsSelected = _.isEqual(
                                _.keys(
                                  _.transform(selectedPostables.current, (result, _value, bolId) => {
                                    if (_.isEmpty(postablesLoadBoardsMap.current[bolId])) {
                                      creating = true
                                    } else {
                                      editing = true
                                      _.forIn(postablesLoadBoardsMap.current[bolId].load_boards, loadBoard => {result[loadBoard] = true})
                                    }
                                    return !(creating && editing)
                                  }, {})
                                ),
                                _.get(postablesLoadBoardsMap.current[_.keys(selectedPostables.current)[0]], 'load_boards', [])
                              )

    const canPerformAction = !noneSelected && !(creating && editing) && likeLoadsSelected
    if (canPerformAction) {
      return (
        <Button className="indigo-button" onClick={e => setShowEditor(uuidv4())}>
          {`${editing ? 'Edit' : 'Post'} ${selectedPostablesCount > 1 ? `${selectedPostablesCount} Loads` : 'Load'}`}
        </Button>
      )
    } else {
      return (
        <Tooltip id="post-loads-button-tooltip" show={creating && editing} text="Cannot post new loads and edit existing loads at the same time" >
          <span><Button disabled className="indigo-button h-100 w-100 mt-md-0 mt-2">Select Loads</Button></span>
        </Tooltip>
      )
    }
  }, [selectedPostablesCount])

  const renderTable = () => {
    return (
      <RefinableList
        Renderer={InteractableTable}
        initialSelectedColumns={_.values(_.pick(selectableColumns, preferencedColumns))}
        initialSortedColumn="BOL #"
        selectableColumns={_.values(selectableColumns)}
        draggableType="loadPostingsColumn"
        searchable
        searchPlaceholder="Search Load Postings"
        searchIndex={`tc_bols_${process.env.NODE_ENV}`}
        searchFilters={`company_id = ${companyId} AND status:OPEN`}
        searchClass="ps-0"
        items={loadPostings}
        rightOfSearchContent={<Col className="d-flex justify-content-end" xs={{ span: 2, offset: 6 }}>{loadEditorButton}</Col>}
        settings={<RefinableListSettingsModal selectableColumns={selectableColumns} userPreferencedColumns={preferencedColumns} refinableListSettingsName='loadPostingsColumns' />}
        pageSize={25}
      />
    )
  }

  const renderCards = () => {
    return (
      <RefinableList
        Renderer={CardsList}
        cardHeaderColumn={selectableColumns.number}
        initialSelectedColumns={_.values(_.pick(selectableColumns, _.without(preferencedColumns, 'number')))}
        initialSortedColumn="BOL #"
        selectableColumns={_.values(selectableColumns)}
        searchable
        searchPlaceholder="Search Load Postings"
        searchIndex={`tc_bols_${process.env.NODE_ENV}`}
        searchFilters={`company_id = ${companyId} AND status:OPEN`}
        items={loadPostings}
        belowSearchContent={loadEditorButton}
        settings={<RefinableListSettingsModal selectableColumns={selectableColumns} userPreferencedColumns={preferencedColumns} refinableListSettingsName='loadPostingsColumns' />}
        pageSize={10}
      />
    )
  }

  return (
    <Requestable
      onMountFetch={graphqlApi.execute(companyOpenBOLsQuery, companyOpenBOLsLoadSuccess)}
      render={() => {
          if (preferencedColumns.length > 0) {
            return (
              <Fragment>
                <Row className="form-sub-header g-0">
                  <h5>Load Postings</h5>
                </Row>
                {screenWidth > MINIMUM_SCREEN_WIDTH_FOR_TABLE_VIEW ? renderTable() : renderCards()}
                {showEditor &&
                  <LoadPostingEditor
                    key={showEditor}
                    initialSelectedPostables={_.keys(selectedPostables.current)}
                    setShowEditor={setShowEditor}
                    postablesLoadBoardsMap={postablesLoadBoardsMap}
                  />
                }
              </Fragment>
            )
          }
        }
      }
    />
  )
}

export default LoadPostings
