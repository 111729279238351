import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function EmailFailed() {
  return (
    <div className="pt-3">
      <div className="d-flex justify-content-center">
        <FontAwesomeIcon className="error-red" icon='exclamation-circle' size='2x' />
      </div>
      <div className="d-flex justify-content-center text-center fw-bold mt-1">
        Email failed to send
      </div>
    </div>
  )
}

export default EmailFailed
