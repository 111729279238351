import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import HeaderErrors from '../errorHandling/HeaderErrors'
import InvoiceForm from './InvoiceForm'
import { restApi } from '../Api'

const CarrierInvoiceUpload = () => {
  const [formData, setFormData] = useState(useParams())
  const [uploadComplete, setUploadComplete] = useState(false)

  return (
    <Container id="landing-page" fluid className="d-flex flex-column">
      <HeaderErrors />
      <Row className="justify-content-center h-100">
        <Col xl={4} xs={11} className="bg-light my-auto rounded">
          {
            uploadComplete ?
            <div className="d-flex flex-column p-4 gap-2">
              <h4 className="mx-auto"><b>Invoice uploaded successfully</b></h4>
              <FontAwesomeIcon className="success-green fa-beat" icon="check-circle" size="5x" style={{'--fa-animation-iteration-count': 1}} />
            </div>
            :
            <InvoiceForm
              confirmMC={true}
              formData={formData}
              formRequest={restApi.uploadCarrierInvoice}
              setFormData={setFormData}
              successHandler={_ => setUploadComplete(true)}
            />
          }
        </Col>
      </Row>
    </Container>
  )
}

export default CarrierInvoiceUpload
