import { createSlice } from '@reduxjs/toolkit'

let initialState = {
  errors: []
}

// https://redux-toolkit.js.org/api/createAsyncThunk

const errorsSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    setErrors(state, action) {
      state.errors = action.payload
    },
    clearErrors(state, _action) {
      state.errors = []
    }
  },
  extraReducers: {

  }
})

export const {
  setErrors,
  clearErrors
} = errorsSlice.actions

export default errorsSlice.reducer
