import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { Fragment, useEffect, useState } from 'react'
import { Alert, Button, CloseButton, Col, FloatingLabel, Form, Modal, Nav, Row, Spinner, Tab, Tabs } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { appendNotification } from '../notifications/notificationsSlice'
import { companyLoadPostOptionsQuery } from '../settings/LoadPostings'
import GenericForm from '../forms/GenericForm'
import Graphql from '../Graphql'
import { graphqlApi } from '../Api'
import OfferRateEditor from './OfferRateEditor'
import RateLookup from './RateLookup'
import Requestable from '../generic/Requestable'
import { rerenderListeningColumns } from '../generic/interactableTableSlice'
import { restApi } from '../Api'
import { setConfirmableAction } from '../generic/confirmableActionSlice'
import { setErrors } from '../errorHandling/errorsSlice'
import StateOptions from '../utils/StateOptions'
import Tooltip from '../generic/Tooltip'

const LOAD_FIELDS = ['id', 'load_boards', 'offer_rate', 'dat_offer_rate']

const companyBOLsQuery = (bolIds, relevantLoadDatas) => {
  const operatingOnSingleBOL = bolIds.length === 1

  return (
    Graphql.company({
      fields: [
        Graphql.relatedBOLs({
          filters: {id__in: _.map(bolIds, n => parseInt(n, 10))},
          fields: [
            ...['id', 'number', ...operatingOnSingleBOL ? _.concat(['version', 'delivery_date', 'delivery_time', 'equipment_length', 'equipment_type', 'pick_up_date', 'pick_up_time', 'weight'], relevantLoadDatas) : []],
            Graphql.relatedLoad({fields: LOAD_FIELDS}),
            ...operatingOnSingleBOL ? [Graphql.relatedOrigin({fields: ['name', 'city', 'state', 'postal']})] : [],
            ...operatingOnSingleBOL ? [Graphql.relatedReceivers({fields: ['id', 'name', 'city', 'state', 'postal']})] : []
          ]
        })
      ]
    })
  )
}

const generatePostActionErrorsContent = (failedActions, bols, verbiage) => {
  return _.transform(failedActions, (result, load) => {
    result.push(
      <Fragment>
        <div><b>{`BOL #${bols[load.bol_id].number}`}</b>{`: Failed to ${verbiage} the following load boards - ${_.join(_.keys(load.errors), ', ')}`}</div>
        {
          _.map(load.errors, (errors, loadBoard) => {
            return (
              <div key={`${loadBoard}-action-errors`}>&emsp;{`${loadBoard} Errors: ${_.join(errors, ', ')}`}</div>
            )
          })
        }
      </Fragment>
    )
  }, [])
}

const generateFailedFetchesErrorsContent = failedFetches => {
  return _.map(failedFetches, (errors, loadBoard) => {
    return (
      <div key={`${loadBoard}-fetch-errors`}>{loadBoard} - {_.join(errors, ', ')}</div>
    )
  })
}

const transformThirdPartyLoad = (loadBoard, bol) => {
  const loadData = _.get(bol, `${_.lowerCase(loadBoard)}LoadData`)

  switch (loadBoard) {
    case 'DAT':
      return {
        destinationCity: _.get(loadData.lane.destination, 'city'),
        destinationState: _.get(loadData.lane.destination, 'stateProv'),
        destinationPostal: _.get(loadData.lane.destination, 'postalCode'),
        originCity: loadData.lane.origin.city,
        originState: loadData.lane.origin.stateProv,
        originPostal: loadData.lane.origin.postalCode,
      }
    default:
      return
  }
}

function DATLoad({bols, ratesData, setRatesData, loadIds, setActiveTab, editingExistingLoads, handleBOLLoadsCreateOrUpdate, handleBOLLoadsDelete, loadPostingsSettings}) {
  const dispatch = useDispatch()
  const [showRateLookupModal, setShowRateLookupModal] = useState(false)
  const [formData, setFormData] = useState({bolIds: _.keys(bols)})
  const [datEquipmentTypeMap, setDatEquipmentTypeMap] = useState({})
  const [datContactMethodMap, setDatContactMethodMap] = useState({})
  const comment1Chars = _.size(formData.comment1)
  const comment2Chars = _.size(formData.comment2)
  const commodityChars = _.size(formData.commodityDescription)

  useEffect(() => {
    const datLoadData = bols[formData.bolIds[0]].datLoadData
    if (_.size(bols) === 1 && datLoadData) {
      const dropOffHoursArray = _.split(datLoadData.freight.dropOffHours, ' ')
      const dropOffHoursStr = `${dropOffHoursArray[3]} ${dropOffHoursArray[4]}`

      setFormData(prevState => {
        const earliestAvailabilityWhen = DateTime.fromISO(datLoadData.exposure.earliestAvailabilityWhen, {zone: 'utc'})
        const latestAvailabilityWhen = DateTime.fromISO(datLoadData.exposure.latestAvailabilityWhen, {zone: 'utc'})
        const startWhen = DateTime.fromISO(datLoadData.exposure.startWhen, {zone: 'utc'})
        const endWhen = DateTime.fromISO(datLoadData.exposure.endWhen, {zone: 'utc'})

        return {
          ...prevState,
          ...transformThirdPartyLoad('DAT', bols[formData.bolIds[0]]),
          dropOffDate: DateTime.fromFormat(dropOffHoursArray[1], 'MM-dd-yyyy').toFormat('yyyy-MM-dd'),
          dropOffTime: dropOffHoursArray[3] ? DateTime.fromFormat(dropOffHoursStr, 't').toFormat('HH:mm:ss') : null,
          equipmentLength: datLoadData.freight.lengthFeet,
          equipmentTypeRequired: datLoadData.freight.equipmentType,
          earliestPickupDate: earliestAvailabilityWhen.toFormat('yyyy-MM-dd'),
          earliestPickupTime: earliestAvailabilityWhen.toFormat('HH:mm:ss'),
          latestPickupDate: latestAvailabilityWhen.toFormat('yyyy-MM-dd'),
          latestPickupTime: latestAvailabilityWhen.toFormat('HH:mm:ss'),
          weight: datLoadData.freight.weightPounds,
          ...(editingExistingLoads && {
            comment1: _.get(datLoadData.freight.comments, '[0].comment', ''),
            comment2: _.get(datLoadData.freight.comments, '[1].comment', ''),
            commodityDescription: _.get(datLoadData.freight.commodity, 'details', ''),
            offerRate: datLoadData.exposure.audience.loadBoard.transactionDetails.loadOfferRateUsd,
            postStartDate: startWhen.toFormat('yyyy-MM-dd'),
            postStartTime: startWhen.toFormat('HH:mm:ss'),
            postEndDate: endWhen.toFormat('yyyy-MM-dd'),
            postEndTime: endWhen.toFormat('HH:mm:ss'),
            preferredContactMethod: datLoadData.exposure.preferredContactMethod
          }),
          ...(!editingExistingLoads && {
            comment1: loadPostingsSettings.dat_default_comment_1,
            comment2: loadPostingsSettings.dat_default_comment_2,
            commodityDescription: loadPostingsSettings.dat_default_commodity_description,
            offerRate: '',
            postStartDate: '',
            postStartTime: '',
            postEndDate: '',
            postEndTime: '',
            preferredContactMethod: loadPostingsSettings.dat_preferred_contact_method
          })
        }
      })
    }
  }, [JSON.stringify(bols[formData.bolIds[0]].datLoadData)]) // eslint-disable-line

  const handleDATFormOptionsFetchSuccess = res => {
    const transformedData = Graphql.transformData(res.data.data)
    setDatEquipmentTypeMap(transformedData.datLoadPostOptions.equipment_types)
    setDatContactMethodMap(transformedData.datLoadPostOptions.contact_methods)
  }

  const handleLoadsCreateAndUpdate = res => {
    const [successfulLoads,] = handleBOLLoadsCreateOrUpdate(res.data, !editingExistingLoads)

    if (successfulLoads.length) {
      const earliestAvailabilityWhen = DateTime.fromISO(res.data[0].datLoadData.exposure.earliestAvailabilityWhen, {zone: 'utc'})
      const latestAvailabilityWhen = DateTime.fromISO(res.data[0].datLoadData.exposure.latestAvailabilityWhen, {zone: 'utc'})
      const startWhen = DateTime.fromISO(res.data[0].datLoadData.exposure.startWhen, {zone: 'utc'})
      const endWhen = DateTime.fromISO(res.data[0].datLoadData.exposure.endWhen, {zone: 'utc'})

      setFormData(prevState => {
        return {
          ...prevState,
          ...(_.size(bols) === 1 && {
            offerRate: res.data[0].dat_offer_rate,
            earliestPickupDate: earliestAvailabilityWhen.toFormat('yyyy-MM-dd'),
            earliestPickupTime: earliestAvailabilityWhen.toFormat('HH:mm:ss'),
            latestPickupDate: latestAvailabilityWhen.toFormat('yyyy-MM-dd'),
            latestPickupTime: latestAvailabilityWhen.toFormat('HH:mm:ss'),
            postStartDate: startWhen.toFormat('yyyy-MM-dd'),
            postStartTime: startWhen.toFormat('HH:mm:ss'),
            postEndDate: endWhen.toFormat('yyyy-MM-dd'),
            postEndTime: endWhen.toFormat('HH:mm:ss'),
          })
        }
      })
    }
  }

  const handleLoadsDelete = res => {
    handleBOLLoadsDelete(res.data)
    setActiveTab('allLoadBoards')
  }

  const formContent = ({handleFormChange, isSubmitting}) => {
    return (
      <Fragment>
        <OfferRateEditor ratesData={ratesData} setRatesData={setRatesData} formData={formData} setFormData={setFormData} bols={bols} editingExistingLoads={editingExistingLoads} />
        <div className="mb-2">
          <h5 className="mb-0"><b>Lane Information</b></h5>
          {
            _.size(bols) === 1 &&
              <Fragment>
                <div>
                  <span className="text-primary cursor-pointer" onClick={() => setShowRateLookupModal(true)}>Look Up Lane Rate Data</span>
                </div>
                <Modal show={showRateLookupModal} onHide={setShowRateLookupModal} dialogClassName="modal-90w" backdrop='static'>
                  <Modal.Header closeButton />
                  <Modal.Body>
                    <RateLookup initOriginCity={formData.originCity} initOriginState={formData.originState} initOriginPostal={formData.originPostal} initDestinationCity={formData.destinationCity} initDestinationState={formData.destinationState} initDestinationPostal={formData.destinationPostal} initEquipment={bols[formData.bolIds[0]].equipmentType} />
                  </Modal.Body>
                </Modal>
              </Fragment>
          }
        </div>
        <Row>
          <Col md={4} xs={12}>
            <FloatingLabel controlId="originCity" label="Origin City">
              <Form.Control disabled={editingExistingLoads} type="text" placeholder="Origin City" value={formData.originCity || ''} onChange={e=>handleFormChange(setFormData, e)} />
            </FloatingLabel>
          </Col>
          <Col md={4} xs={12}>
            <FloatingLabel controlId="originState" label="Origin State">
              <Form.Select disabled={editingExistingLoads} value={formData.originState} onChange={e=>handleFormChange(setFormData, e)} >
                <StateOptions />
              </Form.Select>
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel controlId="originPostal" label="Origin Postal">
              <Form.Control disabled={editingExistingLoads} type="text" placeholder="Origin Postal" value={formData.originPostal || ''} onChange={e=>handleFormChange(setFormData, e)} />
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col md={4} xs={12}>
            <FloatingLabel controlId="destinationCity" label="Destination City">
              <Form.Control disabled={editingExistingLoads} type="text" placeholder="Destination City" value={formData.destinationCity || ''} onChange={e=>handleFormChange(setFormData, e)} />
            </FloatingLabel>
          </Col>
          <Col md={4} xs={12}>
            <FloatingLabel controlId="destinationState" label="Destination State">
              <Form.Select disabled={editingExistingLoads} value={formData.destinationState} onChange={e=>handleFormChange(setFormData, e)} >
                <StateOptions />
              </Form.Select>
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel controlId="destinationPostal" label="Destination Postal">
              <Form.Control disabled={editingExistingLoads} type="text" placeholder="Destination Postal" value={formData.destinationPostal || ''} onChange={e=>handleFormChange(setFormData, e)} />
            </FloatingLabel>
          </Col>
        </Row>
        <div>
          <h5><b>Freight Details</b></h5>
        </div>
        <Row>
          <Col md={4} xs={12}>
            <FloatingLabel controlId="equipmentTypeRequired" label="Equipment Type Required">
              <Form.Select disabled={editingExistingLoads} value={formData.equipmentTypeRequired} onChange={e=>handleFormChange(setFormData, e)} >
                {
                  _.map(datEquipmentTypeMap, (value, key) => {
                    return <option key={key} value={key}>{_.startCase(_.lowerCase(value))}</option>
                  })
                }
              </Form.Select>
            </FloatingLabel>
          </Col>
          <Col md={4} xs={12}>
            <FloatingLabel controlId="equipmentLength" label="Equipment Length">
              <Form.Control type="number" placeholder="Equipment Length" value={formData.equipmentLength || ''} onChange={e=>handleFormChange(setFormData, e)} />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel controlId="weight" label="Weight">
              <Form.Control type="number" placeholder="Weight" value={formData.weight || ''} onChange={e=>handleFormChange(setFormData, e)} />
            </FloatingLabel>
          </Col>
        </Row>
        <div className="mb-2">
          <h5 className="mb-0"><b>Exposure</b></h5>
          <i>Times shown in respect to the <b>origin's</b> time zone</i>
        </div>
        <Row>
          <Col xl={4} md={8} xs={12}>
            <div className="d-flex">
              <Form.Floating className="sbs-datetime">
                <Form.Control id="postStartDate" disabled={editingExistingLoads} required={!_.isEmpty(formData.postStartTime)} type="date" value={formData.postStartDate || ''} onChange={e=>handleFormChange(setFormData, e)} />
                <label htmlFor="postStartDate" className="pe-auto">
                  Post Start Date
                  <Tooltip id="post-start-tooltip" text="Specify the date and time that the post will become visible to target audience. Defaults to the time at which the post is created.">
                    <FontAwesomeIcon icon={['far', 'question-circle']} className="ms-1" />
                  </Tooltip>
                </label>
                <Form.Control.Feedback type="invalid">Post Start Date required when Post Start Time is specified</Form.Control.Feedback>
              </Form.Floating>
              <FloatingLabel controlId="postStartTime" label="Post Start Time" className="sbs-datetime ms-auto">
                <Form.Control disabled={editingExistingLoads} type="time" value={formData.postStartTime || ''} onChange={e=>handleFormChange(setFormData, e)} />
              </FloatingLabel>
            </div>
            <div className="d-flex">
              <Form.Floating className="sbs-datetime">
                <Form.Control id="postEndDate" disabled={editingExistingLoads} required={!_.isEmpty(formData.postEndTime)} type="date" value={formData.postEndDate || ''} onChange={e=>handleFormChange(setFormData, e)} />
                <label htmlFor="postEndDate" className="pe-auto">
                  Post End Date
                  <Tooltip id="post-end-tooltip" text="Specify the date and time that the post will expire. Defaults to 8 days after post start date or the date & time which the post is last available for pickup.">
                    <FontAwesomeIcon icon={['far', 'question-circle']} className="ms-1" />
                  </Tooltip>
                </label>
                <Form.Control.Feedback type="invalid">Post End Date required when Post End Time is specified</Form.Control.Feedback>
              </Form.Floating>
              <FloatingLabel controlId="postEndTime" label="Post End Time" className="sbs-datetime ms-auto">
                <Form.Control disabled={editingExistingLoads} type="time" value={formData.postEndTime || ''} onChange={e=>handleFormChange(setFormData, e)} />
              </FloatingLabel>
            </div>
            <div className="d-flex">
              <Form.Floating className="sbs-datetime">
                <Form.Control id="earliestPickupDate" required={!_.isEmpty(formData.earliestPickupTime)} type="date" value={formData.earliestPickupDate || ''} onChange={e=>handleFormChange(setFormData, e)} />
                <label htmlFor="earliestPickupDate" className="pe-auto">
                  Earliest Pick-Up Date
                  <Tooltip id="post-earliest-pickup-tooltip" text="Specify the date and time the load is available for pick-up. Defaults to the pick-up time specified on the BOL.">
                    <FontAwesomeIcon icon={['far', 'question-circle']} className="ms-1" />
                  </Tooltip>
                </label>
                <Form.Control.Feedback type="invalid">Earliest Pick-Up Date required when Earliest Pick-Up Time is specified</Form.Control.Feedback>
              </Form.Floating>
              <FloatingLabel controlId="earliestPickupTime" label="Earliest Pick-Up Time" className="sbs-datetime ms-auto">
                <Form.Control type="time" value={formData.earliestPickupTime || ''} onChange={e=>handleFormChange(setFormData, e)} />
              </FloatingLabel>
            </div>
            <div className="d-flex">
              <Form.Floating className="sbs-datetime">
                <Form.Control id="latestPickupDate" required={!_.isEmpty(formData.latestPickupTime)} type="date" value={formData.latestPickupDate || ''} onChange={e=>handleFormChange(setFormData, e)} />
                <label htmlFor="latestPickupDate" className="pe-auto">
                  Latest Pick-Up Date
                  <Tooltip id="post-latest-pickup-tooltip" text="Specify the LATEST date and time the load is available for pick-up. The value is always adjusted to the end-of-day PACIFIC TIME of the date given OR earliest pick-up date.">
                    <FontAwesomeIcon icon={['far', 'question-circle']} className="ms-1" />
                  </Tooltip>
                </label>
                <Form.Control.Feedback type="invalid">Latest Pick-Up Date required when Latest Pick-Up Time is specified</Form.Control.Feedback>
              </Form.Floating>
              <FloatingLabel controlId="latestPickupTime" label="Latest Pick-Up Time" className="sbs-datetime ms-auto">
                <Form.Control disabled type="time" value={formData.latestPickupTime || ''} onChange={e=>handleFormChange(setFormData, e)} />
              </FloatingLabel>
            </div>
          </Col>
          <Col xl={8} md={4} xs={12}>
            <Tab.Container defaultActiveKey="public">
              <Row>
                <Col xl={6} xs={12}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item className="cursor-pointer">
                      <Nav.Link eventKey="public">Publicly Visible</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="cursor-pointer">
                      <Nav.Link eventKey="private">Private Network</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col>
                  <Tab.Content className="mb-md-0 mb-2">
                    <Tab.Pane eventKey='private'>
                      <b>Private Network postings are currently unavailable.</b>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>
        <div className="mb-2">
          <h5 className="mb-0"><b>Additional Details</b></h5>
          <i>Times shown in respect to the <b>receiver's</b> time zone</i>
        </div>
        <Row>
          <Col md={4} xs={12}>
            <div className="d-flex">
              <Form.Floating className="sbs-datetime">
                <Form.Control id="dropOffDate" required={!_.isEmpty(formData.dropOffTime)} type="date" value={formData.dropOffDate || ''} onChange={e=>handleFormChange(setFormData, e)} />
                <label htmlFor="dropOffDate" className="pe-auto">
                  Drop-off Date
                  <Tooltip id="post-dropoff-tooltip" text="Specify the date and time the load should be delivered by. Defaults to the delivery date & time specified on the BOL.">
                    <FontAwesomeIcon icon={['far', 'question-circle']} className="ms-1" />
                  </Tooltip>
                </label>
                <Form.Control.Feedback type="invalid">Drop-off Date required when Drop-off Time is specified</Form.Control.Feedback>
              </Form.Floating>
              <FloatingLabel controlId="dropOffTime" label="Drop-off Time" className="sbs-datetime ms-auto">
                <Form.Control type="time" value={formData.dropOffTime || ''} onChange={e=>handleFormChange(setFormData, e)} />
              </FloatingLabel>
            </div>
            <FloatingLabel controlId='preferredContactMethod' label="Preferred Contact Method">
              <Form.Select value={formData.preferredContactMethod} onChange={e=>handleFormChange(setFormData, e)} >
                {
                  _.map(datContactMethodMap, (value, key) => {
                    return <option key={key} value={key}>{_.startCase(_.lowerCase(value))}</option>
                  })
                }
              </Form.Select>
            </FloatingLabel>
          </Col>
          <Col md={4} xs={12}>
            <Form.Floating>
              <Form.Control id="comment1" as="textarea" placeholder="Comment #1" value={formData.comment1 || ''} onChange={e=>handleFormChange(setFormData, e)} />
              <label htmlFor="comment1" className="w-100 d-flex">
                {
                  comment1Chars === 0 ? "Comment #1"
                    : <Fragment><span>Comment #1</span><span className="ms-auto">{`${70 - comment1Chars} characters remaining`}</span></Fragment>
                }
              </label>
            </Form.Floating>
            <Form.Floating>
              <Form.Control id="comment2" as="textarea" placeholder="Comment #2" value={formData.comment2 || ''} onChange={e=>handleFormChange(setFormData, e)} />
              <label htmlFor="comment2" className="w-100 d-flex">
                {
                  comment2Chars === 0 ? "Comment #2"
                    : <Fragment><span>Comment #2</span><span className="ms-auto">{`${70 - comment2Chars} characters remaining`}</span></Fragment>
                }
              </label>
            </Form.Floating>
          </Col>
          <Col>
            <Form.Floating>
              <Form.Control id="commodityDescription" as="textarea" placeholder="Freight Description" value={formData.commodityDescription || ''} onChange={e=>handleFormChange(setFormData, e)} />
              <label htmlFor="commodityDescription" className="w-100 d-flex">
                {
                  commodityChars === 0 ? "Freight Description"
                    : <Fragment><span>Freight Description</span><span className="ms-auto">{`${70 - commodityChars} characters remaining`}</span></Fragment>
                }
              </label>
            </Form.Floating>
          </Col>
        </Row>
        <Button className="w-100 indigo-button" type="submit">
          {
            isSubmitting ? <Spinner size="sm" animation="border" variant="light" />
              : editingExistingLoads ? 'Update'
              : 'Post'
          }
        </Button>
      </Fragment>
    )
  }

  return (
    <>
      <Requestable requestOnly onMountFetch={graphqlApi.execute(Graphql.datLoadPostOptions, handleDATFormOptionsFetchSuccess)} />
      <GenericForm formContent={formContent} formRequest={editingExistingLoads ? restApi.updateDATLoads : restApi.createDATLoads} params={_.merge({loadIds: loadIds}, formData)} handleSuccess={handleLoadsCreateAndUpdate} />
      {
        editingExistingLoads &&
        <Requestable
          withoutLoading
          render={({isLoading, performRequest}) => {
            return (
              <Button variant="danger" className="w-100 mt-2" onClick={() => dispatch(setConfirmableAction({performAction: performRequest, action: restApi.deleteDATLoads({loadIds: loadIds}, handleLoadsDelete), details: {title: 'Confirm DAT Load Posting(s) Deletion', confirmationBody: <div>Please confirm that you wish to remove load posting(s) from DAT Load Board.</div>}}))}>
                {isLoading ? <Spinner size="sm" animation="border" variant="light" /> : 'Delete'}
              </Button>
            )
          }}
        />
      }
    </>
  )
}

const ALL_LOAD_BOARDS = ['DAT']
const ALL_LOAD_DATA_KEYS = ['datLoadData']
const ALL_LOAD_RATE_KEYS = ['dat_offer_rate']

function MultiLoadBoard({bols, ratesData, setRatesData, loadIds, loadBoardsPostedTo, handleBOLLoadsCreateOrUpdate, handleBOLLoadsDelete}) {
  const dispatch = useDispatch()
  const [showRateLookupModal, setShowRateLookupModal] = useState(false)
  const [formData, setFormData] = useState({
    bolIds: _.keys(bols),
    loadBoards: [],
    // If we're editing a single BOL, display its offer rate if set
    ...(_.size(bols) === 1 && {
      offerRate: _.get(bols[_.keys(bols)[0]], 'load.offer_rate')
    })
  })

  const existingAndNewSelected = _(_.intersection(formData.loadBoards, loadBoardsPostedTo)).thru(intersection => {
    return intersection.length >= 1 && formData.loadBoards.length > intersection.length
  }).value()
  const onlyNewSelected = _.difference(formData.loadBoards, loadBoardsPostedTo).length === formData.loadBoards.length
  const canCreateOrUpdate = formData.loadBoards.length > 0 && !existingAndNewSelected
  const canDelete = formData.loadBoards.length > 0 && !existingAndNewSelected && !onlyNewSelected

  // Update formData to include origin/destination info
  useEffect(() => {
    if (formData.bolIds.length === 1) {
      const bol = bols[formData.bolIds[0]]

      if (onlyNewSelected) {
        const lastReceiver = _.last(bol.receivers)
        setFormData(prevState => {
          return {
            ...prevState,
            ...(lastReceiver && {
              destinationCity: lastReceiver.city,
              destinationState: lastReceiver.state,
              destinationPostal: lastReceiver.postal,
            }),
            originCity: bol.origin.city,
            originState: bol.origin.state,
            originPostal: bol.origin.postal,
          }
        })
      } else if (formData.loadBoards.length === 1) {
        setFormData(prevState => {
          return {
            ...prevState,
            ...transformThirdPartyLoad(formData.loadBoards[0], bol)
          }
        })
      } else {
        // TODO - If all boards posted-to share origin/destination
        setFormData(prevState => _.omit(prevState, ['destinationCity', 'destinationState', 'destinationPostal', 'originCity', 'originState', 'originPostal']))
      }
    }
  }, [formData.loadBoards]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleLoadsCreateAndUpdate = res => {
    const [successfulLoads,] = handleBOLLoadsCreateOrUpdate(res.data, onlyNewSelected)

    if (successfulLoads.length) {
      setFormData({
        ...formData,
        loadBoards: [],
        ...(_.size(bols) === 1 && {offerRate: successfulLoads[0].offer_rate})
      })
    }
  }

  const handleLoadsDelete = res => {
    handleBOLLoadsDelete(res.data)
    setFormData({bolIds: formData.bolIds, loadBoards: []})
  }

  const renderSwitchLabel = (loadBoard, isSelected) => {
    const isPostedToLoadBoard = _.includes(loadBoardsPostedTo, loadBoard)
    if (isSelected) {
      return (
        <Fragment>
          {loadBoard}
          {isPostedToLoadBoard && <Fragment> - <b>Will Update Existing Posting</b> <FontAwesomeIcon className="warning-yellow" icon='exclamation' size="lg" /></Fragment>}
          {!isPostedToLoadBoard && <Fragment> - <b>Will Create New Posting</b> <FontAwesomeIcon className="warning-yellow" icon='exclamation' size="lg" /></Fragment>}
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          {loadBoard}
          {isPostedToLoadBoard && <Fragment> - <b>Already Posted</b> <FontAwesomeIcon className="success-green ms-1" icon='check' size="lg" /></Fragment>}
          {!isPostedToLoadBoard && <Fragment> - <b>Unposted</b> <FontAwesomeIcon className="error-red" icon='times' size="lg" /></Fragment>}
        </Fragment>
      )
    }
  }

  return (
    <GenericForm
      formRequest={onlyNewSelected ? restApi.createLoads : restApi.updateLoads}
      params={_.merge({loadIds: loadIds}, formData)}
      handleSuccess={handleLoadsCreateAndUpdate}
      formContent={({handleFormChange, isSubmitting}) => {
        return (
          <Fragment>
            {
              existingAndNewSelected &&
                <Alert variant="danger">Cannot create and edit existing loads at the same time</Alert>
            }
            {
              _.map(ALL_LOAD_BOARDS, loadBoard => {
                const key = `post-to-${loadBoard}-selector`
                const checked = _.includes(formData.loadBoards, loadBoard)
                return <Form.Switch key={key} id={key} label={renderSwitchLabel(loadBoard, checked)} checked={checked} onChange={e => e.target.checked ? setFormData({...formData, loadBoards: _.concat(formData.loadBoards, loadBoard)}) : setFormData({...formData, loadBoards: _.without(formData.loadBoards, loadBoard)})} />
              })
            }
            <div className="mt-3">
              <OfferRateEditor ratesData={ratesData} setRatesData={setRatesData} formData={formData} setFormData={setFormData} bols={bols} editingExistingLoads={!onlyNewSelected} />
            </div>
            <div className="mb-2">
              <h5 className="mb-0"><b>Lane Information</b></h5>
              {
                _.size(bols) === 1 &&
                  <Fragment>
                    <div>
                      <span className="text-primary cursor-pointer" onClick={() => setShowRateLookupModal(true)}>Look Up Lane Rate Data</span>
                    </div>
                    <Modal show={showRateLookupModal} onHide={setShowRateLookupModal} dialogClassName="modal-90w" backdrop='static'>
                      <Modal.Header closeButton />
                      <Modal.Body>
                        <RateLookup initOriginCity={formData.originCity} initOriginState={formData.originState} initOriginPostal={formData.originPostal} initDestinationCity={formData.destinationCity} initDestinationState={formData.destinationState} initDestinationPostal={formData.destinationPostal} initEquipment={bols[formData.bolIds[0]].equipmentType} />
                      </Modal.Body>
                    </Modal>
                  </Fragment>
              }
            </div>
            <Row>
              <Col md={4} xs={12}>
                <FloatingLabel controlId="originCity" label="Origin City">
                  <Form.Control disabled={!onlyNewSelected} type="text" placeholder="Origin City" value={formData.originCity || ''} onChange={e=>handleFormChange(setFormData, e)} />
                </FloatingLabel>
              </Col>
              <Col md={4} xs={12}>
                <FloatingLabel controlId="originState" label="Origin State">
                  <Form.Select disabled={!onlyNewSelected} value={formData.originState} onChange={e=>handleFormChange(setFormData, e)} >
                    <StateOptions />
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="originPostal" label="Origin Postal">
                  <Form.Control disabled={!onlyNewSelected} type="text" placeholder="Origin Postal" value={formData.originPostal || ''} onChange={e=>handleFormChange(setFormData, e)} />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col md={4} xs={12}>
                <FloatingLabel controlId="destinationCity" label="Destination City">
                  <Form.Control disabled={!onlyNewSelected} type="text" placeholder="Destination City" value={formData.destinationCity || ''} onChange={e=>handleFormChange(setFormData, e)} />
                </FloatingLabel>
              </Col>
              <Col md={4} xs={12}>
                <FloatingLabel controlId="destinationState" label="Destination State">
                  <Form.Select disabled={!onlyNewSelected} value={formData.destinationState} onChange={e=>handleFormChange(setFormData, e)} >
                    <StateOptions />
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="destinationPostal" label="Destination Postal">
                  <Form.Control disabled={!onlyNewSelected} type="text" placeholder="Destination Postal" value={formData.destinationPostal || ''} onChange={e=>handleFormChange(setFormData, e)} />
                </FloatingLabel>
              </Col>
            </Row>
            <Button disabled={!canCreateOrUpdate} className="w-100 indigo-button" type="submit">
              {
                isSubmitting ? <Spinner size="sm" animation="border" variant="light" />
                  : onlyNewSelected ? 'Post'
                  : 'Update'
              }
            </Button>
            <Requestable
              withoutLoading
              render={({isLoading, performRequest}) => {
                return (
                  <Button disabled={!canDelete} variant="danger" className="w-100 mt-2" onClick={() => dispatch(setConfirmableAction({performAction: performRequest, action: restApi.deleteLoads({loadIds: loadIds, loadBoards: formData.loadBoards}, handleLoadsDelete), details: {title: 'Confirm Load Posting(s) Deletion', confirmationBody: <div>Please confirm that you wish to remove load posting(s) from the following Load Boards: <b>{_.join(formData.loadBoards, ', ')}</b></div>}}))}>
                    {isLoading ? <Spinner size="sm" animation="border" variant="light" /> : 'Delete'}
                  </Button>
                )
              }}
            />
          </Fragment>
        )
      }}
    />
  )
}

function LoadPostingEditor({initialSelectedPostables, postablesLoadBoardsMap, setShowEditor}) {
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState('allLoadBoards')
  const [bols, setBOLs] = useState({})
  const [ratesData, setRatesData] = useState({})
  const loadIds = _.compact(_.map(bols, (bol, _key) => _.get(bol.load, 'id')))
  const loadBoardsPostedTo = _.size(bols) ? _.get(_.values(bols)[0].load, 'load_boards', []) : _.get(postablesLoadBoardsMap.current[initialSelectedPostables[0]], 'load_boards', [])
  const [loadPostingsSettings, setLoadPostingsSettings] = useState({})

  const companyBOLsLoadSuccess = (res) => {
    setBOLs(prevState => {
      return _.transform(Graphql.getValue(res, 'company.bols'), (result, bolData) => {
        result[bolData.id] = {
          ...Graphql.transformData(bolData),
          ...prevState[bolData.id]
        }
      }, {})
    })
  }

  // Only runs on-mount if operating on a SINGLE BOL
  const handleLoadFetchSuccess = res => {
    const loadData = Graphql.transformData(Graphql.getValue(res, 'load'))
    const [thirdPartyPostings, failedFetches] = loadData.thirdPartyPostings

    if (_.size(failedFetches)) {
      dispatch(setErrors(generateFailedFetchesErrorsContent(failedFetches)))
    }

    setBOLs(prevState => {
      if (_.size(prevState)) {
        return _.transform(prevState, (result, bolData, bolId) => {
          if (_.size(failedFetches)) {
            _.pull(bolData.load.load_boards, ..._.keys(failedFetches))
            if (_.isEmpty(bolData.load.load_boards)) bolData.load.offer_rate = null
          }

          result[bolId] = {
            ...bolData,
            ..._.mapKeys(_.pick(thirdPartyPostings, ALL_LOAD_BOARDS), (_val, key) => `${_.toLower(key)}LoadData`)
          }
        }, {})
      } else {
        return {[initialSelectedPostables[0]]: _.pick(loadData, ALL_LOAD_DATA_KEYS)}
      }
    })
  }

  const handleCompanyLoadPostOptionsFetchSuccess = ({data}) => setLoadPostingsSettings(data.data.company.settings)

  useEffect(() => {
    if (_.size(bols)) {
      _.forIn(bols, (bolData, bolId) => {
        let load = bolData.load
        postablesLoadBoardsMap.current[bolId] = load ? {load_boards: load.load_boards, offer_rate: load.offer_rate, dat_offer_rate: load.dat_offer_rate} : null
      })
      dispatch(rerenderListeningColumns())
    }
  }, [bols]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleBOLLoadsCreateOrUpdate = (responseData, created) => {
    const [successfulLoads, failedLoads] = _.partition(responseData, load => _.isNil(load.errors))

    if (successfulLoads.length) {
      dispatch(appendNotification({type: 'Load', verb: created ? 'posted' : 'updated', pluralize: successfulLoads.length}))
      setBOLs(prevState => {
        const operatingOnSingleBOL = _.size(prevState) === 1

        return {
          ...prevState,
          ..._.transform(successfulLoads, (result, loadData) => {
            result[loadData.bol_id] = {
              ...prevState[loadData.bol_id],
              ...(operatingOnSingleBOL && {
                ..._.pick(prevState[loadData.bol_id], ALL_LOAD_DATA_KEYS),
                ..._.omitBy(_.pick(loadData, ALL_LOAD_DATA_KEYS), _.isNil)
              }),
              load: {
                ...prevState[loadData.bol_id].load,
                id: loadData.id,
                bolId: loadData.bol_id,
                load_boards: loadData.load_boards,
                offer_rate: loadData.offer_rate,
                ..._.pick(loadData, ALL_LOAD_RATE_KEYS)
              }
            }
          }, {})
        }
      })
    }

    if (failedLoads.length) {
      dispatch(setErrors(generatePostActionErrorsContent(failedLoads, bols, 'post to')))
    }

    return [successfulLoads, failedLoads]
  }

  const handleBOLLoadsDelete = (responseData) => {
    let [successfulDeletes, failedDeletes] = _.partition(responseData, load => _.isNil(load.errors))

    if (successfulDeletes.length) {
      dispatch(appendNotification({type: 'Load', verb: 'deleted', pluralize: successfulDeletes.length}))
      successfulDeletes = _.transform(successfulDeletes, (result, load) => result[load.bol_id] = load, {})
      setBOLs(prevState => {
        const operatingOnSingleBOL = _.size(prevState) === 1

        return _.transform(prevState, (result, bolData, bolId) => {
          result[bolId] = {
            ...bolData,
            ...(operatingOnSingleBOL && {
              ..._.pick(bolData, ALL_LOAD_DATA_KEYS),
              ..._.omitBy(_.pick(successfulDeletes[bolId], ALL_LOAD_DATA_KEYS), _.isNil)
            }),
            load: _.get(successfulDeletes[bolId], 'id') ? _.pick(successfulDeletes[bolId], LOAD_FIELDS) : null
          }
        })
      })
    }

    if (failedDeletes.length) {
      dispatch(setErrors(generatePostActionErrorsContent(failedDeletes, bols, 'delete from')))
    }
  }

  return (
    <Requestable
      onMountMultiFetch={[
        graphqlApi.execute(companyBOLsQuery(initialSelectedPostables, _.map(_.difference(ALL_LOAD_BOARDS, loadBoardsPostedTo), loadBoard => `${_.toLower(loadBoard)}_load_data`)), companyBOLsLoadSuccess),
        ...(loadBoardsPostedTo.length && initialSelectedPostables.length === 1 ? [graphqlApi.execute(Graphql.load({filters: {bol_id: initialSelectedPostables[0]}, fields: ['third_party_postings', 'load_boards']}), handleLoadFetchSuccess)] : []),
        graphqlApi.execute(companyLoadPostOptionsQuery, handleCompanyLoadPostOptionsFetchSuccess)
      ]}
      withoutLoading
      render={({isLoading}) => {
        return (
          <div id="load-posting-editor" className="w-100 p-3">
            {
              isLoading ? <Spinner className="d-flex mx-auto" animation="border" variant="secondary" />
              : <Fragment>
                  {setShowEditor && <CloseButton className="float-end" onClick={_ => setShowEditor(false)} />}
                  <h6>
                    <b>Load details for bill of ladings numbered: {_.join(_.map(bols, 'number'), ', ')}</b>
                  </h6>
                  <Tabs activeKey={activeTab} onSelect={key => setActiveTab(key)}>
                    <Tab eventKey='allLoadBoards' title='All Load Boards' className="mt-3">
                      {
                        _.size(bols) &&
                          <MultiLoadBoard bols={bols}
                                          ratesData={ratesData}
                                          setRatesData={setRatesData}
                                          loadIds={loadIds}
                                          loadBoardsPostedTo={loadBoardsPostedTo}
                                          handleBOLLoadsCreateOrUpdate={handleBOLLoadsCreateOrUpdate}
                                          handleBOLLoadsDelete={handleBOLLoadsDelete} />
                      }
                    </Tab>
                    <Tab eventKey='dat' title='DAT' className="mt-3">
                      {
                        _.size(bols) &&
                          <DATLoad bols={bols}
                                   editingExistingLoads={_.includes(loadBoardsPostedTo, 'DAT')}
                                   handleBOLLoadsCreateOrUpdate={handleBOLLoadsCreateOrUpdate}
                                   handleBOLLoadsDelete={handleBOLLoadsDelete}
                                   loadIds={loadIds}
                                   loadPostingsSettings={loadPostingsSettings}
                                   ratesData={ratesData}
                                   setActiveTab={setActiveTab}
                                   setRatesData={setRatesData} />
                      }
                    </Tab>
                    <Tab eventKey='truckstop' title='Truckstop' className="mt-3">
                      Coming Soon
                    </Tab>
                  </Tabs>
              </Fragment>
            }
          </div>
        )
      }}
    />
  )
}

export default LoadPostingEditor
