import _ from 'lodash'
import deepEqual from 'deep-equal'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import Requestable from '../generic/Requestable'
import { setErrors, clearErrors } from '../errorHandling/errorsSlice'

export const handleFormChange = (setFormState, e) => {
  let { id, value } = e.target

  value = e.target.type === 'checkbox' ? e.target.checked
        : e.target.type === 'file' ? e.target.files[0]
        : value

  setFormState(prevFormState => {
    return {
      ...prevFormState,
      [id]: value
    }
  })
}

function GenericForm({className, formContent, formRequest, formRequests, handleSuccess, id, params, withDirtyTracking}) {
  const dispatch = useDispatch()
  const [validated, setValidated] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [initialFormData, setInitialFormData] = useState({})
  const formRef = useRef()

  useEffect(() => {
    setInitialFormData(params)

    return () => {
      dispatch(clearErrors())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleFormSubmission = (res) => {
    setIsSubmitting(false)
    _.isEmpty(res.data.errors) ? handleSuccess(res) : dispatch(setErrors(res.data.errors))
  }

  const submitForm = (performRequest) => {
    return e => {
      e && e.preventDefault()

      if (isSubmitting) return // Prevent duplicate form submissions

      if (formRef.current.checkValidity()) {
        setIsSubmitting(true)
        if (validated) setValidated(false)
        dispatch(clearErrors())

        if (_.isUndefined(formRequests)) {
          performRequest(formRequest(params, handleFormSubmission))
        } else {
          const action = _.get(e, 'nativeEvent.submitter.dataset', e.target.dataset).action
          performRequest(formRequests[action](params, handleFormSubmission))
        }
      } else {
        setValidated(true)
      }
    }
  }

  const resetToInitialFormData = (resetCallback, args) => {
    resetCallback(initialFormData, ...(args || []))
  }

  const handleFormSearchField = useCallback((setFormState, controlId, relevantHitAttribute) => {
    return (hitAttributes, e) => {
      setFormState(prevFormState => {
        return {
          ...prevFormState,
          [controlId]: _.get(hitAttributes, relevantHitAttribute, '')
        }
      })
    }
  }, [])

  return (
    <Requestable
      withoutLoading
      render={({performRequest}) => {
        return (
          <Form id={id} ref={formRef} className={`gen-form ${className || ''}`} noValidate validated={validated} onSubmit={submitForm(performRequest)}>
            {
              formContent({
                handleFormChange: handleFormChange,
                handleFormSearchField: handleFormSearchField,
                isDirty: withDirtyTracking ? !deepEqual(params, initialFormData) : true,
                isSubmitting: isSubmitting,
                resetToInitialFormData: resetToInitialFormData,
                submitForm: submitForm(performRequest),
                validated: validated
              })
            }
          </Form>
        )
      }}
    />
  )
}

export default GenericForm
