import _ from 'lodash'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function InternalServerError(props) {
  const currentUser = useSelector(state => state.user.currentUser)

  return (
    <Container>
      <Row className="mt-4">
        <Col className="d-flex justify-content-center">
          <div>
            <div className="d-flex justify-content-center">
              <FontAwesomeIcon icon='exclamation-triangle' size='6x' className="mb-3" />
            </div>
            <h4>Uh-oh! We had an issue with that request.</h4>
          </div>
        </Col>
      </Row>
      <div className="text-muted d-flex justify-content-center">
        <Link to={`/company/${_.get(currentUser, 'last_used_company_id')}/dashboard`} className="text-muted">Return to Dashboard</Link>
        <span className="mx-2">—</span>
        <span className="text-muted cursor-pointer text-decoration-underline">Contact Support</span>
      </div>
    </Container>
  )
}

export default InternalServerError
