import _ from 'lodash'
import React, { Fragment, useMemo, useRef, useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'

import CardsList from '../generic/CardsList'
import GenericForm from '../forms/GenericForm'
import Graphql from '../Graphql'
import { graphqlApi } from '../Api'
import InteractableTable from '../generic/InteractableTable'
import NewUser from '../users/NewUser'
import RefinableList from '../generic/RefinableList'
import RefinableListSettingsModal from '../generic/RefinableListSettingsModal'
import Requestable from '../generic/Requestable'
import { restApi } from '../Api'
import UserColumns from './UserRefinableColumns'
import useScreenWidth from '../utils/useScreenWidth'
import { MINIMUM_SCREEN_WIDTH_FOR_TABLE_VIEW } from '../generic/interactableTableConstraints'

function UserStatusDisplayer ({currentUserId, user, userStatusMap}) {
  const [counter, setCounter] = useState(1) // Only used to force re-render

  const isActive = user.id in userStatusMap ? userStatusMap[user.id] : user.isActive
  const isCurrentUSer = parseInt(currentUserId) === user.id

  const handleStatusChange = (newStatus) => {
    userStatusMap[user.id] = newStatus
    setCounter(counter + 1)
  }

  const activateUserButton = ({isSubmitting}) => {
    return (
      <Button disabled={isCurrentUSer} style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}} type="submit" size="sm" variant={isActive ? "success" : "secondary"}>
        {
          isSubmitting ? <Spinner size="sm" animation="border" variant="light" className="mx-2" />
            : isActive ? 'Active' : 'Activate'
        }
      </Button>
    )
  }

  const deactivateUserButton = ({isSubmitting}) => {
    return (
      <Button disabled={isCurrentUSer} style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}} type="submit" size="sm" variant={!isActive ? "danger" : "secondary"}>
        {
          isSubmitting ? <Spinner size="sm" animation="border" variant="light" className="mx-3" />
            : !isActive ? 'Inactive' : 'Deactivate'
        }
      </Button>
    )
  }

  return (
    <Fragment>
      <div className="d-inline-flex ms-0">
        <GenericForm formContent={activateUserButton} formRequest={isCurrentUSer ? null : restApi.toggleUserStatus} params={{'id': user.id, isActive: true}} handleSuccess={_ => handleStatusChange(true)} />
        <GenericForm formContent={deactivateUserButton} formRequest={isCurrentUSer ? null : restApi.toggleUserStatus} params={{'id': user.id, isActive: false}} handleSuccess={_ => handleStatusChange(false)} />
      </div>
    </Fragment>
  )
}

const userFields = ['id', 'first_name', 'last_name', 'email', 'date_joined', 'last_login', 'is_active']

function Users({companyId}) {
  const [filter, setFilter] = useState('')
  const [users, setUsers] = useState([])
  const [showNewUserModal, setShowNewUserModal]= useState(false)
  const currentUser = useSelector(state => state.user.currentUser)
  const preferencedColumns  = _.get(currentUser, 'settings.usersColumns', [])
  const userStatusMap = useRef({})
  const screenWidth = useScreenWidth()

  const filteredUsers = useMemo(() => {
    return _.isEmpty(filter) ? users : _.filter(users, user => _.includes(_.lowerCase(`${user.firstName} ${user.lastName}`), _.lowerCase(filter)))
  }, [users.length, filter]) // eslint-disable-line react-hooks/exhaustive-deps

  const selectableColumns = {
    name: UserColumns.name,
    email: UserColumns.email,
    lastLogin: UserColumns.lastLogin,
    dateJoined: UserColumns.dateJoined,
    status: {
      name: 'Status',
      accessor: tableItem => {
        return tableItem.id in userStatusMap.current ? userStatusMap.current[tableItem.id] : tableItem.isActive
      },
      sortable: true,
      filterable: true,
      staticFilters: ['Active', 'Inactive'],
      filterer: isActive => isActive ? 'Active' : 'Inactive',
      displayer: tableItem => <UserStatusDisplayer key={tableItem.id} currentUserId={currentUser.id} user={tableItem} userStatusMap={userStatusMap.current} />,
    },
  }

  const companyUsersQuery = Graphql.company({
                              fields: [
                                'id',
                                Graphql.relatedUsers({
                                  fields: userFields
                                })
                              ]
                            })

  const companyUsersLoadSuccess = (res) => {
    setUsers(
      _.map(Graphql.getValue(res, 'company.users'), (users) => {
        return Graphql.transformData(users)
      })
    )
  }

  const renderTable = () => {
    return (
      <RefinableList
        Renderer={InteractableTable}
        initialSelectedColumns={_.values(_.pick(selectableColumns, preferencedColumns))}
        initialSortedColumn="Name"
        selectableColumns={_.values(selectableColumns)}
        draggableType="userColumn"
        items={filteredUsers}
        settings={<RefinableListSettingsModal selectableColumns={selectableColumns} userPreferencedColumns={preferencedColumns} refinableListSettingsName='usersColumns' />}
        pageSize={25}
      />
    )
  }

  const renderCards = () => {
    return (
      <RefinableList
        Renderer={CardsList}
        cardHeaderColumn={selectableColumns.name}
        initialSelectedColumns={_.values(_.pick(selectableColumns, _.without(preferencedColumns, 'name')))}
        initialSortedColumn="Name"
        selectableColumns={_.values(selectableColumns)}
        items={filteredUsers}
        settings={<RefinableListSettingsModal selectableColumns={selectableColumns} userPreferencedColumns={preferencedColumns} refinableListSettingsName='usersColumns' />}
        pageSize={10}
      />
    )
  }

  return (
    <Requestable
      onMountFetch={graphqlApi.execute(companyUsersQuery, companyUsersLoadSuccess)}
      render={() => {
          if (preferencedColumns.length > 0) {
            return (
              <Fragment>
                <h4><b>Users</b></h4>
                <Row className="mt-2 mb-3">
                  <Col xl={4} xs={12}>
                    <Form.Control
                      type="search"
                      autoComplete="off"
                      placeholder="Filter by name"
                      onChange={(e) => setFilter(e.target.value)}
                      value={filter}
                    />
                  </Col>
                  <Col xl={2} xs={12}>
                    <Button className="indigo-button w-100 mt-xl-0 mt-2" onClick={_ => setShowNewUserModal(true)}>
                      <FontAwesomeIcon className="plus me-2" icon='plus' />
                      Create New User
                    </Button>
                    <NewUser isShowing={showNewUserModal} handleClose={setShowNewUserModal} setUsers={setUsers} />
                  </Col>
                </Row>
                {screenWidth > MINIMUM_SCREEN_WIDTH_FOR_TABLE_VIEW ? renderTable() : renderCards()}
              </Fragment>
            )
          }
        }
      }
    />
  )
}

export default Users
