import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'

function BOLCreated({companyId, number, handleDismissal}) {
  return (
    <div className="pt-3">
      <div className="d-flex justify-content-center">
        <FontAwesomeIcon className="success-green success-check-circle" icon='check-circle' size='2x' />
      </div>
      <div className="d-flex justify-content-center text-center fw-bold mt-1">
        Created BOL #{number}
      </div>
      <div className="d-flex justify-content-center text-center fw-bold">
        <Link to={`/company/${companyId}/shipments/edit-bol/${number}`} onClick={()=>handleDismissal()}>
          Edit this BOL
        </Link>
      </div>
    </div>
  )
}

export default BOLCreated
