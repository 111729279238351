import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'

function OriginAction({companyId, originId, verb, handleDismissal}) {
  return (
    <div className="pt-3">
      <div className="d-flex justify-content-center">
        <FontAwesomeIcon className="success-green success-check-circle" icon='check-circle' size='2x' />
      </div>
      <div className="d-flex justify-content-center text-center fw-bold mt-1">
        Successfully {verb} origin
      </div>
      <div className="d-flex justify-content-center text-center fw-bold">
        <Link to={`/company/${companyId}/locations/origins/edit/${originId}`} onClick={()=>handleDismissal()}>
          View this origin
        </Link>
      </div>
    </div>
  )
}

export default OriginAction
